import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import EasyForm from 'components/Form/EasyForm'
import { notEmptyValidation } from 'util/forms'
import { safeParseFloat } from 'util/parsing'

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const BackLink = styled.div``

export default function AddNewRoom({ basePath, onSubmit }) {
  return (
    <Wrapper>
      <BackLink>
        <Link to={basePath}>
          <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
        </Link>
      </BackLink>
      <h2>Lag nytt rom</h2>
      <EasyForm
        onSubmit={data => {
          onSubmit({
            name: data.name,
            floor: data.floor,
            area: safeParseFloat(data.area),
            comment: data.comment,
            articles: [],
            freeArticles: [],
          })
        }}
        fields={[
          {
            name: 'name',
            label: 'Navn',
            type: 'text',
            validate: notEmptyValidation,
            errorMessage: 'Må fylle inn navn',
            extra: { props: { width: '100%', autoFocus: true } },
          },
          {
            name: 'floor',
            label: 'Etasje',
            type: 'number',
            errorMessage: 'Må være større eller lik 0',
            validate: val => val >= 0,
            extra: { props: { width: '100%' } },
          },
          {
            name: 'area',
            label: 'Areal',
            type: 'text',
            errorMessage: 'Må være et desimaltall',
            validate: val => val && !isNaN(safeParseFloat(val)),
            extra: { props: { width: '100%' } },
          },
        ]}
        submitButtonText="Lag rom"
        submitButtonProps={{ width: '100%' }}
      />
    </Wrapper>
  )
}

AddNewRoom.defaultProps = {
  basePath: '/offers/create',
}
