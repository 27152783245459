import React from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'

type LabelPosition = 'left' | 'right' | 'above' | 'below'

interface WrapperProps {
  labelPosition: LabelPosition
  filterLabel?: Boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => {
    switch (props.labelPosition) {
      case 'above':
        return 'flex-direction: column;'
      case 'below':
        return 'flex-direction: column-reverse;'
      case 'left':
        return 'flex-direction: row;'
      case 'right':
        return 'flex-direction: row-reverse;'
    }
  }}

  ${props =>
    props.filterLabel &&
    css`
      justify-content: left;
      width: 100%;
    `};

  label {
    color: ${props => props.theme.colors.gray};
  }
`
interface CheckMarkProps {
  checked: boolean
  indeterminate: boolean
  filterLabel: boolean
}
const CheckMark = styled.div<CheckMarkProps>`
  position: relative;
  width: 22px;
  height: 22px;
  margin: 4px;

  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 6px;
  background-color: ${props => getCheckMarkBackgroundColor(props)};

  ${props =>
    props.filterLabel &&
    css`
      border: 1px solid ${props => props.theme.colors.purple};
      margin: 0.4rem 0;
    `};

  &::before {
    content: ${props =>
      !props.checked ? '' : props.indeterminate ? "'\f068'" : "'\f00c'"};
    font-family: 'Font Awesome 5 Pro';
    font-size: 0.7rem;
    color: white;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

interface StyledLabelProps {
  filterLabel?: boolean
}

const StyledLabel = styled.label<StyledLabelProps>`
  margin-left: ${props => (props.filterLabel ? '5%' : 0)};
`

interface CheckboxProps {
  className?: string
  value: boolean

  indeterminate?: boolean
  label?: string
  labelPosition?: LabelPosition

  filterLabel?: boolean

  onChange: (checked: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,

  value,

  indeterminate = false,
  label,
  labelPosition = 'right',

  filterLabel = false,

  onChange,
}) => {
  return (
    <Wrapper
      className={className}
      labelPosition={labelPosition}
      onClick={() => onChange(!value)}
      filterLabel={filterLabel}
    >
      {label && <StyledLabel filterLabel={filterLabel}>{label}</StyledLabel>}
      <CheckMark
        filterLabel={filterLabel}
        checked={value}
        indeterminate={indeterminate}
      />
    </Wrapper>
  )
}

function getCheckMarkBackgroundColor(props: CheckMarkProps) {
  if (!props.checked) {
    return theme.colors.white
  } else if (props.filterLabel) {
    return theme.colors.purple
  } else {
    return theme.colors.primary
  }
}

export default Checkbox
