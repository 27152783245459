import React from 'react'

import { Link } from 'react-router-dom'

import Card from 'components/Card'
import { Text } from 'components/Text'
import Margin from 'components/Margin'
import Flex from 'components/Flex'

export default function HardbookFolderCard({ folder }) {
  return (
    <Link to={`/handbook/folder/${folder.slug}`}>
      <Card hoverEffect>
        <Flex alignItems="center">
          <Margin right="2em">
            <Text fontSize="26px" color="gray">
              <i className="fal fa-folder"></i>
            </Text>
          </Margin>
          <div>
            <div>
              <Text fontSize="18px" color="primary">
                {folder.name}
              </Text>
            </div>
            <Margin top="0.5rem">
              <Text fontSize="14px" color="lightGray">
                {folder.description}
              </Text>
            </Margin>
          </div>
        </Flex>
      </Card>
    </Link>
  )
}
