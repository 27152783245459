import { Icon } from '@ur/react-components'
import { QuoTable } from 'components/QuoTable'
import styled from 'styled-components'

export const ViewWrapper = styled.div<{ maxWidth?: string }>`
  ${props => props.theme.defaultContentWrapper};
  max-width: ${props => props.maxWidth ?? '768px'};
  padding-bottom: 32px;
`

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
/* Table components */
export const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`

/* Modal components */
export const ModalWrapper = styled.div<{ height?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 416px;
  height: ${props => props.height ?? 'auto'};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: ${props => props.theme.colors.white};
  padding: 32px;

  ${props => props.theme.media.mobile} {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
`

export const ModalTitle = styled.h2`
  margin: 0;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  ${props => props.theme.media.mobile} {
    font-size: 24px;
    margin: 0;
  }
`

export const ModalBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseModalIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 16px;
  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`
/* Form components */
export interface FieldProps {
  span?: string
}
export const Field = styled.div<FieldProps>`
  ${props => props.span && `grid-column: span ${props.span};`}
  margin-bottom: 20px;
  & > div {
    margin-top: 5px;
  }
`
export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${props => props.theme.colors.darkBlue};
`

export const FileInputWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`
