import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/Button'
import { Icon } from '@ur/react-components'
import { useGlobal, useForm } from '@ur/react-hooks'
import { Input } from 'components/Input'
import { notEmptyValidation, stringContainsOnlyNumbers } from 'util/helpers'
import { WizardRoom } from 'modules/offers/OfferWizard/types'

const Wrapper = styled.div`
  width: 400px;
  height: 350px;
  display: grid;
  padding: 5px 0 10px;
  grid-template-areas:
    'header'
    'body'
    'footer';

  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const HeaderWrapper = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
`

const BodyWrapper = styled.div`
  grid-area: body;
  width: 100%;
  padding: 0 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const FormWrapper = styled.div`
  grid-area: form;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  > :nth-child(4) {
    margin: 15px 0px;
  }

  > :last-child {
    margin-bottom: 15px;
  }
`
const FooterWrapper = styled.div`
  grid-area: footer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

interface EditOfferRoomModalProps {
  closeModalCallback: () => void
  roomData: WizardRoom
}

const StyledInput = styled(Input)`
  input {
    background-color: ${props => props.theme.colors.white};
  }
`

interface EditRoomForm {
  name: string
  floor: string
  area: string
  comment: string
}

export const EditOfferRoomModal: React.FC<EditOfferRoomModalProps> = ({
  closeModalCallback,
  roomData,
}) => {
  const [offerRooms, setOfferRooms] = useGlobal('offer.rooms')

  const {
    formValues: form,
    formErrors: errors,
    updateForm,
    submitHandler,
  } = useForm<EditRoomForm>({
    values: {
      name: roomData.name,
      floor: `${roomData.floor}`,
      area: `${roomData.area}`,
      comment: roomData.comment,
    },
    validators: {
      name: name => (notEmptyValidation(name) ? null : 'Kan ikke være tom'),
      floor: floor => {
        if (!notEmptyValidation(floor)) {
          return 'Kan ikke være tom'
        } else if (!stringContainsOnlyNumbers(floor)) {
          return 'Må være et tall'
        } else return null
      },
      area: area => {
        if (!notEmptyValidation(area)) {
          return 'Kan ikke være tom'
        } else if (!stringContainsOnlyNumbers(area)) {
          return 'Må være et tall'
        } else return null
      },
    },
  })

  const sanitizeForm = () => {
    return {
      name: form.name,
      floor: parseInt(form.floor),
      area: parseInt(form.area),
      comment: form.comment,
    }
  }

  const handleEditRoom = () => {
    const roomIndex = offerRooms.findIndex(
      room => room.localID === roomData.localID
    )
    const updatedRoom = {
      ...roomData,
      ...sanitizeForm(),
    } as WizardRoom
    const updatedRooms = [...offerRooms]
    updatedRooms[roomIndex] = updatedRoom
    setOfferRooms(updatedRooms)
    closeModalCallback()
  }
  return (
    <Wrapper>
      <HeaderWrapper>
        <Icon icon="times" size="26px" onClick={closeModalCallback} />
      </HeaderWrapper>
      <BodyWrapper>
        <FormWrapper>
          <StyledInput
            error={errors.name}
            fullWidth
            label="Navn"
            value={form.name}
            type="text"
            onChange={name => updateForm({ name })}
          />
          <StyledInput
            error={errors.floor}
            fullWidth
            label="Etasje"
            value={form.floor}
            type="number"
            onChange={floor => updateForm({ floor })}
          />
          <StyledInput
            error={errors.area}
            fullWidth
            label="Areal"
            value={form.area}
            type="number"
            onChange={area => updateForm({ area })}
          />
          <StyledInput
            error={errors.comment}
            fullWidth
            label="Kommentar"
            value={form.comment}
            type="text"
            onChange={comment => updateForm({ comment })}
          />
        </FormWrapper>
      </BodyWrapper>
      <FooterWrapper>
        <Button buttonStyle="cancel" onClick={closeModalCallback}>
          Gå tilbake
        </Button>
        <Button buttonStyle="primary" onClick={submitHandler(handleEditRoom)}>
          Endre rom
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}
