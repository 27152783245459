import styled from 'styled-components'

interface ThumbnailProps {
  size?: number
}
const Thumbnail = styled.img<ThumbnailProps>`
  border-radius: 50%;
  width: ${props => props.size ?? 35}px;
  height: ${props => props.size ?? 35}px;
  background: ${props => props.theme.colors.lightGray};
`

export default Thumbnail
