import React, { useState } from 'react'
import { LanguageContext } from 'context/LanguageContext'
import { IntlProvider } from 'react-intl'
import messages from 'locale/messages'
import { setPreferredLanguage, getPreferredLanguage } from 'locale/util'
import { UseTranslateProvider } from '@ur/react-hooks'

interface IntlContextProviderProps {}

const IntlContextProvider: React.FC<IntlContextProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState(() => getPreferredLanguage())

  // Check that the language is valid. If not, fallback to english
  let finalMessages = messages[language]
  if (!finalMessages) {
    finalMessages = messages['en']
    setLanguage('en')
  }

  function handleSetLanguage(language: string) {
    setPreferredLanguage(language)
    setLanguage(language)
  }

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      <UseTranslateProvider locale={language} messages={finalMessages}>
        <IntlProvider locale={language} messages={finalMessages}>
          {children}
        </IntlProvider>
      </UseTranslateProvider>
    </LanguageContext.Provider>
  )
}

export default IntlContextProvider
