import { useQuery } from '@apollo/client'
import CustomerInfoCard from 'modules/offers/Offer/CustomerInfoCard'

import React from 'react'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import styled from 'styled-components'
import {
  HeaderWrapper,
  OrderInfoCard,
  OrderItemsTable,
  Title,
  ViewWrapper,
} from '../components'
import { ORDER_QUERY } from '../queries'
import { OrderQuery, OrderQueryVariables } from '../types.graphql'

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;

  ${props => props.theme.media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledCustomerInfoCard = styled(CustomerInfoCard)`
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
`

interface OrderProps {}

export const Order: React.UFC<OrderProps> = () => {
  const match = useRouteMatch<{ id: string }>()
  const orderId = match.params.id
  const [{ setOverride }] = BreadcrumbsGraph.useGraph()

  const { data } = useQuery<OrderQuery, OrderQueryVariables>(ORDER_QUERY, {
    variables: {
      uniqueId: orderId,
    },
    onCompleted({ order }) {
      if (!order) return
      setOverride(order.uniqueId, order.orderNumber.toString())
    },
  })

  const order = useMemo(() => {
    return data?.order ?? null
  }, [data])

  const items = useMemo(() => {
    return order?.items.edges.map(edge => edge.node) ?? []
  }, [order])

  if (!order) return null

  return (
    <ViewWrapper maxWidth="1000px">
      <HeaderWrapper>
        <Title>Ordre #{order.orderNumber}</Title>
      </HeaderWrapper>
      <InfoWrapper>
        <StyledCustomerInfoCard customer={order.customer} />
        <OrderInfoCard order={order} />
      </InfoWrapper>
      <OrderItemsTable orderItems={items} />
    </ViewWrapper>
  )
}
