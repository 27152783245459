import { gql } from '@apollo/client'

export const CustomerFragments = {
  customerInfo: gql`
    fragment CustomerInfo on CustomerNode {
      id
      name
      phoneNumber
      email
      address
      postalCode
      city
      type
      organizationNumber
    }
  `,
}

export const ALL_CUSTOMERS_QUERY = gql`
  query AllCustomers(
    $q: String
    $first: Int
    $type: String
    $typeIn: [String]
  ) {
    allCustomers(q: $q, first: $first, type: $type, typeIn: $typeIn) {
      edges {
        node {
          ...CustomerInfo
        }
      }
    }
  }
  ${CustomerFragments.customerInfo}
`
export const ALL_CUSTOMERS_WITH_PHONELOOKUP_QUERY = gql`
  query AllCustomersWithPhoneLookup(
    $q: String
    $first: Int
    $type: String
    $typeIn: [String]
  ) {
    allCustomersWithPhoneLookup(
      q: $q
      first: $first
      type: $type
      typeIn: $typeIn
    ) {
      edges {
        node {
          ...CustomerInfo
        }
      }
    }
  }
  ${CustomerFragments.customerInfo}
`

export const CUSTOMER_QUERY = gql`
  query Customer($id: ID!) {
    customer(id: $id) {
      ...CustomerInfo
    }
  }
  ${CustomerFragments.customerInfo}
`

export const ALL_CUSTOMERS_SHALLOW_QUERY = gql`
  query AllCustomersShallow($q: String, $first: Int) {
    allCustomers(q: $q, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
