import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { notEmptyValidation } from 'util/helpers'
import { useServiceOptionMutations } from '../mutations.hooks'
import { ServiceOptionForm } from '../types'
import { ServiceOptionNode } from '../types.graphql'
import {
  CloseModalIcon,
  Field,
  Label,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ModalWrapper,
} from './common'

interface OptionFormModalProps {
  option?: ServiceOptionNode
  serviceId?: string
  closeModalCallback: () => void
}

export const OptionFormModal: React.UFC<OptionFormModalProps> = ({
  option,
  serviceId,
  closeModalCallback,
}) => {
  const { serviceOption: optionMutation } = useServiceOptionMutations()

  const {
    formValues: form,
    formErrors,
    formChangeHandler: handler,
    formValid,
    submitHandler,
  } = useForm<ServiceOptionForm>({
    values: {
      name: option?.name ?? '',
      description: option?.description ?? '',
    },
    validators: {
      name: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
    },
  })

  const submitForm = async (values: ServiceOptionForm) => {
    if (!option) {
      await optionMutation.create({
        variables: {
          input: {
            ...values,
            service: serviceId,
          },
        },
      })
    } else {
      await optionMutation.patch({
        variables: {
          id: option.id,
          input: values,
        },
      })
    }
    closeModalCallback()
  }

  return (
    <ModalWrapper>
      <ModalTitle>
        {option ? 'Endre valgkategori' : 'Ny valgkategori'}
      </ModalTitle>
      <CloseModalIcon
        icon="times"
        size="24px"
        type="light"
        color="darkblue"
        margin="14px 0 0 0"
        onClick={closeModalCallback}
      />
      <ModalBody>
        <Field>
          <Label>Navn</Label>
          <Input
            value={form.name}
            error={formErrors.name}
            fullWidth
            onChange={handler('name')}
          />
        </Field>
        <Field>
          <Label>Beskrivelse</Label>
          <Input
            value={form.description}
            error={formErrors.description}
            fullWidth
            onChange={handler('description')}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button
          fullWidth
          primary
          height="48px"
          disabled={!formValid}
          onClick={submitHandler(submitForm)}
        >
          {option ? 'Lagre' : 'Opprett'}
        </Button>
      </ModalFooter>
    </ModalWrapper>
  )
}
