import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Title from 'components/Title'
import { CREATE_CUSTOMER_MUTATION } from './mutations'
import Input from 'components-old/Input'
import { useForm } from '@ur/react-hooks'
import Label from 'components/Label'
import {
  CreateCustomerMutationParameters,
  CreateCustomerMutationReturn,
  CustomerNode,
} from 'types/graphql/customers'
import { useDebounce } from 'util/hooks'
import { isUndefined } from 'util/typechecks'
import Loader from 'components/Loader'
import { Button } from 'components/Button'
import Margin from 'components/Margin'
import { useToast } from '@ur/react-components'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
  display: flex;
  align-items: center;
  flex-direction: column;
`
const FormWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  width: 400px;
`

const PhoneNumberWrapper = styled.div`
  position: relative;
  .loader {
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translate(100%, -50%);
  }
`

interface PhoneNumberAPIItem {
  address?: {
    postArea: string
    postCode: string
    streetName: string
    streetNumber: string
  }[]
  name?: string
}

interface PhoneNumberAPIResponse {
  count?: number
  hits?: number
  querySearch?: string
  items?: PhoneNumberAPIItem[]
}

interface CreateCustomerProps {
  onCreated: (customer: CustomerNode) => void
}

export const CreateCustomer: React.UFC<CreateCustomerProps> = ({
  onCreated,
}) => {
  const location = useLocation<{ returnTo?: string }>()
  const toasts = useToast()

  const prevPhoneNumberAttempt = useRef('')
  const emailInputRef = useRef<HTMLInputElement | null>(null)

  const [personInfoLoading, setPersonInfoLoading] = useState(false)

  const {
    formValues: form,
    updateForm,
    formErrors: errors,
  } = useForm({
    values: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
    },
    validators: {
      name: val => (!!val ? null : 'Kunden trenger et navn'),
    },
  })

  const returnTo = location.state?.returnTo ?? '/customers'

  const updateValuesFromAPI = useCallback(
    async (phoneNumber: string) => {
      let data: PhoneNumberAPIResponse
      try {
        setPersonInfoLoading(true)

        const linkAPI = `https://www.gulesider.no/api/ps?query=${phoneNumber}&sortOrder=default&profile=no&page=1&lat=0&lng=0&limit=25&client=true`
        const response = await fetch(linkAPI)
        data = await response.json()
      } catch {
        return
      } finally {
        setPersonInfoLoading(false)
      }

      if (!data.hits || !data.items || data.items.length === 0) return

      const item = data.items[0]
      const address = item.address?.[0]
      const hits = {
        name: item.name ?? form.name,
        address: isUndefined(address)
          ? form.address
          : `${address.streetName} ${address.streetNumber}`,
        postalCode: address?.postCode ?? form.postalCode,
        city: address?.postArea ?? form.city,
      }

      updateForm(hits)

      if (!emailInputRef.current) return
      emailInputRef.current.focus()
    },
    [form.address, form.city, form.name, form.postalCode, updateForm]
  )

  const debouncedPhoneNumber = useDebounce(form.phoneNumber)
  useEffect(() => {
    if (
      debouncedPhoneNumber.length < 8 ||
      prevPhoneNumberAttempt.current === debouncedPhoneNumber
    )
      return
    prevPhoneNumberAttempt.current = debouncedPhoneNumber
    updateValuesFromAPI(debouncedPhoneNumber)
  }, [debouncedPhoneNumber, updateValuesFromAPI])

  const [createCustomerMutation] = useMutation<
    CreateCustomerMutationReturn,
    CreateCustomerMutationParameters
  >(CREATE_CUSTOMER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllCustomers'],
    onCompleted({ createCustomer: { customer } }) {
      toasts('success', `Kunden: ${customer.name} ble opprettet`)
      onCreated(customer)
    },
    onError(err) {
      toasts('error', 'Error - kunden ble ikke opprettet.')
      console.error(err)
    },
  })

  const onSubmit = useCallback(() => {
    createCustomerMutation({
      variables: {
        input: {
          name: form.name,
          address: form.address,
          email: form.email,
          phoneNumber: form.phoneNumber,
          postalCode: form.postalCode,
          city: form.city,
        },
      },
    })
  }, [
    form.name,
    form.address,
    form.email,
    form.phoneNumber,
    form.postalCode,
    form.city,
    createCustomerMutation,
  ])

  return (
    <Wrapper>
      <div>
        <div>
          <Link to={returnTo}>
            <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
          </Link>
        </div>
        <Title.H2>Opprett kunde</Title.H2>

        <FormWrapper>
          <div>
            <Label htmlFor="phone-number">Telefonnummer</Label>
            <PhoneNumberWrapper>
              <Input
                id="phone-number"
                value={form.phoneNumber}
                error={errors.phoneNumber}
                inputMode="tel"
                width="400px"
                onChange={evt => updateForm({ phoneNumber: evt.target.value })}
              />
              {personInfoLoading && (
                <div className="loader">
                  <Loader />
                </div>
              )}
            </PhoneNumberWrapper>
          </div>

          <div>
            <Label htmlFor="name">Navn</Label>
            <Input
              id="name"
              value={form.name}
              error={errors.name}
              width="400px"
              onChange={evt => updateForm({ name: evt.target.value })}
            />
          </div>
          <div>
            <Label htmlFor="e-mail">E-post</Label>
            <Input
              ref={emailInputRef}
              id="e-mail"
              value={form.email}
              error={errors.email}
              inputMode="email"
              width="400px"
              onChange={evt => updateForm({ email: evt.target.value })}
            />
          </div>

          <div>
            <Label htmlFor="address">Adresse</Label>
            <Input
              id="address"
              value={form.address}
              error={errors.address}
              width="400px"
              onChange={evt => updateForm({ address: evt.target.value })}
            />
          </div>

          <div>
            <Label htmlFor="zip">Postnummer</Label>
            <Input
              id="zip"
              value={form.postalCode}
              error={errors.postalCode}
              width="400px"
              onChange={evt => updateForm({ postalCode: evt.target.value })}
            />
          </div>

          <div>
            <Label htmlFor="city">Poststed</Label>
            <Input
              id="city"
              value={form.city}
              error={errors.city}
              width="400px"
              onChange={evt => updateForm({ city: evt.target.value })}
            />
          </div>
        </FormWrapper>
        <Margin top="20px">
          <Link to={returnTo}>
            <Button primary fullWidth onClick={onSubmit}>
              Opprett
            </Button>
          </Link>
        </Margin>
      </div>
    </Wrapper>
  )
}
