import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useContext, useState } from 'react'
import { NoFunctions } from 'types/util'

type ModalSectionFn = (args: { closeModal: () => void }) => void
export interface Modal {
  id: string
  title?: string
  header?: string | ModalSectionFn
  body: string | ModalSectionFn
  footer?: string | ModalSectionFn
  onClose?: () => void
}

type AddModalFn = (modal: Omit<Modal, 'id'>) => void
interface ModalStoreData {
  modals: Modal[]
  addModal: AddModalFn
  removeModal: (id: string) => void
}
const ModalContext = React.createContext<ModalStoreData | null>(null)

function useModals(): ModalStoreData
function useModals<T extends keyof ModalStoreData>(value?: T): ModalStoreData[T]
function useModals<T extends keyof ModalStoreData>(value?: T) {
  const ctx = useContext(ModalContext)
  if (ctx === null)
    throw new Error('useModals can only be used within a ModalProvider.')

  if (typeof value === 'undefined') return ctx

  return ctx[value]
}
export { useModals }

export type InitialModalState = Partial<NoFunctions<ModalStoreData>>

function useModalState(initialState: InitialModalState = {}): ModalStoreData {
  const [modals, setModals] = useState(initialState.modals ?? [])

  const removeModal = useCallback((id: string) => {
    setModals(ms => ms.filter(m => m.id !== id))
  }, [])

  const addModal = useCallback<AddModalFn>(
    modal => {
      const newId = uniqueId('modal')
      setModals(m =>
        m.concat({
          id: newId,
          onClose: () => removeModal(newId),
          ...modal,
        })
      )
    },
    [removeModal]
  )

  return {
    modals,
    addModal,
    removeModal,
  }
}

const ModalProvider: React.FC<{
  initialState?: InitialModalState
}> = ({ children, initialState = {} }) => {
  const state = useModalState(initialState)

  return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
}

export default ModalProvider
