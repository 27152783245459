export interface SearchBar2LayoutStylingProps {
  width?: string
  margin?: string
}

export const defaultLayoutStylingProps: SearchBar2LayoutStylingProps = {
  width: '100%',
}

export interface SearchBar2InputStylingProps {
  border?: string
  background?: string
  boxShadow?: string
  width?: string
  height?: string
  borderRadius?: string
}

export const defaultSearchBarStyling: SearchBar2InputStylingProps = {
  border: 'none',
  background: 'white',
  boxShadow: '0px 4px 15px rgba(0,0,0,0.1)',
  width: '100%',
  height: '48px',
  borderRadius: '24px',
}

export interface DropdownStylingProps {}

interface SelectOption<ValueType, ExtraType = any> {
  value: ValueType
  label: string
  extra?: ExtraType
}

export interface SearchBar2Props<ValueType, ExtraType = any> {
  search: string
  onSearchChanged: (search: string) => void
  onSelect: (value: ValueType, extra?: ExtraType) => void
  options: SelectOption<ValueType, ExtraType>[]
  clearValueOnSelect?: boolean
  placeholder?: string
  layoutStyling?: SearchBar2LayoutStylingProps
  searchBarStyling?: SearchBar2InputStylingProps
  dropdownStyling?: DropdownStylingProps
  scrollToOnFocus?: boolean
  loading?: boolean
}
