import React, { useContext, useState, useCallback } from 'react'
import { NoFunctions } from 'types/util'
import {
  OfferFullNode,
  OfferRoomArticleNode,
  OfferRoomNode,
} from 'types/graphql/offers'

type OfferCloneRoom = {
  articles: (Pick<OfferRoomArticleNode, 'id' | 'count'> & {
    identifier: string
    name: string
    price: number
  })[]
} & Omit<OfferRoomNode, 'articles'>

export type OfferClone = {
  rooms: OfferCloneRoom[]
} & Omit<OfferFullNode, 'rooms'>

interface OffersStoreData {
  tempCloneData: OfferClone | null
  setTempCloneData: (data: OfferClone | null) => void
  consumeCloneData: () => OfferClone
}
const OfferContext = React.createContext<OffersStoreData | null>(null)

function useOffers(): OffersStoreData
function useOffers<T extends keyof OffersStoreData>(
  value?: T
): OffersStoreData[T]
function useOffers<T extends keyof OffersStoreData>(value?: T) {
  const ctx = useContext(OfferContext)
  if (ctx === null)
    throw new Error('useOffers can only be used within a OfferProvider.')

  if (typeof value === 'undefined') return ctx

  return ctx[value]
}
export { useOffers }

export type InitialOffersState = Partial<NoFunctions<OffersStoreData>>

function useOfferState(initialState: InitialOffersState = {}): OffersStoreData {
  const [tempCloneData, setTempCloneData] = useState<OfferClone | null>(
    initialState.tempCloneData ?? null
  )

  const consumeCloneData = useCallback(() => {
    const toReturn = tempCloneData
    setTempCloneData(() => null)
    return toReturn
  }, [tempCloneData, setTempCloneData])

  return {
    tempCloneData,
    setTempCloneData,
    consumeCloneData,
  }
}

const OfferProvider: React.FC<{
  initialState?: InitialOffersState
}> = ({ children, initialState = {} }) => {
  const state = useOfferState(initialState)

  return <OfferContext.Provider value={state}>{children}</OfferContext.Provider>
}

export default OfferProvider
