import styled from 'styled-components'

interface FlexItemProps{
  flexGrow?: string,
  flexShrink?: string,
  flex?: string,
  flexBasis?: string,
}
const FlexItem = styled.div<FlexItemProps>`
  flex: ${props => props.flex};
  flex-grow: ${props => props.flexGrow};
  flex-shrink: ${props => props.flexShrink};
  flex-basis: ${props => props.flexBasis};
`

export default FlexItem