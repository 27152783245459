import { Modal } from '@ur/react-components'
import { MenuItem, DropdownMenu } from 'components/DropdownMenu'
import Thumbnail from 'components/Thumbnail'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { OptionItemFormModal } from '.'
import { useServiceOptionItemMutations } from '../mutations.hooks'
import { OptionItemNode } from '../types.graphql'

const ItemWrapper = styled.div`
  display: flex;
  border: ${props => props.theme.borders.standard};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  padding: 0.5rem;
  & > * + * {
    margin-left: 1rem;
  }
`

const ItemContent = styled.div`
  flex: 1;
  color: ${props => props.theme.colors.text.normal};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  h3 {
    flex: 1;
    font-size: 16px;
    margin: 0;
    color: ${props => props.theme.colors.text.normal};
  }
  & > * + * {
    margin-left: 1rem;
  }
  .fa-ellipsis-v {
    font-size: 16px !important;
  }
`

const PriceTag = styled.div`
  padding: 0 0.5rem;
  border: ${props => props.theme.borders.standard};
  border-radius: 35px;
  text-align: center;
`

const Description = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
`
interface OptionCardItemProps {
  item: OptionItemNode
}

export const OptionCardItem: React.UFC<OptionCardItemProps> = ({ item }) => {
  const { colors } = useTheme()
  const [openMenu, setOpenMenu] = useState(false)
  const [editOpenModal, setEditOpenModal] = useState(false)

  const { serviceOptionItem: optionItemMutation } =
    useServiceOptionItemMutations()

  function handleEdit() {
    setOpenMenu(false)
    setEditOpenModal(true)
  }

  const menuItems: MenuItem[] = [
    {
      displayText: 'Endre',
      icon: {
        icon: 'edit',
        size: '17px',
        type: 'solid',
      },
      colorOverride: colors.darkBlue,
      mobileOnly: false,
      onClick: handleEdit,
    },
    {
      displayText: 'Slett',
      icon: {
        icon: 'trash-alt',
        size: '17px',
        type: 'solid',
      },
      mobileOnly: false,
      onClick: () => optionItemMutation.delete({ variables: { id: item.id } }),
    },
  ]

  return (
    <ItemWrapper>
      {item.image && <Thumbnail src={item.image} size={50} />}
      <ItemContent>
        <ItemHeader>
          <h3>{item.name}</h3>
          <PriceTag>+ {item.price},-</PriceTag>
          <DropdownMenu
            menuItems={menuItems}
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            onBarsClick={() => setOpenMenu(true)}
          />
        </ItemHeader>
        <Description>{item.description}</Description>
      </ItemContent>
      <Modal open={editOpenModal} onClose={() => setEditOpenModal(false)}>
        <OptionItemFormModal
          optionItem={item}
          closeModalCallback={() => setEditOpenModal(false)}
        />
      </Modal>
    </ItemWrapper>
  )
}
