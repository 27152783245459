import FlexCenter from 'components/FlexCenter'
import { Text } from 'components/Text'

export const NoCompanies: React.UFC = () => {
  return (
    <FlexCenter vertical horizontal fillParent>
      <h1>
        <Text color="primary">Du hører ikke til noen firmaer.</Text>
      </h1>
    </FlexCenter>
  )
}
