import { useQuery } from '@apollo/client'
import FullLoader from 'components/Loader/FullLoader'
import React from 'react'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { useBreadcrumbs } from 'store/breadcrumbs-old'
import {
  OfferFullQueryParameters,
  OfferFullQueryReturn,
} from 'types/graphql/offers'
import { OFFER_FULL_QUERY } from '../queries'
import EditOfferInner from './EditOfferInner'

const EditOffer: React.FC = () => {
  const breadcrumbs = useBreadcrumbs()
  const match = useRouteMatch<{ id: string }>()

  const offerId = match.params.id
  const { loading, data } = useQuery<
    OfferFullQueryReturn,
    OfferFullQueryParameters
  >(OFFER_FULL_QUERY, {
    variables: { id: offerId },
    onCompleted({ offer }) {
      breadcrumbs.addOverride(offer.id, offer.name)
    },
  })

  if (loading || !data) {
    return <FullLoader />
  }

  return <EditOfferInner offer={data.offer} />
}

export default withRouter(EditOffer)
