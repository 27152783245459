import React from 'react'
import styled from 'styled-components'
import { useModals } from 'store/modals'
import ModalObj from './Modal'

const ModalOverlay = styled.div<{ active: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  ${props =>
    props.active &&
    `
    background: rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  `}
`

const ModalContainer: React.FC = () => {
  const modals = useModals('modals')

  return (
    <ModalOverlay active={modals.length > 0}>
      {modals.map(modal => (
        <ModalObj key={modal.id} modal={modal} />
      ))}
    </ModalOverlay>
  )
}

export default ModalContainer
