import { gql } from '@apollo/client'

export const ALL_SERVICES_QUERY = gql`
  query AllServices {
    allServices {
      edges {
        node {
          id
          name
          price
          image
          slug
          allowMultiple
          allowUpload
          tags
        }
      }
    }
  }
`

export const GET_SERVICE_QUERY = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      id
      name
      price
      image
      slug
      allowMultiple
      allowUpload
      tags
      serviceOptions {
        edges {
          node {
            id
            name
            description
            serviceOptionItems {
              edges {
                node {
                  id
                  name
                  description
                  image
                  price
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_TAGS_QUERY = gql`
  query AllTags {
    allTags(orderBy: "name") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const ALL_ORDERS_QUERY = gql`
  query AllOrdersQuery {
    allOrders {
      edges {
        node {
          id
          orderNumber
          status
          uniqueId
          createdAt
          customer {
            id
            name
          }
          items {
            edges {
              node {
                price
                amount
                service {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ORDER_QUERY = gql`
  query OrderQuery($uniqueId: String!) {
    order(uniqueId: $uniqueId) {
      id
      orderNumber
      status
      uniqueId
      createdAt
      customer {
        id
        name
        phoneNumber
        email
        address
        postalCode
        city
      }
      items {
        edges {
          node {
            id
            price
            amount
            service {
              id
              name
            }
            attachments {
              id
              name
              file
              url
            }
            orderItemOptions {
              edges {
                node {
                  serviceOption {
                    id
                    name
                  }
                  serviceOptionItem {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
