import { useContext, useState, useEffect } from 'react'
import { UserContext, CompanyContext } from 'context'

export function useDebounce<T>(value: T, delay: number = 350) {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value)
    }, delay)

    return () => clearTimeout(timeout)
  }, [value, delay])

  return debounced
}

export function usePermissions() {
  const user = useContext(UserContext)

  return {
    permissions: user.allPermissions,
    hasPermissions: (...permissions: string[]) =>
      [].concat(permissions).every(perm => user.allPermissions.includes(perm)),
  }
}

export const useUser = () => useContext(UserContext)
export const useCompany = () => useContext(CompanyContext)

export * from './useWindowDimensions'
export * from './useClickOutside'
export * from './useInfiniteScroll'
export * from './useScrollBottom'
