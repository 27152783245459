import Bootstrap from './Bootstrap'
import theme from '../theme'
import client from '../apollo'
import { ApolloProvider } from '@apollo/client'
import IntlContextProvider from './IntlContextProvider'
import StoreProvider from 'store'
import Graph from 'store/graph'
import { UrThemeProvider } from '@ur/react-components'
import { ToastProvider, ToastOptions } from '@ur/react-components'
import { UseGlobalProvider } from '@ur/react-hooks'
import { initialStore } from 'store'
import { isMobile } from 'react-device-detect'

const toastOptions: ToastOptions = {
  position: isMobile ? 'bottom-center' : 'bottom-right',
  showProgressBar: false,
  showCloseIcon: false,

  width: 360,
  height: 74,
  contentAlignHorizontal: 'center',
  contentAlignVertical: 'center',
  iconWidth: 32,

  icons: {
    error: {
      icon: 'exclamation-triangle',
      type: 'solid',
      color: 'white',
      translateX: '22px',
    },
    info: {
      icon: 'exclamation-circle',
      type: 'solid',
      color: 'white',
      translateX: '22px',
    },
    warning: {
      icon: 'exclamation-circle',
      color: 'black',
      type: 'solid',
      translateX: '22px',
    },
    success: {
      icon: 'check-circle',
      type: 'solid',
      color: 'white',
      translateX: '22px',
    },
  },
  background: {
    success: '#209958',
    warning: '#eece24',
    error: '#b52041',
    info: '#5ea9bc',
  },
  color: {
    success: '#ffffff',
    warning: '#000000',
    error: '#ffffff',
    info: '#ffffff',
  },
  boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
}

export default function Root() {
  return (
    <ApolloProvider client={client}>
      <UrThemeProvider theme={theme}>
        <IntlContextProvider>
          <UseGlobalProvider store={initialStore}>
            <Graph.Provider>
              <ToastProvider options={toastOptions}>
                <StoreProvider initialState={{}}>
                  <Bootstrap />
                </StoreProvider>
              </ToastProvider>
            </Graph.Provider>
          </UseGlobalProvider>
        </IntlContextProvider>
      </UrThemeProvider>
    </ApolloProvider>
  )
}
