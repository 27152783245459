import styled from 'styled-components'

export default styled.div<IMargin>`
  display: ${props => props.inline ? "inline-block" : "block"};

  ${props => props.all ? `
    margin-top: ${props.all};
    margin-bottom: ${props.all};
    margin-left: ${props.all};
    margin-right: ${props.all};
  ` : ""}

  ${props => props.top ? `margin-top: ${props.top};` : ""}
  ${props => props.bottom ? `margin-bottom: ${props.bottom};` : ""}
  ${props => props.left ? `margin-left: ${props.left};` : ""}
  ${props => props.right ? `margin-right: ${props.right};` : ""}
  ${props => props.width ?  `width: ${props.width};` : ""}
`
export interface IMargin {
  all?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  inline?: boolean;
}