import React from 'react'

import Input from 'components-old/Input'
import Select from 'components/Select'
import QuillEditor from 'components/QuillEditor/QuillEditor'
import TextArea from 'components/TextArea/TextArea'
import { format } from 'util/date-fns'

function getFieldExtraProps(field) {
  if (field.extra && field.extra.props) {
    return field.extra.props
  }
  return {}
}

export function renderRegularInputField(field, value, onChange, error) {
  return (
    <Input
      name={field.name}
      type={field.type}
      value={value}
      placeholder={field.placeholder}
      onChange={e => onChange(e.target.value, e)}
      error={error}
      {...getFieldExtraProps(field)}
    />
  )
}

export function renderSelectField(field, value, onChange, error) {
  return (
    <Select
      name={field.name}
      value={value}
      options={field.extra ? field.extra.options : []}
      onChange={newValue => onChange(newValue)}
      error={error}
      {...getFieldExtraProps(field)}
    />
  )
}

export function renderWysiwygField(field, value, onChange, error) {
  return (
    <QuillEditor
      onChange={newContents => onChange(JSON.stringify(newContents))}
      error={error}
      {...getFieldExtraProps(field)}
    />
  )
}

export function renderTextAreaField(field, value, onChange, error) {
  return (
    <TextArea
      value={value}
      onChange={e => onChange(e.target.value, e)}
      error={error}
      {...getFieldExtraProps(field)}
    />
  )
}

export function renderDateField(field, value, onChange, error) {
  if (field.widget === 'datepicker') {
    // TODO
    return null
  } else {
    // We customize the onChange value here, as we do not want
    // to return Date objects, but moment objects.
    return (
      <Input
        name={field.name}
        type={field.type}
        value={value && format(value, 'yyyy-MM-dd')}
        placeholder={field.placeholder}
        onChange={e => onChange(new Date(e.target.value), e)}
        error={error}
        {...getFieldExtraProps(field)}
      />
    )
  }
}

export function renderDateTimeField(field) {}

export function renderColorField(field) {
  return <div>TODO:</div>
}

export function renderSliderField(field) {
  // TODO:
  return <div>TODO</div>
}

export function getRenderedFieldFromFieldSpec(field, value, onChange, error) {
  switch (field.type) {
    default:
    case 'text':
    case 'number':
      return renderRegularInputField(field, value, onChange, error)
    case 'color':
      return renderColorField(field, value, onChange, error)
    case 'date':
      return renderDateField(field, value, onChange, error)
    case 'datetime':
      return renderDateTimeField(field, value, onChange, error)
    case 'textarea':
      return renderTextAreaField(field, value, onChange, error)
    case 'wysiwyg':
      return renderWysiwygField(field, value, onChange, error)
    case 'slider':
      return renderSliderField(field, value, onChange, error)
    case 'select':
      return renderSelectField(field, value, onChange, error)
  }
}

export function getLikelyDefaultValueFromFieldSpec(field) {
  if (field.value) {
    return field.value
  }
  if (field.initialValue) {
    return field.initialValue
  }
  switch (field.type) {
    default:
    case 'text':
      return ''
    case 'number':
      return 0
    case 'color':
      return '#ffffff'
    case 'date':
      return new Date()
    case 'textarea':
      return ''
    case 'wysiwyg':
      return {}
    case 'slider':
      return 0
    case 'select':
      return field.options && field.options[0] ? field.options[0].value : ''
  }
}
