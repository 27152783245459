import { Icon } from '@ur/react-components'
import { useClickOutside } from '@ur/react-hooks'
import { useRef, useState } from 'react'
import Loader from 'components/Loader'
import { useTheme } from 'styled-components'
import {
  SearchBar2Curtain,
  SearchBar2IconWrapper,
  SearchBar2Input,
  SearchBar2InputWrapper,
  SearchBar2Layout,
  SearchBar2List,
  SearchBar2ListItem,
} from './SearchBar2.components'
import {
  defaultLayoutStylingProps,
  defaultSearchBarStyling,
  SearchBar2Props,
} from './SearchBar2.types'

const SearchBar2 = <InputType, ExtraType>({
  options,
  onSelect,
  clearValueOnSelect,
  search,
  onSearchChanged,
  placeholder,
  layoutStyling,
  searchBarStyling,
  dropdownStyling,
  scrollToOnFocus,
  loading = false,
}: SearchBar2Props<InputType, ExtraType>) => {
  const [open, setOpen] = useState(false)

  const layoutRef = useRef<HTMLDivElement>(null)
  useClickOutside(layoutRef, () => setOpen(false), false)
  const theme = useTheme()

  const finalLayoutStyling = {
    ...defaultLayoutStylingProps,
    ...(layoutStyling || {}),
  }

  const finalInputStyling = {
    ...defaultSearchBarStyling,
    ...(searchBarStyling || {}),
  }

  return (
    <SearchBar2Layout ref={layoutRef} {...finalLayoutStyling}>
      <SearchBar2InputWrapper
        onFocus={() => {
          if (scrollToOnFocus) {
            layoutRef.current?.scrollIntoView()
          }
          setOpen(true)
        }}
      >
        <SearchBar2Input
          placeholder={placeholder}
          value={search}
          onChange={e => onSearchChanged(e.target.value)}
          {...finalInputStyling}
        />
        <SearchBar2IconWrapper>
          {loading ? (
            <Loader />
          ) : (
            <Icon icon="search" color={theme.colors.gray4} />
          )}
        </SearchBar2IconWrapper>
      </SearchBar2InputWrapper>
      <SearchBar2Curtain
        open={open && options.length > 0}
        offset={finalInputStyling.height}
      >
        <SearchBar2List>
          {options.map((option, index) => (
            <SearchBar2ListItem
              key={index}
              onClick={() => {
                onSelect(option.value, option.extra)
                setOpen(false)
              }}
            >
              {option.label}
            </SearchBar2ListItem>
          ))}
        </SearchBar2List>
      </SearchBar2Curtain>
    </SearchBar2Layout>
  )
}

export default SearchBar2
