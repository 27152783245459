import { Select as BaseSelect, SelectProps } from '@ur/react-components'
import { getYearOptions } from 'modules/statistics/util'
import React from 'react'
import styled from 'styled-components'

export interface YearSelectProps<
  ValueType extends string | number = string | number,
  ExtraType = any
> extends Omit<SelectProps<ValueType, ExtraType>, 'options'> {}

export const StyledSelect = styled(BaseSelect)<YearSelectProps>`
  width: 80%;
  min-width: 80px;
  .--select-display {
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.darkBlue};
  }
  .--select-display-inner {
    padding: 4px 4px 0 12px !important;
    color: ${props => props.theme.colors.darkBlue} !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 22px;
    text-align: left;
  }
  .--select-arrow-icon {
    color: ${props => props.theme.colors.darkBlue};
  }

  .--select-placeholder {
    color: ${props => props.theme.colors.darkBlue} !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 22px;
    text-align: left;
  }

  .--select-curtain {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: ${props => props.theme.colors.white};
    border: none;
  }
  .--select-curtain-option {
    margin: 2px 0;
    width: 90%;
    &:hover {
      background-color: ${props => props.theme.colors.gray5};
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .--select-curtain-inner {
    width: 100% !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

export const YearSelect: React.UFC<YearSelectProps> = ({ ...props }) => {
  return <StyledSelect options={getYearOptions()} {...props} />
}
