import { useGlobal } from '@ur/react-hooks'
import { Button } from 'components/Button'
import React from 'react'
import styled, { css } from 'styled-components'
import { getNextStep } from '../util'

interface WrapperProps {
  width: string
  needsPadding: boolean
}

const Wrapper = styled.div<WrapperProps>`
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 0;
    margin-bottom: 14px;
    ${props =>
      props.needsPadding &&
      css`
        padding: 0 14px;
      `};
  }

  > :first-child {
    margin-right: 40px;
  }
`

interface StepControlsProps {
  nextText?: string
  backText?: string
  nextDisabled?: boolean
  backDisabled?: boolean
  onNext?: () => void
  onBack?: () => void
  width?: string
}

export const StepControls: React.UFC<StepControlsProps> = ({
  nextText = 'Neste',
  backText = 'Tilbake',
  onNext,
  onBack,
  nextDisabled,
  backDisabled,
  width = '100%',
}) => {
  const [wizardState, setWizardState] = useGlobal('offer.wizardState')

  function handleBack() {
    if (onBack) onBack()
    const step = getNextStep(wizardState, -1)
    setWizardState(step)
  }

  function handleNext() {
    if (onNext) onNext()
    const step = getNextStep(wizardState)
    setWizardState(step)
  }

  function needsPadding() {
    if (wizardState === 'Artikler' || wizardState === 'Oppsummering') {
      return true
    } else {
      return false
    }
  }

  return (
    <Wrapper width={width} needsPadding={needsPadding()}>
      <Button
        buttonStyle="cancel"
        fullWidth
        disabled={backDisabled}
        onClick={handleBack}
      >
        {backText}
      </Button>
      <Button
        buttonStyle="secondary"
        fullWidth
        disabled={nextDisabled}
        onClick={handleNext}
      >
        {nextText}
      </Button>
    </Wrapper>
  )
}
