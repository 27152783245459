import { useQuery } from '@apollo/client'
import { Icon } from '@ur/react-components'

import { Input } from 'components/Input'
import { ALL_USERS_SHALLOW_QUERY } from 'modules/users/queries'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { ZIndexRange } from 'types/enums'
import {
  AllUsersShallowQUeryParameters,
  AllUsersShallowQueryReturns,
} from 'types/graphql/users'
import useClickOutside from 'util/hooks/useClickOutside'
import { UsersFilterList } from './UsersFilterList'

const Wrapper = styled.div<TableHeaderFilterIconProps>`
  margin-left: 3px;
  grid-area: filter;
  display: flex;
  position: relative;
  align-items: center;

  color: ${props =>
    props.activeFilter > 0
      ? props.theme.colors.purple
      : props.theme.colors.gray3};
  :hover {
    color: ${props => props.theme.colors.purple};
    cursor: pointer;
  }
`

const PopupContainer = styled.aside`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: absolute;
  top: 32px;
  right: 0;
  width: 400px;
  height: 400px;
  z-index: ${ZIndexRange.Dropdowns};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.default};
`

const PopupControllerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px 15px;

  i {
    color: ${props => props.theme.colors.gray3};
    align-self: flex-end;
  }

  i:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.primary};
  }
`

const ActiveFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -130%;
  right: 43%;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.purple};

  &:hover {
    div {
      display: flex;
      cursor: pointer;
    }
  }
`
const StyledIcon = styled.i`
  font-size: 1rem;
`

const RemoveFilters = styled.div`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.purple};
  border-radius: 100%;
  width: 100%;
  height: 100%;
  z-index: ${ZIndexRange.Dropdowns};
`

interface OffersCustomerFilterButtonProps {
  gridArea: string
  createdByIds: string[]
  setCreatedByIdsCallback: (ids: string[]) => void
  label: string
}

interface TableHeaderFilterIconProps {
  activeFilter: number
}

export const OffersCustomerFilterButton: React.VFC<
  OffersCustomerFilterButtonProps
> = ({ createdByIds, setCreatedByIdsCallback, label }) => {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const handleClosePopup = () => {
    setOpen(false)
  }
  useClickOutside(menuRef, handleClosePopup)

  const togglePopup = () => {
    setOpen(open => !open)
  }

  const handleClearFilters = () => {
    setCreatedByIdsCallback([])
  }
  const { data } = useQuery<
    AllUsersShallowQueryReturns,
    AllUsersShallowQUeryParameters
  >(ALL_USERS_SHALLOW_QUERY, {
    variables: { q: search },
  })

  const users = data?.allUsers.edges.map(edge => ({
    ...edge.node,
  }))

  return (
    <Wrapper
      ref={menuRef}
      onClick={togglePopup}
      activeFilter={createdByIds.length}
    >
      {label}
      <StyledIcon className={'fa fa-filter'} />

      {createdByIds.length > 0 && (
        <ActiveFilters>
          {createdByIds.length}
          <RemoveFilters onClick={handleClearFilters}>
            <Icon icon="times" size="18px" />
          </RemoveFilters>
        </ActiveFilters>
      )}
      {open && (
        <PopupContainer>
          <PopupControllerWrapper>
            <Icon
              margin="4px"
              icon="times"
              size="24px"
              type="light"
              onClick={handleClosePopup}
            />
            <Input
              value={search}
              fullWidth
              placeholder="Søk etter bruker..."
              onChange={setSearch}
            />
          </PopupControllerWrapper>
          <UsersFilterList
            users={users ?? []}
            createdByIds={createdByIds}
            setCreatedByIdsCallback={setCreatedByIdsCallback}
          />
        </PopupContainer>
      )}
    </Wrapper>
  )
}
