import React from 'react'
import styled from 'styled-components'

import { overloadColor } from 'util/style'
import { CSSCursor } from 'types/style'

interface IProps {
  size: string
  color: string
  hoverColor?: string
  cursor: string
}
const I = styled.i<IProps>`
  color: ${props => overloadColor(props.color)};
  font-size: ${props => props.size};
  cursor: ${props => props.cursor};

  ${props =>
    props.hoverColor
      ? `
      &:hover{
        color: ${overloadColor(props.hoverColor)};
      }
    `
      : ''}
`

interface IconProps {
  className?: string
  icon: string
  solid?: boolean
  light?: boolean
  brand?: boolean

  title?: string
  size?: string
  color?: string
  hoverColor?: string
  cursor?: CSSCursor

  onClick?: (evt: React.MouseEvent<HTMLElement>) => void
}

const Icon: React.FC<IconProps> = ({
  className,
  icon,
  solid = false,
  light = false,
  brand = false,

  title,
  color = 'inherit',
  hoverColor,
  size = '1em',
  cursor = 'auto',

  onClick = () => void 0,
}) => {
  let iconName: string
  let iconStyle = solid ? 'fa' : light ? 'fal' : brand ? 'fab' : 'far'

  const iconSplitted = icon.split(' ')
  if (iconSplitted.length > 1) {
    if (['fa', 'fal', 'far', 'fab'].includes(iconSplitted[0])) {
      iconStyle = iconSplitted[0]
    }
    iconName = iconSplitted[1]
    const prefix = /^fa[lrb]*$/.test(iconStyle) ? 'fa-' : 'dic-'
    iconName =
      iconName.indexOf(prefix) !== 0 ? `${prefix}${iconName}` : iconName
  } else {
    iconName = iconSplitted[0]
    const prefix = /^fa[lrb]*$/.test(iconStyle) ? 'fa-' : 'dic-'
    iconName =
      iconName.indexOf(prefix) !== 0 ? `${prefix}${iconName}` : iconName
  }

  const iconClass = `${iconStyle} ${iconName}`
  return (
    <I
      className={`${iconClass} ${className}`}
      title={title}
      color={color}
      hoverColor={hoverColor}
      size={size}
      cursor={cursor}
      onClick={onClick}
    />
  )
}

export default Icon
