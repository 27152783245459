import React, { useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { PATCH_USER_MUTATION } from '../mutations'

import { Text } from 'components/Text'
import Thumbnail from 'components/Thumbnail/index'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import Flex from 'components/Flex'
import FileInput from 'components/Input/FileInput'
import { useToast } from '@ur/react-components'
import { UserNode } from 'types/graphql/users'

interface ChangeProfilePictureProps {
  user: UserNode
  area?: string
}

export const ChangeProfilePicture: React.UFC<ChangeProfilePictureProps> = ({
  user,
}) => {
  const toasts = useToast()
  const [newImage, setNewImage] = useState(null)

  return (
    <Mutation
      onCompleted={() => {
        toasts('success', 'Profilbildet ble oppdatert')
      }}
      mutation={PATCH_USER_MUTATION}
    >
      {patchUserMutation => (
        <div>
          <h3>
            <Text color="primary">Bytt profilbilde</Text>
          </h3>
          <Flex alignItems="center" justifyContent="flex-start" flexWrap="wrap">
            <Thumbnail size={100} src={user.profilePicture} />
          </Flex>
          <Margin top="2em">
            <FileInput onChange={files => setNewImage(files[0])} />
            <Margin top="1em">
              <Button
                primary
                onClick={() =>
                  patchUserMutation({
                    variables: {
                      id: user.id,
                      input: {
                        profilePicture: newImage,
                      },
                    },
                  })
                }
              >
                Last opp
              </Button>
            </Margin>
          </Margin>
        </div>
      )}
    </Mutation>
  )
}
