import styled from 'styled-components'

interface GridProps {
  inline?: boolean
  columns?: string
  rows?: string
  areas?: string
  gap?: string
  columnGap?: string
  rowGap?: string
  alignItems?: string
}

const Grid = styled.div<GridProps>`
  display: ${props => (props.inline ? 'inline-grid' : 'grid')};

  grid-template-columns: ${props => props.columns};
  grid-template-rows: ${props => props.rows};

  ${props =>
    props.areas
      ? `
    grid-template-areas: ${props.areas};
  `
      : ''}

  gap: ${props => props.gap};
  column-gap: ${props => props.columnGap};
  row-gap: ${props => props.rowGap};
  
  align-items: ${props => props.alignItems};

`

export default Grid
