import {
  CompanyNode,
  CompanySettingsNode,
} from 'modules/companies/types.graphql'

export enum CompanySettingKeys {
  NextInternalOfferReference = 'next-internal-offer-reference',
  OfferConditions = 'offer-conditions',
}

export interface UpdateCompanySettingsFormData {
  companyLogo?: File
  fullName?: string
  address?: string
  postalCode?: string
  city?: string
  contactEmailAddress?: string
  contactPhoneNumber?: string
  settings?: {
    offerReference: Partial<Pick<CompanySettingsNode, 'id' | 'key' | 'value'>>
    offerConditions: Partial<Pick<CompanySettingsNode, 'id' | 'key' | 'value'>>
  }
}

export interface CompanyFormData {
  companyLogo?: File
  fullName?: string
  address?: string
  postalCode?: string
  city?: string
  contactEmailAddress?: string
  contactPhoneNumber?: string
  settings?: {
    offerReference: Partial<Pick<CompanySettingsNode, 'id' | 'key' | 'value'>>
    offerConditions: Partial<Pick<CompanySettingsNode, 'id' | 'key' | 'value'>>
  }
}
export interface PatchCompanyMutation {
  patchCompany: {
    company: Pick<CompanyNode, 'id'>
  }
}

export type PatchCompanyMutationInput = Omit<CompanyFormData, 'settings'> & {
  settings?: Partial<Pick<CompanySettingsNode, 'id' | 'key' | 'value'>>[]
}
export interface PatchCompanyMutationVariables {
  id: string
  input: PatchCompanyMutationInput
}
