import React from 'react'
import styled from 'styled-components'

import Card from 'components/Card'
import { Text } from 'components/Text'
import Table from 'components/Table'
import { roomSummary } from '../../util'

const BorderTableCell = styled.td`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: 0.75rem 0.75rem;
`

export default function OfferSummary({ rooms }) {
  const summary = roomSummary(rooms)
  return (
    <Card>
      {rooms && rooms.length > 0 ? (
        <Table fullWidth>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell>Rom</Table.HeaderCell>
              <Table.HeaderCell>Kostnad rom</Table.HeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {summary.rooms.map((room, index) => (
              <Table.Row key={room.id ?? room.localId ?? index}>
                <Table.Cell>{room.name}</Table.Cell>
                <Table.Cell>{room.sum} kr</Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <BorderTableCell>
                <Text color="primary">Total kost</Text>
              </BorderTableCell>
              <BorderTableCell>{summary.totalSum} kr</BorderTableCell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Text color="primary" fontWeight="bold">
                  Total kost inkl. mva
                </Text>
              </Table.Cell>
              <Table.Cell>{summary.totalSum * 1.25} kr</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <div>Ingen rom er lagt til enda</div>
      )}
    </Card>
  )
}
