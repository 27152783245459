import styled from 'styled-components'

interface WrapperProps {
  gridArea: string
}

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  grid-area: ${props => (props.gridArea ? props.gridArea : 'none')};
  width: 100%;
  align-items: center;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.gray3};
`

const StyledSpan = styled.span`
  font-size: 12px;
  margin: 0 15px;
  content: 'Eller';
  color: ${props => props.theme.colors.gray3};
`

interface OrSeparatorProps {
  gridArea?: string
}

export const OrSeparator: React.FC<OrSeparatorProps> = ({ gridArea }) => {
  return (
    <Wrapper gridArea={gridArea}>
      <Line />
      <StyledSpan>eller</StyledSpan>
      <Line />
    </Wrapper>
  )
}
