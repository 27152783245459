import React from 'react'
import BreadcrumbsProvider, { InitialBreadcrumbsState } from './breadcrumbs-old'
import OffersProvider, { InitialOffersState } from './offer'
import ToastProvider, { InitialToastState } from './toasts'
import ModalProvider, { InitialModalState } from './modals'
export * from './globalStore'

interface StoreProviderProps {
  initialState: {
    breadcrumbs?: InitialBreadcrumbsState
    offers?: InitialOffersState
    toasts?: InitialToastState
    modals?: InitialModalState
  }
}
const StoreProvider: React.FC<StoreProviderProps> = ({
  children,
  initialState = {},
}) => {
  return (
    <BreadcrumbsProvider initialState={initialState.breadcrumbs ?? {}}>
      <OffersProvider initialState={initialState.offers ?? {}}>
        <ToastProvider>
          <ModalProvider>{children}</ModalProvider>
        </ToastProvider>
      </OffersProvider>
    </BreadcrumbsProvider>
  )
}
export default StoreProvider
