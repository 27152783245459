import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Mutation } from '@apollo/client/react/components'
import Title from 'components/Title'
import EasyForm from 'components/Form/EasyForm'
import { CREATE_CUSTOMER_MUTATION } from './mutations'
import { notEmptyValidation } from 'util/forms'
import queryString from 'query-string'
import { CUSTOMER_QUERY } from './queries'
import FullLoader from 'components/Loader/FullLoader'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
  display: flex;
  align-items: center;
  flex-direction: column;
`

function EditCustomer({ onEdited }) {
  const history = useHistory()
  const match = useRouteMatch()
  const customerId = match.params.customerId
  const { loading, data } = useQuery(CUSTOMER_QUERY, {
    variables: {
      id: customerId,
    },
  })

  if (loading || !data) {
    return <FullLoader />
  }

  const customer = data.customer
  const returnTo =
    queryString.parse(history.location.search).returnTo || '/customers'
  return (
    <Wrapper>
      <div>
        <div>
          <Link to={returnTo}>
            <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
          </Link>
        </div>
        <Title.H2>Endre {customer.name}</Title.H2>
        <Mutation
          mutation={CREATE_CUSTOMER_MUTATION}
          refetchQueries={['AllCustomers']}
          onCompleted={data => {
            onEdited(data.createCustomer.customer)
            history.push(returnTo)
          }}
        >
          {createCustomer => (
            <EasyForm
              fields={[
                {
                  initialValue: customer.name,
                  name: 'name',
                  label: 'Navn',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha et navn',
                  extra: { props: { width: '400px' } },
                },
                {
                  initialValue: customer.address,
                  name: 'address',
                  label: 'Addresse',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha en addresse',
                  extra: { props: { width: '400px' } },
                },
                {
                  initialValue: customer.email,
                  name: 'email',
                  label: 'E-post',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha en e-post',
                  extra: { props: { width: '400px' } },
                },
                {
                  initialValue: customer.phoneNumber,
                  name: 'phoneNumber',
                  label: 'Telefonnummer',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha en tlf',
                  extra: { props: { width: '400px' } },
                },
                {
                  initialValue: customer.postalCode,
                  name: 'postalCode',
                  label: 'Postkode',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha en postkode',
                  extra: { props: { width: '400px' } },
                },
                {
                  initialValue: customer.city,
                  name: 'city',
                  label: 'By',
                  validate: notEmptyValidation,
                  errorMessage: 'Kunden må ha en by',
                  extra: { props: { width: '400px' } },
                },
              ]}
              submitButtonText="Endre"
              submitButtonProps={{ width: '100%' }}
              onSubmit={formData =>
                createCustomer({
                  variables: { input: { ...formData } },
                })
              }
            />
          )}
        </Mutation>
      </div>
    </Wrapper>
  )
}

export default EditCustomer
