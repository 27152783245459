import styled from 'styled-components'

interface TableRowProps {
  striped?: boolean
}

const TableRow = styled.tr<TableRowProps>`
  ${props =>
    props.striped &&
    `:nth-child(even) {
    background: ${props.theme.colors.tableRowColor};
  }`}
`

export default TableRow
