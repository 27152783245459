import { gql } from '@apollo/client'
import { UserFragments } from './queries'

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUserMutation(
    $user: ID
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $address: String!
    $city: String!
    $postalCode: String!
  ) {
    registerUser(
      user: $user
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      postalCode: $postalCode
    ) {
      ok
      user {
        ...UserInfo
        ...UserTypes
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $userType: ID!
    $company: String
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      userType: $userType
      company: $company
    ) {
      ok
      user {
        ...UserInfo
        ...UserTypes
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangedPasswordMutation($password: String!, $newPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword) {
      ok
      user {
        ...UserInfo
        ...UserTypes
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
`

export const PATCH_USER_MUTATION = gql`
  mutation PatchUserMutation($id: ID!, $input: PatchUserInput!) {
    patchUser(id: $id, input: $input) {
      user {
        ...UserInfo
      }
    }
  }
  ${UserFragments.info}
`

export const CREATE_USER_TYPE_MUTATION = gql`
  mutation CreateUserType($input: CreateUserTypeInput!) {
    createUserType(input: $input) {
      userType {
        ...UserTypeInfo
      }
    }
  }
  ${UserFragments.userTypeInfo}
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($user: String) {
    resetPassword(user: $user) {
      ok
    }
  }
`

export const RESET_MY_PASSWORD_MUTATION = gql`
  mutation ResetMyPasswordMutation($username: String!) {
    resetMyPassword(username: $username) {
      ok
    }
  }
`

export const RESET_PASSWORD_BY_TOKEN_MUTATION = gql`
  mutation ResetPasswordByTokenMutation(
    $newPassword: String!
    $token: String!
  ) {
    resetPasswordByToken(token: $token, newPassword: $newPassword) {
      ok
      loginToken
    }
  }
`
