import styled from 'styled-components'
import {
  SearchBar2LayoutStylingProps,
  SearchBar2InputStylingProps,
} from './SearchBar2.types'

export const SearchBar2Layout = styled.div<SearchBar2LayoutStylingProps>`
  width: ${props => props.width};
  margin: ${props => props.margin};
  position: relative;
`

export const SearchBar2InputWrapper = styled.div`
  position: relative;
`

export const SearchBar2IconWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`

export const SearchBar2Input = styled.input<SearchBar2InputStylingProps>`
  outline: none;
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  background: ${props => props.background};
  box-shadow: ${props => props.boxShadow};

  height: ${props => props.height};
  width: ${props => props.width};

  padding-left: 24px;
  font-size: 18px;

  ::placeholder {
    color: ${props => props.theme.colors.gray4};
  }

  &:focus-within {
    box-shadow: 0 4px 15px rgba(0, 15, 159, 0.25);
    i {
      color: ${props => props.theme.colors.darkBlue};
    }
  }
`

interface SearchBar2CurtainProps {
  open: boolean
  offset: string
}

export const SearchBar2Curtain = styled.div<SearchBar2CurtainProps>`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  top: calc(${props => props.offset} + 5px);
  left: 0;

  width: 100%;
  max-height: 400px;

  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  border-radius: 10px;

  z-index: 4000;
  overflow-y: auto;
`

export const SearchBar2List = styled.ul`
  margin: 0;
  padding: 8px;
  list-style: none;
`

export const SearchBar2ListItem = styled.li`
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.gray5};
  }
`
