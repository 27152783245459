import { useQuery } from '@apollo/client'
import Card from 'components/Card'
import FullLoader from 'components/Loader/FullLoader'
import Margin from 'components/Margin'
import QuillRenderer from 'components/QuillRenderer'
import { Text } from 'components/Text'
import PermissionsRequired from 'containers/PermissionsRequired'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useBreadcrumbs } from 'store/breadcrumbs-old'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import styled from 'styled-components'
import {
  HandbookEntryAttachmentNode,
  HandbookEntryBySlugQueryParameters,
  HandbookEntryBySlugQueryReturn,
  HandbookFolderNode,
} from 'types/graphql/handbook'
import { format } from 'util/date-fns'
import { safeParseJson } from 'util/parsing'
import HandbookEntryAttachment from './HandbookEntryAttachment'
import { MANUAL_ENTRY_BY_SLUG_QUERY } from './queries'
import { CrumbInsert } from '@ur/react-components'
import { Button } from 'components/Button'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const EditWrapper = styled.div`
  float: right;
  cursor: pointer;
`

const HandbookEntry: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch<{ entryId: string }>()
  const [{ setOverridesAndInserts }] = BreadcrumbsGraph.useGraph()
  const breadcrumbs = useBreadcrumbs()

  const parsedSearch = queryString.parse(history.location.search)
  let folder = parsedSearch.folder
  folder = folder instanceof Array ? folder.join('') : folder
  const entrySlug = match.params.entryId

  const { loading, data } = useQuery<
    HandbookEntryBySlugQueryReturn,
    HandbookEntryBySlugQueryParameters
  >(MANUAL_ENTRY_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { slug: entrySlug, folder },
    onCompleted({ handbookEntry }) {
      if (!data) return

      const inserts: CrumbInsert[] = handbookEntry.slugHierarchy
        .slice(0, -1)
        .map(folder => ({
          index: 0,
          crumb: {
            text: handbookEntry.name,
            href: `/handbook/${handbookEntry.folder.slug}`,
          },
        }))
      setOverridesAndInserts(
        {
          [handbookEntry.slug]: handbookEntry.name,
          [handbookEntry.folder.slug]: handbookEntry.folder.name,
        },
        inserts
      )
      // breadcrumbs.setSliceEnd(-2)

      // breadcrumbs.setSuffixCrumbs(
      //   handbookEntry.slugHierarchy.map((entry, i) => ({
      //     link: `/handbook/${
      //       i === handbookEntry.slugHierarchy.length - 1 ? 'entry' : 'folder'
      //     }/${entry}`,
      //     render: entry,
      //   }))
      // )
    },
  })

  const handbookEntry =
    data && data.handbookEntry
      ? data.handbookEntry
      : {
          id: '',
          name: '',
          createdAt: '1993-01-09T07:28:15.000Z',
          description: '',
          richtext: '{}',
          folder: { id: '' } as HandbookFolderNode,
          attachments: [] as HandbookEntryAttachmentNode[],
          slugHierarchy: [] as string[],
        }

  useEffect(() => {
    return () => {
      breadcrumbs.setSliceEnd(null)
      breadcrumbs.setSuffixCrumbs([])
    }
    // eslint-disable-next-line
  }, [handbookEntry])

  return (
    <Wrapper>
      {loading && <FullLoader />}
      <Link to={`/handbook/folder/${handbookEntry.folder.id}`}>
        <Button>Tilbake</Button>
      </Link>
      <Card hoverEffect={false} padding="1rem 3rem">
        <Margin top="0.5rem" bottom="2em">
          <h2>
            <Text color="primary">{handbookEntry.name}</Text>
            <PermissionsRequired permissions="handbook.change_handbookentry">
              <EditWrapper>
                <Link to={`/handbook/update-entry?id=${handbookEntry.id}`}>
                  <Text color="gray">
                    <i className="far fa-edit"></i>
                  </Text>
                </Link>
              </EditWrapper>
            </PermissionsRequired>
          </h2>
          <Margin top="-12px">
            <Text color="lightGray">
              {format(new Date(handbookEntry.createdAt), 'dd. MMM, yyyy')}
            </Text>
          </Margin>
          <p>
            <Text color="gray" fontSize="18px">
              {handbookEntry.description}
            </Text>
          </p>
        </Margin>
        {handbookEntry.richtext && handbookEntry.richtext !== '{}' && (
          <Margin top="2em" bottom="2rem">
            <QuillRenderer contents={safeParseJson(handbookEntry.richtext)} />
          </Margin>
        )}
        {handbookEntry.attachments.map(attachment => (
          <HandbookEntryAttachment attachment={attachment} />
        ))}
      </Card>
    </Wrapper>
  )
}

export default HandbookEntry
