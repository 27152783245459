import styled from 'styled-components'
import { DiffStates, ArticleDiff } from './types'

const DetailsContentRowCell = styled.div`
  width: 300px;
  font-size: 16px;

  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`

interface RowState {
  rowState: DiffStates
}

const DetailsContentRow = styled.div<RowState>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 80px;
    text-align: left;
  }

  color: ${props =>
    props.rowState === 'increased'
      ? props.theme.colors.success
      : props.rowState === 'decreased' || props.rowState === 'deleted'
      ? props.theme.colors.danger
      : props.theme.colors.darkBlue};

  text-decoration: ${props =>
    props.rowState === 'deleted' ? 'line-through' : 'none'};
`
interface ArticleRowDiffProps {
  article: Omit<ArticleDiff, 'price' | 'priceDiff' | 'priceState'>
}
export const ArticleRowDiff: React.FC<ArticleRowDiffProps> = ({ article }) => {
  const diffSign = article.countDiff <= 0 ? '' : '+'
  return (
    <DetailsContentRow rowState={article.countState}>
      <DetailsContentRowCell>{article.name}</DetailsContentRowCell>
      <DetailsContentRowCell>
        {article.countState === 'deleted'
          ? -article.countDiff
          : article.countState === 'unaltered'
          ? article.count
          : `${article.count} (${diffSign}${article.countDiff})`}
      </DetailsContentRowCell>
    </DetailsContentRow>
  )
}
