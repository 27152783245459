import traph from 'react-traph'
import ToastGraph from './toasts-new'
import { BreadcrumbsGraph } from './breadcrumbs'
import { SidebarGraph } from './sidebar'

const Graph = traph({
  toasts: ToastGraph,
  offer: null,
  breadcrumbs: BreadcrumbsGraph,
  sidebar: SidebarGraph,
})

export default Graph
