import { format as formatBase } from 'date-fns'
import { nb } from 'date-fns/locale'

export function format(date: Date, formatString: string) {
  return formatBase(date, formatString, {
    locale: nb,
  })
}

export function localizeMonth(i: number) {
  return nb.localize.month(i)
}
