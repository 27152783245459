import { useMutation, useQuery } from '@apollo/client'
import { Icon, useToast } from '@ur/react-components'
import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import Select from 'components/Select'
import { SelectOption } from 'components/Select/Select'
import { CREATE_USER_MUTATION } from 'modules/users/mutations'
import { USER_TYPES_QUERY } from 'modules/users/queries'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  AllUserTypesQueryReturn,
  CreateUserMutation,
  CreateUserMutationParameters,
} from 'types/graphql/users'
import { isValidPhoneNumber, notEmptyValidation } from 'util/helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  height: 654px;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: ${props => props.theme.colors.white};

  ${props => props.theme.media.mobile} {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding-left: 32px;
  padding-right: 16px;

  ${props => props.theme.media.mobile} {
    align-items: center;
    padding-left: 15px;
    padding-right: 27px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
`

const ModalTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: auto 0 0 0;
  color: ${props => props.theme.colors.darkBlue};
  ${props => props.theme.media.mobile} {
    font-size: 24px;
    margin: 0;
  }
`

const BodyWrapper = styled.div`
  width: 100%;
  padding: 32px 32px 50px;
`

const WidgetSection = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${props => props.theme.colors.darkBlue};
`

const CloseModalIcon = styled(Icon)`
  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`

interface CreateUserModalProps {
  closeModalCallback: () => void
}
export const CreateUserModal: React.UFC<CreateUserModalProps> = ({
  closeModalCallback,
}) => {
  const addToast = useToast()
  const theme = useTheme()
  const [userTypeOptions, setUserTypeOptions] = useState<
    SelectOption<string>[]
  >([])
  const {
    formValues: form,
    updateForm,
    formErrors,
    submitHandler,
  } = useForm({
    values: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      userType: '',
    },
    validators: {
      email: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
      firstName: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
      lastName: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
      phoneNumber: val =>
        isValidPhoneNumber(val) ? null : 'Må være et gyldig telefonnummer',
      userType: val =>
        notEmptyValidation(val) ? null : 'Må velge en brukertype',
    },
  })

  const [createUser] = useMutation<
    CreateUserMutation,
    CreateUserMutationParameters
  >(CREATE_USER_MUTATION, {
    refetchQueries: ['Users'],
    onCompleted() {
      addToast('success', `Bruker opprettet`)
      closeModalCallback()
    },
    onError(error) {
      if (error.message.toLowerCase().includes('unique')) {
        addToast('error', 'Brukeren finnes allerede.')
      } else {
        addToast('error', 'En feil har oppstått')
      }
    },
  })

  useQuery<AllUserTypesQueryReturn>(USER_TYPES_QUERY, {
    onError() {
      addToast('error', 'En feil har oppstått')
    },
    onCompleted(data) {
      const { allUserTypes } = data
      const userTypes = allUserTypes.edges.map(n => n.node)
      setUserTypeOptions(
        userTypes.map(node => ({
          label: node.name,
          value: node.id,
        }))
      )
    },
  })

  const submitCreateUserForm = (values: typeof form) => {
    createUser({
      variables: {
        company: '',
        ...values,
      },
    })
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <ModalTitle>Opprett bruker</ModalTitle>
        <CloseModalIcon
          icon="times"
          size="36px"
          type="light"
          color="darkblue"
          margin="14px 0 0 0"
          onClick={closeModalCallback}
        />
      </HeaderWrapper>
      <BodyWrapper>
        <WidgetSection>
          <InputLabel>E-post</InputLabel>
          <Input
            value={form.email}
            error={formErrors.email}
            fullWidth
            autoFocus
            onChange={email => updateForm({ email })}
          />
        </WidgetSection>
        <WidgetSection>
          <InputLabel>Fornavn</InputLabel>
          <Input
            value={form.firstName}
            error={formErrors.firstName}
            fullWidth
            onChange={firstName => updateForm({ firstName })}
          />
        </WidgetSection>
        <WidgetSection>
          <InputLabel>Etternavn</InputLabel>
          <Input
            value={form.lastName}
            error={formErrors.lastName}
            fullWidth
            onChange={lastName => updateForm({ lastName })}
          />
        </WidgetSection>
        <WidgetSection>
          <InputLabel>Telefonnummer</InputLabel>
          <Input
            value={form.phoneNumber}
            error={formErrors.phoneNumber}
            fullWidth
            onChange={phoneNumber => updateForm({ phoneNumber })}
          />
        </WidgetSection>
        <WidgetSection>
          <InputLabel>Brukertype</InputLabel>
          <Select
            options={userTypeOptions}
            value={form.userType}
            width="100%"
            shadow="none"
            border={`1px solid ${theme.colors.darkBlue}`}
            onChange={userType => updateForm({ userType })}
          />
        </WidgetSection>
        <Button
          fullWidth
          primary
          height="48px"
          onClick={submitHandler(submitCreateUserForm)}
        >
          Opprett
        </Button>
      </BodyWrapper>
    </Wrapper>
  )
}
