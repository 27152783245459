import React, { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'

import Table from 'components/Table'
import Input from 'components-old/Input'
import Margin from 'components/Margin'
import { OfferRoomArticleNode } from 'types/graphql/offers'
import { useIntl } from 'react-intl'
import { getCurrentLocale } from 'util/locale'
import { safeParseFloat } from 'util/parsing'
import { useToast } from '@ur/react-components'

const ActionButton = styled.button<{ delete?: boolean }>`
  ${props => props.theme.resetButton};
  cursor: pointer;

  color: ${props => props.theme.colors.gray};

  :hover {
    color: ${props =>
      props.delete ? props.theme.colors.red : props.theme.colors.primary};
  }
`

type ExtendedArticle = OfferRoomArticleNode & { name: string; price: number }
interface RoomCardArticleRowProps {
  article: ExtendedArticle
  editMode: boolean
  onToggleEdit: () => void
  onArticleChanged: (article: ExtendedArticle) => void
  onArticleDeleted: (id: string) => void
}
const RoomCardArticleRow: React.FC<RoomCardArticleRowProps> = ({
  article,
  editMode,
  onToggleEdit,
  onArticleChanged,
  onArticleDeleted,
}) => {
  const countInputRef = useRef<HTMLInputElement | null>(null)
  const priceInputRef = useRef<HTMLInputElement | null>(null)

  const t = useIntl().formatMessage
  const translations = {
    countMustBeNumber: t({ id: 'offers.toasts.count-must-be-number' }),
    priceMustBeNumber: t({ id: 'offers.toasts.price-must-be-number' }),
  }

  const toasts = useToast()

  const [articleEditData, setArticleEditData] = useState({
    count: article.count.toString(),
    price: article.price.toString(),
  })

  const handleArticleChangedClick = useCallback(() => {
    const count = parseInt(articleEditData.count)
    const price = safeParseFloat(articleEditData.price)

    if (isNaN(count)) {
      toasts('warning', translations.countMustBeNumber)
      if (countInputRef.current) countInputRef.current.focus()
      return
    } else if (isNaN(price)) {
      toasts('warning', translations.priceMustBeNumber)
      if (priceInputRef.current) priceInputRef.current.focus()
      return
    }

    onToggleEdit()

    onArticleChanged({
      ...article,
      count,
      price,
    })
  }, [
    article,
    articleEditData.count,
    articleEditData.price,
    onToggleEdit,
    onArticleChanged,
    toasts,
    translations.countMustBeNumber,
    translations.priceMustBeNumber,
  ])

  const locale = getCurrentLocale()

  return (
    <Table.Row>
      {editMode ? (
        <React.Fragment>
          <Table.Cell>{article.name}</Table.Cell>
          <Table.Cell width="25%">
            <Input
              ref={countInputRef}
              value={articleEditData.count}
              type="number"
              autoFocus
              height="25px"
              fontSize="12px"
              width="auto"
              onChange={e =>
                setArticleEditData({
                  ...articleEditData,
                  count: e.target.value,
                })
              }
            />
          </Table.Cell>
          <Table.Cell width="25%">
            <Input
              ref={priceInputRef}
              value={articleEditData.price}
              type="text"
              height="25px"
              fontSize="12px"
              width="auto"
              onChange={e =>
                setArticleEditData({
                  ...articleEditData,
                  price: e.target.value,
                })
              }
            />
          </Table.Cell>
          <Table.Cell>
            {(article.price * article.count).toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            <ActionButton onClick={handleArticleChangedClick}>
              <i className="far fa-check"></i>
            </ActionButton>
          </Table.Cell>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Table.Cell>{article.name}</Table.Cell>
          <Table.Cell width="25%">{article.count}</Table.Cell>
          <Table.Cell width="25%">
            {article.price.toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            {(article.price * article.count).toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            <Margin inline>
              <ActionButton onClick={onToggleEdit}>
                <i className="far fa-edit"></i>
              </ActionButton>
            </Margin>
            <Margin left="1em" inline>
              <ActionButton delete onClick={() => onArticleDeleted(article.id)}>
                <i className="far fa-trash"></i>
              </ActionButton>
            </Margin>
          </Table.Cell>
        </React.Fragment>
      )}
    </Table.Row>
  )
}

export default RoomCardArticleRow
