export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms))

type DelayUntilCallbackFn<T> = (args: T) => void
/**
 * This is a helper function allowing us to
 * cause a delay until a callback is called. If
 * the returned function is called more than once, the previous
 * timeout is cleared, and a new one is created.
 * Example usage:
 *   <pre>
 *   <code>
 *      const delayWrapper = DelayUntil()
 *      // Will only log a click that is not succeeded by another click for
 *      // at least one second.
 *      element.addEventHandler('click', delayWrapper(console.log, 1000)))
 *   </code>
 *   </pre>
 *   ```
 */
export function DelayUntil() {
  let timer = 0
  return function <T>(callback: DelayUntilCallbackFn<T>, ms: number, args: T) {
    clearTimeout(timer)
    timer = window.setTimeout(callback.bind(null, args), ms)
  }
}
