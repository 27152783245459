import { DropdownMenu as BaseMenu, MenuItem } from 'components/DropdownMenu'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { ServiceOptionNode } from '../types.graphql'

const DropdownMenu = styled(BaseMenu)`
  .fa-ellipsis-v {
    font-size: 14px !important;
  }
`

const MenuWrapper = styled.div`
  height: 30px;
  .fa-ellipsis-v {
    font-size: 16px !important;
  }
`

interface OptionCardMenuProps {
  option: ServiceOptionNode
  onEdit: () => void
  onDelete: () => void
}

export const OptionCardMenu: React.UFC<OptionCardMenuProps> = ({
  onEdit,
  onDelete,
}) => {
  const { colors } = useTheme()
  const [openMenu, setOpenMenu] = useState(false)

  function handleEdit() {
    setOpenMenu(false)
    onEdit()
  }
  function handleDelete() {
    setOpenMenu(false)
    onDelete()
  }

  const menuItems: MenuItem[] = [
    {
      displayText: 'Endre',
      icon: {
        icon: 'edit',
        size: '17px',
        type: 'solid',
      },
      colorOverride: colors.darkBlue,
      mobileOnly: false,
      onClick: handleEdit,
    },
    {
      displayText: 'Slett',
      icon: {
        icon: 'trash-alt',
        size: '17px',
        type: 'solid',
      },
      mobileOnly: false,
      onClick: handleDelete,
    },
  ]
  return (
    <MenuWrapper>
      <DropdownMenu
        menuItems={menuItems}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        onBarsClick={() => setOpenMenu(true)}
      />
    </MenuWrapper>
  )
}
