import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components/Button'
import { SearchBar } from 'components/SearchBar'
import { Modal } from '@ur/react-components'

import UsersPrimaryList from './UsersPrimaryList'
import { CreateUserModal } from './CreateUserModal'
import { DelayUntil } from 'util/time'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'title search create'
    'table table  table';
  grid-column-gap: 48px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr 2fr auto;
  ${props => props.theme.defaultContentWrapper};

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`

const CreateUserWrapper = styled.div`
  grid-area: create;
  justify-self: end;
`

const TableWrapper = styled.div`
  grid-area: table;
  padding-bottom: 40px;
`

const UsersSearchBar = styled(SearchBar)`
  grid-area: search;
  justify-self: end;
  align-self: center;
  min-height: 48px;
  width: 80%;
  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const Title = styled.span`
  grid-area: title;
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const delay = DelayUntil()

export const Users: React.FC = () => {
  const [query, setQuery] = useState('')
  // This is the debounced version of query
  const [search, setSearch] = useState('')
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false)

  function handleQueryChanged(newValue) {
    setSearch(newValue)
  }

  const toggleCreateUserModal = () => {
    setOpenCreateUserModal(!openCreateUserModal)
  }

  return (
    <Wrapper>
      <Title>Ansatte</Title>
      <UsersSearchBar
        value={query}
        onChange={e => {
          setQuery(e)
          delay(handleQueryChanged, 500, e)
        }}
        fullWidth={isMobile}
        placeholder="Søk etter brukere..."
      />
      <CreateUserWrapper>
        <Button
          type="button"
          icon={{ size: '24px', icon: 'plus' }}
          onClick={toggleCreateUserModal}
        >
          <FormattedMessage
            id="users.createUser"
            defaultMessage="Create user"
          />
        </Button>
      </CreateUserWrapper>
      <Modal open={openCreateUserModal} onClose={toggleCreateUserModal}>
        <CreateUserModal closeModalCallback={toggleCreateUserModal} />
      </Modal>
      <TableWrapper>
        <UsersPrimaryList search={search} />
      </TableWrapper>
    </Wrapper>
  )
}
