import React, { useRef } from 'react'
import { Icon } from '@ur/react-components'
import styled, { useTheme } from 'styled-components'
import { ZIndexRange } from 'types/enums'
import useClickOutside from 'util/hooks/useClickOutside'
import { IconProps } from '@ur/react-components'
import { Loader } from '@ur/react-components'

interface ButtonWrapperProps {
  backgroundColor: string
}

const Wrapper = styled.div<ButtonWrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.veryLightGray};
  cursor: pointer;
`

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  cursor: pointer;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
`

const MenuWrapper = styled.aside`
  position: absolute;
  display: flex;
  width: 300px;
  flex-direction: column;
  top: calc(100% + 8px);
  right: calc(100% - 34px);
  background: ${props => props.theme.colors.veryLightGray};
  border-radius: 12px;

  border: 1px solid ${props => props.theme.colors.veryLightGray};
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.1);

  width: 270px;
  z-index: ${ZIndexRange.Dropdowns};

  padding: 0;
`
interface MobileProp {
  mobileOnly: boolean
}

const MenuItemWrapper = styled.div.attrs(props => ({
  color: props.theme.colors.red,
  ...props,
}))<MobileProp>`
  display: ${props => (props.mobileOnly ? 'none' : 'inline-flex')};
  color: ${props => props.color};
  align-items: center;
  padding: 14px 21px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    // We need something sensible here to distinguish hover states
    color: ${props => props.theme.colors.primary};
    i {
      color: ${props => props.theme.colors.primary};
    }
  }

  i {
    padding-right: 20px;
  }

  ${props => props.theme.media.mobile} {
    display: inline-flex;
  }
`

const CloseMenuWrapper = styled.div`
  display: flex;
  height: 25px;
  flex-direction: row;
  justify-content: flex-end;

  i {
    color: ${props => props.theme.colors.gray3};
  }

  &:hover {
    i {
      color: ${props => props.theme.colors.primary};
    }
  }
`

export type MenuItem = {
  displayText: string
  icon: IconProps
  mobileOnly: boolean
  colorOverride?: string

  onClick?: () => void
}

export interface DropdownMenuProps {
  menuItems: MenuItem[]
  loading?: boolean
  open: boolean
  backgroundColor?: string
  menuIconColor?: string
  onBarsClick: () => void
  onClose: () => void
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open,
  menuItems,
  loading,
  backgroundColor,
  menuIconColor = 'black',
  onBarsClick,
  onClose,
}) => {
  const menuRef = useRef<HTMLElement | null>(null)
  const theme = useTheme()

  useClickOutside(menuRef, onClose)

  if (loading) {
    return (
      <Wrapper backgroundColor={backgroundColor}>
        <Loader.Spinner size={12} />
      </Wrapper>
    )
  }

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <ButtonWrapper onClick={onBarsClick} backgroundColor={backgroundColor}>
        <Icon
          color={menuIconColor}
          icon="ellipsis-v"
          size="20px"
          type="solid"
          margin="10px"
        />
      </ButtonWrapper>
      {open && (
        <MenuWrapper ref={menuRef}>
          <CloseMenuWrapper onClick={onClose}>
            <Icon margin="10px 14px" icon="times" size="24px" type="light" />
          </CloseMenuWrapper>

          {menuItems.map((item, index) => (
            <MenuItemWrapper
              onClick={item.onClick}
              mobileOnly={item.mobileOnly}
              key={`dropdown-menu-item-${index}`}
              color={item.colorOverride ?? theme.colors.gray2}
            >
              <Icon color={item.colorOverride} {...item.icon} />
              <span>{item.displayText}</span>
            </MenuItemWrapper>
          ))}
        </MenuWrapper>
      )}
    </Wrapper>
  )
}
