import { OrderItemOptionNode } from 'modules/services/types.graphql'
import styled from 'styled-components'

const Wrapper = styled.div`
  & > div + div {
    margin-top: 5px;
    border-top: 1px solid ${props => props.theme.colors.gray4}55;
  }
`

const OptionItemLine = styled.div`
  padding-top: 5px;

  & > div + div {
    margin-top: 3px;
  }
  .--option-title {
    font-weight: 300;
  }
`

interface OrderItemOptionsCellProps {
  options: OrderItemOptionNode[]
}

export const OrderItemOptionsCell: React.UFC<OrderItemOptionsCellProps> = ({
  options,
}) => {
  return (
    <Wrapper>
      {options.map(option => (
        <OptionItemLine key={option.serviceOption.id}>
          <div className="--option-title">{option.serviceOption.name}</div>
          <div>{option.serviceOptionItem.name}</div>
        </OptionItemLine>
      ))}
    </Wrapper>
  )
}
