import React from 'react'
import { useQuery } from '@apollo/client'
import { USER_PRICELISTS_QUERY } from 'modules/articles/queries'
import Select from 'components/Select'
import { useUser } from 'util/hooks'

interface PriceListSelectorProps {
  pricelist: string
  width?: string
  onChange: (id: string) => void
}

export const PricelistSelector: React.UFC<PriceListSelectorProps> = ({
  pricelist,
  width,
  onChange,
}) => {
  const user = useUser()
  const { loading, data } = useQuery(USER_PRICELISTS_QUERY, {
    variables: {
      userId: user.id,
    },
  })
  let options = []

  if (!loading || (data && data.allPricelists)) {
    options = options.concat(
      data.allUserPricelists.edges.map(edge => ({
        label: edge.node.name,
        value: edge.node.id,
      }))
    )
  }

  return (
    <Select
      value={pricelist}
      options={options}
      width={width}
      onChange={onChange}
    />
  )
}
