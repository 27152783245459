import get from 'lodash/get'
import theme from 'theme'

/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param props The props passed to a component.
 */
interface ThemePropsWithColor {
  theme: typeof theme
  color: string
  [x: string]: any
}
export function overloadColorProp(props: ThemePropsWithColor, key = 'color') {
  return props[key] in props.theme.colors
    ? props.theme.colors[props[key]]
    : props[key]
}

export function overloadColor(color?: string, fallback: string | null = null) {
  if (typeof color === 'undefined') return null
  return get(theme.colors, color, fallback ?? color)
}
