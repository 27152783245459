import { useQuery } from '@apollo/client'
import { Button } from 'components/Button'
import FullLoader from 'components/Loader/FullLoader'
import PermissionsRequired from 'containers/PermissionsRequired'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import styled from 'styled-components'
import { PricelistQueryReturn } from 'types/graphql/articles'
import { IdParameter } from 'types/graphql/common'
import { ArticleTable } from './ArticleTable'
import { PRICELIST_QUERY } from './queries'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'title create'
    'table table';
  grid-column-gap: 48px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr auto;
  ${props => props.theme.defaultContentWrapper};

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`

const TableWrapper = styled.div`
  grid-area: table;
`

const Title = styled.h1`
  grid-area: title;
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const CreatePricelsitLink = styled(Link)`
  grid-area: create;
  align-self: center;
  justify-self: end;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`
interface PricelistProps {}

export const Pricelist: React.FC<PricelistProps> = () => {
  const { pricelistId } = useParams<{ pricelistId: string }>()

  const [{ setOverride }] = BreadcrumbsGraph.useGraph()

  const { loading, data } = useQuery<PricelistQueryReturn, IdParameter>(
    PRICELIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: pricelistId,
      },
      onCompleted(data) {
        if (!data) return
        setOverride(data.pricelist.id, data.pricelist.name)
      },
    }
  )

  if (!data || loading) return <FullLoader />

  return (
    <Wrapper>
      <Title>{data.pricelist.name}</Title>

      <PermissionsRequired permissions="articles.add_pricelist">
        <CreatePricelsitLink to={`/pricelists/${pricelistId}/edit`}>
          <Button type="button" primary icon={{ icon: 'edit' }}>
            Endre prisliste
          </Button>
        </CreatePricelsitLink>
      </PermissionsRequired>
      <TableWrapper>
        <ArticleTable id={pricelistId} showData={true} />
      </TableWrapper>
    </Wrapper>
  )
}
