import styled from 'styled-components'
import { useState } from 'react'
import { useGlobal } from '@ur/react-hooks'
import Select from 'components/Select'
import { Input } from 'components/Input'
import { SelectOption } from 'components/Select/Select'
import { OfferDisplayMode } from 'types/graphql/offers'
import TextArea from 'components/TextArea'
import { CreateOfferSummaryRoomRow } from './CreateOfferSummaryRoomRow'
import { CreateOfferSummaryArticlesCard } from './CreateOfferSummaryArticlesCard'
import { useEffect } from 'react'
import { useToast } from '@ur/react-components'
import { numberWithSpaces } from 'util/parsing'
import { StepControls } from '../common'
import { OfferPreview } from './OfferPreview'
import { OfferSummaryAccordion } from './OfferSummaryAccordion'
import { useOfferMutations } from '../mutations.hooks'
import { OfferNameInput } from '../AddConditions/OfferNameInput'
import { formatNumber } from '../util'

const DiscountInput = styled(Input)`
  width: 100%;
  height: 100%;
  input {
    border: 0;
    text-align: right;
    padding-right: 10px;

    &:focus {
      border: 0;
    }
  }
`

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  width: 108px;

  border: 1px solid black;
  border-radius: 4px;
`

const PercentageCharacter = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${props => props.theme.colors.gray3};
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 430px auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-column-gap: 40px;
  grid-row-gap: 25px;
  grid-template-areas:
    'offername preview'
    'display details'
    'customer details'
    'conditions details'
    'cost details'
    'buttons details'
    '. details';
  width: 100%;
  max-width: 1100px;
  height: auto;

  ${props => props.theme.media.mobile} {
    grid-template-areas:
      'offername'
      'display'
      'customer'
      'details'
      'conditions'
      'cost'
      'preview'
      'buttons';
    grid-template-columns: 100%;
    grid-row-gap: 0;
  }
`

const SectionLabel = styled.label`
  font-size: 20px;
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 10px;

  ${props => props.theme.media.mobile} {
    padding: 0 14px;
  }
`

const RoomCard = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;

  > div:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  > div:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  > :not(:last-child) {
    margin-bottom: 1px;
  }

  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  ${props => props.theme.media.mobile} {
    border-radius: 0;
  }
`

const DisplayOptionWrapper = styled.div`
  grid-area: display;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${props => props.theme.media.mobile} {
    .--display-option-label {
      display: none;
    }
    > div:last-child {
      padding: 0 16px;
      & > div:first-child {
        border: 1px solid #1f2461;
        border-radius: 5px;
        box-shadow: none;
      }
      & > div:last-child > ul {
        border: 1px solid #1f2461;
        border-top: none;
        border-radius: 5px;
        box-shadow: none;
      }
    }
    margin-bottom: 24px;
  }
`

const CustomerDetails = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 6px;
  color: ${props => props.theme.colors.darkBlue};
`

const CustomerWrapper = styled.div`
  grid-area: customer;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  .--customer-info {
    display: flex;
    align-items: baseline;
    width: 100%;
  }
  .--offer-summary-accordion {
    display: none;
  }
  ${props => props.theme.media.mobile} {
    .--customer-info {
      display: none;
    }
    .--offer-summary-accordion {
      display: block;
    }
    .--summary-accordion-content {
      padding: 10px;
      font-size: 18px;
      color: ${props => props.theme.colors.text.normal};
      font-weight: 400;
    }
  }
`

const ConditionsWrapper = styled.div`
  grid-area: conditions;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${props => props.theme.media.mobile} {
    display: none;
    > :last-child {
      padding: 0 14px;
    }
  }
`

const CostWrapper = styled.div`
  grid-area: cost;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const DetailsWrapper = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  height: 100%;
`

const CostCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  padding-top: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`

const CostCardRow = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 18px;
`

const CostCardRowLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
`

const CostCardRowValue = styled.div`
  font-size: 18px;
  color: ${props => props.theme.colors.darkBlue};
`

const PreviewWrapper = styled.div`
  grid-area: preview;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

const OfferNameWrapper = styled.div`
  grid-area: offername;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

const offerDisplayOptions: SelectOption<OfferDisplayMode>[] = [
  {
    value: 'SUMMARY_ONLY',
    label: 'Bare oppsummering',
  },
  {
    value: 'NO_PRICES',
    label: 'Ingen priser',
  },
  {
    value: 'PER_ROOM',
    label: 'Per rom',
  },
  {
    value: 'PER_ARTICLE',
    label: 'Per artikkel',
  },
]

export const CreateOfferSummary: React.FC = () => {
  const [offer] = useGlobal('offer')
  const [rooms] = useGlobal('offer.rooms')
  const [offerDisplayMode, setOfferDisplayMode] = useGlobal('offer.displayMode')
  const [conditions] = useGlobal('offer.conditions')
  const [discount, setDiscount] = useGlobal('offer.discount')
  const [discountPlaceholder, setDiscountPlaceholder] = useState(`${discount}`)
  const [markup, setMarkup] = useGlobal('offer.markup')

  const [markupPlaceholder, setMarkupPlaceholder] = useState(`${markup}`)

  const [totalPrice, setTotalPrice] = useState(0)
  const [priceWithDiscount, setPriceWithDiscount] = useState(0)

  const [showCost, setShowCost] = useState(false)

  const addToast = useToast()

  const { updateOffer, createOffer } = useOfferMutations()

  useEffect(() => {
    const displayCostOptions = ['PER_ROOM', 'PER_ARTICLE', 'SUMMARY_ONLY']
    setShowCost(displayCostOptions.includes(offerDisplayMode))
  }, [offerDisplayMode])

  // Calculating the total price of the offer
  useEffect(() => {
    const articles = rooms.map(room => room.articles)

    const flattenedArticles = articles.reduce(
      (flattenedArticles, roomArticles) => [
        ...flattenedArticles,
        ...roomArticles,
      ]
    )

    setTotalPrice(
      flattenedArticles.reduce((accumulatedPrice, article) => {
        return accumulatedPrice + article.count * article.price
      }, 0)
    )
  }, [rooms, setTotalPrice])

  // Calculating the discounted price
  useEffect(() => {
    if (
      isNaN(parseInt(discountPlaceholder)) ||
      isNaN(parseInt(markupPlaceholder))
    )
      return
    const discountFactor = (100 - parseInt(discountPlaceholder)) / 100
    const markupFactor = (100 + parseInt(markupPlaceholder)) / 100

    //Price after markup, discount and VAT rounded to to decimals
    const finalPrice =
      Math.round(totalPrice * markupFactor * discountFactor * 1.25 * 10) / 10

    setPriceWithDiscount(finalPrice)
  }, [
    discountPlaceholder,
    totalPrice,
    setPriceWithDiscount,
    discount,
    markupPlaceholder,
  ])

  const handleDiscountChange = value => {
    const parsedValue = parseInt(value)
    const isInvalidValue =
      isNaN(parsedValue) || parsedValue < 0 || parsedValue > 100

    if (isInvalidValue) {
      setDiscountPlaceholder('0')
      return
    }

    setDiscountPlaceholder(value)
    setDiscount(parsedValue)
  }

  const handleMarkupChange = value => {
    const parsedValue = parseInt(value)
    const isInvalidValue = isNaN(parsedValue) || parsedValue < 0

    if (isInvalidValue) {
      setMarkupPlaceholder('0')
      return
    }

    setMarkupPlaceholder(value)
    setMarkup(parsedValue)
  }

  const offerIsValid = (): boolean => {
    // Assuming we validate the room step and article step before moving
    // on to the next step the only possible "invalid" values are name
    // and displayMode

    // We could consider passing in some callbacks from the container component
    // which we invoke and render error messages. Could also set the
    // display option to an inital valid value, so that the dropdown never
    // can actually have an invalid value
    let message: string = ''

    if (offer.name === '') {
      message += '\u2022 Navn på tilbud kan ikke være tom \n'
    }

    if (offer.displayMode === null) {
      message += '\u2022 Visningsmodus mangler et gyldig valg'
    }

    if (message !== '') {
      message = 'Det er følgende mangler på skjemaet \n' + message
      alert(message)
      return false
    }

    return true
  }

  const handleSubmit = () => {
    // Here we split articles into Article and FreeArticle for the mutation.
    // We need to delete properties from all objects that don't exist in the actual model,
    // otherwise the mutation will trigger an error
    if (!offerIsValid()) return

    const rooms = offer.rooms.map(room => {
      return {
        name: room.name,
        area: room.area,
        floor: room.floor,
        versionId: room.versionId,
        comment: room.comment,
        articles: room.articles
          .filter(article => article.type === 'ARTICLE')
          .map(article => {
            return {
              id: article.id,
              price: article.price,
              count: article.count,
              versionId: article.versionId,
            }
          }),
        freeArticles: room.articles
          .filter(article => article.type === 'FREE_ARTICLE')
          .map(article => {
            return {
              name: article.name,
              price: article.price,
              count: article.count,
              versionId: article.versionId,
            }
          }),
      }
    })

    if (offer.status === 'ARCHIVED') {
      addToast('error', 'Kan ikke oppdatere arkivert tilbud')
    } else if (['CREATED', 'OPEN'].includes(offer.status)) {
      // The offer has not been sent yet, meaning we should just trigger an update so we dont start versioning the offer prematurely
      updateOffer({
        variables: {
          id: offer.id,
          status: offer.status,
          name: offer.name,
          displayMode: offer.displayMode,
          conditions: offer.conditions,
          customer: offer.customer.id,
          discount: offer.discount,
          markup: offer.markup,
          rooms: rooms,
        },
      })
    } else {
      createOffer({
        variables: {
          name: offer.name,
          conditions: offer.conditions,
          customer: offer.customer.id,
          rooms: rooms,
          discount: discount,
          markup: offer.markup,

          displayMode: offerDisplayMode,
          internalReferenceId: offer.internalReferenceId,
          previousVersion: offer.previousVersion,
        },
      })
    }
  }

  return (
    <Wrapper>
      <OfferNameWrapper>
        <OfferNameInput />
      </OfferNameWrapper>
      <PreviewWrapper>
        <OfferPreview />
      </PreviewWrapper>

      <DisplayOptionWrapper>
        <SectionLabel className="--display-option-label">
          Visningsmodus
        </SectionLabel>
        <Select
          value={offerDisplayMode}
          width="100%"
          onChange={setOfferDisplayMode}
          options={offerDisplayOptions}
        />
      </DisplayOptionWrapper>
      <CustomerWrapper>
        <div className="--customer-info">
          <SectionLabel>Kunde: </SectionLabel>
          <CustomerDetails> {offer.customer.name}</CustomerDetails>
        </div>
        <OfferSummaryAccordion title="Kunde">
          <CustomerDetails className="--summary-accordion-content">
            {' '}
            {offer.customer.name}
          </CustomerDetails>
        </OfferSummaryAccordion>
      </CustomerWrapper>
      <ConditionsWrapper>
        <SectionLabel>Vilkår</SectionLabel>
        <TextArea
          value={conditions}
          width="100%"
          minHeight="180px"
          borderRadius="5px"
          disabled
          noShadow
          onChange={() => {}}
        />
      </ConditionsWrapper>
      <CostWrapper>
        <OfferSummaryAccordion title="Kostnader">
          <CostCard className="--summary-accordion-content">
            {showCost && (
              <CostCardRow>
                <CostCardRowLabel>Total</CostCardRowLabel>
                <CostCardRowValue>
                  {numberWithSpaces(formatNumber(totalPrice))},- NOK
                </CostCardRowValue>
              </CostCardRow>
            )}
            <CostCardRow>
              <CostCardRowLabel>Rabatt</CostCardRowLabel>
              <DiscountWrapper>
                <DiscountInput
                  value={discountPlaceholder}
                  width="80px"
                  placeholder="0"
                  onChange={setDiscountPlaceholder}
                  onBlur={evt => handleDiscountChange(evt.target.value)}
                />
                <PercentageCharacter>%</PercentageCharacter>
              </DiscountWrapper>
            </CostCardRow>

            <CostCardRow>
              <CostCardRowLabel>Påslag</CostCardRowLabel>
              <DiscountWrapper>
                <DiscountInput
                  value={markupPlaceholder}
                  width="80px"
                  placeholder="0"
                  onChange={setMarkupPlaceholder}
                  onBlur={evt => handleMarkupChange(evt.target.value)}
                />
                <PercentageCharacter>%</PercentageCharacter>
              </DiscountWrapper>
            </CostCardRow>

            {showCost && (
              <CostCardRow>
                <CostCardRowLabel>Total inkl. Mva</CostCardRowLabel>
                <CostCardRowValue>
                  {numberWithSpaces(formatNumber(priceWithDiscount))},- NOK
                </CostCardRowValue>
              </CostCardRow>
            )}
          </CostCard>
        </OfferSummaryAccordion>
      </CostWrapper>
      <DetailsWrapper>
        <OfferSummaryAccordion title="Rom">
          <RoomCard className="--summary-accordion-content">
            {rooms.map((room, index) => (
              <CreateOfferSummaryRoomRow
                room={room}
                key={index}
                displayMode={offerDisplayMode}
                markup={markup}
              />
            ))}
          </RoomCard>
        </OfferSummaryAccordion>

        <OfferSummaryAccordion title="Vareoversikt">
          <CreateOfferSummaryArticlesCard
            className="--summary-accordion-content"
            rooms={rooms}
          />
        </OfferSummaryAccordion>
      </DetailsWrapper>

      <StepControls
        nextText="Ferdigstill"
        nextDisabled={!offerDisplayMode}
        onNext={handleSubmit}
      />
    </Wrapper>
  )
}
