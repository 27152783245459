import React from 'react'
import styled from 'styled-components'

interface FormElProps {
  display?: string
}
const FormEl = styled.form<FormElProps>`
  display: ${props => props.display};
`

interface FormProps {
  preventDefault?: boolean
  display?: string
  onSubmit: (event: React.FormEvent) => void
}

const Form: React.FC<FormProps> = ({
  preventDefault,
  children,
  display,
  onSubmit,
}) => {
  return (
    <FormEl
      display={display}
      onSubmit={event => {
        if (preventDefault) event.preventDefault()
        onSubmit(event)
      }}
    >
      {children}
    </FormEl>
  )
}

export default Form
