import Card from 'components/Card'
import Image from 'components/Image'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components'
import { CompanyNode } from 'types/graphql/companies'

const Wrapper = styled(Card)`
  display: grid;
  grid-template-rows: 90px 35px;
  align-items: center;
  align-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 175px;
  padding: 0;

  color: ${props => props.theme.colors.darkGray};
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadow.default};
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }

  & > * + * {
    margin-top: 1rem;
  }

  ${props => props.theme.media.smallScreen} {
    width: 100%;
  }
`

const CompanyLogo = styled.span`
  font-size: 40px;
`

interface SelectCompanyTileProps {
  company: CompanyNode
}

export const SelectCompanyTile: React.UFC<SelectCompanyTileProps> = ({
  company,
}) => {
  return (
    <Wrapper as="a" href={`/c/${company.shortName}/`}>
      <CompanyLogo>
        {company.companyLogo ? (
          <Image src={company.companyLogo} maxWidth="250px" />
        ) : (
          <i className="far fa-building"></i>
        )}
      </CompanyLogo>
      <Text fontSize="20px" color="gray">
        {company.fullName}
      </Text>
    </Wrapper>
  )
}
