import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import client from 'apollo'
import { removeLoginToken } from './util'

const Wrapper = styled.div`
  width: 100%;
  padding: 6em 0;
  text-align: center;
`

function Logout() {
  useEffect(() => {
    removeLoginToken()
    client.resetStore()
  })

  return (
    <Wrapper>
      <FormattedMessage id="common.loggingOut" defaultMessage="Logging out" />...
    </Wrapper>
  )
}

export default Logout
