import React from 'react'
import styled from 'styled-components'
import FullLoader from 'components/Loader/FullLoader'
import Margin from 'components/Margin'
import { Link, useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ROOM_TEMPLATE_QUERY } from './queries'
import {
  RoomTemplateQuery,
  RoomTemplateQueryParameters,
} from 'types/graphql/roomTemplates'
import RoomTemplateForm from './RoomTemplateForm'
import { useBreadcrumbs } from 'store/breadcrumbs-old'
import { useToast } from '@ur/react-components'

const Wrapper = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
`
const TitleHeader = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  margin-top: 0;
`

interface EditRoomTemplateProps {}

const EditRoomTemplate: React.FC<EditRoomTemplateProps> = () => {
  const match = useRouteMatch<{ templateId?: string }>()
  const breadcrumbs = useBreadcrumbs()
  const toasts = useToast()

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<RoomTemplateQuery, RoomTemplateQueryParameters>(
    ROOM_TEMPLATE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: false,
      variables: {
        id: match.params.templateId,
      },
      onCompleted({ roomTemplate }) {
        breadcrumbs.addOverride(
          match.params.templateId,

          roomTemplate.name
        )
      },
      onError(err) {
        toasts('error', 'Kunne ikke hente rommal')
      },
    }
  )

  if (queryLoading || !queryData) return <FullLoader />
  if (queryError) return <span>Error</span> // TODO error message

  return (
    <Wrapper>
      <Margin top="3em">
        <Link to={'/roomtemplates'}>
          <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
        </Link>
        <TitleHeader>Rediger rommal</TitleHeader>
      </Margin>
      <RoomTemplateForm initialData={queryData} />
    </Wrapper>
  )
}

export default EditRoomTemplate
