import React from 'react'
import styled from 'styled-components'

import Card from 'components/Card'
import Margin from 'components/Margin'

import RoomCard from './RoomCard'

const Wrapper = styled.div``

export default function RoomSummary({
  rooms,
  offer,
  onRoomAdded,
  onRoomChanged,
  onRoomDeleted,
  pricelist,
  priceOverrides,
  onArticleChanged,
}) {
  return (
    <Wrapper>
      {rooms.length === 0 ? (
        <Card>Ingen rom lagt til</Card>
      ) : (
        rooms.map(room => (
          <Margin key={room.id ?? room.localId} bottom="1em">
            <RoomCard
              key={room.localId}
              room={room}
              pricelist={pricelist}
              onAddArticle={article => {
                // Check if the article has a current price override.
                if (article.id in priceOverrides) {
                  article.price = priceOverrides[article.id]
                }

                let newRoomData = {}
                // Check if the article already exists in the room
                if (
                  room.articles.map(article => article.id).includes(article.id)
                ) {
                  // Add the number to the existing article
                  newRoomData = {
                    ...room,
                    articles: room.articles.map(existingArticle => {
                      if (existingArticle.id === article.id) {
                        existingArticle.count += article.count
                      }
                      return existingArticle
                    }),
                  }
                } else {
                  newRoomData = {
                    ...room,
                    articles: room.articles.concat(article),
                  }
                }
                onRoomChanged(room.localId, newRoomData)
              }}
              onAddFreeArticle={freeArticle => {
                const newRoomdata = {
                  ...room,
                  freeArticles: room.freeArticles.concat(freeArticle),
                }
                onRoomChanged(room.localId, newRoomdata)
              }}
              onArticleChanged={article => {
                const newRoomArticleData = room.articles.map(
                  existingArticle => {
                    if (existingArticle.id === article.id) {
                      return article
                    }
                    return existingArticle
                  }
                )
                onRoomChanged(
                  room.localId,
                  {
                    ...room,
                    articles: newRoomArticleData,
                  },
                  article
                )
              }}
              onFreeArticleChanged={freeArticle => {
                const newRoomArticleData = room.freeArticles.map(
                  existingArticle => {
                    if (existingArticle.id === freeArticle.id) {
                      return freeArticle
                    }
                    return existingArticle
                  }
                )
                onRoomChanged(room.localId, {
                  ...room,
                  freeArticles: newRoomArticleData,
                })
              }}
              onArticleDeleted={deletedArticle => {
                const newRoomArticleData = room.articles.filter(
                  article => article.id !== deletedArticle
                )
                onRoomChanged(room.localId, {
                  ...room,
                  articles: newRoomArticleData,
                })
              }}
              onFreeArticleDeleted={deletedFreeArticle => {
                const newRoomArticleData = room.freeArticles.filter(
                  article => article.id !== deletedFreeArticle
                )
                onRoomChanged(room.localId, {
                  ...room,
                  freeArticles: newRoomArticleData,
                })
              }}
              onCloneRoom={() => {
                onRoomAdded({ ...room })
              }}
              onRoomChanged={newRoom => {
                onRoomChanged(newRoom.localId, newRoom)
              }}
              onRoomDeleted={onRoomDeleted}
            />
          </Margin>
        ))
      )}
    </Wrapper>
  )
}
