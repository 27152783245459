import React from 'react'
import UserContext from 'context/UserContext'

export default function SuperuserPermissionsRequired({ children }) {
  return (
    <UserContext.Consumer>
      {user => {
        if (!user.isSuperuser) {
          return null
        }
        return children
      }}
    </UserContext.Consumer>
  )
}
