import styled from 'styled-components'
import { useState, useCallback } from 'react'
import Select from 'components/Select'
import { SelectOption } from 'components/Select/Select'

import { RoomDiffRow } from '../VersionControl/RoomDiffRow'
import { ArticlesDiffCard } from '../VersionControl/ArticlesDiffCard'
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { OFFER_DIFF_QUERY } from '../queries'
import { useHistory, useParams } from 'react-router'
import { numberWithSpaces } from 'util/parsing'
import { OfferDetailActions } from 'modules/offers/OfferDetails/OfferDetailActions'
import { DiffStates } from '../VersionControl/types'
import { CreateOfferSummaryArticlesCard } from '../OfferWizard/Summary/CreateOfferSummaryArticlesCard'
import { CreateOfferSummaryRoomRow } from '../OfferWizard/Summary/CreateOfferSummaryRoomRow'
import { getTranslatedStatus } from '../util'
import { formatNumber } from 'modules/offers/OfferWizard/util'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: grid;
  height: 100%;
  grid-template-columns: 215px 215px 245px 245px;
  grid-template-rows: auto auto auto auto auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 25px;
  grid-template-areas:
    'title title version actions'
    'customer customer details details'
    'conditions conditions details details'
    'cost cost details details'
    '. . details details';
  width: 100%;
  max-width: 900px;
  height: auto;

  margin-bottom: 1rem;

  ${props => props.theme.media.mobile} {
    padding: 15px;
    grid-template-areas:
      'title'
      'actions'
      'version'
      'customer'
      'details'
      'conditions'
      'cost'
      'buttons';
    grid-template-columns: 100%;
  }
`

const Title = styled.div`
  grid-area: title;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
  color: ${props => props.theme.colors.darkBlue};
  word-wrap: normal;
`

const SectionLabel = styled.label`
  font-size: 20px;
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 10px;

  ${props => props.theme.media.mobile} {
    padding: 0 14px;
  }
`
const RoomCard = styled.div`
  width: 100%;
  height: auto;

  border-radius: 10px;
  margin-bottom: 15px;

  > :first-child {
    border-radius: 10px 10px 0 0;
  }

  > :not(:last-child) {
    margin-bottom: 1px;
  }

  > :last-child {
    border-radius: 0 0 10px 10px;
  }

  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  ${props => props.theme.media.mobile} {
    border-radius: 0;
  }
`

const CustomerWrapper = styled.div`
  grid-area: customer;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: baseline;
`

const CustomerName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 18px;
`

const CustomerDetailsRow = styled.span`
  color: ${props => props.theme.colors.darkBlue};
  font-size: 16px;
  font-weight: 400;
`

const CustomerCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  width: 100%;
  height: 190px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  > span:not(:first-child) {
    margin-bottom: 16px;
  }
`

const ConditionsWrapper = styled.div`
  grid-area: conditions;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${props => props.theme.media.mobile} {
    > :last-child {
      padding: 0 14px;
    }
  }
`
const ConditionsContentWrapper = styled.div`
  display: flex;
  width: 100%;

  height: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-height: 100px;
  padding: 14px;
  border-radius: 10px;
  white-space: pre-wrap;
  max-height: 250px;
  overflow-y: scroll;
`

const CostWrapper = styled.div`
  grid-area: cost;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const DetailsWrapper = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const CostCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 10px;
`

const CostCardRow = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 18px;
`

const CostCardRowLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
`

const SelectVersionWrapper = styled.div`
  grid-area: version;
`

const CostCardRowValue = styled.div`
  font-size: 18px;
  color: ${props => props.theme.colors.darkBlue};
`

interface PriceDiffStates {
  state: Omit<DiffStates, 'deleted'>
}

const TotalPriceDiff = styled.div<PriceDiffStates>`
  font-size: 18px;
  color: ${props =>
    props.state === 'increased'
      ? props.theme.colors.success
      : props.state === 'decreased'
      ? props.theme.colors.danger
      : props.theme.colors.darkBlue};
`

const VersionSelectOption = styled.div`
  span:last-child {
    font-size: 12px;
    margin-left: 1rem;
  }
`

interface ParamProps {
  id: string
}

export const OfferDetails = () => {
  const history = useHistory()
  const params = useParams<ParamProps>()
  const [offerDiff, setOfferDiff] = useState(null)
  const [offer, setOffer] = useState(null)
  const [totalPriceDisplayString, setTotalPriceDisplayString] = useState('')
  const [priceDiffState, setPriceDiffState] =
    useState<Omit<DiffStates, 'deleted'>>('unaltered')
  const [versionOptions, setVersionOptions] = useState<SelectOption<string>[]>(
    []
  )

  const handleVersionSelect = (offerId: string) => {
    history.push(`/offers/${offerId}`)
  }

  const handleDeterminePriceDiffState = useCallback((): Omit<
    DiffStates,
    'deleted'
  > => {
    if (offerDiff === null) return
    const { totalPriceWithVatDiff: diff } = offerDiff
    if (diff > 0) {
      return 'increased'
    } else if (diff < 0) {
      return 'decreased'
    } else {
      return 'unaltered'
    }
  }, [offerDiff])

  useEffect(() => {
    if (offerDiff === null) return

    const priceDiffSign =
      offerDiff.totalCostAfterMarkupAndDiscountWithVatDiff <= 0 ? '' : '+'
    const priceString = numberWithSpaces(
      `${offerDiff.totalCostAfterMarkupAndDiscountWithVat}`
    )
    const priceDiffString = numberWithSpaces(
      `${offerDiff.totalCostAfterMarkupAndDiscountWithVatDiff}`
    )
    setTotalPriceDisplayString(
      `${priceString} (${priceDiffSign}${priceDiffString}),- NOK`
    )
    setPriceDiffState(handleDeterminePriceDiffState())
  }, [offerDiff, setTotalPriceDisplayString, handleDeterminePriceDiffState])

  useQuery(OFFER_DIFF_QUERY, {
    variables: {
      offerId: params.id,
    },
    fetchPolicy: 'network-only',
    onCompleted(returnData) {
      const { offerDiff } = returnData
      const { offer } = offerDiff

      const flattenedRooms = offer.rooms.map(room => {
        const parsedRoom = {
          ...room,
          freeArticles: [],
          articles: [
            ...room.articles.map(article => {
              const flattenedArticle = {
                ...article,
                ...article.article,
                type: 'ARTICLE',
              }
              flattenedArticle.price = article.priceOverride
              return flattenedArticle
            }),
            ...room.freeArticles.map(freeArticle => {
              return { ...freeArticle, type: 'FREE_ARTICLE' }
            }),
          ],
        }
        return parsedRoom
      })
      setOfferDiff(offerDiff)
      setOffer({ ...offer, rooms: flattenedRooms })
    },
  })

  useEffect(() => {
    if (offer === null) return

    const options: SelectOption<string>[] = offer.versionHistory.map(offer => ({
      value: offer.id,
      label: (
        <VersionSelectOption>
          <span>Versjon {offer.version}</span>
          <span>{getTranslatedStatus(offer.status)}</span>
        </VersionSelectOption>
      ),
    }))

    setVersionOptions(options)
  }, [offer, setVersionOptions])

  return (
    <Wrapper>
      <Title>{offer?.name}</Title>
      <SelectVersionWrapper>
        <Select
          value={params.id}
          onChange={handleVersionSelect}
          options={versionOptions}
          placeholder="Velg versjon"
          width="100%"
        />
      </SelectVersionWrapper>

      <OfferDetailActions offer={offer} />
      <CustomerWrapper>
        <SectionLabel>Kunde: </SectionLabel>
        <CustomerCard>
          <CustomerName> {offer?.customer.name}</CustomerName>
          <CustomerDetailsRow>{offer?.customer.address}</CustomerDetailsRow>
          <CustomerDetailsRow>
            {offer?.customer.postalCode}, {offer?.customer.city}
          </CustomerDetailsRow>
          <CustomerDetailsRow>{offer?.customer.phoneNumber}</CustomerDetailsRow>
          <CustomerDetailsRow>{offer?.customer.email}</CustomerDetailsRow>
        </CustomerCard>
      </CustomerWrapper>
      <ConditionsWrapper>
        <SectionLabel>Vilkår</SectionLabel>
        <ConditionsContentWrapper>{offer?.conditions}</ConditionsContentWrapper>
      </ConditionsWrapper>
      <CostWrapper>
        <SectionLabel>Kostnader</SectionLabel>
        <CostCard>
          <CostCardRow>
            <CostCardRowLabel>Total</CostCardRowLabel>
            <CostCardRowValue>
              {numberWithSpaces(
                offerDiff ? formatNumber(offerDiff.totalCostAfterMarkup) : ''
              )}
              ,- NOK
            </CostCardRowValue>
          </CostCardRow>
          <CostCardRow>
            <CostCardRowLabel>Rabatt</CostCardRowLabel>
            <CostCardRowValue>{offer?.discount}%</CostCardRowValue>
          </CostCardRow>

          <CostCardRow>
            <CostCardRowLabel>Total inkl. Mva</CostCardRowLabel>
            {offer?.version === 1 ? (
              <CostCardRowValue>
                {numberWithSpaces(
                  offer
                    ? formatNumber(offer.totalCostAfterMarkupAndDiscountWithVat)
                    : ''
                )}
                ,- NOK
              </CostCardRowValue>
            ) : (
              <TotalPriceDiff state={priceDiffState}>
                {totalPriceDisplayString}
              </TotalPriceDiff>
            )}
          </CostCardRow>
        </CostCard>
      </CostWrapper>
      {offer?.version === 1 ? (
        <DetailsWrapper>
          <SectionLabel>Rom</SectionLabel>
          <RoomCard>
            {offer?.rooms.map((room, index) => (
              <CreateOfferSummaryRoomRow
                markup={offer?.markup}
                room={room}
                key={index}
                displayMode={offer?.displayMode}
              />
            ))}
          </RoomCard>
          <SectionLabel>Vareoversikt</SectionLabel>
          <CreateOfferSummaryArticlesCard rooms={offer.rooms ?? []} />
        </DetailsWrapper>
      ) : (
        <DetailsWrapper>
          <SectionLabel>Rom</SectionLabel>
          <RoomCard>
            {offerDiff?.roomDiffs?.map((room, index) => (
              <RoomDiffRow
                room={room}
                key={index}
                showArticlePrices={offer?.displayMode === 'PER_ARTICLE'}
              />
            ))}
          </RoomCard>
          <SectionLabel>Vareoversikt</SectionLabel>
          <ArticlesDiffCard rooms={offerDiff ? offerDiff.roomDiffs : []} />
        </DetailsWrapper>
      )}
    </Wrapper>
  )
}
