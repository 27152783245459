import Center from 'components/Center'
import Margin from 'components/Margin'
import UserContext from 'context/UserContext'
import PropTypes from 'prop-types'
import React from 'react'
import { match, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import styled from 'styled-components'
import { isUndefined } from 'util/typechecks'

const ErrorMessage = styled.div`
  font-size: 24px;
  color: ${props => props.theme.colors.red};
`

interface PermissionsRequiredRouteProps extends RouteProps {
  permissions: string | string[]
  computedMatch: match<{ [param: string]: string }>
  alsoMe?: string
  component: React.FC<any>
  render?: (props: RouteComponentProps<any, any, any>) => any
}
const PermissionsRequiredRoute: React.FC<PermissionsRequiredRouteProps> = ({
  permissions,
  alsoMe,
  component: Component,
  render,
  computedMatch,
  ...rest
}) => {
  const id = computedMatch.params[alsoMe]
  if (!isUndefined(alsoMe) && isUndefined(id))
    throw new Error(`Parameter "${alsoMe}" not found in path.`)

  return (
    <UserContext.Consumer>
      {user => {
        const isMe = user.id === id
        const havePermission = []
          .concat(permissions)
          .every(permission => isMe || user.allPermissions.includes(permission))

        if (!havePermission) {
          return (
            <Margin top="20em">
              <Center>
                <ErrorMessage>
                  Du har dessverre ikke tillatelse til å se dette.
                </ErrorMessage>
              </Center>
            </Margin>
          )
        }

        return (
          <Route
            {...rest}
            render={props =>
              render ? render(props) : <Component {...props} />
            }
          />
        )
      }}
    </UserContext.Consumer>
  )
}

PermissionsRequiredRoute.propTypes = {
  component: PropTypes.any,
}

PermissionsRequiredRoute.defaultProps = {}

export default PermissionsRequiredRoute
