import { Button } from 'components/Button'
import Flex from 'components/Flex/Flex'
import Margin from 'components/Margin'
import Padding from 'components/Padding'
import { CompanySettingKeys } from 'modules/companies/CompanySettings/types'
import { CreateCustomer } from 'modules/customers'
import EditCustomer from 'modules/customers/EditCustomer'
import AddNewRoom from 'modules/offers/CreateOffer/AddNewRoom/AddNewRoom'
import AddRoomTemplate from 'modules/offers/CreateOffer/AddNewRoomTemplate/AddRoomTemplate'
import CreateOfferOverview from 'modules/offers/CreateOffer/Overview/CreateOfferOverview'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Prompt, Route, Switch, useHistory } from 'react-router-dom'
import { useModals } from 'store/modals'
import styled from 'styled-components'
import {
  OfferFullNode,
  OfferRoomNode,
  UpdateOfferMutationParameters,
  UpdateOfferMutationReturn,
} from 'types/graphql/offers'
import { useCompany, useUser } from 'util/hooks'
import { v4 as uuid } from 'uuid'
import { UPDATE_OFFER_MUTATION } from '../mutations'
import {
  clearLastEditOfferDraft,
  getLastEditOfferDraft,
  hasLastEditOfferDraft,
  setLastEditOfferDraft,
} from '../util'
import { useToast } from '@ur/react-components'

const EditOfferWrapper = styled.div`
  max-width: 1600px;
  width: 90%;
  margin: 0 auto;
  margin-top: 3rem;
  position: relative;
`

export interface OfferDataArticle {
  id: string
  identifier: string
  name: string
  price: number
  count: number
}
export interface OfferDataRoom extends Omit<OfferRoomNode, 'articles'> {
  localId: string
  articles: OfferDataArticle[]
}
export interface OfferData extends Omit<OfferFullNode, 'rooms'> {
  rooms: OfferDataRoom[]
}

interface EditOfferInnerProps {
  offer: OfferFullNode
}
const EditOfferInner: React.FC<EditOfferInnerProps> = ({ offer }) => {
  const history = useHistory()
  const { settings: companySettings } = useCompany()

  const [updateOfferMutation, { loading }] = useMutation<
    UpdateOfferMutationReturn,
    UpdateOfferMutationParameters
  >(UPDATE_OFFER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllOffers'],
    onCompleted(data) {
      clearLastEditOfferDraft(offer.id)
      toasts('success', 'Endret tilbud!')
      history.push(`/offers/${offer.id}`)
    },
  })

  // Bootstrap priceOverrides, and flatten articles
  const preparedPriceOverrides = {}
  const preparedOfferData: OfferData = {
    ...offer,
    rooms: offer.rooms.map(room => ({
      ...room,
      localId: room.id,
      articles: room.articles.map(roomArticle => {
        // If there exists a priceOverride we use this, otherwise we fallback to the articles own price field
        const articlePrice = roomArticle.priceOverride
          ? roomArticle.priceOverride
          : roomArticle.article.price
        preparedPriceOverrides[roomArticle.article.id] =
          roomArticle.priceOverride
        return {
          id: roomArticle.article.id,
          identifier: roomArticle.article.identifier,
          name: roomArticle.article.name,
          price: articlePrice,
          count: roomArticle.count,
        }
      }),
    })),
  }

  const toasts = useToast()
  const modals = useModals()

  const [offerData, setOfferData] = useState<OfferData>(preparedOfferData)
  const user = useUser()
  const defaultPricelist =
    user.userTypes?.filter(type => type.defaultPricelist !== null)?.pop()
      ?.defaultPricelist?.id ?? null
  const [selectedPricelist, setSelectedPricelist] = useState(defaultPricelist)
  const [priceOverrides, setPriceOverrides] = useState(preparedPriceOverrides)
  const [canLeaveUnprompted, setCanLeaveUnprompted] = useState(true)

  useEffect(() => {
    if (loading) {
      return
    }

    if (hasLastEditOfferDraft(offer.id)) {
      modals.addModal({
        title: 'Gjenopprett utkast',
        body: 'Du har en lagret endring på dette tilbudet. Vil du gjenopprette dette?',
        footer: ({ closeModal }) => (
          <Padding all="1em">
            <Flex justifyContent="space-between">
              <div>
                <Margin inline right="1em">
                  <Button
                    width="100px"
                    primary
                    onClick={() => {
                      closeModal()
                      setOfferData(getLastEditOfferDraft(offer.id) as OfferData)
                    }}
                  >
                    Ja
                  </Button>
                </Margin>
                <Button width="100px" primary onClick={() => closeModal()}>
                  Nei
                </Button>
              </div>
              <Button
                buttonStyle="danger"
                onClick={() => {
                  clearLastEditOfferDraft(offer.id)
                  closeModal()
                }}
              >
                Slett utkast
              </Button>
            </Flex>
          </Padding>
        ),
      })
    }
    // eslint-disable-next-line
  }, [offer, setOfferData, loading])

  function handleOfferDataChanged(newOfferData) {
    if (canLeaveUnprompted) {
      setCanLeaveUnprompted(false)
    }
    setLastEditOfferDraft(offer.id, newOfferData)
    setOfferData(newOfferData)
  }

  function handleArticleChanged(changedArticle) {
    // Propagate new article price through data
    const newOfferData = {
      ...offerData,
      rooms: offerData.rooms.map(room => ({
        ...room,
        articles: room.articles.map(article => {
          if (article.id === changedArticle.id) {
            return {
              ...article,
              price: changedArticle.price,
            }
          }
          return article
        }),
      })),
    }

    handleOfferDataChanged(newOfferData)
  }

  function handleRoomChanged(
    roomId: string,
    changedRoom,
    updatedArticle = null
  ) {
    const newOfferData = {
      ...offerData,
      rooms: offerData.rooms.map(room => {
        if (room.localId === roomId) {
          return changedRoom
        } else {
          // Updated article price if needed
          if (updatedArticle) {
            return {
              ...room,
              articles: room.articles.map(article => {
                if (article.id === updatedArticle.id) {
                  return {
                    ...article,
                    price: updatedArticle.price,
                  }
                }
                return article
              }),
            }
          }
          return room
        }
      }),
    }

    if (updatedArticle) {
      setPriceOverrides({
        ...priceOverrides,
        [updatedArticle.id]: updatedArticle.price,
      })
    }

    // Handle articleUpdates
    handleOfferDataChanged(newOfferData)
  }

  function handleRoomDeleted(roomId: string) {
    const newOfferData = {
      ...offerData,
      rooms: offerData.rooms.filter(room => room.localId !== roomId),
    }
    handleOfferDataChanged(newOfferData)
  }

  function handleSubmit() {
    setCanLeaveUnprompted(true)

    let conditionsHasChanged = true
    const relevantSetting = companySettings.find(
      setting => setting.key === CompanySettingKeys.OfferConditions
    )
    if (
      relevantSetting !== null &&
      relevantSetting !== undefined &&
      relevantSetting.value === offerData.conditions
    )
      conditionsHasChanged = false

    updateOfferMutation({
      variables: {
        id: offer.id,
        status: 'CREATED',
        name: offerData.name,
        conditions: conditionsHasChanged ? offerData.conditions || null : null,
        discount: offerData.discount,
        markup: offerData.markup,
        displayMode: offerData.displayMode,
        customer: offerData.customer.id,
        rooms: offerData.rooms.map(room => ({
          name: room.name,
          floor: room.floor,
          area: room.area,
          comment: room.comment,
          articles: room.articles.map(article => ({
            id: article.id,
            price: article.price,
            count: article.count,
          })),
          freeArticles: room.freeArticles.map(freeArticle => ({
            name: freeArticle.name,
            price: freeArticle.price,
            count: freeArticle.count,
          })),
        })),
      },
    })
  }

  const basePath = `/offers/${offer.id}/edit`
  return (
    <EditOfferWrapper>
      <Prompt
        when={!canLeaveUnprompted}
        message={location => {
          if (location.pathname.startsWith(`/offers/${offer.id}/edit`)) {
            return true
          }
          return 'Tilbudet ditt er ikke opprettet enda. Vil du fortsette?'
        }}
      />
      <Switch>
        <Route
          path={`${basePath}/add-room-template`}
          render={() => (
            <AddRoomTemplate
              basePath={basePath}
              onSubmit={room => {
                handleOfferDataChanged({
                  ...offerData,
                  rooms: [room, ...offerData.rooms],
                })
                history.push(basePath)
              }}
            />
          )}
        />
        <Route
          path={`${basePath}/add-room`}
          render={() => (
            <AddNewRoom
              basePath={basePath}
              onSubmit={data => {
                handleOfferDataChanged({
                  ...offerData,
                  rooms: [
                    { ...data, localId: uuid(), articles: [] },
                    ...offerData.rooms,
                  ],
                })
                history.push(basePath)
              }}
            />
          )}
        />
        <Route
          path={`${basePath}/add-customer`}
          render={() => (
            <CreateCustomer
              onCreated={customer =>
                handleOfferDataChanged({
                  ...offerData,
                  customer: customer,
                })
              }
            />
          )}
        />
        <Route
          path={`${basePath}/edit-customer/:customerId`}
          render={() => (
            <EditCustomer
              onEdited={customer =>
                handleOfferDataChanged({
                  ...offerData,
                  customer: customer,
                })
              }
            />
          )}
        />
        <Route
          path={basePath}
          render={() => (
            <CreateOfferOverview
              basePath={basePath}
              continueText="Lagre og gå videre"
              name={offerData.name}
              conditions={offerData.conditions}
              customer={offerData.customer}
              rooms={offerData.rooms}
              pricelist={selectedPricelist}
              priceOverrides={priceOverrides}
              onNameChanged={name =>
                handleOfferDataChanged({ ...offerData, name })
              }
              onConditionsChanged={conditions =>
                handleOfferDataChanged({ ...offerData, conditions })
              }
              onPricelistChanged={newPricelist =>
                setSelectedPricelist(newPricelist)
              }
              onRoomAdded={room =>
                handleOfferDataChanged({
                  ...offerData,
                  rooms: [{ ...room, localId: uuid() }, ...offerData.rooms],
                })
              }
              onRoomChanged={handleRoomChanged}
              onRoomDeleted={handleRoomDeleted}
              onArticleChanged={handleArticleChanged}
              onCustomerChanged={customer =>
                handleOfferDataChanged({ ...offerData, customer: customer })
              }
              onSubmit={handleSubmit}
            />
          )}
        />
      </Switch>
    </EditOfferWrapper>
  )
}

export default EditOfferInner
