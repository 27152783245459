import React from 'react'
import styled from 'styled-components'
import { PATCH_USER_MUTATION } from '../mutations'

import { Text } from 'components/Text'

import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import useOnErrorAuto from 'util/hooks/error-handling'
import { useForm } from '@ur/react-hooks'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import {
  UserNode,
  PatchUserMutation,
  PatchUserMutationVariables,
} from 'types/graphql/users'

const Wrapper = styled.div`
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  box-sizing: border-box;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

interface EditUserFormInitalData {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: string
  postalCode: string
  city: string
}

type EditUserForm = Omit<EditUserFormInitalData, 'id'>

interface ChangeBasicInfoProps {
  user: UserNode
  area?: string
}

export const ChangeBasicInfo: React.UFC<ChangeBasicInfoProps> = ({ user }) => {
  const onErrorAuto = useOnErrorAuto()
  const toasts = useToast()

  const {
    formValues: form,
    formValid,
    formErrors: errors,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<EditUserForm>({
    values: {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      phoneNumber: user.phoneNumber ?? '',
      address: user.address ?? '',
      postalCode: user.postalCode ?? '',
      city: user.city ?? '',
    },
    validators: {
      firstName: val => (!!val ? null : 'Kan ikke være tom'),
      lastName: val => (!!val ? null : 'Kan ikke være tom'),
      email: val => (!!val ? null : 'Kan ikke være tom'),
      phoneNumber: val => (!!val ? null : 'Kan ikke være tom'),
      address: val => (!!val ? null : 'Kan ikke være tom'),
      postalCode: val => (!!val ? null : 'Kan ikke være tom'),
      city: val => (!!val ? null : 'Kan ikke være tom'),
    },
  })

  const [patchUser] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Me'],
    onCompleted() {
      toasts('success', 'Lagret info ')
    },
    onError: onErrorAuto('Kunne ikke lagre info'),
  })

  function handleEditBasicInfo() {
    patchUser({
      variables: {
        id: user.id,
        input: { ...form },
      },
    })
  }

  return (
    <Wrapper>
      <h3>
        <Text color="primary">Endre info</Text>
      </h3>
      <FormWrapper>
        <Input
          value={form.firstName}
          placeholder="Fornavn"
          label="Fornavn"
          error={errors.firstName}
          fullWidth
          onChange={handler('firstName')}
        />
        <Input
          value={form.lastName}
          placeholder="Etternavn"
          label="Etternavn"
          error={errors.lastName}
          fullWidth
          onChange={handler('lastName')}
        />
        <Input
          value={form.email}
          placeholder="E-post"
          label="E-post"
          error={errors.email}
          fullWidth
          onChange={handler('email')}
        />
        <Input
          value={form.phoneNumber}
          placeholder="Telefonnummer"
          label="Telefonnummer"
          error={errors.phoneNumber}
          fullWidth
          onChange={handler('phoneNumber')}
        />
        <Input
          value={form.address}
          placeholder="Adresse"
          label="Adresse"
          error={errors.address}
          fullWidth
          onChange={handler('address')}
        />
        <Input
          value={form.postalCode}
          placeholder="Postnummer"
          label="Postnummer"
          error={errors.postalCode}
          fullWidth
          onChange={handler('postalCode')}
        />
        <Input
          value={form.city}
          placeholder="By"
          label="By"
          error={errors.city}
          fullWidth
          onChange={handler('city')}
        />
        <Button
          width="100%"
          disabled={!formValid}
          onClick={submitHandler(handleEditBasicInfo)}
        >
          Lagre
        </Button>
      </FormWrapper>
    </Wrapper>
  )
}
