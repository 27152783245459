import styled from 'styled-components'
import { useState, useRef } from 'react'
import { useClickOutside } from '@ur/react-hooks'
import { WizardOffer } from 'modules/offers/OfferWizard/types'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import TextArea from 'components/TextArea'
import { defaultBody } from '../consts'
import { Icon, useToast } from '@ur/react-components'
import { useMutation, useQuery } from '@apollo/client'
import { SEND_OFFER_EMAIL } from '../mutations'
import { EMAIL_TEMPLATE_BY_COMPANY_QUERY } from '../queries'
import { useCompany } from 'util/hooks'
import Loader from 'components/Loader'

import {
  SendOfferEmailMutationReturn,
  SendOfferEmailMutationParameters,
  EmailTemplateNodeReturn,
  EmailTemplateParameters,
} from 'types/graphql/offers'

const Wrapper = styled.div`
  width: 600px;
  height: 420px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  display: grid;
  padding: 14px 32px;
  grid-template-areas:
    'title title title'
    'recipient recipient recipient'
    'body body body'
    'close . send';
  grid-template-columns: 2fr 1fr 2fr;

  ${props => props.theme.media.mobile} {
    width: 90vw;
    height: auto;
    grid-row-gap: 15px;
  }
`

const StyledLoader = styled(Loader)`
  margin: 10% 0;
  margin-left: 50%;
`
const Help = styled.aside`
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 0.8rem;

  background: white;
  border-radius: 4px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  user-select: none;

  h6 {
    margin: 0;
    font-size: 1rem;
    color: #222;
  }
  p {
    margin: 0;
    font-size: 0.8rem;
    font-style: italic;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-top: 0.4rem;

      em {
        font-weight: bold;
        color: ${props => props.theme.colors.lightPrimary};
        user-select: all;
      }
    }
  }
`

const Title = styled.h2`
  grid-area: title;
  margin: 0;
`

const CloseButton = styled(Button)`
  grid-area: close;
`

const SendButton = styled(Button)`
  grid-area: send;
`

const RecipientInputWrapper = styled.div`
  grid-area: recipient;
`

const BodyInputWrapper = styled.div`
  grid-area: body;
  display: flex;
  flex-direction: column;
`
const BodyLabelHelpWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

interface OfferEmailClientModalProps {
  offer: WizardOffer
  onClose: () => void
}

export const OfferEmailClientModal: React.FC<OfferEmailClientModalProps> = ({
  offer,
  onClose,
}) => {
  const { id: companyId } = useCompany()
  const { loading: loadingTemplateQuery } = useQuery<
    EmailTemplateNodeReturn,
    EmailTemplateParameters
  >(EMAIL_TEMPLATE_BY_COMPANY_QUERY, {
    variables: {
      companyId,
    },
    onCompleted: ({ emailTemplate }) => {
      setBody(emailTemplate.templateText)
    },
  })

  const [recipient, setRecipient] = useState(offer.customer.email)
  const [body, setBody] = useState(defaultBody)
  const [helpOpen, setHelpOpen] = useState(false)
  const helpRef = useRef()
  const addToast = useToast()

  const [sendOffer, { loading }] = useMutation<
    SendOfferEmailMutationReturn,
    SendOfferEmailMutationParameters
  >(SEND_OFFER_EMAIL, {
    onCompleted({ sendOfferEmail }) {
      const { ok, smsOk } = sendOfferEmail

      ok
        ? addToast('success', 'E-post med tilbud har blitt sendt')
        : addToast('error', 'Noe gikk galt ved sending av e-post')

      smsOk
        ? addToast('success', 'SMS med tilbud har blitt sendt')
        : addToast('error', 'Noe gikk galt ved sending av sms')

      if (ok || smsOk) onClose()
    },
  })

  useClickOutside(helpRef, () => setHelpOpen(false), true)

  const handleSendOffer = () => {
    sendOffer({
      variables: {
        id: offer.id,
        body: body,
        recipientEmail: recipient,
      },
      refetchQueries: ['AllOffers'],
    })
  }

  return (
    <Wrapper>
      <Title>{offer.name}</Title>
      <RecipientInputWrapper>
        <Input
          value={recipient}
          onChange={setRecipient}
          label="Kundens e-post"
          fullWidth
        />
      </RecipientInputWrapper>

      <BodyInputWrapper>
        <BodyLabelHelpWrapper ref={helpRef}>
          <span>E-postens innhold</span>
          <Icon
            type="solid"
            icon="question-circle"
            color="primary"
            hoverColor="lightPrimary"
            onClick={() => setHelpOpen(v => !v)}
          />
          {helpOpen && (
            <Help>
              <h6>Brukerveiledning:</h6>
              <p>Du kan bruke disse nøkkelordene i epostens innhold:</p>
              <ul>
                <li>
                  <em>&#123;customer_name&#125;</em> Navn på kunden forbundet
                  med tilbudet
                </li>
                <li>
                  <em>&#123;offer_name&#125;</em> Navn på tilbudet
                </li>
                <li>
                  <em>&#123;company_name&#125;</em> Navn på firmaet som har gitt
                  tilbudet
                </li>
                <li>
                  <em>&#123;company_contact_email&#125;</em> Epost til firmaets
                  kontaktperson
                </li>
                <li>
                  <em>&#123;company_contact_phone&#125;</em> Telefonnummer til
                  firmaets kontaktperson
                </li>
                <li>
                  <em>&#123;sending_user_name&#125;</em> Navnet til den
                  innloggede brukeren
                </li>
                <li>
                  <em>&#123;sending_user_email&#125;</em> Epost-adressen til den
                  innloggede brukeren
                </li>
              </ul>
            </Help>
          )}
        </BodyLabelHelpWrapper>
        {loadingTemplateQuery ? (
          <StyledLoader />
        ) : (
          <TextArea
            value={body}
            onChange={evt => {
              setBody(evt.target.value)
            }}
            width="100%"
            minHeight="200px"
          />
        )}
      </BodyInputWrapper>
      <CloseButton
        buttonStyle="danger"
        icon={{ icon: 'times' }}
        onClick={onClose}
      >
        Lukk klient
      </CloseButton>
      <SendButton
        buttonStyle="success"
        icon={{ icon: 'paper-plane' }}
        loading={loading}
        onClick={handleSendOffer}
      >
        Send tilbud
      </SendButton>
    </Wrapper>
  )
}
