import React from 'react'
import { UserNode } from 'types/graphql/users'

export const UserContext = React.createContext<UserNode | null>(null)

export function withUser<T>(Component: React.FC<T>) {
  return function WrapperComponent(props: T) {
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
      </UserContext.Consumer>
    )
  }
}

export default UserContext
