import styled from 'styled-components'
import { CSSTextAlign } from 'types/css/rules'

export interface TableCellProps {
  fontSize?: string
  textAlign?: CSSTextAlign

  height?: string
  width?: string
  background?: string
  padding?: string

  onClick?: (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) => void
}

export const TableCell = styled.td.attrs((props: TableCellProps) => ({
  boxSizing: 'border-box',
  padding: '0  1.5625rem',
  height: '64px',
  background: '#fff',
  ...props,
}))`
  box-sizing: ${props => props.boxSizing};
  padding: ${props => props.padding};
  height: ${props => props.height};
  width: ${props => props.width};

  background: ${props => props.background};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  cursor: ${props => props.onClick && 'pointer'};
`
