import uniqueId from 'lodash/uniqueId'
import { sleep } from 'util/time'
import traph from 'react-traph'
import { ToastType } from 'types/toasts'

interface GraphData {
  toasts: ToastType[]
}

const ToastGraph = traph({
  toasts: [] as ToastType[],
  async addToast(type: string, message: string, life = 3000) {
    const nextId = uniqueId('toast')
    this.updateGraph({
      toasts: this.toasts.concat({
        id: nextId,
        type,
        message,
        life,
        remove: () => this.removeToast(nextId),
      }),
    })
    await sleep(life)
    this.removeToast(nextId)
  },
  async removeToast(id: string) {
    this.updateGraph((data: GraphData) => ({
      ...data,
      toasts: data.toasts.filter(toast => toast.id !== id),
    }))
  },
})

export default ToastGraph
