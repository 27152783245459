import { useToast } from '@ur/react-components'
import Input from 'components-old/Input'
import Margin from 'components/Margin'
import Table from 'components/Table'
import React, { useCallback, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { OfferRoomFreeArticleNode } from 'types/graphql/offers'
import { getCurrentLocale } from 'util/locale'
import { safeParseFloat } from 'util/parsing'

const ActionButton = styled.button<{ delete?: boolean }>`
  ${props => props.theme.resetButton};
  cursor: pointer;

  color: ${props => props.theme.colors.gray};

  :hover {
    color: ${props =>
      props.delete ? props.theme.colors.red : props.theme.colors.primary};
  }
`

interface RoomCardFreeArticleRowProps {
  freeArticle: OfferRoomFreeArticleNode
  editMode: boolean
  onToggleEdit: () => void
  onFreeArticleChanged: (article: OfferRoomFreeArticleNode) => void
  onFreeArticleDeleted: (id: string) => void
}
const RoomCardFreeArticleRow: React.FC<RoomCardFreeArticleRowProps> = ({
  freeArticle,
  editMode,
  onToggleEdit,
  onFreeArticleChanged,
  onFreeArticleDeleted,
}) => {
  const countInputRef = useRef<HTMLInputElement | null>(null)
  const priceInputRef = useRef<HTMLInputElement | null>(null)

  const t = useIntl().formatMessage
  const translations = {
    countMustBeNumber: t({ id: 'offers.toasts.count-must-be-number' }),
    priceMustBeNumber: t({ id: 'offers.toasts.price-must-be-number' }),
  }

  const toasts = useToast()

  const [articleEditData, setArticleEditData] = useState({
    name: freeArticle.name,
    count: freeArticle.count.toString(),
    price: freeArticle.price.toString(),
  })

  const handleArticleChangedClick = useCallback(() => {
    const count = parseInt(articleEditData.count)
    const price = safeParseFloat(articleEditData.price)

    if (isNaN(count)) {
      toasts('warning', translations.countMustBeNumber)
      if (countInputRef.current) countInputRef.current.focus()
      return
    } else if (isNaN(price)) {
      toasts('warning', translations.priceMustBeNumber)
      if (priceInputRef.current) priceInputRef.current.focus()
      return
    }

    onToggleEdit()

    onFreeArticleChanged({
      ...freeArticle,
      name: articleEditData.name,
      count,
      price,
    })
  }, [
    freeArticle,
    articleEditData.name,
    articleEditData.count,
    articleEditData.price,
    onToggleEdit,
    onFreeArticleChanged,
    toasts,
    translations.countMustBeNumber,
    translations.priceMustBeNumber,
  ])

  const locale = getCurrentLocale()

  return (
    <Table.Row>
      {editMode ? (
        <React.Fragment>
          <Table.Cell width="25%">
            <Input
              value={articleEditData.name}
              autoFocus
              height="25px"
              fontSize="12px"
              width="auto"
              onChange={e =>
                setArticleEditData({ ...articleEditData, name: e.target.value })
              }
            />
          </Table.Cell>
          <Table.Cell width="20%">
            <Input
              ref={countInputRef}
              value={articleEditData.count}
              type="number"
              height="25px"
              fontSize="12px"
              width="auto"
              onChange={e =>
                setArticleEditData({
                  ...articleEditData,
                  count: e.target.value,
                })
              }
            />
          </Table.Cell>
          <Table.Cell width="20%">
            <Input
              ref={priceInputRef}
              value={articleEditData.price}
              type="text"
              height="25px"
              fontSize="12px"
              width="auto"
              onChange={e =>
                setArticleEditData({
                  ...articleEditData,
                  price: e.target.value,
                })
              }
            />
          </Table.Cell>
          <Table.Cell>
            {(freeArticle.price * freeArticle.count).toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            <ActionButton onClick={handleArticleChangedClick}>
              <i className="far fa-check"></i>
            </ActionButton>
          </Table.Cell>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Table.Cell>{freeArticle.name}</Table.Cell>
          <Table.Cell width="25%">{freeArticle.count}</Table.Cell>
          <Table.Cell width="25%">
            {freeArticle.price.toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            {(freeArticle.price * freeArticle.count).toLocaleString(locale)} kr
          </Table.Cell>
          <Table.Cell>
            <Margin inline>
              <ActionButton onClick={onToggleEdit}>
                <i className="far fa-edit"></i>
              </ActionButton>
            </Margin>
            <Margin left="1em" inline>
              <ActionButton
                delete
                onClick={() => {
                  onToggleEdit()
                  onFreeArticleDeleted(freeArticle.id)
                }}
              >
                <i className="far fa-trash"></i>
              </ActionButton>
            </Margin>
          </Table.Cell>
        </React.Fragment>
      )}
    </Table.Row>
  )
}

export default RoomCardFreeArticleRow
