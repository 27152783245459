import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import Card from 'components/Card'
import Margin from 'components/Margin'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import ArticleSelector from '../Selectors/ArticleSelector'
import Flex from 'components/Flex/index'
import Table from 'components/Table'
import Input from 'components-old/Input'
import Rule from 'components/Rule/Rule'
import RoomCardArticleRow from './RoomCardArticleRow'
import EasyForm from 'components/Form/EasyForm'
import { notEmptyValidation } from 'util/forms'
import { v4 as uuid } from 'uuid'
import RoomCardFreeArticleRow from './RoomCardFreeArticleRow'
import { Tooltip } from 'react-tippy'
import { safeParseFloat } from 'util/parsing'

const EditButton = styled.button`
  ${props => props.theme.resetButton};
  cursor: pointer;
  font-size: 16px;

  color: ${props => props.theme.colors.gray};

  :hover {
    color: ${props =>
      props.delete ? props.theme.colors.red : props.theme.colors.primary};
  }
`

export default function RoomCard({
  room,
  onRoomChanged,
  onRoomDeleted,
  onAddArticle,
  onAddFreeArticle,
  onCloneRoom,
  pricelist,
  toggleEdit,
  onArticleChanged,
  onArticleDeleted,
  onFreeArticleChanged,
  onFreeArticleDeleted,
}) {
  const [newArticle, setNewArticle] = useState({})
  const [count, setCount] = useState(1)
  const [articlesUnderEdit, setArticlesUnderEdit] = useState([])
  const [freeArticlesUnderEdit, setFreeArticlesUnderEdit] = useState([])
  const [editBaseInfo, setEditBaseInfo] = useState(false)

  const numArticles = room.articles.length + room.freeArticles.length

  const articleSelectorRef = useRef({ clear: () => null })

  return (
    <Card>
      <Margin bottom="1em">
        {editBaseInfo ? (
          <Flex justifyContent="space-between">
            <div>
              <EasyForm
                inline
                fields={[
                  {
                    name: 'name',
                    initialValue: room.name,
                    label: 'Navn',
                    validate: notEmptyValidation,
                    extra: { props: { height: '35px', width: 'auto' } },
                  },
                  {
                    name: 'floor',
                    label: 'Etasje',
                    initialValue: room.floor,
                    type: 'number',
                    errorMessage: 'Må være større eller lik 0',
                    validate: val => val >= 0,
                    extra: { props: { height: '35px', width: '80px' } },
                  },
                  {
                    name: 'area',
                    label: 'Areal',
                    initialValue: room.area,
                    type: 'text',
                    errorMessage: 'Må være et desimaltall',
                    validate: val => val && !isNaN(safeParseFloat(val)),
                    extra: { props: { height: '35px', width: '80px' } },
                  },
                  {
                    name: 'comment',
                    initialValue: room.comment,
                    type: 'text',
                    label: 'Kommentar',
                    validate: notEmptyValidation,
                    extra: { props: { height: '35px', width: 'auto' } },
                  },
                ]}
                submitButtonText="Ferdig"
                onSubmit={data => {
                  setEditBaseInfo(false)
                  onRoomChanged({
                    ...room,
                    ...data,
                    area: safeParseFloat(data.area),
                  })
                }}
              />
            </div>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <div>
              <Text block fontSize="1.5em" color="darkGray">
                {room.name}
              </Text>
              <Text block color="gray">
                {room.floor} etg. — {room.area} m²
              </Text>
            </div>
            <Flex>
              <Margin>
                <Tooltip
                  title="Kopier innhold til nytt rom"
                  delay={500}
                  hideDelay={0}
                >
                  <EditButton onClick={onCloneRoom}>
                    <i className="far fa-clone"></i>
                  </EditButton>
                </Tooltip>
              </Margin>
              <Margin left="1em">
                <EditButton onClick={() => setEditBaseInfo(true)}>
                  <i className="far fa-edit"></i>
                </EditButton>
              </Margin>
              <Margin left="1em">
                <EditButton onClick={() => onRoomDeleted(room.localId)} delete>
                  <i className="far fa-trash"></i>
                </EditButton>
              </Margin>
            </Flex>
          </Flex>
        )}
      </Margin>
      <Rule />
      <Margin top="1em" bottom="1em">
        <Table fullWidth>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell>Navn</Table.HeaderCell>
              <Table.HeaderCell>Antall</Table.HeaderCell>
              <Table.HeaderCell>Pris</Table.HeaderCell>
              <Table.HeaderCell>Sum</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {numArticles > 0 ? (
              room.articles
                .map(article => {
                  const currentlyEditing = articlesUnderEdit.includes(
                    article.id
                  )
                  return (
                    <RoomCardArticleRow
                      key={room.localId + '-' + article.id}
                      article={article}
                      editMode={articlesUnderEdit.includes(article.id)}
                      onToggleEdit={() => {
                        if (currentlyEditing) {
                          setArticlesUnderEdit(
                            articlesUnderEdit.filter(x => x !== article.id)
                          )
                        } else {
                          setArticlesUnderEdit(
                            articlesUnderEdit.concat(article.id)
                          )
                        }
                      }}
                      onArticleChanged={onArticleChanged}
                      onArticleDeleted={onArticleDeleted}
                    />
                  )
                })
                .concat(
                  room.freeArticles.map(freeArticle => {
                    const currentlyEditing = freeArticlesUnderEdit.includes(
                      freeArticle.id
                    )
                    return (
                      <RoomCardFreeArticleRow
                        key={room.id + '-' + freeArticle.id}
                        freeArticle={freeArticle}
                        editMode={freeArticlesUnderEdit.includes(
                          freeArticle.id
                        )}
                        onToggleEdit={() => {
                          if (currentlyEditing) {
                            setFreeArticlesUnderEdit(
                              freeArticlesUnderEdit.filter(
                                x => x !== freeArticle.id
                              )
                            )
                          } else {
                            setFreeArticlesUnderEdit(
                              freeArticlesUnderEdit.concat(freeArticle.id)
                            )
                          }
                        }}
                        onFreeArticleChanged={onFreeArticleChanged}
                        onFreeArticleDeleted={onFreeArticleDeleted}
                      />
                    )
                  })
                )
            ) : (
              <Table.Row>
                <Table.Cell colSpan="4">Ingen varer lagt til enda</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Margin>
      <Rule />
      <Margin top="2em">
        <Flex alignItems="center" flexWrap="wrap">
          <ArticleSelector
            pricelist={pricelist}
            selectedArticle={newArticle.id}
            onChange={(id, data) => setNewArticle(data)}
            ref={articleSelectorRef}
          />
          <Margin left="1em">
            <Input
              type="number"
              placeholder="Antall"
              width="100px"
              value={count}
              onChange={e => setCount(Math.max(parseInt(e.target.value), 1))}
            />
          </Margin>
          <Margin left="1em">
            <Button
              primary
              onClick={() => {
                // Check that we have actually added an article
                if (newArticle.id !== undefined) {
                  onAddArticle({ ...newArticle, count })
                  articleSelectorRef.current.clear()
                }
              }}
            >
              Legg til vare
            </Button>
          </Margin>
        </Flex>
        <Margin top="1em">
          <Button
            primary
            onClick={() => {
              const id = uuid()
              onAddFreeArticle({
                id: id,
                name: 'Ny vare',
                price: 0,
                count: 0,
              })
              setFreeArticlesUnderEdit(freeArticlesUnderEdit.concat(id))
            }}
          >
            Legg til frivare
          </Button>
        </Margin>
      </Margin>
    </Card>
  )
}
