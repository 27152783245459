import { useQuery } from '@apollo/client'
import { Modal, useToast } from '@ur/react-components'
import { useForm, useGlobal } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { DropdownMenu } from 'components/DropdownMenu'
import { Input } from 'components/Input'
import { SearchBar } from 'components/SearchBar'
import { WizardRoom } from 'modules/offers/OfferWizard/types'
import { ALL_ROOM_TEMPLATES_QUERY } from 'modules/roomtemplates/queries'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  AllRoomTemplatesQueryParameters,
  AllRoomTemplatesQueryReturn,
  RoomTemplate,
} from 'types/graphql/roomTemplates'
import { notEmptyValidation, stringContainsOnlyNumbers } from 'util/helpers'
import { v4 as uuidv4 } from 'uuid'
import { StepControls } from '../common'
import { OrSeparator } from '../OrSeparator'
import { AddRoomFromTemplateModal } from './AddRoomFromTemplateModal'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'search search rooms rooms'
    'orseparator orseparator rooms rooms'
    'label label rooms rooms'
    'form form rooms rooms'
    '. . rooms rooms';
  width: 800px;
  grid-column-gap: 50px;
  grid-template-columns: 215px 215px 2fr 2fr;
  height: auto;

  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 0 15px;
    grid-template-areas:
      'search'
      'orseparator'
      'label'
      'form'
      'rooms';
    grid-template-columns: auto;
  }
`
const FormWrapper = styled.div`
  grid-area: form;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  > :nth-child(2n) {
    margin: 15px 0;
  }

  > :last-child {
    margin: 10px 0px;
  }
`

const RoomsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: rooms;
  width: 700px;

  > div {
    padding: 14px;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const RoomsTableHeader = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.gray6};
  height: 65px;
  margin-bottom: 1px;
  border-radius: 10px 10px 0 0;

  > :nth-child(1) {
    width: 80px;
    margin-right: auto;
  }
  > :nth-child(2) {
    width: 100px;
    margin-right: auto;
    ${props => props.theme.media.mobile} {
      display: none;
    }
  }
  > :nth-child(3) {
    width: 110px;
    margin-right: auto;
    ${props => props.theme.media.mobile} {
      display: none;
    }
  }
  > :nth-child(4) {
    width: 40px; // change this when the proper icon is back
    margin-right: 0;
  }
`
const RoomsTableHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.gray3};
`

const RoomsTableRow = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  margin: 1px 0;
  color: ${props => props.theme.colors.darkBlue};
  background-color: ${props => props.theme.colors.white};

  > :nth-child(1) {
    width: 100px;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
  }
  > :nth-child(2) {
    width: 120px;
    margin-right: auto;
    font-size: 14px;
    ${props => props.theme.media.mobile} {
      display: none;
    }
  }
  > :nth-child(3) {
    width: 100px;
    margin-right: auto;
    font-size: 14px;
    ${props => props.theme.media.mobile} {
      display: none;
    }
  }
  > :nth-child(4) {
    width: 40px;
    margin-right: 0;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  > div {
    display: flex;
    align-items: center;
  }
`

const StyledSearch = styled(SearchBar)`
  grid-area: search;
  margin: 15px 0 30px;
  width: 100%;
`
const StyledInput = styled(Input)`
  input {
    background-color: ${props => props.theme.colors.white};
  }
`

const AddRoomLabel = styled.label`
  grid-area: label;
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  margin: 15px 0;
`

export const AddRooms = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)

  const [offerRooms, setOfferRooms] = useGlobal('offer.rooms')
  const [search, setSearch] = useState('')
  const [roomTemplates, setRoomTemplates] = useState<RoomTemplate[]>([])
  const [roomTemplateNames, setRoomTemplateNames] = useState<string[]>([])
  const [executeSelect, setExecuteSelect] = useState(false)
  const [roomBuffer, setRoomBuffer] = useState<WizardRoom>(null)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [openMenu, setOpenMenu] = useState('')
  const [openRoomTemplateModal, setOpenRoomTemplateModal] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<RoomTemplate>(null)

  const addToast = useToast()

  useQuery<AllRoomTemplatesQueryReturn, AllRoomTemplatesQueryParameters>(
    ALL_ROOM_TEMPLATES_QUERY,
    {
      onCompleted(data) {
        const { allRoomTemplates } = data
        const templates = allRoomTemplates.edges.map(edge => {
          const { node } = edge
          return node
        })
        setRoomTemplates(templates)
        setRoomTemplateNames(templates.map(template => template.name))
      },
      onError() {
        addToast('error', 'En feil har oppstått')
      },
    }
  )

  useEffect(() => {
    if (offerRooms.length === 0) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }, [offerRooms])

  // Execute template selection
  useEffect(() => {
    if (executeSelect) {
      const template = roomTemplates.filter(
        template => template.name === search
      )[0]
      setSelectedTemplate(template)
      setOpenRoomTemplateModal(true)
      setSearch('')
      setExecuteSelect(false)
    }
  }, [search, setSearch, executeSelect, setExecuteSelect, roomTemplates])

  const toggleModal = () => {
    setOpenRoomTemplateModal(!openRoomTemplateModal)
  }

  interface AddRoomForm {
    name: string
    floor: string
    area: string
    comment: string
  }

  const {
    formValues: form,
    formErrors: errors,
    updateForm,
    submitHandler,
    resetForm,
  } = useForm<AddRoomForm>({
    values: {
      name: '',
      floor: '',
      area: '',
      comment: '',
    },
    validators: {
      name: name => (notEmptyValidation(name) ? null : 'Kan ikke være tom'),
      floor: floor => {
        if (!notEmptyValidation(floor)) {
          return 'Kan ikke være tom'
        } else if (!stringContainsOnlyNumbers(floor)) {
          return 'Må være et tall'
        } else return null
      },
      area: area => {
        if (!notEmptyValidation(area)) {
          return 'Kan ikke være tom'
        } else if (!stringContainsOnlyNumbers(area)) {
          return 'Må være et tall'
        } else return null
      },
    },
  })

  const handleDeleteRoom = (room: WizardRoom) => {
    const { localID } = room
    const filteredRooms = offerRooms.filter(room => room.localID !== localID)
    setOfferRooms(filteredRooms)
  }

  const handleEditRoom = (room: WizardRoom) => {
    // This probably has to be handled dfferently, there is no UX/design on this yet
    // If a user comes from the `AddArticles` step and edits a room we need to retain the articles
    // added to said room. We do this by placing the room we want to edi in its own buffer variable
    // where we overwrite the old room with its new useForm data
    setRoomBuffer(room)
    handleDeleteRoom(room)
    updateForm({
      name: room.name,
      floor: `${room.floor}`,
      area: `${room.area}`,
      comment: room.comment,
    })
  }

  const handleDuplicateRoom = (room: WizardRoom) => {
    const newRoom = {
      ...room,
      localID: uuidv4(),
      versionId: null,
    }
    setOfferRooms([...offerRooms, newRoom])
  }

  const sanitizeForm = () => {
    return {
      name: form.name,
      area: parseInt(form.area),
      floor: parseInt(form.floor),
      comment: form.comment,
    }
  }

  const handleAddRoom = room => {
    let newRoom: WizardRoom
    if (roomBuffer === null) {
      newRoom = {
        localID: uuidv4(),
        name: form.name,
        floor: parseInt(form.floor, 10),
        area: parseInt(form.area, 10),
        comment: form.comment,
        articles: [],
        versionId: null,
        ...room,
      }
    } else {
      // should replace this logic, terribe UX
      newRoom = {
        ...roomBuffer,
        ...sanitizeForm(),
      }
    }

    setOfferRooms([...offerRooms, newRoom])
    resetForm()

    nameInputRef.current && nameInputRef.current.focus()
    setRoomBuffer(null)
  }

  const handleSelectTemplate = (name: string) => {
    setSearch(name)
    setExecuteSelect(true)
  }

  return (
    <Wrapper>
      <StyledSearch
        value={search}
        placeholder="Søk etter roommal"
        onChange={setSearch}
        autoCompleteSource={roomTemplateNames}
        onAutoCompleteItemSelect={handleSelectTemplate}
      />
      <OrSeparator gridArea="orseparator" />
      <AddRoomLabel>Legg til rom</AddRoomLabel>
      <FormWrapper>
        <StyledInput
          ref={nameInputRef}
          error={errors.name}
          fullWidth
          label="Navn"
          value={form.name}
          type="text"
          onChange={name => updateForm({ name })}
        />
        <StyledInput
          error={errors.floor}
          fullWidth
          label="Etasje"
          value={form.floor}
          type="number"
          onChange={floor => updateForm({ floor })}
        />
        <StyledInput
          error={errors.area}
          fullWidth
          label="Areal"
          value={form.area}
          type="number"
          onChange={area => updateForm({ area })}
        />

        <StyledInput
          fullWidth
          label="Kommentar"
          value={form.comment}
          type="text"
          onChange={comment => updateForm({ comment })}
        />

        <Button
          buttonStyle="primary"
          icon={{ icon: 'plus' }}
          onClick={submitHandler(handleAddRoom)}
        >
          Legg til rom
        </Button>
        <StepControls nextDisabled={nextButtonDisabled} />
      </FormWrapper>
      <RoomsWrapper>
        <RoomsTableHeader>
          <RoomsTableHeaderCell>Navn</RoomsTableHeaderCell>
          <RoomsTableHeaderCell>Etasje</RoomsTableHeaderCell>
          <RoomsTableHeaderCell>Størrelse</RoomsTableHeaderCell>

          <RoomsTableHeaderCell></RoomsTableHeaderCell>
        </RoomsTableHeader>
        {offerRooms.map(room => {
          return (
            <RoomsTableRow key={room.localID}>
              <div>{room.name} </div>
              <div>{room.floor} Etg. </div>
              <div>
                {room.area} m<sup>2</sup>
              </div>

              <div>
                <DropdownMenu
                  open={openMenu === room.localID}
                  onBarsClick={() => setOpenMenu(room.localID)}
                  onClose={() => setOpenMenu('')}
                  menuItems={[
                    {
                      displayText: 'Endre',
                      icon: { icon: 'edit', type: 'solid' },
                      onClick: () => handleEditRoom(room),
                      mobileOnly: false,
                      colorOverride: 'darkBlue',
                    },
                    {
                      displayText: 'Dupliser',
                      icon: { icon: 'copy', type: 'solid' },
                      onClick: () => handleDuplicateRoom(room),
                      mobileOnly: false,
                      colorOverride: 'darkBlue',
                    },
                    {
                      displayText: 'Slett',
                      icon: { icon: 'trash-alt', type: 'solid' },
                      onClick: () => handleDeleteRoom(room),
                      mobileOnly: false,
                    },
                  ]}
                />
              </div>
            </RoomsTableRow>
          )
        })}
      </RoomsWrapper>
      <Modal open={openRoomTemplateModal} onClose={toggleModal}>
        <AddRoomFromTemplateModal
          template={selectedTemplate}
          addRoomCallback={handleAddRoom}
          closeModalCallback={toggleModal}
        />
      </Modal>
    </Wrapper>
  )
}
