export enum COLUMN_SECTION_LABEL {
  NUMBER = 'Nr.',
  VERSION = 'Versjon',
  MADE_BY = 'Laget av',
  CUSTOMER = 'Kunde',
  OFFER_NAME = 'Navn',
  STATUS = 'Status',
}

export const ColumnLabels = Object.values(COLUMN_SECTION_LABEL)
