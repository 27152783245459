import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Thumbnail from 'components/Thumbnail'
import { Button } from 'components/Button'
import { usePermissionsOrMe } from 'util/hooks'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import { USER_QUERY } from './queries'
import { useQuery } from '@apollo/client'
import { UserQueryData, UserQueryVariables } from './types'
import { Loader } from '@ur/react-components'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
`

const UserTitle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.darkGray};
  text-align: center;
  font-size: 1.5em;

  h3 {
    color: ${props => props.theme.colors.gray};
    text-align: center;
    font-size: 1rem;
    margin: 5px 0;
  }
`

const ProfileCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Userinfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  width: 400px;

  ${props => props.theme.media.mobile} {
    width: 100%;
    flex-direction: column;
  }
`

const InfoLabel = styled.h3`
  font-size: 1rem;
  margin: 0;
`

const InfoContentLink = styled.a`
  font-weight: 500;
  color: ${props => props.theme.colors.link};
  align-self: center;
  &:hover {
    text-decoration: underline;
  }
  ${props => props.theme.media.mobile} {
    align-self: flex-start;
  }
`
const InfoContent = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  color: ${props => props.theme.colors.link};
  align-self: center;
  ${props => props.theme.media.mobile} {
    align-self: flex-start;
  }
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const User: React.FC = () => {
  const { hasPermissionsOrMe } = usePermissionsOrMe()
  const [{ setOverride }] = BreadcrumbsGraph.useGraph()
  const match = useRouteMatch<{ userId: string }>()

  const { data, loading } = useQuery<UserQueryData, UserQueryVariables>(
    USER_QUERY,
    {
      variables: { id: match.params.userId },
    }
  )

  if (loading) {
    return (
      <LoadingWrapper>
        <Loader.Spinner />
      </LoadingWrapper>
    )
  }

  const user = data?.user
  setOverride(user.id, user.fullName)

  const userTypes = user.userTypes
  const canEdit = hasPermissionsOrMe(user.id, 'users.change_user')

  return (
    <Wrapper>
      {canEdit && (
        <Link
          to={`/users/${user.id}/edit`}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '26px',
          }}
        >
          <Button primary icon={{ icon: 'edit' }}>
            Endre
          </Button>
        </Link>
      )}
      <ProfileCard>
        <CardHeader>
          <Thumbnail src={user.profilePicture} size={100} />
          <TitleWrapper>
            <UserTitle>
              {user.fullName}
              <h3>
                {user.position ||
                  userTypes.map(type => type.name).join(', ') ||
                  'Ingen stilling'}
              </h3>
            </UserTitle>
          </TitleWrapper>
        </CardHeader>
        <Userinfo>
          <InfoLabel>E-post</InfoLabel>
          <InfoContentLink href={`mailto:${user.email}`}>
            {' '}
            {user.email}
          </InfoContentLink>
        </Userinfo>
        <Userinfo>
          <InfoLabel>Telefonnummer</InfoLabel>
          <InfoContentLink href={`tel:${user.phoneNumber}`}>
            {user.phoneNumber}
          </InfoContentLink>
        </Userinfo>
        <Userinfo>
          <InfoLabel>Adresse</InfoLabel>
          <InfoContent> {user.address}</InfoContent>
        </Userinfo>
        <Userinfo>
          <InfoLabel>Sted</InfoLabel>
          <InfoContent> {user.city}</InfoContent>
        </Userinfo>
      </ProfileCard>
    </Wrapper>
  )
}
