import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ maxHeight?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: ${props => props.theme.borders.standard};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background: ${props => props.theme.colors.white};
  padding: 0;
  width: 100%;
  max-width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  color: ${props => props.theme.colors.text.normal};
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
      overflow: scroll;
    `}
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  margin-bottom: 10px;
  margin-right: 7px;
  padding: 0 5px;
  padding-right: 5px;
  white-space: nowrap;
  line-height: 26px;

  button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    line-height: 12px;
    margin-left: 5px;
  }
  i {
    margin-top: 3px;
  }
`

interface TagsDisplayProps {
  tags: string[]
  maxHeight?: string
  onDelete?: (index: number) => void
}

export const TagsDisplay: React.FC<TagsDisplayProps> = ({
  tags,
  maxHeight,
  children,
  onDelete,
}) => {
  return (
    <Wrapper maxHeight={maxHeight}>
      {tags.map((tag, i) => (
        <Tag key={tag}>
          {tag}
          {onDelete && (
            <button onClick={() => onDelete?.(i)}>
              <i className="fal fa-times" />
            </button>
          )}
        </Tag>
      ))}
      {/* All children must be styled as inline elements, primarily used for the input element right now. */}
      {children}
    </Wrapper>
  )
}
