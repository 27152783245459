import { gql } from '@apollo/client'
import { ArticleFragments } from 'modules/articles/queries'
import { CompanyFragments } from 'modules/companies/queries'
import { CustomerFragments } from 'modules/customers/queries'

export const OfferFragments: { [x: string]: any } = {
  offerInfo: gql`
    fragment OfferInfo on OfferNode {
      id
      name
      createdAt
      updatedAt
      internalReferenceId
      previousVersion {
        id
      }

      status
      discount
      displayMode
      conditions

      markup
      totalCost
      totalCostWithVat
      totalCostWithVatAfterDiscount
      version
      versionHistory {
        id
        version
      }
    }
  `,
  offerRoomInfo: gql`
    fragment OfferRoomInfo on OfferRoomNode {
      id
      name
      area
      floor
      totalCost
      versionId
      comment
    }
  `,
  offerRoomArticleInfo: gql`
    fragment OfferRoomArticleInfo on OfferRoomArticleNode {
      id
      article {
        id
        identifier
        name
        price
      }
      autoGenerated
      priceOverride
      count
      totalCost
      versionId
    }
  `,
  roomTemplateInfo: gql`
    fragment RoomTemplateInfo on RoomTemplateNode {
      id
      name
      floor
      area
    }
  `,
  roomTemplateOptionInfo: gql`
    fragment RoomTemplateOptionInfo on RoomTemplateOptionNode {
      id
      shortName
      text
      fieldType
      options
    }
  `,
  roomTemplateConditionInfo: gql`
    fragment RoomTemplateConditionInfo on RoomTemplateConditionNode {
      id
      valueCondition
    }
  `,
  roomTemplateFormulaInfo: gql`
    fragment RoomTemplateFormulaInfo on RoomTemplateFormulaNode {
      id
      formula
    }
  `,
  roomTemplateArticleActionInfo: gql`
    fragment RoomTemplateArticleActionInfo on RoomTemplateArticleActionNode {
      id
      article {
        ...ArticleInfo
      }
    }
    ${ArticleFragments.articleInfo}
  `,
}

OfferFragments.fullOfferInfo = gql`
  fragment FullOfferInfo on OfferNode {
    ...OfferInfo
    customer {
      ...CustomerInfo
    }
    company {
      ...CompanyInfo
    }
    rooms {
      ...OfferRoomInfo
      articles {
        ...OfferRoomArticleInfo
      }
      freeArticles {
        id
        name
        price
        count
        versionId
      }
    }
  }
  ${OfferFragments.offerInfo}
  ${OfferFragments.offerRoomInfo}
  ${OfferFragments.offerRoomArticleInfo}
  ${CustomerFragments.customerInfo}
  ${CompanyFragments.info}
`

OfferFragments.fullRoomTemplateInfo = gql`
  fragment FullRoomTemplateInfo on RoomTemplateNode {
    ...RoomTemplateInfo
    formulas {
      ...RoomTemplateFormulaInfo
      articleActions {
        ...RoomTemplateArticleActionInfo
      }
      conditions {
        ...RoomTemplateConditionInfo
      }
    }
    options {
      ...RoomTemplateOptionInfo
      conditions {
        ...RoomTemplateConditionInfo
      }
    }
  }
  ${OfferFragments.roomTemplateInfo}
  ${OfferFragments.roomTemplateFormulaInfo}
  ${OfferFragments.roomTemplateArticleActionInfo}
  ${OfferFragments.roomTemplateConditionInfo}
  ${OfferFragments.roomTemplateOptionInfo}
`
