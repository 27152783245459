import React, { useState } from 'react'
import styled from 'styled-components'
import { Query, Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import invariant from 'invariant'
import queryString from 'query-string'

import { useIntl } from 'react-intl'

import { Text } from 'components/Text'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components-old/Input'
import Label from 'components/Label'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import QuillEditor from 'components/QuillEditor'
import FullLoader from 'components/Loader/FullLoader'
import Padding from 'components/Padding'

import { MANUAL_ENTRY_QUERY } from './queries'
import { UPDATE_MANUAL_ENTRY_MUTATION } from './mutations'

import { validateField, notEmptyValidation } from 'util/forms'
import { safeParseJson } from 'util/parsing'
import FileToggleTile from 'components/files/FileToggleTile/FileToggleTile'
import FileInput from 'components/Input/FileInput'
import { useToast } from '@ur/react-components'

export const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
`

export const ExistingFiles = styled.div`
  display: flex;
`

function HandbookUpdateEntry({ history }) {
  const toasts = useToast()
  const intl = useIntl()
  const t = intl.formatMessage

  const parsedSearch = queryString.parse(history.location.search)
  const entryId = parsedSearch.id

  invariant(entryId, 'Must specify entry id')

  const [formData, setFormData] = useState(null)
  const [attachmentsToRemove, setAttachmentsToRemove] = useState([])
  const [newAttachments, setNewAttachments] = useState([])
  // This has to live in its own container due to race-conditions
  const [richtext, setRichtext] = useState({})

  const [errors, setErrors] = useState({
    name: null,
    description: null,
    richtext: null,
  })

  function onSubmit(updateHandbookEntryMutation) {
    const newErrors = {}
    newErrors['name'] = validateField(
      formData,
      'name',
      notEmptyValidation,
      intl
    )

    const canSubmit = !Object.values(newErrors).some(x => x !== null)

    if (!canSubmit) {
      setErrors(newErrors)
      return
    }

    updateHandbookEntryMutation({
      variables: {
        id: entryId,
        input: {
          ...formData,
          richtext: richtext,
          attachmentsAdd: newAttachments.map(attachment => ({
            name: attachment.name,
            file: attachment,
          })),
          attachmentsRemove: attachmentsToRemove,
        },
      },
    })
  }

  return (
    <Query query={MANUAL_ENTRY_QUERY} variables={{ id: entryId }}>
      {({ loading, data, error }) => {
        if (loading) {
          return <FullLoader />
        }

        const handbookEntry = data.handbookEntry

        if (!handbookEntry) {
          invariant(handbookEntry, `Handbook entry ${entryId} does not exist.`)
          return null
        }

        if (formData === null) {
          // Set initial data
          setFormData({
            name: handbookEntry.name,
            description: handbookEntry.description,
            folder: handbookEntry.folder.id,
          })
          return null
        }

        return (
          <>
            <Wrapper>
              <h1>
                <Text color="primary">
                  {t({
                    id: 'common.update-entry',
                    defaultMessage: 'Endre innlegg',
                  })}
                </Text>
              </h1>
              <Mutation
                onCompleted={({ updateHandbookEntry }) => {
                  invariant(
                    updateHandbookEntry,
                    'Expected a result back from the createHandbookEntry mutation.'
                  )

                  toasts(
                    'success',
                    t({
                      id: 'driverhandbook.update-entry-success',
                      defaultMessage: 'Successfully updated entry',
                    })
                  )

                  history.push(
                    `/handbook/entry/${updateHandbookEntry.handbookEntry.slug}`
                  )
                }}
                onError={error => {
                  if (error.message.includes('UNIQUE')) {
                    setErrors({
                      ...errors,
                      name: t({
                        id: 'errors.name-exists',
                        defaultMessage: 'Name already exists.',
                      }),
                    })
                    return
                  }
                  toasts(
                    'error',
                    t({
                      id: 'errors.generic',
                      defaultMessage: 'Error on server.',
                    })
                  )
                }}
                mutation={UPDATE_MANUAL_ENTRY_MUTATION}
              >
                {updateHandbookEntryMutation => (
                  <Form
                    preventDefault
                    onSubmit={() => onSubmit(updateHandbookEntryMutation)}
                  >
                    <Margin bottom="1em">
                      <FormField inline>
                        <Label htmlFor="name">
                          {t({ id: 'common.name', defaultMessage: 'Name' })}
                        </Label>
                        <Input
                          id="name"
                          error={errors.name}
                          value={formData.name}
                          placeholder={'Name'}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              name: e.target.value.slice(0, 128),
                            })
                          }
                        />
                      </FormField>
                    </Margin>
                    <Margin bottom="1em">
                      <FormField>
                        <Label htmlFor="description">
                          {t({
                            id: 'common.description',
                            defaultMessage: 'Description',
                          })}
                        </Label>
                        <Input
                          id="description"
                          error={errors.description}
                          value={formData.description}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        />
                      </FormField>
                    </Margin>
                    <Margin bottom="1em">
                      <FormField>
                        <Label htmlFor="richtext">
                          {t({
                            id: 'common.content',
                            defaultMessage: 'Content',
                          })}
                        </Label>
                        <QuillEditor
                          initialContents={safeParseJson(
                            handbookEntry.richtext
                          )}
                          onChange={contents =>
                            setRichtext(JSON.stringify(contents))
                          }
                        />
                      </FormField>
                    </Margin>
                    <Margin bottom="1em">
                      <ExistingFiles>
                        {handbookEntry.attachments.map(attachment => (
                          <Padding all="0.5em">
                            <FileToggleTile
                              file={attachment.name}
                              toggled={attachmentsToRemove.includes(
                                attachment.id
                              )}
                              onToggle={() => {
                                if (
                                  attachmentsToRemove.includes(attachment.id)
                                ) {
                                  setAttachmentsToRemove(
                                    attachmentsToRemove.filter(
                                      x => x !== attachment.id
                                    )
                                  )
                                } else {
                                  setAttachmentsToRemove(
                                    attachmentsToRemove.concat(attachment.id)
                                  )
                                }
                              }}
                            />
                          </Padding>
                        ))}
                      </ExistingFiles>
                    </Margin>
                    <Margin bottom="1.75em">
                      <FileInput
                        multiple
                        label={t({
                          id: 'common.add-files',
                          defaultMessage: 'Legg til filer',
                        })}
                        onChange={files => {
                          setNewAttachments(Array.from(files))
                        }}
                      />
                    </Margin>
                    <Button primary>
                      {t({ id: 'common.update', defaultMessage: 'Update' })}
                    </Button>
                  </Form>
                )}
              </Mutation>
            </Wrapper>
          </>
        )
      }}
    </Query>
  )
}

export default withRouter(HandbookUpdateEntry)
