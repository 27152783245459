import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getLoginToken } from 'modules/login/util'
import { API_URL } from 'util/env'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLoginToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message))
})

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(uploadLink)]),
  cache: new InMemoryCache(),
})

export default client
