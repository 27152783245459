import { Mutation } from '@apollo/client/react/components'
import { useTranslate } from '@ur/react-hooks'
import client from 'apollo'
import Input from 'components-old/Input'
import Checkbox from 'components/Checkbox'
import Form from 'components/Form'
import Margin from 'components/Margin'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
  LoginField,
  LoginLabel,
  LoginLink,
  SubmitButton,
  FormWrapper,
  Header,
  LoginSection,
  LoginWrapper,
  LogoSection,
} from './components'
import { IS_LOGGED_IN_QUERY, LOGIN_MUTATION } from './queries'
import { setLoginToken } from './util'

const CheckboxField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  .checkbox-input {
    & > div {
      width: 17px;
      height: 17px;
      border-radius: 2px;
      background-color: ${props =>
        props.checked
          ? props.theme.colors.secondary
          : props.theme.colors.white};
      &::before {
        color: #003857;
        font-size: 0.9rem;
      }
    }
    label {
      margin-left: 0.5rem;
      font-family: 'Work Sans';
      line-height: 22px;
      text-align: left;
      color: ${props => props.theme.colors.darkBlue};
    }
  }
`

export function Login({ history }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState(null)

  const translations = useTranslate({
    login: 'form.login',
    username: 'form.username',
    password: 'form.password',
    rememberMe: 'form.remember-me',
    forgotPassword: 'form.forgot-password',
  })

  return (
    <Mutation
      mutation={LOGIN_MUTATION}
      onCompleted={data => {
        const ok = data.login.ok

        if (!ok) {
          setError('Feil brukernavn eller passord')
          return
        }

        const token = data.login.token
        setLoginToken(token)

        // Break caching for logged in query
        client.writeQuery({
          query: IS_LOGGED_IN_QUERY,
          data: {
            isLoggedIn: true,
          },
        })

        history.push('/')
      }}
      onError={err => {
        setError('Feil på tjeneren. Vennligst prøv igjen.')
      }}
    >
      {login => (
        <LoginWrapper>
          <LoginSection>
            <FormWrapper>
              <Form
                preventDefault
                onSubmit={() => {
                  login({ variables: { username, password } })
                }}
              >
                <Header title={translations.login} />
                <Margin bottom="1em">
                  <LoginField>
                    <LoginLabel className="login-label" htmlFor="username">
                      {translations.username}
                    </LoginLabel>
                    <Input
                      id="username"
                      error={error}
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                      width="100%"
                    />
                  </LoginField>
                </Margin>
                <Margin bottom="1em">
                  <LoginField>
                    <LoginLabel className="login-label" htmlFor="password">
                      {translations.password}
                    </LoginLabel>
                    <Input
                      id="password"
                      error={error ? '' : null}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      width="100%"
                    />
                  </LoginField>
                </Margin>
                <CheckboxField checked={checked}>
                  <Checkbox
                    className="checkbox-input"
                    value={checked}
                    onChange={setChecked}
                    label={`${translations.rememberMe}`}
                  />
                  <LoginLink
                    to="/forgotten-password"
                    className="forgotten-link"
                  >
                    {translations.forgotPassword}
                  </LoginLink>
                </CheckboxField>
                <SubmitButton type="submit" primary width="100%">
                  {translations.login}
                </SubmitButton>
              </Form>
            </FormWrapper>
          </LoginSection>
          <LogoSection />
        </LoginWrapper>
      )}
    </Mutation>
  )
}

export default withRouter(Login)
