import Card from 'components/Card'
import Margin from 'components/Margin'
import Table from 'components/Table'
import { OfferRoomObject } from 'modules/offers/types'
import {
  extractArticlesFromRooms,
  extractFreeArticlesFromViewRooms,
} from 'modules/offers/util'
import React from 'react'

interface ArticleSummaryProps {
  rooms: OfferRoomObject[]
}
const ArticleSummary: React.FC<ArticleSummaryProps> = ({ rooms }) => {
  const articlesResult = extractArticlesFromRooms(rooms)
  const freeArticles = extractFreeArticlesFromViewRooms(rooms)

  return (
    <Card>
      {rooms.length === 0 || articlesResult.uniqueArticles.length === 0 ? (
        <div>Ingen varer er lagt til enda.</div>
      ) : (
        <div>
          <Margin top="1em">
            <Table fullWidth>
              <Table.Head>
                <Table.Row>
                  <Table.HeaderCell>Vare</Table.HeaderCell>
                  <Table.HeaderCell>Antall</Table.HeaderCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {articlesResult.uniqueArticles.map(article => (
                  <Table.Row key={article.id}>
                    <Table.Cell>
                      {article.name} ({article.identifier})
                    </Table.Cell>
                    <Table.Cell>{article.count}</Table.Cell>
                  </Table.Row>
                ))}
                {freeArticles.map(article => (
                  <Table.Row key={article.id}>
                    <Table.Cell>{article.name}</Table.Cell>
                    <Table.Cell>{article.count}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Margin>
        </div>
      )}
    </Card>
  )
}

export default ArticleSummary
