import { useQuery } from '@apollo/client'
import { Modal } from '@ur/react-components'
import { Button } from 'components/Button'
import { useState } from 'react'
import { useMemo } from 'react'
import {
  ServiceFormModal,
  ServicesTable,
  Title,
  HeaderWrapper,
  ViewWrapper,
} from '../components'
import { ALL_TAGS_QUERY, ALL_SERVICES_QUERY } from '../queries'
import {
  AllTagsQuery,
  AllServicesQuery,
  ShallowServiceNode,
} from '../types.graphql'

export const Services: React.UFC = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [editService, setEditService] = useState<ShallowServiceNode | null>(
    null
  )

  const toggleCreateModal = () => {
    setOpenCreateModal(!openCreateModal)
  }

  function handleEdit(service: ShallowServiceNode) {
    setEditService(service)
  }

  const { data } = useQuery<AllServicesQuery>(ALL_SERVICES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { data: tagsData } = useQuery<AllTagsQuery>(ALL_TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const services = useMemo(() => {
    if (!data) return []
    return data.allServices.edges.map(edge => edge.node)
  }, [data])

  const tags = useMemo(() => {
    return tagsData?.allTags.edges?.map(edge => edge.node) ?? []
  }, [tagsData])

  return (
    <ViewWrapper>
      <HeaderWrapper>
        <Title>Tjenester</Title>
        <Button
          type="button"
          icon={{ size: '24px', icon: 'plus' }}
          onClick={toggleCreateModal}
        >
          Ny tjeneste
        </Button>
      </HeaderWrapper>
      <ServicesTable services={services} onEdit={handleEdit} />
      <Modal open={openCreateModal} onClose={toggleCreateModal}>
        <ServiceFormModal tags={tags} closeModalCallback={toggleCreateModal} />
      </Modal>
      <Modal open={editService !== null} onClose={() => setEditService(null)}>
        <ServiceFormModal
          service={editService}
          tags={tags}
          closeModalCallback={() => setEditService(null)}
        />
      </Modal>
    </ViewWrapper>
  )
}
