import styled from 'styled-components'
import FormField from 'components/FormField'
import { RadioGroup } from '@ur/react-components'
import { Input } from 'components/Input'
import { RoomTemplateOption } from 'types/graphql/roomTemplates'

const Wrapper = styled(FormField)`
  margin-top: 1.5rem;
`

const WrapperLabel = styled.label`
  color: ${props => props.theme.colors.darkBlue};
  font-weight: 700;
  margin-bottom: 12px;
  display: block;
  font-size: 14px;
`

interface RoomTemplateOptionStandardWrapperProps {
  label: string
}

const RoomTemplateOptionStandardWrapper: React.FC<
  RoomTemplateOptionStandardWrapperProps
> = ({ children, label }) => {
  return (
    <Wrapper>
      <WrapperLabel>{label}</WrapperLabel>
      {children}
    </Wrapper>
  )
}

interface RoomTemplateOptionRadioProps {
  value: boolean
  option: RoomTemplateOption
  onChange: (value: boolean) => void
}

export const RoomTemplateOptionRadio: React.FC<
  RoomTemplateOptionRadioProps
> = ({ value, option, onChange }) => {
  const options = [
    { value: 1, label: 'Ja' },
    { value: 0, label: 'Nei' },
  ]

  return (
    <RoomTemplateOptionStandardWrapper label={option.text}>
      <RadioGroup
        value={value ? 1 : 0}
        checkboxProps={{
          labelPosition: 'left',
          boxBackground: 'white',
          // Doesnt allow to set border color outside of boxColor
          boxColor: 'primary',
          checkedIconProps: {
            icon: 'circle',
            type: 'solid',
            color: 'primary',
            size: '10px',
          },
        }}
        layout={{
          direction: 'horizontal',
          type: 'flex',
          gap: '1rem',
        }}
        onChange={val => onChange(!!val)}
        radios={options}
      />
    </RoomTemplateOptionStandardWrapper>
  )
}

interface RoomTemplateOptionRangeProps {
  value: string
  option: RoomTemplateOption
  onChange: (value: number) => void
}

export const RoomTemplateOptionRange: React.FC<
  RoomTemplateOptionRangeProps
> = ({ value, option, onChange }) => {
  return (
    <RoomTemplateOptionStandardWrapper label={option.text}>
      <Input
        width="100%"
        type="number"
        value={value}
        onChange={val => {
          onChange(parseInt(val))
        }}
      />
    </RoomTemplateOptionStandardWrapper>
  )
}
