import { useEffect } from 'react'
import { useGlobal } from '@ur/react-hooks'
import styled from 'styled-components'
import {
  Icon,
  ComponentSwitch,
  ComponentSwitchCase,
} from '@ur/react-components'
import { AddConditions, AddOrCreateCustomer } from '.'
import { AddRooms } from './AddRooms/AddRooms'
import { AddArticles } from './AddArticles/AddArticles'
import { CreateOfferSummary } from './Summary/CreateOfferSummary'
import { StepIndicator } from 'components/StepIndicator'
import { useHistory } from 'react-router'

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    'wizardstep wizardstep wizardstep wizardstep'
    'offername offername offername offername ';
  grid-template-rows: 72px 1fr;
  padding: 32px 80px 32px 80px;
  min-height: 100%;

  ${props => props.theme.media.mobile} {
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.background};
    display: flex;
    flex-direction: column;
  }
`

const SwitchWrapper = styled.div`
  grid-area: wizardstep;
  padding-bottom: 1rem;
  flex: 1;
`

const ExitIcon = styled(Icon)`
  display: none;

  color: ${props => props.theme.colors.gray1};
  ${props => props.theme.media.mobile} {
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
`

const StepIndicatorWrapper = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  min-height: 65px;
  padding-bottom: 5px;
  margin: 5px 0 10px;

  ${props => props.theme.media.mobile} {
    display: flex;
  }
`

export const WizardContainer = () => {
  const [wizardState, setWizardState] = useGlobal('offer.wizardState')
  const [, setWizardActive] = useGlobal('offer.wizardActive')

  const history = useHistory()

  useEffect(() => {
    setWizardActive(true)

    // This means we are opening the wizard for the first time, otherwise we can pick up where we left off
    if (wizardState === null) {
      setWizardState('Kunde')
    }

    return function cleanup() {
      setWizardActive(false)
    }
  }, [wizardState, setWizardActive, setWizardState])

  return (
    <Wrapper>
      <ExitIcon
        icon="times"
        size="36px"
        type="light"
        onClick={() => {
          history.push('/offers')
        }}
      />
      <StepIndicatorWrapper>
        <StepIndicator />
      </StepIndicatorWrapper>
      <SwitchWrapper>
        <ComponentSwitch>
          <ComponentSwitchCase case={wizardState === 'Kunde'}>
            <AddOrCreateCustomer />
          </ComponentSwitchCase>
          <ComponentSwitchCase case={wizardState === 'Vilkår'}>
            <AddConditions />
          </ComponentSwitchCase>
          <ComponentSwitchCase case={wizardState === 'Rom'}>
            <AddRooms />
          </ComponentSwitchCase>
          <ComponentSwitchCase case={wizardState === 'Artikler'}>
            <AddArticles />
          </ComponentSwitchCase>
          <ComponentSwitchCase case={wizardState === 'Oppsummering'}>
            <CreateOfferSummary />
          </ComponentSwitchCase>
        </ComponentSwitch>
      </SwitchWrapper>
    </Wrapper>
  )
}
