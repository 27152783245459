import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { v4 as uuid } from 'uuid'

import Flex from 'components/Flex'

import RoomTemplateSelector from '../Selectors/RoomTemplateSelector'
import AddRoomTemplateOptions from './AddRoomTemplateOptions'
import AddRoomTemplateBasicInfo from './AddRoomTemplateBasicInfo'
import AddRoomTemplateActionResults from './AddRoomTemplateActionResults'

import Margin from 'components/Margin'
import Padding from 'components/Padding'
import { Button } from 'components/Button'
import { recalculateActions } from '../util'
import { ROOM_TEMPLATE_QUERY } from 'modules/roomtemplates/queries'

const SectionHeader = styled.h3`
  color: ${props => props.theme.colors.gray};
  font-weight: 500;
  margin-top: 0;
`

export default function AddRoomTemplate({ basePath, onSubmit }) {
  const [selectedRoomTemplate, setSelectedRoomTemplate] = useState(null)
  const [roomTemplateData, setRoomTemplateData] = useState({
    id: null,
    name: null,
    floor: null,
    area: null,
  })
  const [selectedOptions, setSelectedOptions] = useState({})
  const [articleActions, setArticleActions] = useState([])

  function handleSubmit() {
    // TODO: Validation
    const finalData = {
      ...roomTemplateData,
      localId: uuid(),
      articles: articleActions,
      freeArticles: [],
    }

    onSubmit(finalData)
  }

  function handleTemplateDataUpdated(name, value, roomTemplate) {
    const newData = {
      ...roomTemplateData,
      [name]: value,
    }
    setRoomTemplateData(newData)

    const articleActions = recalculateActions(
      roomTemplate,
      selectedOptions,
      newData
    )
    setArticleActions(articleActions)
  }

  function handleOptionUpdated(id, newValue, roomTemplate) {
    const newOptions = {
      ...selectedOptions,
      [id]: newValue,
    }
    setSelectedOptions(newOptions)

    const articleActions = recalculateActions(
      roomTemplate,
      newOptions,
      roomTemplateData
    )
    setArticleActions(articleActions)
  }
  return (
    <div>
      <Padding all="0.1em">
        <Margin bottom="1em">
          <Link to={basePath}>
            <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
          </Link>
        </Margin>
        <SectionHeader>Velg Rommal</SectionHeader>
        <Flex justifyContent="space-between">
          <RoomTemplateSelector
            selectedRoomTemplate={selectedRoomTemplate}
            onChange={setSelectedRoomTemplate}
          />
          <Button onClick={handleSubmit} primary icon="far fa-plus">
            Fullfør og legg til
          </Button>
        </Flex>
      </Padding>
      <Margin top="1em">
        {selectedRoomTemplate && (
          <Query
            query={ROOM_TEMPLATE_QUERY}
            variables={{ id: selectedRoomTemplate }}
          >
            {({ loading, data, error }) => {
              if (loading || !data) {
                return null
              }
              const roomTemplate = data.roomTemplate

              // Reset data
              if (roomTemplate.id !== roomTemplateData.id) {
                setRoomTemplateData({
                  ...roomTemplateData,
                  id: roomTemplate.id,
                  name: roomTemplate.name,
                  floor: roomTemplate.floor,
                  area: roomTemplate.area,
                  articles: [],
                })
                const newOptions = roomTemplate.options.reduce(
                  (acc, option) => {
                    acc[option.id] = option.fieldType === 'BOOL' ? false : 0
                    return acc
                  },
                  {}
                )
                setSelectedOptions(newOptions)
                setArticleActions(recalculateActions(roomTemplate, newOptions))
              }
              const propertyList = [
                ...(roomTemplate.titles || []),
                ...(roomTemplate.options || []),
              ]

              const sortedProperties = propertyList.sort(
                (a, b) => a.order - b.order
              )

              function isTitle(property) {
                return 'title' in property
              }

              return (
                <Flex flexWrap="wrap" justifyContent="space-between">
                  <Flex.Item flexBasis="25%">
                    <Padding all="0.1em">
                      <SectionHeader>Basisinfo</SectionHeader>
                      <AddRoomTemplateBasicInfo
                        roomTemplateData={roomTemplateData}
                        onFieldChange={(name, value) => {
                          handleTemplateDataUpdated(name, value, roomTemplate)
                        }}
                      />
                    </Padding>
                  </Flex.Item>
                  <Flex.Item flexBasis="30%">
                    <Padding all="0.1em">
                      {sortedProperties.map(property => {
                        if (isTitle(property) && property.order === 0) {
                          return <SectionHeader>{property.title}</SectionHeader>
                        } else if (isTitle(property)) {
                          return (
                            <Margin top="2.5em">
                              <SectionHeader>{property.title}</SectionHeader>
                            </Margin>
                          )
                        } else {
                          const optionProperty = [property]
                          return (
                            <AddRoomTemplateOptions
                              options={optionProperty}
                              selectedOptions={selectedOptions}
                              onOptionChanged={(id, newValue) => {
                                handleOptionUpdated(id, newValue, roomTemplate)
                              }}
                            />
                          )
                        }
                      })}
                    </Padding>
                  </Flex.Item>
                  <Flex.Item flexBasis="35%">
                    <Padding all="0.1em">
                      <SectionHeader>Varer automatisk lagt til</SectionHeader>
                      <AddRoomTemplateActionResults articles={articleActions} />
                    </Padding>
                  </Flex.Item>
                </Flex>
              )
            }}
          </Query>
        )}
      </Margin>
    </div>
  )
}

AddRoomTemplate.defaultProps = {
  basePath: '/offers/create',
}
