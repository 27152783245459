import styled from 'styled-components'
import { TableWrapperProps } from './types'

export const Table = styled.table.attrs((props: TableWrapperProps) => ({
  boxSizing: 'border-box',
  borderCollapse: 'collapse',
  borderRadius: '5px',
  fullWidth: false,
  maxWidth: 'auto',

  fontSize: '14px',
  wordBreak: 'normal',

  overflow: 'visible',
  overflowX: 'visible',
  overflowY: 'visible',
  ...props,
}))`
  box-sizing: ${props => props.boxSizing};
  border-collapse: ${props => props.borderCollapse};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  max-width: ${props => props.maxWidth};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  word-break: ${props => props.wordBreak};
  table-layout: ${props => props.tableLayout};

  overflow: ${props => props.overflow};
  overflow-x: ${props => props.overflowX};
  overflow-y: ${props => props.overflowY};

  /* Necessary for keeping border-radius after we use border-collapse method
  since tables are weird */

  tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
  }

  thead th:first-child {
    border-top-left-radius: 12px;
  }

  thead th:last-child {
    border-top-right-radius: 12px;
  }
`
