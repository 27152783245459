import React from 'react'
import styled from 'styled-components'
import { Breadcrumbs, Icon, Crumb } from '@ur/react-components'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import { SidebarGraph } from 'store/sidebar'
import CompanyContext from 'context/CompanyContext'
import Image from 'components/Image'
import capitalize from 'lodash/capitalize'
import { overloadColor } from 'util/style'
import { useIntl } from 'react-intl'
import HeaderRightMenu from './HeaderRightMenu'
import { useGlobal } from '@ur/react-hooks'
import { StepIndicator } from 'components/StepIndicator'
import { ComponentSwitch, ComponentSwitchCase } from '@ur/react-components'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.header`
  display: flex;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  ${props => props.theme.media.mobile} {
    padding: 0 16px;
  }
`

const RouteBreadcrumbs = styled(Breadcrumbs.Route)`
  max-width: 96vw;
  overflow: hidden;
  color: ${props => props.theme.colors.gray3};
  font-weight: 600;
  a {
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const Logo = styled.div`
  display: none;

  ${props => props.theme.media.mobile} {
    display: flex;
  }
`

const MenuIcon = styled(Icon)`
  display: none;

  ${props => props.theme.media.mobile} {
    display: block;
  }
`

export function Header() {
  const [{ overrides, inserts }] = BreadcrumbsGraph.useGraph()
  const [{ toggle }] = SidebarGraph.useGraph()
  const formatMessage = useIntl().formatMessage
  const [wizardActive] = useGlobal('offer.wizardActive')
  const history = useHistory()

  function overrideCrumb(
    path: string,
    index: number,
    total: number
  ): Partial<Crumb> | null {
    const text = overrides.hasOwnProperty(path)
      ? overrides[path]
      : formatMessage({
          id: `common.${path}`,
          defaultMessage: capitalize(path),
        })

    if (text === null) return null

    return {
      text,
      color: index === total - 1 ? overloadColor('primary') : undefined,
    }
  }
  return (
    <Wrapper>
      <ComponentSwitch>
        <ComponentSwitchCase case={!wizardActive}>
          <RouteBreadcrumbs
            mainPageTitle={'Kontrollpanel'}
            overrideCrumb={overrideCrumb}
            inserts={inserts}
            fontSize="14px"
            //fontWeight="400" fix this when prop exist in component
            iconProps={{
              size: '0.6em',
            }}
          />
        </ComponentSwitchCase>
        <ComponentSwitchCase case={wizardActive && !isMobileOnly}>
          <StepIndicator width="500px" />
        </ComponentSwitchCase>
      </ComponentSwitch>

      <CompanyContext.Consumer>
        {company => (
          <React.Fragment>
            {company.companyLogo && (
              <Logo>
                <Image
                  maxWidth="160px"
                  maxHeight="40px"
                  src={company.companyLogo}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/dashboard`)
                  }}
                />
              </Logo>
            )}
          </React.Fragment>
        )}
      </CompanyContext.Consumer>

      <MenuIcon
        icon="bars"
        type="light"
        size="45px"
        color="primary"
        onClick={() => {
          toggle()
        }}
      />

      <HeaderRightMenu />
    </Wrapper>
  )
}

const StyledHeader = styled(Header)`
  border-bottom: 1px solid #dfe1ec;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);

  height: 100%;

  position: relative;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1em;

  box-sizing: border-box;
`

export default StyledHeader
