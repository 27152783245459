import { useQuery } from '@apollo/client'
import { Button } from 'components/Button'
import Card from 'components/Card'
import Input from 'components-old/Input'
import FullLoader from 'components/Loader/FullLoader'
import EasyTable, { Row } from 'components/Table/EasyTable'
import React, { useMemo, useState, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'theme'
import { QueryVariable } from 'types/graphql/common'
import { AllRoomTemplatesQueryReturn } from 'types/graphql/roomTemplates'
import { useDebounce } from 'util/hooks'
import { isUndefined } from 'util/typechecks'
import { ALL_ROOM_TEMPLATES_QUERY } from './queries'
import Icon from 'components/Icon'
import { useMutation } from '@apollo/client'
import { DELETE_ROOM_TEMPLATE_MUTATION } from './mutations'
import { useModals } from 'store/modals'
import Padding from 'components/Padding'
import Flex from 'components/Flex'
import Margin from 'components/Margin'

const RoomTemplatesWrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
`

const RoomTemplatesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`

const RoomTemplates: React.FC = () => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const modals = useModals()

  const { loading, data } = useQuery<
    AllRoomTemplatesQueryReturn,
    QueryVariable
  >(ALL_ROOM_TEMPLATES_QUERY, {
    variables: { q: debouncedSearch },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const [deleteRoomTemplateMutation, { loading: deleteLoading }] = useMutation(
    DELETE_ROOM_TEMPLATE_MUTATION,
    {
      refetchQueries: ['AllRoomTemplates'],
      onError(err) {
        console.error(err)
      },
    }
  )

  const rowKeys: string[] =
    data?.allRoomTemplates.edges.map(({ node }) => node.id) ?? []

  const rows = useMemo<Row[]>(() => {
    function handleDelete(id: string) {
      modals.addModal({
        title: 'Slett rommal',
        body: `Du er i ferd med å slette en rommal. Vil du det?`,
        footer: ({ closeModal }) => (
          <Padding all="1em">
            <Flex justifyContent="space-between">
              <Margin inline right="1em">
                <Button
                  width="100px"
                  primary
                  onClick={() => {
                    closeModal()
                  }}
                >
                  Avbryt
                </Button>
              </Margin>
              <Button
                buttonStyle="danger"
                onClick={() => {
                  deleteRoomTemplateMutation({
                    variables: {
                      id: id,
                    },
                  })
                  closeModal()
                }}
              >
                Slett rommal
              </Button>
            </Flex>
          </Padding>
        ),
      })

      // }
    }

    if (isUndefined(data)) return []

    return data.allRoomTemplates.edges.map(({ node }) =>
      ([node.name, node.area, node.floor] as ReactNode[]).concat([
        <Link to={`/roomtemplates/edit-room-template/${node.id}`}>
          <Icon
            className="edit"
            icon="edit"
            size="1em"
            color="gray"
            hoverColor="lightGray"
            cursor="pointer"
          />
        </Link>,
        <Icon
          className="delete"
          icon="trash-alt"
          size="1em"
          color="gray"
          hoverColor="lightGray"
          cursor="pointer"
          onClick={() => handleDelete(node.id)}
        />,
      ])
    )
  }, [data, deleteRoomTemplateMutation, modals])

  return (
    <RoomTemplatesWrapper>
      {(deleteLoading || loading) && <FullLoader />}
      <RoomTemplatesHeader>
        <Input
          value={search}
          placeholder="Søk"
          onChange={e => setSearch(e.target.value)}
        />
        <Link to="/roomtemplates/add-new-room-template">
          <Button primary>Lag ny rommal</Button>
        </Link>
      </RoomTemplatesHeader>
      <Card padding="0px">
        <EasyTable
          tableProps={{ fullWidth: true }}
          cellProps={{
            borderTop: '1px solid ' + theme.colors.veryLightGray,
            padding: '1em 2em',
          }}
          columnNames={['Navn', 'Areal', 'Etasje', 'Rediger', 'Slett']}
          data={rows}
          rowKeys={rowKeys}
        />
      </Card>
    </RoomTemplatesWrapper>
  )
}

export default RoomTemplates
