import { useClickOutside } from '@ur/react-hooks'
import React, { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { FilterButton, Popup } from './components'

const Wrapper = styled.div`
  position: relative;
`

export const FilterPopup: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)

  const popupRef = useRef<HTMLElement>(null)
  useClickOutside(popupRef, () => setOpen(false), false)

  function handleClick(value: boolean) {
    return function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      event.stopPropagation()
      setOpen(value)
    }
  }

  return (
    <Wrapper>
      <FilterButton
        iconLeftProps={{ icon: 'users', type: 'solid' }}
        onClick={handleClick(true)}
      >
        {isMobile ? '' : 'Filtrer brukere'}
      </FilterButton>

      {open && (
        <Popup ref={popupRef}>
          <FilterButton
            open
            iconLeftProps={{ icon: 'users', type: 'solid' }}
            onClick={handleClick(false)}
          >
            Filtrer brukere
          </FilterButton>
          {children}
        </Popup>
      )}
    </Wrapper>
  )
}
