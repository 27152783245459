import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Button } from 'components/Button'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import {
  AllOffersOfferNode,
  AllOffersQueryParameters,
  AllOffersQueryReturn,
  CloneOfferMutationReturn,
  CloneOfferMutationParameters,
  OfferStatus,
  OfferStatusArray,
} from 'types/graphql/offers'
import { COLUMN_SECTION_LABEL } from './components/types'
import { useDebounce } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { isUndefined } from 'util/typechecks'
import { ALL_OFFERS_QUERY, OFFER_FULL_QUERY } from './queries'
import { CLONE_OFFER_MUTATION, PATCH_OFFER_MUTATION } from './mutations'
import {
  getTranslatedStatus,
  normalizeOffer,
  statusStyleHelper,
  decapitalizeOfferStatus,
} from './util'
import { SearchBar } from 'components/SearchBar'
import { Icon } from '@ur/react-components'
import { isMobile } from 'react-device-detect'
import { QuoTable } from 'components/QuoTable'
import { DropdownMenu, MenuItem } from 'components/DropdownMenu/DropdownMenu'
import { ZIndexRange } from 'types/enums'
import { Column } from 'components/QuoTable/types'
import { useGlobal } from '@ur/react-hooks'
import { WizardOffer } from './OfferWizard/types'
import { truncate } from 'lodash'
import {
  OffersCustomerFilterButton,
  FilterIconButton,
} from 'modules/offers/components'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: grid;
  grid-template-areas:
    'title  search  .   create'
    'table  table table table';
  grid-column-gap: 40px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr 2fr auto;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
`

const TableWrapper = styled.div`
  grid-area: table;
  padding-bottom: 40px;

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`
const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.input};
  width: 100%;

  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`

const Title = styled.span`
  grid-area: title;
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const OfferSearchBar = styled(SearchBar)`
  grid-area: search;
  justify-self: end;
  align-self: center;
  width: 80%;
  min-height: 48px;
  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`
interface CreateOfferLinkProps {
  mobileOnly: boolean
}

const CreateOfferLink = styled(Link)<CreateOfferLinkProps>`
  display: ${props => (props.mobileOnly ? 'none' : 'flex')};
  grid-area: create;

  ${props => props.theme.media.mobile} {
    display: ${props => (props.mobileOnly ? 'flex' : 'none')};
    position: fixed;
    bottom: 14px;
    right: 10vw;
    width: 60px;
    height: 60px;
    z-index: ${ZIndexRange.Sidebar - 1};
  }
`

interface StatusProp {
  status: OfferStatus
}

const StatusCell = styled.div<StatusProp>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${props => statusStyleHelper(props.status)};
`

const UserThumbnail = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary};
  font-weight: 600;
  font-size: 20px;
  border-radius: 100%;
  letter-spacing: 0.025em;
`

const UserThumbnailWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;
`

const StatusCircle = styled.div<StatusProp>`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 7px;

  background-color: ${props => statusStyleHelper(props.status)};
`

const Offers: React.FC = () => {
  const DEFAULT_PAGE_SIZE = 50
  const history = useHistory()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const { colors } = useTheme()
  const [openMenu, setOpenMenu] = useState('')
  const [, setOffer] = useGlobal('offer')
  const [createdByIds, setCreatedByIds] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<OfferStatus[]>([])

  const [cloneOfferMutation] = useMutation<
    CloneOfferMutationReturn,
    CloneOfferMutationParameters
  >(CLONE_OFFER_MUTATION, {
    // should probably have som FullLoader overlay, or maybe turn menu icon into loader
    onCompleted({ cloneOffer }) {
      const { id } = cloneOffer.clonedOffer
      history.push(`/offers/${id}/edit`)
    },
    refetchQueries: ['AllOffers'],
  })

  const [patchOfferMutation] = useMutation(PATCH_OFFER_MUTATION, {
    refetchQueries: ['AllOffers'],
  })

  const handleCloneOfferMutation = useCallback(
    (offer: AllOffersOfferNode) => {
      cloneOfferMutation({
        variables: {
          offerId: offer.id,
        },
      })
    },
    [cloneOfferMutation]
  )

  const handlePatchOfferMutation = useCallback(
    (offer: AllOffersOfferNode) => {
      patchOfferMutation({
        variables: {
          id: offer.id,
          input: {
            status: 'ARCHIVED',
          },
        },
      })
    },
    [patchOfferMutation]
  )

  const [fetchOffer] = useLazyQuery(OFFER_FULL_QUERY, {
    onCompleted(data) {
      const { offer } = data

      const normalizedOffer = normalizeOffer(offer)
      const wizardOffer: WizardOffer = {
        ...normalizedOffer,
        isNewVersion: true,
        wizardInProgress: true,
        wizardState: 'Rom',
        wizardActive: true,
      }
      setOffer(wizardOffer)
      history.push(`/offers/${offer.id}/new-version`)
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleMenuItems = useCallback(
    (offer: AllOffersOfferNode): MenuItem[] => {
      const menuProps: MenuItem[] = [
        {
          displayText: 'Åpne',
          icon: {
            icon: 'eye',
            size: '17px',
            type: 'solid',
          },
          colorOverride: colors.darkBlue,
          mobileOnly: true,
          onClick: () => {
            history.push(`/offers/${offer.id}`)
          },
        },
        {
          displayText: 'Endre',
          icon: {
            icon: 'edit',
            size: '17px',
            type: 'solid',
          },
          colorOverride: colors.darkBlue,
          mobileOnly: false,
          onClick: () => {
            fetchOffer({ variables: { id: offer.id } })
          },
        },
        {
          displayText: 'Dupliser',
          icon: {
            icon: 'copy',
            size: '17px',
            type: 'solid',
          },
          colorOverride: colors.darkBlue,
          mobileOnly: false,
          onClick: () => {
            handleCloneOfferMutation(offer)
          },
        },
        {
          displayText: 'Arkiver',
          icon: {
            icon: 'trash-alt',
            size: '17px',
            type: 'solid',
          },
          mobileOnly: false,
          onClick: () => {
            handlePatchOfferMutation(offer)
          },
        },
      ]
      return menuProps
    },
    [
      colors.darkBlue,
      history,
      handleCloneOfferMutation,
      handlePatchOfferMutation,
      fetchOffer,
    ]
  )

  const handleTableRow = useCallback(
    (offer: AllOffersOfferNode, rowIndex: number) => {
      const menuItems = handleMenuItems(offer)
      const row = {
        id: offer.id,
        data: {
          number: (
            <Link to={`/offers/${offer.id}`}>{offer.internalReferenceId}</Link>
          ),
          version: <Link to={`/offers/${offer.id}`}>{offer.version}</Link>,
          madeBy: (
            <UserThumbnailWrapper>
              <UserThumbnail to={`/users/${offer.createdBy?.id ?? ''}`}>
                {offer.createdBy?.initials ?? ''}
              </UserThumbnail>
            </UserThumbnailWrapper>
          ),
          customer: offer.customer?.name ?? '',
          offer: truncate(offer.name, { length: 30 }),
          status: (
            <StatusCell
              key={`${offer.id}-status-${rowIndex}`}
              status={offer.status}
            >
              <StatusCircle status={offer.status} />{' '}
              {getTranslatedStatus(offer.status)}
            </StatusCell>
          ),
          menu: (
            <DropdownMenu
              menuItems={menuItems}
              loading={false}
              open={openMenu === offer.id}
              onBarsClick={() => setOpenMenu(offer.id)}
              onClose={() => setOpenMenu('')}
            />
          ), // Having a hard time passing this state
        },
      }
      return row
    },
    [handleMenuItems, openMenu]
  )

  const {
    loading: queryLoading,
    data,
    fetchMore,
  } = useQuery<AllOffersQueryReturn, AllOffersQueryParameters>(
    ALL_OFFERS_QUERY,
    {
      variables: {
        q: debouncedSearch,
        first: DEFAULT_PAGE_SIZE,
        createdBy: createdByIds,
        // GraphQL wants lower-case letters, while most of the utility methods here are written with capitalized letters
        status: !statusFilter.length
          ? decapitalizeOfferStatus(OfferStatusArray)
          : decapitalizeOfferStatus(statusFilter),
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const pageInfo = data?.allOffers?.pageInfo
  const hasNextPage = pageInfo?.hasNextPage

  const columns: Column[] = [
    {
      id: 'number',
      label: COLUMN_SECTION_LABEL.NUMBER,
      hide: isMobile,
    },
    {
      id: 'version',
      label: 'Versjon',
      cellStyle: {
        textAlign: 'center',
        width: '20px',
      },
      hide: isMobile,
    },
    {
      id: 'madeBy',
      label: COLUMN_SECTION_LABEL.MADE_BY,
      hide: isMobile,
      filterComponent: (
        <OffersCustomerFilterButton
          createdByIds={createdByIds}
          setCreatedByIdsCallback={setCreatedByIds}
          gridArea={'filter'}
          label={COLUMN_SECTION_LABEL.MADE_BY}
        />
      ),
    },
    {
      id: 'customer',
      label: COLUMN_SECTION_LABEL.CUSTOMER,
      hide: isMobile,
      cellStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '44ch',
      },
    },
    {
      id: 'offer',
      label: COLUMN_SECTION_LABEL.OFFER_NAME,
      cellStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '44ch',
      },
    },
    {
      id: 'status',
      label: COLUMN_SECTION_LABEL.STATUS,
      hide: isMobile,
      filterComponent: (
        <FilterIconButton
          statuses={statusFilter}
          setStatusFilter={setStatusFilter}
          label={COLUMN_SECTION_LABEL.STATUS}
        />
      ),
    },
    {
      id: 'menu',
      label: '',
      isMenu: true,
    },
  ]

  const offers = useMemo(() => {
    if (isUndefined(data)) return []

    const tableData = data?.allOffers?.edges?.map((edge, index) => {
      const { node: offer } = edge
      const row = handleTableRow(offer, index)
      return row
    })

    return tableData
  }, [data, handleTableRow])

  const handleScrollBottom = useCallback(async () => {
    if (
      typeof data === 'undefined' ||
      typeof pageInfo === 'undefined' ||
      !pageInfo.hasNextPage
    )
      return

    try {
      fetchMore &&
        (await fetchMore({
          variables: {
            q: debouncedSearch,
            first: DEFAULT_PAGE_SIZE,
            after: pageInfo.endCursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (typeof fetchMoreResult === 'undefined') return prev
            const alloffers = fetchMoreResult?.allOffers
            const data = {
              allOffers: {
                pageInfo: alloffers.pageInfo,
                edges: [...prev?.allOffers.edges, ...alloffers.edges],
              },
            }
            return data
          },
        }))
    } catch (e) {
      console.error(e)
    }
  }, [data, debouncedSearch, fetchMore, pageInfo])

  useInfiniteScroll(handleScrollBottom, 250, !queryLoading && hasNextPage)

  return (
    <Wrapper>
      <Title>Tilbud</Title>

      <OfferSearchBar
        value={search}
        placeholder="Søk etter tilbud..."
        onChange={setSearch}
      />
      <CreateOfferLink mobileOnly={false} to="/offers/create">
        <Button primary icon={{ icon: 'plus' }}>
          Nytt tilbud
        </Button>
      </CreateOfferLink>
      <CreateOfferLink mobileOnly={true} to="/offers/create">
        <Button width="62px" height="62px" borderRadius="50%">
          <Icon icon="plus" />
        </Button>
      </CreateOfferLink>
      <TableWrapper>
        <StyledQuotable
          columns={columns}
          data={offers ?? []}
          loading={queryLoading}
          tableProps={{ fullWidth: true, tableLayout: 'auto' }}
        />
      </TableWrapper>
    </Wrapper>
  )
}

export default Offers
