import React from 'react'

import CheckButton from 'components/CheckButton'
import FormField from 'components/FormField'
import Label from 'components/Label'
import Margin from 'components/Margin'
import { Input } from 'components/Input'

export default function AddRoomTemplateOptions({
  options,
  selectedOptions,
  onOptionChanged
}) {

  return (
    <div>
      {options.map(option => {
        switch(option.fieldType.toLowerCase()){
          case "bool":
            return (
              <Margin top="1.25em">
                <FormField>
                  <Label>{option.text}</Label>
                  <Margin top="1em" bottom="-6px">
                    <CheckButton
                      checked={selectedOptions[option.id]}
                      onChange={(val) => onOptionChanged(option.id, val)}
                    />
                  </Margin>
                </FormField>
              </Margin>
            )
          default:
          case "range":
            return (
              <Margin top="1.25em">
                <FormField>
                  <Label>{option.text}</Label>
                  <Margin top="0.2em">
                    <Input
                      type="number"
                      value={selectedOptions[option.id]}
                      onChange={e => onOptionChanged(option.id, parseInt(e.target.value))}
                    />
                  </Margin>
                </FormField>
              </Margin>
            )
        }
      })}
    </div>
  )
}
