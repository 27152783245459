import { useGlobal } from '@ur/react-hooks'
import TextArea from 'components/TextArea'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'
import { StepControls } from '../common'
import { OfferNameInput } from './OfferNameInput'

const Wrapper = styled.div`
  padding-top: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.mobile} {
    padding: 0 15px;
  }
`

const ConditionsInput = styled.div`
  margin-top: 0.48px;
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 10px;
  }
`

const ConditionsLabel = styled.label`
  margin-top: 50px;
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
`
const ConditionsAreaWrapper = styled.div`
  height: 100%;
  flex: 1;
  overflow: hidden;
`
const ConditionsArea = styled(TextArea)`
  height: 100%;
`

interface AddConditionsProps {}

export const AddConditions: React.UFC<AddConditionsProps> = () => {
  const firstRender = useRef(true)
  const company = useCompany()
  const [conditions, setConditions] = useGlobal('offer.conditions')
  const [maxHeight, setMaxHeight] = useState<number>(0)

  // Initializing the offer with the company's standard offer conditions
  useEffect(() => {
    if (!firstRender.current) return
    firstRender.current = false
    const textarea = document.getElementsByTagName('textarea')[0]
    const textareaWrapper = document.getElementById('textarea-wrapper')
    setTextareaHeight(textarea, textareaWrapper.clientHeight)
    setMaxHeight(textareaWrapper.clientHeight)

    const defaultConditions = company.settings.filter(
      setting => setting.key === 'offer-conditions'
    )[0]
    if (conditions === '' && defaultConditions !== undefined) {
      setConditions(defaultConditions.value)
    }
  }, [conditions, company.settings, setConditions, setMaxHeight])

  function setTextareaHeight(element: HTMLTextAreaElement, maxHeight: number) {
    element.style.height = 'inherit'
    element.style.height = Math.min(element.scrollHeight + 3, maxHeight) + 'px'
  }

  function handleChange(evt: any) {
    setTextareaHeight(evt.target, maxHeight)
    setConditions(evt.target.value)
  }

  return (
    <Wrapper>
      <OfferNameInput />
      <ConditionsInput>
        <ConditionsLabel>Vilkår</ConditionsLabel>
        <ConditionsAreaWrapper id="textarea-wrapper">
          <ConditionsArea
            value={conditions}
            width="100%"
            borderRadius="5px"
            minHeight="200px"
            resize="none"
            onChange={handleChange}
          />
        </ConditionsAreaWrapper>
      </ConditionsInput>
      <StepControls width="400px" />
    </Wrapper>
  )
}
