import React, { useState } from 'react'
import styled from 'styled-components'
import theme from 'theme'

import DetectClickOutside from 'containers/DetectClickOutside'
import SelectMainInput from './SelectMainInput'

interface SelectWrapperProps {
  inline: boolean
}

const SelectWrapper = styled.div<SelectWrapperProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  position: relative;
`

interface SelectListProps {
  open: boolean
  height: string
}

const SelectList = styled.ul<SelectListProps>`
  display: ${props => (props.open ? 'block' : 'none')};

  background: white;
  padding: 1em;
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  max-height: ${props => props.height};
  overflow-y: auto;

  box-sizing: border-box;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

  list-style: none;

  z-index: 1000;
`

const SelectListItem = styled.li`
  text-align: left;

  padding: 0.5em;
  cursor: pointer;

  color: ${props => props.theme.colors.darkGray};

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

export interface SelectOption<ValueType, ExtraType = any> {
  value: ValueType
  label: string
  extra?: ExtraType
}

interface SelectProps<ValueType, ExtraType = any> {
  id?: string

  searchable?: boolean
  options: SelectOption<ValueType, ExtraType>[]
  value: ValueType | null
  placeholder?: string

  inline?: boolean
  width?: string
  height?: string
  inputHeight?: string
  shadow?: string
  border?: string

  onChange: (value: ValueType, extra: ExtraType) => void
  searchValue?: string
  onSearchChanged?: (query: string) => void
}

const Select = <ValueType, ExtraType>({
  id,

  searchable,
  options,
  value,
  placeholder,

  inline,
  width = '300px',
  inputHeight = '40px',
  height = '300px',
  shadow = theme.shadow.default,
  border = '1px solid ' + theme.colors.veryLightGray,

  onChange,

  searchValue,
  onSearchChanged,
}: SelectProps<ValueType, ExtraType>) => {
  const [open, setOpen] = useState(false)
  const labelOfSelected = options.find(o => o.value === value)?.label ?? ''

  return (
    <DetectClickOutside active={open} onClickOutside={() => setOpen(false)}>
      <SelectMainInput
        open={open}
        setOpen={setOpen}
        labelOfSelected={labelOfSelected}
        placeholder={placeholder}
        searchable={searchable}
        width={width}
        height={inputHeight}
        border={border}
        shadow={shadow}
        searchValue={searchValue}
        onSearchChanged={onSearchChanged}
      />
      <SelectWrapper id={id} inline={inline}>
        <SelectList open={open} height={height}>
          {options.map(option => (
            <SelectListItem
              key={JSON.stringify(option.value)}
              onClick={() => {
                onChange(option.value, option.extra)
                setOpen(false)
              }}
            >
              {option.label}
            </SelectListItem>
          ))}
        </SelectList>
      </SelectWrapper>
    </DetectClickOutside>
  )
}

export default Select
