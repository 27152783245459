import styled from 'styled-components'
import { Icon, IconProps, Modal } from '@ur/react-components'
import { useState, useRef, useEffect, useCallback } from 'react'
import { ZIndexRange } from 'types/enums'
import useClickOutside from 'util/hooks/useClickOutside'
import { useCompany, useUser } from 'util/hooks'
import { useLazyQuery, useMutation } from '@apollo/client'
import { PATCH_OFFER_MUTATION } from '../mutations'
import { useGlobal } from '@ur/react-hooks'
import {
  PatchOfferMutationReturn,
  PatchOfferMutationParameters,
} from 'types/graphql/offers'
import { BACKEND_URL } from 'util/env'
import { WizardOffer } from '../OfferWizard/types'
import { useHistory } from 'react-router'
import { OfferEmailClientModal } from './OfferEmailClientModal'
import { OfferPreviewModal } from '../OfferPreview'
import { OFFER_FULL_QUERY } from '../queries'
import { normalizeOffer } from '../util'

const Wrapper = styled.div`
  display: flex;
  grid-area: actions;
  flex-direction: row;
  width: 223px;
  height: 48px;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  border-radius: 10px;
  padding: 0px 14px 0 24px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${props => props.theme.media.mobile} {
    width: 100%;
    justify-self: center;
    padding: 0 21px 0 36px;
  }
`

const ButtonText = styled.span`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`

const MenuWrapper = styled.aside`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 300px;
  top: 40px;
  right: -50px;
  background: ${props => props.theme.colors.veryLightGray};
  border-radius: 12px;

  border: 1px solid ${props => props.theme.colors.veryLightGray};
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.1);

  z-index: ${ZIndexRange.Dropdowns};

  ${props => props.theme.media.mobile} {
    top: 55px;
    right: 40px;
  }
`

const MenuItemWrapper = styled.div`
  display: 'inline-flex';
  color: ${props => props.theme.colors.darkBlue};
  align-items: center;
  padding: 14px 21px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    // We need something sensible here to distinguish hover states
    color: ${props => props.theme.colors.primary};
    i {
      color: ${props => props.theme.colors.primary};
    }
  }

  i {
    padding-right: 20px;
  }
`

const CloseMenuWrapper = styled.div`
  display: flex;
  height: 25px;
  flex-direction: row;
  justify-content: flex-end;

  i {
    color: ${props => props.theme.colors.gray3};
  }

  &:hover {
    i {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const HiddenSendEmailLink = styled.a`
  display: none;
`
const HiddenDownloadPDFLink = styled.a`
  display: none;
`

const HiddenShowPDFLink = styled.a`
  display: none;
`

interface OfferDetailActionsProps {
  offer: WizardOffer
}

interface MenuOptions {
  icon: IconProps
  label: string
  onClick: () => void
}

export const OfferDetailActions: React.FC<OfferDetailActionsProps> = ({
  offer,
}) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [menuOptions, setMenuOptions] = useState<MenuOptions[]>([])
  const menuRef = useRef<HTMLElement | null>(null)
  const [, setOffer] = useGlobal('offer')
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const emailLinkBody = useRef('')

  const history = useHistory()
  const company = useCompany()
  const { lastName } = useUser()

  const toggleEmailModal = () => {
    setOpenEmailModal(!openEmailModal)
  }

  const [patchOffer] = useMutation<
    PatchOfferMutationReturn,
    PatchOfferMutationParameters
  >(PATCH_OFFER_MUTATION, {
    onCompleted() {
      // I dont like this approach
      window.location.reload()
    },
  })

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  useClickOutside(menuRef, () => {
    setOpenMenu(false)
  })

  const handleOpenEmailClient = useCallback(() => {
    setOpenEmailModal(true)
    setOpenMenu(false)
  }, [setOpenEmailModal, setOpenMenu])

  const handleDownloadPDF = useCallback((offer: WizardOffer) => {
    document.getElementById('offer-pdf-download').click()
  }, [])

  const handleArchiveOffer = useCallback(
    (offer: WizardOffer) => {
      patchOffer({
        variables: {
          id: offer.id,
          input: {
            status: 'ARCHIVED',
          },
        },
        refetchQueries: ['OfferFull'],
      })
    },
    [patchOffer]
  )

  const [fetchOffer] = useLazyQuery(OFFER_FULL_QUERY, {
    onCompleted(data) {
      const { offer } = data

      const normalizedOffer = normalizeOffer(offer)
      const wizardOffer: WizardOffer = {
        ...normalizedOffer,
        isNewVersion: true,
        wizardInProgress: true,
        wizardState: 'Rom',
        wizardActive: true,
      }
      setOffer(wizardOffer)
      history.push(`/offers/${offer.id}/new-version`)
    },
  })

  const handleEditOffer = useCallback(
    (offer: WizardOffer) => {
      if (offer === null) return

      // 1. Trigger a query which hits a resolver and returns the latest offer in the timeline
      // 2. Take the return data and normalize it with the util helper
      // 3. Take normalized offer and add to global state
      // 4. Push to wizard
      // 5. Phase out the id based url view
      // We want to append a localID to all articles and rooms
      fetchOffer({ variables: { id: offer.id } })
    },
    [fetchOffer]
  )

  useEffect(() => {
    if (offer === null) return

    const emailNewlineCharacter = '%0D%0A'
    emailLinkBody.current = `
      Hei ${offer.customer?.name},
      ${emailNewlineCharacter}
      ${emailNewlineCharacter}
      Vedlagt ligger et nytt tilbud fra ${company.fullName} ved navn ${offer.name}.
      ${emailNewlineCharacter}
      ${emailNewlineCharacter}
      Med vennlig hilsen,
      ${emailNewlineCharacter}
      ${company.fullName}
      ${emailNewlineCharacter}
      ${company.contactPhoneNumber}
    `

    const options: MenuOptions[] = [
      {
        icon: {
          icon: 'download',
          type: 'solid',
          size: '19px',
        },
        label: 'Last ned',
        onClick: () => {
          handleDownloadPDF(offer)
        },
      },
      {
        icon: {
          icon: 'eye',
          type: 'solid',
          size: '19px',
        },
        label: 'Forhåndsvis',
        onClick: () => setOpenPreviewModal(true),
      },
      {
        icon: {
          icon: 'edit',
          type: 'solid',
          size: '20px',
        },
        label: 'Gjør endringer',
        onClick: () => {
          handleEditOffer(offer)
        },
      },
      {
        icon: {
          icon: 'paper-plane',
          type: 'solid',
          size: '20px',
        },
        label: 'Send tilbud',
        onClick: handleOpenEmailClient,
      },
      {
        icon: {
          icon: 'trash-alt',
          type: 'solid',
          size: '21px',
          color: 'gray3',
        },
        label: 'Arkiver',
        onClick: () => {
          handleArchiveOffer(offer)
        },
      },
    ]
    setMenuOptions(options)
  }, [
    offer,
    company.contactPhoneNumber,
    company.fullName,
    setMenuOptions,
    handleEditOffer,
    handleDownloadPDF,
    handleOpenEmailClient,
    handleArchiveOffer,
  ])

  if (offer === null) return <span>Loading</span>
  return (
    <Wrapper onClick={toggleMenu}>
      <ButtonText>Velg handling</ButtonText>
      <Icon icon="file-export" color="white" type="solid" size="20px" />
      {openMenu && (
        <MenuWrapper ref={menuRef}>
          <CloseMenuWrapper onClick={toggleMenu}>
            <Icon margin="10px 14px" icon="times" size="24px" type="light" />
            <HiddenSendEmailLink
              href={`mailto:${offer.customer.email}?subject=${offer.name}&body=${emailLinkBody.current}&lastname=${lastName}`}
              target="_blank"
              rel="noopener noreferrer"
              id="offer-send-email"
            />

            <HiddenDownloadPDFLink
              href={`${BACKEND_URL}/calculator/print-offer/${offer.id}?download&lastname=${lastName}`}
              target="_blank"
              rel="noopener noreferrer"
              id="offer-pdf-download"
            />

            <HiddenShowPDFLink
              href={`${BACKEND_URL}/calculator/print-offer/${offer.id}?lastname=${lastName}`}
              target="_blank"
              rel="noopener noreferrer"
              id="offer-pdf-show"
            />
          </CloseMenuWrapper>
          {menuOptions.map((option, index) => (
            <MenuItemWrapper onClick={option.onClick} key={index}>
              <Icon {...option.icon} />
              <span>{option.label}</span>
            </MenuItemWrapper>
          ))}
        </MenuWrapper>
      )}
      <Modal open={openEmailModal} onClose={toggleEmailModal}>
        <OfferEmailClientModal offer={offer} onClose={toggleEmailModal} />
      </Modal>
      <Modal open={openPreviewModal} onClose={() => setOpenPreviewModal(false)}>
        {openPreviewModal && (
          <OfferPreviewModal
            offer={offer}
            onClose={() => setOpenPreviewModal(false)}
          />
        )}
      </Modal>
    </Wrapper>
  )
}
