import { useScrollBottom } from './useScrollBottom'

/**
 * Hook to assist us in implementing infinite scroll.
 *
 * @param {Function} onFetchMore Callback
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 * @param {Boolean} enabled Used to disable the callbacks when e.g. data is loading
 */
export function useInfiniteScroll(
  onFetchMore: () => Promise<void>,
  offsetTrigger = 200,
  enabled = true,
  domElement?: HTMLElement | null
) {
  useScrollBottom(
    () => {
      if (enabled) {
        onFetchMore()
      }
    },
    offsetTrigger,
    500,
    domElement
  )
}
