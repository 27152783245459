import { gql } from '@apollo/client'
import { OfferFragments } from './fragments'

export const ALL_OFFERS_QUERY = gql`
  query AllOffers(
    $q: String
    $createdBy: [ID!]!
    $status: [String!]!
    $first: Int
    $after: String
  ) {
    allOffers(
      q: $q
      createdBy: $createdBy
      status: $status
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          internalReferenceId
          version
          status
          discount
          displayMode
          createdBy {
            id
            initials
          }
          customer {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const OFFER_FULL_QUERY = gql`
  query OfferFull($id: ID!) {
    offer(id: $id) {
      ...FullOfferInfo
    }
  }
  ${OfferFragments.fullOfferInfo}
`

export const ALL_ROOM_TEMPLATES_QUERY = gql`
  query AllRoomTemplatesDeep {
    allRoomTemplates {
      edges {
        node {
          ...RoomTemplateInfo
        }
      }
    }
  }
  ${OfferFragments.roomTemplateInfo}
`

export const OFFER_STATUS_PER_QUARTER_QUERY = gql`
  query OfferStatusPerQuarter($id: ID!, $year: Int!) {
    company(id: $id) {
      id
      offerStatusPerQuarter(year: $year) {
        sumCreated
        sumOpened
        sumSent
        sumArchived
      }
    }
  }
`

export const EMAIL_TEMPLATE_BY_COMPANY_QUERY = gql`
  query EmailTemplateByCompanyQuery($companyId: ID!) {
    emailTemplate(companyId: $companyId) {
      templateText
      id
    }
  }
`

export const OFFER_DIFF_QUERY = gql`
  query OfferDiffQuery($offerId: ID!) {
    offerDiff(offerId: $offerId) {
      roomDiffs {
        name
        floor
        area
        comment
        articleDiffs {
          name
          status
          count
          countDiff
          priceState
          countState
          price
          priceDiff
        }
      }
      totalCostAfterMarkup
      totalCostAfterMarkupAndDiscountWithVat
      totalCostAfterMarkupAndDiscountWithVatDiff
      offer {
        id
        name
        discount
        markup
        totalCostAfterMarkup
        totalCostAfterMarkupAndDiscountWithVat
        conditions
        status
        internalReferenceId
        displayMode
        version
        versionHistory {
          id
          version
          status
        }
        customer {
          id
          name
          phoneNumber
          postalCode
          city
          address
          email
        }
        rooms {
          id
          name
          comment
          versionId
          articles {
            article {
              price
              name
              id
            }
            count
            priceOverride
          }
          freeArticles {
            name
            count
            price
            versionId
          }

          floor
          area
        }
      }
    }
  }
`
