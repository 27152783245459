import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 23px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  svg {
    font-size: 24px;
    position: relative;
    top: 20px;
  }

  ${props => props.theme.media.mobile} {
    align-items: stretch;

    h2 {f
      font-size: 32px;
    }
    div {
      font-size: 19px;
    }
    svg {
      position: static;
    }
  }
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
`

interface HeaderProps {
  title: string
  description?: string
  menu?: React.ReactNode
}

export const CardHeader: React.UFC<HeaderProps> = ({
  title,
  description,
  menu,
}) => {
  return (
    <Header>
      <HeaderText>
        <h2>{title}</h2>
        <div>{description}</div>
      </HeaderText>
      {menu}
    </Header>
  )
}
