import { gql } from '@apollo/client'
import { RoomTemplateFragments } from './fragments'

export const ALL_ROOM_TEMPLATES_QUERY = gql`
  query AllRoomTemplates($q: String) {
    allRoomTemplates(q: $q) {
      edges {
        node {
          id
          name
          area
          floor
          areaConditions {
            valueCondition
            id
          }
          formulas {
            id
            formula
            articleActions {
              id
            }
            optionConditions {
              id
            }
            floorConditions {
              id
            }
            areaConditions {
              id
              valueCondition
            }
          }
        }
      }
    }
  }
`

export const ROOM_TEMPLATE_QUERY = gql`
  query RoomTemplate($id: ID!) {
    roomTemplate(id: $id) {
      ...RoomTemplateInfo
      titles {
        ...RoomTemplateTitleInfo
      }
      options {
        ...RoomTemplateOptionInfo
        optionConditions {
          ...RoomTemplateOptionConditionInfo
        }
      }
      formulas {
        ...RoomTemplateFormulaInfo
        optionConditions {
          ...RoomTemplateOptionConditionInfo
        }
        areaConditions {
          ...RoomTemplateAreaConditionInfo
        }
        floorConditions {
          ...RoomTemplateFloorConditionInfo
        }
        articleActions {
          ...RoomTemplateArticleActionInfo
        }
      }
    }
  }
  ${RoomTemplateFragments.roomTemplateInfo}
  ${RoomTemplateFragments.roomTemplateTitleInfo}
  ${RoomTemplateFragments.roomTemplateOptionInfo}
  ${RoomTemplateFragments.roomTemplateOptionConditionInfo}
  ${RoomTemplateFragments.roomTemplateAreaConditionInfo}
  ${RoomTemplateFragments.roomTemplateFloorConditionInfo}
  ${RoomTemplateFragments.roomTemplateFormulaInfo}
  ${RoomTemplateFragments.roomTemplateArticleActionInfo}
`
