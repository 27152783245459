import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ArticleRowDiff } from './ArticleRowDiff'
import { ArticleDiff } from './types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  ${props => props.theme.media.mobile} {
    border-radius: 0;
  }

  > div {
    padding: 0 30px;
  }
`

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 3px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 80px;
    text-align: left;
  }
`

const DetailsHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 300px;
  color: ${props => props.theme.colors.gray3};
  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`
interface RoomDiff {
  name: string
  floor: number
  area: number
  comment: string
  articleDiffs: ArticleDiff[]
}

interface ArticlesDiffCardProps {
  rooms: RoomDiff[]
}

export const ArticlesDiffCard: React.FC<ArticlesDiffCardProps> = ({
  rooms,
}) => {
  const [articleSummary, setArticleSummary] = useState<
    Omit<ArticleDiff, 'price' | 'priceDiff' | 'priceState'>[]
  >([])

  useEffect(() => {
    const articleReducer = (flattenedArticles, roomArticles) => [
      ...flattenedArticles,
      ...roomArticles,
    ]
    const allArticles = rooms.map(room => room.articleDiffs)
    const flattenedArticles = allArticles.reduce(articleReducer, [])
    const articleNames = flattenedArticles.map(article => article.name)

    // we want to remove duplicate names
    // doing it this way due to TS not keeping up
    // https://stackoverflow.com/a/33464709
    const articleNamesClean = [...Array.from(new Set(articleNames))]

    const finalArticleSummary = articleNamesClean.map(name => {
      const articles = flattenedArticles.filter(
        article => article.name === name
      )
      const articleCount = articles.reduce(
        (totalCount, article) => totalCount + article.count,
        0
      )
      const articleCountDiff = articles.reduce(
        (totalCountDiff, article) => totalCountDiff + article.countDiff,
        0
      )
      let articleCountState
      if (articleCountDiff > 0) {
        articleCountState = 'increased'
      } else if (articleCountDiff < 0) {
        articleCountState = 'decreased'
        if (articleCount + articleCountDiff === 0) {
          articleCountState = 'deleted'
        }
      } else {
        articleCountState = 'unaltered'
      }
      const articleInfo: Omit<
        ArticleDiff,
        'price' | 'priceDiff' | 'priceState'
      > = {
        name: name as string,
        count: articleCount,
        countDiff: articleCountDiff,
        countState: articleCountState,
      }
      return articleInfo
    })

    setArticleSummary(finalArticleSummary)
  }, [rooms, setArticleSummary])

  return (
    <Wrapper>
      <DetailsHeader>
        <DetailsHeaderCell>VARE</DetailsHeaderCell>
        <DetailsHeaderCell>ANTALL</DetailsHeaderCell>
      </DetailsHeader>
      {articleSummary.map((article, index) => {
        return <ArticleRowDiff article={article} key={index} />
      })}
    </Wrapper>
  )
}
