import React, { useState } from 'react'
import styled from 'styled-components'
import { Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import invariant from 'invariant'
import queryString from 'query-string'
import { useIntl } from 'react-intl'

import { Text } from 'components/Text'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components-old/Input'
import Label from 'components/Label'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import CompanyContext from 'context/CompanyContext'

import { ALL_ROOT_MANUAL_FOLDERS_QUERY } from './queries'
import { CREATE_MANUAL_FOLDER_MUTATION } from './mutations'

import { validateField, notEmptyValidation } from 'util/forms'
import { useToast } from '@ur/react-components'

export const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: flex;
  flex-direction: column;
  align-items: center;
`

function HandbookCreateFolder({ history }) {
  const toasts = useToast()
  const intl = useIntl()
  const t = intl.formatMessage
  const parsedSearch = queryString.parse(history.location.search)
  const parentFolder = parsedSearch.parentFolder

  const [formData, setFormData] = useState({
    name: '',
    description: '',
  })

  const [errors, setErrors] = useState({
    name: null,
    description: null,
    richtext: null,
  })

  function onSubmit(createManualFolderMutation, company) {
    const newErrors = {}
    newErrors['name'] = validateField(
      formData,
      'name',
      notEmptyValidation,
      intl
    )

    const canSubmit = !Object.values(newErrors).some(x => x !== null)

    if (!canSubmit) {
      setErrors(newErrors)
      return
    }

    createManualFolderMutation({
      variables: {
        input: {
          ...formData,
          company: company.id,
          parentFolder: parentFolder,
        },
      },
    })
  }

  return (
    <CompanyContext.Consumer>
      {company => (
        <>
          <Wrapper>
            <h1>
              <Text color="primary">
                {t({
                  id: 'common.create-folder',
                  defaultMessage: 'Create new folder',
                })}
              </Text>
            </h1>
            <Mutation
              onCompleted={({ createHandbookFolder }) => {
                invariant(
                  createHandbookFolder,
                  'Expected a result back from the createHandbookFolder mutation.'
                )

                toasts(
                  'success',
                  t({
                    id: 'drivermanual.create-folder-success',
                    defaultMessage: 'Opprettet mappe',
                  })
                )

                history.push(
                  `/handbook/folder/${createHandbookFolder.handbookFolder.slug}`
                )
              }}
              onError={error => {
                if (error.message.includes('UNIQUE')) {
                  setErrors({
                    ...errors,
                    name: t({
                      id: 'errors.name-exists',
                      defaultMessage: 'Navn på mappe eksisterer allerede.',
                    }),
                  })
                  return
                }
                toasts(
                  'error',
                  t({
                    id: 'errors.generic',
                    defaultMessage: 'Feil på tjeneren.',
                  })
                )
              }}
              refetchQueries={() => {
                if (!parentFolder) {
                  return [{ query: ALL_ROOT_MANUAL_FOLDERS_QUERY }]
                }
              }}
              mutation={CREATE_MANUAL_FOLDER_MUTATION}
            >
              {createManualFolderMutation => (
                <Form
                  preventDefault
                  onSubmit={() => onSubmit(createManualFolderMutation, company)}
                >
                  <Margin bottom="1em">
                    <FormField inline>
                      <Label htmlFor="name">
                        {t({ id: 'common.name', defaultMessage: 'Name' })}
                      </Label>
                      <Input
                        id="name"
                        error={errors.name}
                        value={formData.name}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            name: e.target.value.slice(0, 128),
                          })
                        }
                      />
                    </FormField>
                  </Margin>
                  <Margin bottom="1em">
                    <FormField>
                      <Label htmlFor="description">
                        {t({
                          id: 'common.description',
                          defaultMessage: 'Description',
                        })}
                      </Label>
                      <Input
                        id="description"
                        error={errors.description}
                        value={formData.description}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormField>
                  </Margin>
                  <Button primary>
                    {t({ id: 'common.create', defaultMessage: 'Create' })}
                  </Button>
                </Form>
              )}
            </Mutation>
          </Wrapper>
        </>
      )}
    </CompanyContext.Consumer>
  )
}

export default withRouter(HandbookCreateFolder)
