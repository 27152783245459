import { useQuery } from '@apollo/client'
import { useForm } from '@ur/react-hooks'
import { ChartOptions, ChartData } from 'chart.js'
import CenteredErrorMessage from 'components/CenteredErrorMessage'
import { Loader } from 'components/Loader/Loader'
import { YearSelect } from 'components/Select/YearSelect'
import { COMPANY_SALES_STATISTICS_QUERY } from 'modules/companies/queries'
import React, { useMemo, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { isMobile } from 'react-device-detect'
import seedrandom from 'seedrandom'
import styled from 'styled-components'
import {
  CompanySalesStatisticsQueryParameters,
  CompanySalesStatisticsQueryReturn,
  UserSalesStatisticsNode,
} from 'types/graphql/companies'
import { useCompany } from 'util/hooks'
import { UserPopupFilter, UsersFilter } from './UserPopupFilter'
import { truncateFirstAndLastName } from './util'

interface DoughnutChartProps {
  inputYear: number
  enableFilter?: boolean
  dashboard?: boolean
}

interface AreaProp {
  area?: string
}

const GraphCard = styled.div`
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 0 56px 46px 32px;
  display: grid;
  grid-template-areas:
    'title filter'
    'graph graph';
  grid-template-columns: 2fr 1fr;
`

const GraphTitle = styled.h2<AreaProp>`
  grid-area: ${props => props.area};
  color: ${props => props.theme.colors.darkBlue};
  margin: 0;
  padding: 14px 0 14px 30px;
  font-size: 1.125rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  white-space: nowrap;
`

const GraphWrapper = styled.div<AreaProp>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const FilterSelectWrapper = styled.div<AreaProp>`
  grid-area: ${props => props.area};
  padding-top: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`
const DoughnutInfo = styled.div`
  position: absolute;
  padding-right: 120px;
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  color: ${props => props.theme.colors.gray4};
  margin: 0;

  ${props => props.theme.media.mobile} {
    display: flex;
    align-content: center;
    padding-right: 0px;
  }
`

export const DoughnutChart: React.UFC<DoughnutChartProps> = ({
  inputYear,
  enableFilter,
  dashboard = true,
}) => {
  const company = useCompany()
  const [year, setYear] = useState(inputYear)

  const {
    data: salesData,
    loading: salesLoading,
    error: salesError,
  } = useQuery<
    CompanySalesStatisticsQueryReturn,
    CompanySalesStatisticsQueryParameters
  >(COMPANY_SALES_STATISTICS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      companyId: company.id,
      year: dashboard ? year : inputYear,
      userIds: [],
      excludeUsers: [],
    },
  })

  const { formValues: filter, updateForm } = useForm<UsersFilter>({
    values: {
      excludeUsers: [],
    },
  })

  const usersWithNoSales = useMemo(
    () =>
      salesData?.companySalesStatistics.users.reduce<UserSalesStatisticsNode[]>(
        (acc, user) => {
          if (user.totalSales === 0) return acc
          return acc.concat(user)
        },
        []
      ) ?? [],
    [salesData]
  )

  const filteredUsers = useMemo(
    () =>
      salesData?.companySalesStatistics.users.reduce<UserSalesStatisticsNode[]>(
        (acc, user) => {
          if (salesData.companySalesStatistics.total === 0) return acc
          if (user.totalSales === 0) return acc
          if (filter.excludeUsers.includes(user.id)) return acc

          return acc.concat(user)
        },
        []
      ) ?? [],
    [salesData, filter.excludeUsers]
  )

  const salesPerUserData = useMemo(
    () => filteredUsers.map(user => user.totalSales),
    [filteredUsers]
  )

  const salesPerUserLabels = useMemo(
    () => filteredUsers.map(user => truncateFirstAndLastName(user.fullName)),
    [filteredUsers]
  )

  const filteredTotalSales = useMemo(() => {
    return filteredUsers.reduce((acc, user) => {
      return acc + user.totalSales
    }, 0)
  }, [filteredUsers])

  if (salesLoading || !salesData)
    return (
      <GraphCard>
        <Loader />
      </GraphCard>
    )

  if (salesError)
    return (
      <GraphCard>
        <CenteredErrorMessage />
      </GraphCard>
    )

  function salesPerUserColors(
    source: string[],
    saturation = 80,
    lightness = 80
  ) {
    return source.map(seed => {
      const random = seedrandom(seed)
      const hue = 137.6 * Math.floor(random() * 2000)
      return `hsl(${hue}deg, ${saturation}%, ${lightness}%)`
    })
  }

  const doughnutData: ChartData<'doughnut'> = {
    labels: salesPerUserLabels,
    datasets: [
      {
        data: salesPerUserData,
      },
    ],
  }

  const salesOptions: ChartOptions<'doughnut'> = {
    radius: '100%',
    cutout: '80%',
    aspectRatio: 2,
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: isMobile ? false : true,
        position: 'right',
        onClick: null,
        maxWidth: 140,
        labels: {
          usePointStyle: true,
          boxWidth: 8,
        },
      },
    },
    datasets: {
      doughnut: {
        hoverBorderWidth: 3,
        backgroundColor: salesPerUserColors(
          filteredUsers.map(user => user.fullName),
          95,
          78
        ),
        hoverBackgroundColor: salesPerUserColors(
          filteredUsers.map(user => user.fullName),
          95,
          78
        ),
        hoverBorderColor: salesPerUserColors(
          filteredUsers.map(user => user.fullName),
          80,
          90
        ),
      },
    },
  }

  return (
    <GraphCard>
      <GraphTitle area="title">Salg</GraphTitle>

      {!isMobile && (
        <FilterSelectWrapper area="filter">
          {enableFilter ? (
            <UserPopupFilter
              users={usersWithNoSales}
              filter={filter}
              onUpdate={updateForm}
            />
          ) : (
            <YearSelect
              iconProps={{ type: 'solid' }}
              placeholder={year.toString()}
              onChange={(value: number) => setYear(value)}
              value={year}
            />
          )}
        </FilterSelectWrapper>
      )}

      <GraphWrapper area="graph">
        <Doughnut type="doughnut" data={doughnutData} options={salesOptions} />
        <DoughnutInfo>{filteredTotalSales},-</DoughnutInfo>
      </GraphWrapper>
    </GraphCard>
  )
}
