import { gql } from '@apollo/client'
import { ArticleFragments } from 'modules/articles/queries'

export const RoomTemplateFragments = {
  roomTemplateInfo: gql`
    fragment RoomTemplateInfo on RoomTemplateNode {
      id
      name
      floor
      floorConditions {
        id
        valueCondition
      }
      area
      areaConditions {
        id
        valueCondition
      }
      pricelist {
        id
      }
    }
  `,
  roomTemplateTitleInfo: gql`
    fragment RoomTemplateTitleInfo on RoomTemplateTitleNode {
      id
      title
      order
    }
  `,
  roomTemplateOptionInfo: gql`
    fragment RoomTemplateOptionInfo on RoomTemplateOptionNode {
      id
      shortName
      text
      fieldType
      options
      order
    }
  `,
  roomTemplateOptionConditionInfo: gql`
    fragment RoomTemplateOptionConditionInfo on RoomTemplateOptionConditionNode {
      id
      valueCondition
    }
  `,
  roomTemplateAreaConditionInfo: gql`
    fragment RoomTemplateAreaConditionInfo on RoomTemplateAreaConditionNode {
      id
      valueCondition
    }
  `,
  roomTemplateFloorConditionInfo: gql`
    fragment RoomTemplateFloorConditionInfo on RoomTemplateFloorConditionNode {
      id
      valueCondition
    }
  `,
  roomTemplateFormulaInfo: gql`
    fragment RoomTemplateFormulaInfo on RoomTemplateFormulaNode {
      id
      formula
    }
  `,
  roomTemplateArticleActionInfo: gql`
    fragment RoomTemplateArticleActionInfo on RoomTemplateArticleActionNode {
      id
      article {
        ...ArticleInfo
      }
    }
    ${ArticleFragments.articleInfo}
  `,
}
