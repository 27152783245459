import { useQuery } from '@apollo/client'
import FullLoader from 'components/Loader/FullLoader'
import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { RelayNode } from 'types/graphql/common'
import {
  HandbookEntryNode,
  HandbookFolderBySlugQueryParameters,
  HandbookFolderBySlugQueryReturn,
  HandbookFolderNode,
} from 'types/graphql/handbook'
import HandbookEntryCard from '../HandbookEntryCard'
import HandbookFolderCard from '../HandbookFolderCard'
import { MANUAL_FOLDER_BY_SLUG_QUERY } from '../queries'
import HandbookFolderButtonsHeader from './HandbookFolderButtonsHeader'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HandbookFolder: React.FC = () => {
  const match = useRouteMatch<{ folderId: string }>()
  const folderSlug = match.params.folderId
  //const breadcrumbs = useBreadcrumbs()

  const { loading, data } = useQuery<
    HandbookFolderBySlugQueryReturn,
    HandbookFolderBySlugQueryParameters
  >(MANUAL_FOLDER_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { slug: folderSlug },
  })

  const folder =
    data && data.handbookFolder
      ? data.handbookFolder
      : {
          childrenFolders: {
            edges: [] as RelayNode<HandbookFolderNode>[],
          },
          entries: {
            edges: [] as RelayNode<HandbookEntryNode>[],
          },
          slugHierarchy: [] as string[],
        }

  useEffect(() => {
    return () => {
      // breadcrumbs.setSliceEnd(null)
      //breadcrumbs.setSuffixCrumbs([])
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      {loading && <FullLoader />}
      <HandbookFolderButtonsHeader folder={folder} />
      {folder.childrenFolders.edges.map(edge => (
        <HandbookFolderCard folder={edge.node} />
      ))}
      {folder.entries.edges.map(edge => (
        <HandbookEntryCard entry={edge.node} parentFolder={folder} />
      ))}
    </Wrapper>
  )
}

export default HandbookFolder
