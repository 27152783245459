import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ALL_ROOT_MANUAL_FOLDERS_QUERY } from './queries'

import { useQuery } from '@apollo/client'

import FullLoader from 'components/Loader/FullLoader'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import HandbookFolderCard from './HandbookFolderCard'

import PermissionsRequired from 'containers/PermissionsRequired'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
`

const Handbook: React.FunctionComponent<IHandbookProps> = ({ history }) => {
  const { loading, data } = useQuery(ALL_ROOT_MANUAL_FOLDERS_QUERY)
  let rootFolderEdges = []

  if (data && data.allRootHandbookFolders) {
    rootFolderEdges = data.allRootHandbookFolders.edges
  }

  return (
    <>
      <Wrapper>
        <PermissionsRequired permissions="handbook.add_handbookfolder">
          <Link to={`/handbook/create-folder`}>
            <Button primary width="200px">
              <FormattedMessage
                id="common.create-folder"
                defaultMessage="Create sub folder"
              />
            </Button>
          </Link>
        </PermissionsRequired>
        {loading && <FullLoader />}
        {rootFolderEdges.map(folderEdge => (
          <Margin top="1rem" key={folderEdge.node.id}>
            <HandbookFolderCard folder={folderEdge.node} />
          </Margin>
        ))}
      </Wrapper>
    </>
  )
}

export interface IHandbookProps {
  history?: any
}

export default Handbook
