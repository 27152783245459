const en = {
  'common.absence': 'Absence',
  'common.activities': 'Activities',
  'common.activity': 'Activity',
  'common.add-customer': 'Add customer',
  'common.add-files': 'Add files',
  'common.add-new-room-template': 'Add new room template',
  'common.add-room': 'Add rom',
  'common.availability': 'Availability',
  'common.business': 'Business',
  'common.businessOverview': 'Business overview',
  'common.category': 'Category',
  'common.codes': 'Codes',
  'common.company-pricelists': 'Company pricelists access',
  'common.company-settings': 'Company settings',
  'common.company-user-types': 'User types',
  'common.content': 'Content',
  'common.copy': 'Copy',
  'common.create': 'Create',
  'common.create-article': 'Create article',
  'common.create-entry': 'Create new entry',
  'common.create-folder': 'Create new folder',
  'common.dashboard': 'Dashboard',
  'common.date': 'Date',
  'common.day-end': 'Dag slutt',
  'common.day-start': 'Dag start',
  'common.description': 'Beskrivelse',
  'common.detail': 'Detail',
  'common.distance': 'Distanse',
  'common.documentation': 'Dokumentasjon',
  'common.documentationIssues': 'Documentation issues',
  'common.driver': 'Driver',
  'common.drivers': 'Drivers',
  'common.driversHandbook': 'Drivers handbook',
  'common.driving': 'Driving',
  'common.edit': 'Edit',
  'common.edit-room-template': 'Edit room template',
  'common.email': 'Email',
  'common.employee': 'Employee',
  'common.employees': 'Employees',
  'common.end': 'End',
  'common.entry': 'Entry',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.firstName': 'First name',
  'common.folder': 'Folder',
  'common.forum': 'Forum',
  'common.handbook': 'Handbook',
  'common.home': 'Home',
  'common.hourlist': 'Hourlist',
  'common.hours-lowercase': 'hours',
  'common.issue': 'Issue',
  'common.issues': 'Issues',
  'common.km': 'km',
  'common.lastName': 'Last name',
  'common.loggingOut': 'Logging out',
  'common.month': 'Month',
  'common.name': 'Name',
  'common.offers': 'Offers',
  'common.office': 'Office',
  'common.open': 'Open',
  'common.orders': 'Orders',
  'common.other-work': 'Other work',
  'common.overtime': 'Overtime',
  'common.pause': 'Pause',
  'common.phoneNumber': 'Phone number',
  'common.pricelists': 'Pricelists',
  'common.reg-nr': 'Reg.nr.',
  'common.regular': 'Regular',
  'common.rest': 'Rest',
  'common.role': 'Role',
  'common.roomtemplate': 'Room template',
  'common.roomtemplates': 'Room templates',
  'common.rootPricelist': 'Root pricelist',
  'common.services': 'Services',
  'common.shortcuts': 'Shortcuts',
  'common.start': 'Start',
  'common.statistics': 'Statistics',
  'common.submit': 'Submit',
  'common.terminal': 'Terminal',
  'common.thread': 'Thread',
  'common.title': 'Title',
  'common.update': 'Update',
  'common.update-entry': 'Update entry',
  'common.update-folder': 'Update folder',
  'common.user': 'User',
  'common.userType': 'User type',
  'common.users': 'Users',
  'common.work': 'Work',
  'common.year': 'Year',
  'create-user.error-email-exists': 'Email is already in use',
  'createUser.title': 'Create new user',
  'form.forgot-password': 'Forgot password?',
  'form.login': 'Log in',
  'form.password': 'Password',
  'form.remember-me': 'Remember me',
  'form.username': 'Username',
  'offers.archived-offers': 'Archived offers',
  'offers.open-offers': 'Open offers',
  'offers.status-archived': 'Archived',
  'offers.status-open': 'Open',
  'offers.toasts.count-must-be-number': 'Count must be a number',
  'offers.toasts.price-must-be-number': 'Price must be a number',
  'permissions.no-access-to-page':
    'Your user does not have access to this page',
  'pricelists.create-new': 'Create price list',
  'server.general-error-update':
    'Server error. Please try refreshing the page.',
  'users.createUser': 'Create user',
  'users.new': 'New user',
  'users.search': 'Search for user',
}
export default en
