import { useContext, useState, useEffect } from 'react'
import { UserContext, CompanyContext } from 'context'

export function useDebounce(value: string, delay = 350) {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value)
    }, delay)

    return () => clearTimeout(timeout)
  }, [value, delay])

  return debounced
}

export function usePermissions() {
  const user = useContext(UserContext)

  return {
    permissions: user.allPermissions,
    hasPermissions: (...permissions: string[]) =>
      [].concat(permissions).every(perm => user.allPermissions.includes(perm)),
  }
}

export function usePermissionsAndMe() {
  const me = useContext(UserContext)

  return {
    permissions: me.allPermissions,
    hasPermissionsAndMe: (otherUserId: string, ...permissions: string[]) =>
      otherUserId === me.id &&
      [].concat(permissions).every(perm => me.allPermissions.includes(perm)),
  }
}

export function usePermissionsOrMe() {
  const me = useContext(UserContext)

  return {
    permissions: me.allPermissions,
    hasPermissionsOrMe: (otherUserId: string, ...permissions: string[]) =>
      otherUserId === me.id ||
      [].concat(permissions).every(perm => me.allPermissions.includes(perm)),
  }
}

export const useUser = () => useContext(UserContext)
export const useCompany = () => useContext(CompanyContext)
