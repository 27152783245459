import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'components/Button'
import PermissionsRequired from 'containers/PermissionsRequired'

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${Button} {
    margin-right: 2em;
  }
`

export default function HandbookFolderButtonsHeader({ folder }) {
  const { parentFolder } = folder
  const backURL = parentFolder
    ? `/handbook/folder/${parentFolder.slug}`
    : '/handbook'

  return (
    <ButtonWrapper>
      <Link to={backURL}>
        <Button>Tilbake</Button>
      </Link>
      <PermissionsRequired permissions="handbook.add_handbookentry">
        <Link to={`/handbook/create-entry?parentFolder=${folder.id}`}>
          <Button primary width="200px">
            <FormattedMessage
              id="common.create-entry"
              defaultMessage="Create new entry"
            />
          </Button>
        </Link>
      </PermissionsRequired>
      <PermissionsRequired permissions="handbook.add_handbookfolder">
        <Link to={`/handbook/create-folder?parentFolder=${folder.id}`}>
          <Button primary width="200px">
            <FormattedMessage
              id="common.create-folder"
              defaultMessage="Create sub folder"
            />
          </Button>
        </Link>
      </PermissionsRequired>
      <PermissionsRequired permissions="handbook.change_handbookfolder">
        <Link to={`/handbook/update-folder?id=${folder.id}`}>
          <Button primary width="200px">
            <FormattedMessage
              id="common.update-folder"
              defaultMessage="Update folder"
            />
          </Button>
        </Link>
      </PermissionsRequired>
    </ButtonWrapper>
  )
}
