const saveLanguageKey = 'firmadok-language-preference'

// Some languages are more specified versions of ones we support.
// For these, we normalise the language string into one we support.
const languageNormalization: { [locale: string]: string } = {
  'en-GB': 'en',
  'en-US': 'en',
  'nb-NO': 'nb',
  'nn-NO': 'nb',
}

export function setPreferredLanguage(language: string) {
  localStorage.setItem(saveLanguageKey, language)
}

export function getPreferredLanguage() {
  const saved = localStorage.getItem(saveLanguageKey)

  if (!saved) {
    return languageNormalization['nb-NO']
    /* 
    TODO Use this code when i18n is implemented fully
    (
      languageNormalization[window.navigator.language] ||
      window.navigator.language
    ) 
    */
  }

  return saved
}
