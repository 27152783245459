import capitalize from 'lodash/capitalize'

import { WizardState } from './types'
import { round } from 'lodash'
interface CompanyDetails {
  name: string
  address: string
  postalCode: string
  city: string
}

interface DataAddress {
  adresse: string[]
  kommune: string
  kommunenummer: string
  land: string
  landkode: string
  postnummer: string
  poststed: string
}

export const organizationTypes = ['ASA', 'AS', 'ENK', 'ANS', 'DA', 'NUF', 'SA']

export function capitalizeOrganizationName(wordString: string) {
  return wordString
    .split(' ')
    .map(word => {
      if (organizationTypes.includes(word.toUpperCase())) {
        return word.toUpperCase()
      }
      return capitalize(word)
    })
    .join(' ')
}

export async function searchForOrganization(organizationNumber: string) {
  const response = await fetch(
    `https://data.brreg.no/enhetsregisteret/api/enheter/${organizationNumber}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }
  )
  if (!response.ok) {
    return Promise.reject(new Error('No organization found with this number.'))
  }

  const data = await response.json()
  const dataAddress = data.forretningsadresse as DataAddress

  return Promise.resolve({
    name: capitalizeOrganizationName(data.navn ?? ''),
    address: dataAddress.adresse[0],
    postalCode: dataAddress.postnummer,
    city: capitalize(dataAddress.kommune),
  } as CompanyDetails)
}

export const steps: WizardState[] = [
  'Kunde',
  'Vilkår',
  'Rom',
  'Artikler',
  'Oppsummering',
]

export function getNextStep(currentStep: WizardState, move: number = 1) {
  const idx = steps.findIndex(step => step === currentStep)
  const nextIdx = idx + move
  if (idx > -1 && nextIdx > -1 && nextIdx < steps.length) {
    return steps[nextIdx]
  }
  return currentStep
}

export function formatNumber(number: number) {
  return round(number)
}
