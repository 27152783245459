import elmanLogo from 'assets/images/elman_logo-3.png'
import styled from 'styled-components'

const Wrapper = styled.div`
  img {
    display: none;
  }
  ${props => props.theme.media.mobile} {
    img {
      display: block;
      margin: 100px auto;
    }
    h1 {
      display: none;
    }
  }
  margin-bottom: 40px;
`

const LoginTitle = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-family: 'Work Sans';
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
`

const Subtitle = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.text.gray};
`

interface HeaderProps {
  title: string
  subtitle?: string
}

export const Header: React.UFC<HeaderProps> = ({ title, subtitle }) => {
  return (
    <Wrapper>
      <img src={elmanLogo} alt="elman logo" />
      <LoginTitle>{title}</LoginTitle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}
