import { Data } from 'components/QuoTable/types'
import { OrderItemNode } from 'modules/services/types.graphql'
import React, { useMemo } from 'react'
import { StyledQuotable } from '../common'
import { OrderItemOptionsCell } from './OrderItemOptionsCell'
import { OrderItemAttachmentsCell } from './OrderItemAttachmentsCell'

interface OrderItemsTableProps {
  orderItems: OrderItemNode[]
}

export const OrderItemsTable: React.UFC<OrderItemsTableProps> = ({
  orderItems,
}) => {
  const columns = [
    {
      id: 'service',
      label: 'Tjeneste',
    },
    {
      id: 'options',
      label: 'Valg',
    },
    {
      id: 'attachments',
      label: 'Vedlegg',
    },
    {
      id: 'amount',
      label: 'Antall',
    },
    {
      id: 'price',
      label: 'Pris',
    },
  ]
  const tableData = useMemo((): Data[] => {
    const itemRows = orderItems.map(item => {
      return {
        id: item.id,
        data: {
          service: item.service.name,
          options: (
            <OrderItemOptionsCell
              options={item.orderItemOptions.edges.map(edge => edge.node)}
            />
          ),
          attachments: (
            <OrderItemAttachmentsCell attachments={item.attachments} />
          ),
          amount: item.amount,
          price: item.price + ' kr',
        },
      }
    })

    const totalPrice = orderItems.reduce((acc, item) => {
      return acc + item.price
    }, 0)
    const summationRow = {
      id: 'summation-row',
      data: {
        amount: 'Totalpris:',
        price: totalPrice + ' kr',
      },
      props: {
        summation: true,
      },
    }
    return [...itemRows, summationRow]
  }, [orderItems])

  return (
    <StyledQuotable
      columns={columns}
      data={tableData}
      tableProps={{ fullWidth: true, tableLayout: 'auto' }}
      summationRow
    />
  )
}
