import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import Checkbox from 'components/Checkbox'
import { Input } from 'components/Input'
import FileInput from 'components/Input/FileInput'
import Thumbnail from 'components/Thumbnail'
import styled from 'styled-components'
import { notEmptyValidation } from 'util/helpers'
import { TagsInput } from '.'
import {
  CloseModalIcon,
  Field as BaseField,
  FieldProps,
  Label,
  ModalFooter,
  ModalTitle,
  ModalWrapper,
} from '.'
import { useServiceMutations } from '../mutations.hooks'
import { CreateServiceForm } from '../types'
import { ShallowServiceNode, TagNode } from '../types.graphql'
import { TagsDisplay } from './TagsDisplay'

const FileInputWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`

const CheckboxInput = styled(Checkbox)`
  justify-content: flex-end;
`

const ModalBody = styled.div`
  flex: 1;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 20px;
`

const Field = styled(BaseField)<FieldProps>`
  margin-bottom: 0;
  & > div {
    margin-top: 5px;
  }
`

interface ServiceFormModalProps {
  service?: ShallowServiceNode
  tags?: TagNode[]
  closeModalCallback: () => void
}

export const ServiceFormModal: React.UFC<ServiceFormModalProps> = ({
  service,
  tags,
  closeModalCallback,
}) => {
  const {
    formValues: form,
    formErrors,
    formChangeHandler: handler,
    formValid,
    submitHandler,
  } = useForm<CreateServiceForm>({
    values: {
      name: service?.name ?? '',
      price: service?.price.toString() ?? '',
      image: undefined,
      allowMultiple: service?.allowMultiple ?? false,
      allowUpload: service?.allowUpload ?? false,
      tags: service?.tags ?? [],
    },
    validators: {
      name: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
      price: val =>
        notEmptyValidation(val) && val !== '0' ? null : 'Må være større enn 0',
      image: val =>
        !!val || !!service?.image ? null : 'Tjenester må ha et bilde',
    },
  })

  const { service: serviceMutation } = useServiceMutations()

  const submitForm = async (values: CreateServiceForm) => {
    if (!service) {
      await serviceMutation.create({
        variables: {
          input: values,
        },
      })
    } else {
      await serviceMutation.patch({
        variables: {
          id: service.id,
          input: values,
        },
      })
    }

    closeModalCallback()
  }

  return (
    <ModalWrapper>
      <ModalTitle>{service ? 'Endre tjeneste' : 'Ny tjeneste'}</ModalTitle>
      <CloseModalIcon
        icon="times"
        size="24px"
        type="light"
        color="darkblue"
        margin="14px 0 0 0"
        onClick={closeModalCallback}
      />
      <ModalBody>
        <Field>
          <Label>Navn</Label>
          <Input
            value={form.name}
            error={formErrors.name}
            fullWidth
            onChange={handler('name')}
          />
        </Field>
        <Field>
          <Label>Pris</Label>
          <Input
            value={form.price}
            error={formErrors.price}
            fullWidth
            type="number"
            onChange={handler('price')}
          />
        </Field>
        <Field span="2">
          <Label>Bilde</Label>
          <FileInputWrapper>
            {service?.image && <Thumbnail src={service.image} size={50} />}
            <FileInput
              label={service?.image ? 'Endre bilde' : 'Velg fil'}
              onChange={files => handler('image')(files[0])}
            />
          </FileInputWrapper>
        </Field>
        <Field span="2">
          <Label>Tags</Label>
          <TagsInput tags={form.tags} onChange={handler('tags')} />
        </Field>
        {tags.length > 0 && (
          <Field span="2">
            <Label>Eksisterende tags</Label>
            <TagsDisplay tags={tags.map(tag => tag.name)} maxHeight="95px" />
          </Field>
        )}
        <Field>
          <Label>Flere valg av tjeneste</Label>
          <CheckboxInput
            value={form.allowMultiple}
            label="Ja"
            onChange={handler('allowMultiple')}
          />
        </Field>
        <Field>
          <Label>Bilde av monteringssted</Label>
          <CheckboxInput
            value={form.allowUpload}
            label="Ja"
            onChange={handler('allowUpload')}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button
          fullWidth
          primary
          height="48px"
          onClick={submitHandler(submitForm)}
          disabled={!formValid}
        >
          {service ? 'Lagre' : 'Opprett'}
        </Button>
      </ModalFooter>
    </ModalWrapper>
  )
}
