import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Select from 'components/Select'
import { ALL_CUSTOMERS_QUERY } from 'modules/customers/queries'
import theme from 'theme'
import { useDebounce } from 'util/hooks'

export default function CustomerSelector({ customer, onChange }) {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const { loading, data } = useQuery(ALL_CUSTOMERS_QUERY, {
    variables: { q: debouncedSearch, first: 20 },
  })
  let options = []
  if (!loading && data && data.allCustomers) {
    options = options.concat(
      data.allCustomers.edges.map(edge => ({
        label: edge.node.name,
        value: edge.node.id,
        extra: edge.node,
      }))
    )
  }

  return (
    <Select
      value={customer}
      options={options}
      onChange={onChange}
      width="100%"
      shadow="none"
      border={'1px solid ' + theme.colors.lighterGray}
      searchable
      searchValue={search}
      onSearchChanged={setSearch}
    />
  )
}
