import traph from 'react-traph'
import { isMobile } from 'react-device-detect'

export const SidebarGraph = traph({
  open: !isMobile,
  toggle(value?: boolean) {
    this.updateGraph({
      open: value ?? !this.open,
    })
  },
})
