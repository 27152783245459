import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { overloadColorProp } from 'util/style'

interface MenuItemProps {
  color?: string
  hoverColor?: string
}

export const MenuItem = styled.div<MenuItemProps>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  color: ${props =>
    props.color ? overloadColorProp : props.theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 600;
  font-family: 'Work Sans';
  padding: 0 1rem;
  &:hover {
    color: ${props => props.hoverColor ?? props.theme.colors.primary};
  }
  span {
    flex: 1;
    text-align: left;
    margin-left: 0.5rem;
    line-height: 22px;
  }
`

export const MenuLink = styled(MenuItem).attrs({ as: Link })``

export const MenuButton = styled(MenuItem).attrs({ as: 'button' })`
  cursor: pointer;
  border: none;
  background: white;
`
