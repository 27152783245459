import { Icon, IconProps, Input, InputProps } from '@ur/react-components'
import { getYear } from 'date-fns'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const YearInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
`

interface ButtonProps extends IconProps {
  iconDirection?: 'left' | 'right'
}

const StyledButton = styled(Icon)<ButtonProps>`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  ${props =>
    props.iconDirection === 'left' &&
    `
    border-radius: 10px 0 0 10px;
  `}
  ${props =>
    props.iconDirection === 'right' &&
    `
    border-radius: 0 10px 10px 0;
  `};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.highlight};
  }
`

const StyledInput = styled(Input)<InputProps>`
  height: 40px;
  width: 120px;
  .--input-input {
    background-color: #f2f2f2;
    border-radius: 0;
    border: 1px solid #000f9f;
    border-left: 0;
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    line-height: 24px;
    padding: 0 8px;

    ${props =>
      props.type === 'number' &&
      css`
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}
  }
  .--input-icon-left {
    display: flex;
    background-color: #f2f2f2;
    border: 1px solid #000f9f;
    border-right: 0;
    border-radius: 0;
    padding-left: 30px;
  }
`

interface YearInputProps {
  value: number

  inputProps?: InputProps

  onValidate?: (value: number) => boolean
  onChange: (value: number) => void
}

export const YearInput = React.forwardRef<HTMLInputElement, YearInputProps>(
  ({ value, inputProps = {}, onValidate = () => true, onChange }, ref) => {
    const currentYear = getYear(new Date())

    const [prevYear, setPrevYear] = useState(currentYear)

    function inputHandler(value: number) {
      if (isNaN(value) || value > 9999) return

      onChange(value)
    }
    function blurHandler() {
      if (!onValidate(value)) onChange(prevYear)
    }

    function incrementYear(step: 1 | -1) {
      const newValue = value + step
      if (!onValidate(newValue)) return

      onChange(newValue)
    }

    return (
      <YearInputWrapper>
        <StyledButton
          iconDirection="left"
          icon="chevron-left"
          size="11px"
          type="solid"
          color="white"
          onClick={() => incrementYear(-1)}
        />
        <StyledInput
          ref={ref}
          type="number"
          value={value + ''}
          iconLeftProps={{
            icon: 'calendar-alt',
            size: '13px',
            type: 'solid',
            color: 'primary',
          }}
          maxLength={4}
          onChange={value => inputHandler(parseFloat(value))}
          onFocus={() => setPrevYear(value)}
          onBlur={blurHandler}
          {...inputProps}
        />
        <StyledButton
          iconDirection="right"
          icon="chevron-right"
          size="11px"
          type="solid"
          color="white"
          onClick={() => incrementYear(1)}
        />
      </YearInputWrapper>
    )
  }
)
