import { Input, InputProps } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'

const StyledSearchBar = styled(Input)`
  border-radius: 32px;
  box-shadow: ${props => props.theme.shadow.input};

  input::placeholder {
    color: ${props => props.theme.colors.gray4};
  }

  .--input-input {
    color: ${props => props.theme.colors.darkBlue};
    font-size: 18px;
    padding: 0 0 0 20px;
    border-radius: 32px 0 0 32px;
  }

  .--input-autocomplete {
    width: 100%;
    margin-top: 2px;
    padding 1rem 0.5rem;
    border: unset;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-height: 450px !important;

    ${props => props.theme.media.mobile} {
      max-height: 300px !important;
    }

    .--input-autocomplete-item + .--input-autocomplete-item {
      margin-top: 0.5rem;
    }
  }
  
  .--input-autocomplete-inner{
    max-height: calc(450px - 2rem) !important;

    ${props => props.theme.media.mobile} {
      max-height: calc(300px - 2rem) !important;
    }
  }

  .--input-autocomplete-item {
    color: ${props => props.theme.colors.gray2};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    
    padding: 0.75rem 1rem;
    border-radius: 10px;

    :hover {
      background-color: ${props => props.theme.colors.gray5};
      &:before {
        content: unset;
      }
    }
  }

  &:focus-within {
    box-shadow: 0 4px 15px rgba(0, 15, 159, 0.25);
    i {
      color: ${props => props.theme.colors.darkBlue};
    }
  }
`

export const SearchBar = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<InputProps>
>(({ ...props }, ref) => (
  <StyledSearchBar
    ref={ref}
    border="0px"
    height="48px"
    width="448px"
    fontSize="18px"
    color="secondary"
    borderRadius="32px"
    iconRightProps={{
      icon: 'search',
      size: '20px',
      type: 'solid',
      translateX: '-10px',
      color: 'gray4',
    }}
    {...props}
  />
))
