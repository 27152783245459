import { YearInput } from 'components/Input/YearInput'
import React, { useState } from 'react'
import { DoughnutChart } from './DoughnutChart'
import { BarChart } from './BarChart'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}

  display: grid;
  grid-template-areas:
    'title date'
    'sales sales';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;

  grid-column-gap: 32px;
  grid-row-gap: 56px;
`

const Title = styled.span<AreaProp>`
  grid-area: ${props => props.area};
  font-size: 32px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`
const SectionTitle = styled.h1`
  color: ${props => props.theme.colors.darkBlue};
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 32px;
  text-align: left;
`

interface AreaProp {
  area?: string
}

const Section = styled.div<AreaProp>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;
  gap: 22px;
`
const GrapCardhWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 40px;
  }
`
export const Statistics: React.FC = () => {
  const currentYear = new Date().getFullYear()
  const [inputYear, setInputYear] = useState(currentYear)

  function validateYear(value: number) {
    return value >= 2018 && value <= currentYear
  }

  return (
    <Wrapper>
      <Section area="title">
        <Title>Statistikk</Title>
      </Section>

      <Section area="date" style={{ justifySelf: 'end' }}>
        <YearInput
          value={inputYear}
          onValidate={validateYear}
          onChange={setInputYear}
        />
      </Section>

      <Section area="sales">
        <SectionTitle>Salgsoversikt</SectionTitle>
        <GrapCardhWrapper>
          <DoughnutChart
            inputYear={inputYear}
            enableFilter={true}
            dashboard={false}
          />
          <BarChart inputYear={inputYear} dashboard={false} />
        </GrapCardhWrapper>
      </Section>
    </Wrapper>
  )
}
