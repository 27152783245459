import en from './en'

const nb: typeof en = {
  'common.absence': 'Fravær',
  'common.activities': 'Aktiviteter',
  'common.activity': 'Aktivitet',
  'common.add-customer': 'Legg til kunde',
  'common.add-files': 'Legg til filer',
  'common.add-new-room-template': 'Legg til ny rommal',
  'common.add-room': 'Legg til rom',
  'common.availability': 'Tilgjengelighet',
  'common.business': 'Firma',
  'common.businessOverview': 'Firmaoversikt',
  'common.category': 'Kategori',
  'common.codes': 'Koder',
  'common.company-pricelists': 'Prislistetilganger',
  'common.company-settings': 'Firmainnstillinger',
  'common.company-user-types': 'Brukertyper',
  'common.content': 'Innhold',
  'common.copy': 'Kopiér',
  'common.create': 'Opprett ny',
  'common.create-article': 'Legg til vare',
  'common.create-entry': 'Lag nytt innlegg',
  'common.create-folder': 'Lag ny mappe',
  'common.dashboard': 'Kontrollpanel',
  'common.date': 'Dato',
  'common.day-end': 'Dag slutt',
  'common.day-start': 'Dag start',
  'common.description': 'Beskrivelse',
  'common.detail': 'Detalje',
  'common.distance': 'Distanse',
  'common.documentation': 'Dokumentasjon',
  'common.documentationIssues': 'Dokumentasjonsavvik',
  'common.driver': 'Sjåfør',
  'common.drivers': 'Sjåfører',
  'common.driversHandbook': 'Sjåførhåndbok',
  'common.driving': 'Kjøring',
  'common.edit': 'Endre',
  'common.edit-room-template': 'Endre rommal',
  'common.email': 'E-post',
  'common.employee': 'Ansatt',
  'common.employees': 'Ansatte',
  'common.end': 'Slutt',
  'common.entry': 'Innlegg',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.firstName': 'Fornavn',
  'common.folder': 'Mappe',
  'common.forum': 'Forum',
  'common.handbook': 'Håndbok',
  'common.home': 'Hjem',
  'common.hourlist': 'Timeliste',
  'common.hours-lowercase': 'timer',
  'common.issue': 'Avvik',
  'common.issues': 'Avvik',
  'common.km': 'km',
  'common.lastName': 'Etternavn',
  'common.loggingOut': 'Logger ut',
  'common.month': 'Måned',
  'common.name': 'Navn',
  'common.offers': 'Tilbud',
  'common.office': 'Kontor',
  'common.open': 'Åpne',
  'common.orders': 'Bestillinger',
  'common.other-work': 'Annet arbeid',
  'common.overtime': 'Overtid',
  'common.pause': 'Pause',
  'common.phoneNumber': 'Telefonnummer',
  'common.pricelists': 'Prislister',
  'common.reg-nr': 'Reg.nr.',
  'common.regular': 'Normal',
  'common.rest': 'Hvile',
  'common.role': 'Rolle',
  'common.roomtemplate': 'Rommal',
  'common.roomtemplates': 'Rommaler',
  'common.rootPricelist': 'Rotprisliste',
  'common.services': 'Tjenester',
  'common.shortcuts': 'Snarveier',
  'common.start': 'Start',
  'common.statistics': 'Statistikk',
  'common.submit': 'Send inn',
  'common.terminal': 'Terminal',
  'common.thread': 'Tråd',
  'common.title': 'Tittel',
  'common.update': 'Oppdater',
  'common.update-entry': 'Endre innlegg',
  'common.update-folder': 'Endre mappe',
  'common.user': 'Bruker',
  'common.userType': 'Brukertype',
  'common.users': 'Brukere',
  'common.work': 'Arbeid',
  'common.year': 'År',
  'create-user.error-email-exists': 'E-posten er allerede brukt',
  'createUser.title': 'Opprett ny bruker',
  'form.forgot-password': 'Glemt passord?',
  'form.login': 'Logg inn',
  'form.password': 'Passord',
  'form.remember-me': 'Husk meg',
  'form.username': 'Brukernavn',
  'offers.archived-offers': 'Arkiverte tilbud',
  'offers.open-offers': 'Åpne tilbud',
  'offers.status-archived': 'Arkivert',
  'offers.status-open': 'Åpen',
  'offers.toasts.count-must-be-number': 'Antall må være et tall',
  'offers.toasts.price-must-be-number': 'Pris må være et tall',
  'permissions.no-access-to-page':
    'Brukeren din har ikke tilgang til denne siden',
  'pricelists.create-new': 'Ny prisliste',
  'server.general-error-update':
    'Server-feil. Vennligst forsøk å oppdatere siden.',
  'users.createUser': 'Opprett bruker',
  'users.new': 'Ny bruker',
  'users.search': 'Søk etter bruker',
}
export default nb
