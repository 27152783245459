import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface StyledNavLinkProps {
  color: string
}

const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  padding: 1em 0 1em 1em;
  color: ${props => (props.color ? props.color : 'white')};
`

const Item = styled.li`
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;

  a {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  &:hover {
    background-color: ${props => props.theme.colors.highlight};
    &.active,
    i {
      color: white;
    }
  }

  &:not(:hover) .active {
    color: ${props => props.theme.colors.secondary};
  }
`

const ItemIconWrapper = styled.span`
  display: inline-block;
  width: 20px;
  margin-right: 1em;
`

interface SidebarNavItemProps {
  icon: string
  to: string
  text: string
  color?: string
  onClick?: () => void
}

export const SidebarNavItem: React.UFC<SidebarNavItemProps> = ({
  icon,
  to,
  text,
  color,
  onClick,
}) => {
  return (
    <Item onClick={onClick}>
      <StyledNavLink to={to} color={color}>
        <ItemIconWrapper>
          <i className={icon}></i>
        </ItemIconWrapper>
        {text}
      </StyledNavLink>
    </Item>
  )
}
