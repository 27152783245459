import React from 'react'
import styled from 'styled-components'
import { SORT } from './QuoTable'

const Layout = styled.span`
  box-sizing: border-box;
  display: span;
  margin-left: 10px;
`

interface TableHeaderCellSortIconProps {
  sortStatus?: SORT
}

const StyledIcon = styled.i<TableHeaderCellSortIconProps>`
  font-size: 13px;

  color: ${props =>
    props.sortStatus ? props.theme.colors.primary : props.theme.colors.gray3};
`

export const TableHeaderCellSortIcon: React.FC<TableHeaderCellSortIconProps> = ({
  sortStatus,
}) => {
  const icon = getIcon(sortStatus)

  return (
    <Layout>
      <StyledIcon className={icon} sortStatus={sortStatus}></StyledIcon>
    </Layout>
  )
}

function getIcon(sortStatus?: SORT) {
  switch (sortStatus) {
    case SORT.ASCENDING:
      return 'fa fa-sort-amount-down-alt'
    case SORT.DESCENDING:
      return 'fa fa-sort-amount-up'
    default:
      return 'fa fa-sort-alt'
  }
}
