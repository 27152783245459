import styled from 'styled-components'
import PropTypes from 'prop-types'
import { overloadColorProp } from 'util/style'

const Rule = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${overloadColorProp};
`

Rule.propTypes = {
  color: PropTypes.string,
}

Rule.defaultProps = {
  color: 'veryLightGray',
}

export default Rule
