import { RoomTemplateOption } from 'types/graphql/roomTemplates'
import {
  RoomTemplateOptionRadio,
  RoomTemplateOptionRange,
} from './AddRoomTemplateOptions.components'

export interface AddRoomTemplateOptionProps {
  option: RoomTemplateOption
  value: boolean | string
  onChange: (value: boolean | number) => void
}

const AddRoomTemplateOption: React.FC<AddRoomTemplateOptionProps> = ({
  option,
  value,
  onChange,
}) => {
  switch (option.fieldType) {
    default:
    case 'BOOL':
      return (
        <RoomTemplateOptionRadio
          value={value as boolean}
          onChange={onChange}
          option={option}
        />
      )

    case 'RANGE':
      return (
        <RoomTemplateOptionRange
          value={value as string}
          onChange={onChange}
          option={option}
        />
      )
  }
}

export default AddRoomTemplateOption
