import styled, { useTheme } from 'styled-components'
import { Icon, useToast } from '@ur/react-components'
import { useGlobal } from '@ur/react-hooks'
import { Button, Modal, Loader } from '@ur/react-components'
import React, { useState, useEffect, useCallback } from 'react'
import { RoomArticleCardRow } from './RoomArticleCardRow'
import { v4 as uuidv4 } from 'uuid'
import { DeleteOfferRoomModal } from './DeleteOfferRoomModal'
import { EditOfferRoomModal } from './EditOfferRoomModal'
import { numberWithSpaces } from 'util/parsing'
import { DropdownMenu } from 'components/DropdownMenu'
import SearchBar2 from 'components/SearchBar/SearchBar2'
import {
  AllArticlesShallowQueryParameters,
  AllArticlesShallowQueryReturn,
  ArticleShallowNode,
} from 'types/graphql/articles'
import { isMobile } from 'react-device-detect'
import { ALL_ARTICLES_SHALLOW_QUERY } from 'modules/articles/queries'
import { useQuery } from '@apollo/client'
import Card from 'components/Card'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'roomtitle'
    'articles'
    'addarticle';
  width: 100%;
  max-width: 1100px;
  height: auto;
  margin-bottom: 32px;
  ${props => props.theme.media.mobile} {
    margin-bottom: 16px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: articles;
  width: 100%;
  border-radius: 10px;
  > div {
    padding: 14px;
  }
`
const RoomCardHeaderWrapper = styled.div`
  grid-area: roomtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 65px;
  padding: 0 16px 0 32px;

  ${props => props.theme.media.mobile} {
    justify-content: flex-start;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.darkBlue};
    border-radius: 0;
    padding: 0 14px;
    text-overflow: wrap;
  }
`

const RoomName = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  ${props => props.theme.media.mobile} {
    color: ${props => props.theme.colors.white};
    margin-right: 10px;
  }
`

const RoomDetails = styled.div`
  display: none;

  ${props => props.theme.media.mobile} {
    color: white;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin-right: auto;
    word-break: keep-all;
  }
`
const AddArticleButtonTextWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const AddArticleButtonContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AddArticleButton = styled(Button)`
  height: 48px;
  width: 200px;
  border-radius: 10px;
  margin-right: auto;
  ${props => props.theme.media.mobile} {
    width: auto;
    background-color: white;
    border: 0;
    box-shadow: none;
    margin: 0;
    &:active {
      box-shadow: none;
      filter: none;
    }
    &:focus {
      box-shadow: none;
      filter: none;
    }

    &:hover {
      box-shadow: none;
      filter: none;
    }
  }
`

const AddArticleIcon = styled(Icon)`
  color: ${props => props.theme.colors.white};
  font-size: 24px;
  margin: 0 14px;
  ${props => props.theme.media.mobile} {
    margin: 0;
    color: ${props => props.theme.colors.darkBlue};
  }
`

const RoomHeaderIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90px;
  justify-content: space-between;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const MobileHeaderDropdownWrapper = styled.div`
  display: none;

  ${props => props.theme.media.mobile} {
    display: flex;
  }
`

const RoomsTableHeader = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  height: 65px;
  margin: 1px 0;

  > :nth-child(1) {
    width: 180px;
    margin-right: auto;
  }
  > :nth-child(2) {
    width: 100px;
    margin-right: auto;
  }
  > :nth-child(3) {
    width: 100px;
    margin-right: auto;
  }
  > :nth-child(4) {
    width: 100px;
    margin-right: 0;
  }
  > :nth-child(5) {
    width: 40px; // change this when the proper icon is back
    margin-right: 0;
  }

  ${props => props.theme.media.mobile} {
    display: none;
  }
`
const RoomTableHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.gray3};
`

const AddArticleFooterWrapper = styled.div`
  grid-area: addarticle;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 10px 10px;

  ${props => props.theme.media.mobile} {
    padding: 7px 14px;
    border-radius: 0;
  }
`

const RoomTotalPriceWrapper = styled.div`
  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const RoomTotalSpan = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  margin-right: 5px;
`

const RoomPriceSpan = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.darkBlue};
`

const SearchWrapper = styled.div`
  margin-right: 16px;
`

interface RoomArticleCardProps {
  roomID: string
  pricelist: string
}

interface OfferWizardArticle {
  localID: string
  id: string | null
  name: string
  count: number
  price: number
  type: 'ARTICLE' | 'FREE_ARTICLE'
  autoGenerated: boolean
}

export const RoomArticleCard: React.FC<RoomArticleCardProps> = ({
  roomID,
  pricelist,
}) => {
  const theme = useTheme()
  const addToast = useToast()

  const [thisRoom, setThisRoom] = useState(null)
  const [offerRooms, setOfferRooms] = useGlobal('offer.rooms')
  const [search, setSearch] = useState('')
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState(false)
  const [openEditRoomModal, setOpenEditRoomModal] = useState(false)
  const [roomTotalPrice, setRoomTotalPrice] = useState<number>(0)
  const [executeSelect, setExecuteSelect] = useState(false)

  const [openMenu, setOpenMenu] = useState(false)

  const { data: allArticlesData } = useQuery<
    AllArticlesShallowQueryReturn,
    AllArticlesShallowQueryParameters
  >(ALL_ARTICLES_SHALLOW_QUERY, {
    variables: {
      q: search,
      pricelistId: pricelist,
    },
    onError: () => {
      addToast('error', 'En feil har oppstått')
    },
  })

  const allArticles =
    allArticlesData?.allArticles.edges.map(edge => edge.node) ?? []
  const articleOptions = allArticles.map(article => ({
    value: article.id,
    label: article.name,
    extra: article,
  }))

  const toggleDeleteRoomModal = () => {
    setOpenDeleteRoomModal(!openDeleteRoomModal)
  }

  const toggleEditRoomModal = () => {
    setOpenEditRoomModal(!openEditRoomModal)
  }

  const handleFindArticle = useCallback(
    (articleName: string): OfferWizardArticle | null => {
      const freeArticle: OfferWizardArticle = {
        count: 1,
        id: null,
        localID: uuidv4(),
        name: articleName,
        price: 0,
        type: 'FREE_ARTICLE',
        autoGenerated: false,
      }
      return freeArticle
    },
    []
  )

  const handleAddFreeArticle = useCallback(
    (articleName: string) => {
      if (thisRoom === null || articleName === '') return
      const article = handleFindArticle(articleName)

      const itemIndex = offerRooms.findIndex(
        item => item.localID === thisRoom.localID
      )

      const updatedArticles = [...thisRoom.articles, article]
      const updatedRoom = {
        ...thisRoom,
        articles: updatedArticles,
      }
      const updatedRooms = [...offerRooms]
      updatedRooms[itemIndex] = updatedRoom
      setOfferRooms(updatedRooms)
    },
    [thisRoom, offerRooms, setOfferRooms, handleFindArticle]
  )

  function handleAddArticle(article: ArticleShallowNode) {
    const roomIndex = offerRooms.findIndex(
      item => item.localID === thisRoom.localID
    )

    const offerRoomArticle: OfferWizardArticle = {
      count: 1,
      id: article.id,
      localID: uuidv4(),
      name: article.name,
      price: article.price,
      type: 'ARTICLE',
      autoGenerated: false,
    }

    const updatedArticles = [...thisRoom.articles, offerRoomArticle]
    const updatedRoom = {
      ...thisRoom,
      articles: updatedArticles,
    }
    const updatedRooms = [...offerRooms]
    updatedRooms[roomIndex] = updatedRoom
    setOfferRooms(updatedRooms)
  }

  // Side effect handler if we have triggered a select event
  useEffect(() => {
    if (executeSelect) {
      handleAddFreeArticle(search)
      setExecuteSelect(false)
      setSearch('')
    }
  }, [search, setSearch, executeSelect, setExecuteSelect, handleAddFreeArticle])

  // Find the local room and sets it to this variable
  useEffect(() => {
    const roomPlaceholder = offerRooms.filter(
      room => room.localID === roomID
    )[0]
    if (roomPlaceholder === null) return
    setThisRoom(roomPlaceholder)
  }, [roomID, offerRooms, thisRoom, setOfferRooms])

  // Update the cards totalprice whenever the local articles change.
  useEffect(() => {
    if (thisRoom === null) return
    const priceList = thisRoom.articles.map(
      article => article.price * article.count
    )
    const aggregation = priceList.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
    setRoomTotalPrice(aggregation)
  }, [setRoomTotalPrice, thisRoom])

  const handleDeleteRoom = () => {
    setOfferRooms(offerRooms.filter(room => room.localID !== roomID))
    toggleDeleteRoomModal()
  }

  const handleCopyRoom = () => {
    setOfferRooms([...offerRooms, { ...thisRoom, localID: uuidv4() }])
  }

  if (thisRoom === null) return <Loader.Spinner size={30} />
  return (
    <Wrapper>
      <RoomCardHeaderWrapper>
        <RoomName>{thisRoom?.name}</RoomName>
        <RoomDetails>
          {thisRoom?.floor} etg. - {thisRoom?.area}m2
        </RoomDetails>
        <RoomHeaderIconContainer>
          <Icon
            icon="edit"
            type="solid"
            size="17px"
            color="darkBlue"
            cursor="pointer"
            onClick={toggleEditRoomModal}
          />
          <Icon
            icon="copy"
            type="solid"
            size="17px"
            color="darkBlue"
            cursor="pointer"
            onClick={handleCopyRoom}
          />
          <Icon
            icon="trash-alt"
            type="solid"
            size="17px"
            color="gray2"
            cursor="pointer"
            onClick={toggleDeleteRoomModal}
          />
        </RoomHeaderIconContainer>
        <MobileHeaderDropdownWrapper>
          <DropdownMenu
            open={openMenu}
            onBarsClick={() => {
              setOpenMenu(!openMenu)
            }}
            backgroundColor="primary"
            menuIconColor="white"
            onClose={() => {
              setOpenMenu(false)
            }}
            menuItems={[
              {
                displayText: 'Rediger',
                icon: {
                  icon: 'edit',
                  type: 'solid',
                  size: '17px',
                },
                mobileOnly: false,
                colorOverride: 'darkBlue',
                onClick: toggleEditRoomModal,
              },
              {
                displayText: 'Dupliser',
                icon: {
                  icon: 'copy',
                  type: 'solid',
                  size: '17px',
                },
                mobileOnly: false,
                colorOverride: 'darkBlue',
                onClick: handleCopyRoom,
              },
              {
                displayText: 'Slett',
                icon: {
                  icon: 'trash-alt',
                  type: 'solid',
                  size: '17px',
                },
                mobileOnly: false,
                onClick: toggleDeleteRoomModal,
              },
            ]}
          />
        </MobileHeaderDropdownWrapper>
      </RoomCardHeaderWrapper>
      <TableWrapper>
        <RoomsTableHeader>
          <RoomTableHeaderCell>Navn</RoomTableHeaderCell>
          <RoomTableHeaderCell>Antall</RoomTableHeaderCell>
          <RoomTableHeaderCell>Pris</RoomTableHeaderCell>
          <RoomTableHeaderCell>Sum</RoomTableHeaderCell>
          <RoomTableHeaderCell></RoomTableHeaderCell>
        </RoomsTableHeader>
        {thisRoom.articles.map(article => {
          return (
            <RoomArticleCardRow
              key={`${article.name}`}
              roomID={roomID}
              localArticle={article}
            />
          )
        })}
      </TableWrapper>
      <AddArticleFooterWrapper>
        <SearchWrapper>
          <SearchBar2
            placeholder="Søk etter vare..."
            search={search}
            onSearchChanged={setSearch}
            options={articleOptions}
            onSelect={(value, extra) => handleAddArticle(extra)}
            layoutStyling={{
              width: isMobile ? '293px' : '433px',
            }}
            searchBarStyling={{
              border: `1px solid ${theme.colors.darkBlue}`,
              borderRadius: '10px',
              boxShadow: 'none',
            }}
            scrollToOnFocus={isMobile}
          />
        </SearchWrapper>
        <AddArticleButton
          onClick={() => {
            handleAddFreeArticle(search)
          }}
        >
          <AddArticleButtonContentWrapper>
            <AddArticleIcon icon="plus" type="light" />
            <AddArticleButtonTextWrapper>
              Legg til vare
            </AddArticleButtonTextWrapper>
          </AddArticleButtonContentWrapper>
        </AddArticleButton>
        <RoomTotalPriceWrapper>
          <RoomTotalSpan>Total:</RoomTotalSpan>
          <RoomPriceSpan>
            {numberWithSpaces(roomTotalPrice)},- NOK
          </RoomPriceSpan>
        </RoomTotalPriceWrapper>
      </AddArticleFooterWrapper>

      <Modal open={openDeleteRoomModal} onClose={toggleDeleteRoomModal}>
        <DeleteOfferRoomModal
          closeModalCallback={toggleDeleteRoomModal}
          deleteCallback={handleDeleteRoom}
        />
      </Modal>
      <Modal open={openEditRoomModal} onClose={toggleEditRoomModal}>
        <EditOfferRoomModal
          closeModalCallback={toggleEditRoomModal}
          roomData={thisRoom}
        />
      </Modal>
      {!!thisRoom.comment && thisRoom.comment.length && (
        <Card margin="20px 0">
          <h3>Kommentar</h3>
          <p>{thisRoom.comment}</p>
        </Card>
      )}
    </Wrapper>
  )
}
