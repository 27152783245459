import { GlobalStore } from '@ur/react-hooks'

export const initialStore: GlobalStore = {
  // Any changes here do not trigger a hot-reload.
  // Webapp needs to manually be refreshed and any data
  // here will fallback to initial values
  offer: {
    name: '',
    wizardInProgress: false,
    wizardActive: false,
    conditions: '',
    customer: null,
    discount: 0,
    markup: 0,
    displayMode: null,
    rooms: [],
    wizardState: null,
    isNewVersion: false,
    internalReferenceId: null,
    previousVersion: null,
    status: '',
    id: null,
  },
}
