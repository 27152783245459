import { gql } from '@apollo/client'
import { ArticleFragments } from './queries'

export const CREATE_PRICELIST_MUTATION = gql`
  mutation CreatePricelist($input: CreatePricelistInput!) {
    createPricelist(input: $input) {
      pricelist {
        id
      }
    }
  }
`

export const CREATE_ARTICLE_MUTATION = gql`
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      article {
        ...ArticleInfo
      }
    }
  }
  ${ArticleFragments.articleInfo}
`

export const PATCH_PRICELIST_MUTATION = gql`
  mutation PatchPricelist($id: ID!, $input: PatchPricelistInput!) {
    patchPricelist(id: $id, input: $input) {
      pricelist {
        id
      }
    }
  }
`

export const PATCH_ARTICLE_MUTATION = gql`
  mutation PatchArticle($id: ID!, $input: PatchArticleInput!) {
    patchArticle(id: $id, input: $input) {
      article {
        id
      }
    }
  }
`
export const DELETE_PRICELIST_MUTATION = gql`
  mutation DeletePricelist($id: ID!) {
    deletePricelist(id: $id) {
      found
      deletedId
    }
  }
`

export const DELETE_ARTICLE_MUTATION = gql`
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      found
      deletedId
    }
  }
`
