import isFunction from 'lodash/isFunction'
import React, { ReactNode } from 'react'
import range from 'lodash/range'
import Table, { TableProps } from './Table'
import TableBody from './TableBody'
import TableCell, { TableCellProps } from './TableCell'
import TableHead from './TableHead'
import TableHeaderCell, { TableHeaderCellProps } from './TableHeaderCell'
import TableRow from './TableRow'

type Cell =
  | string
  | number
  | JSX.Element
  | ReactNode
  | ((row: Row, index: number) => string | JSX.Element | ReactNode | number)
export type Row = Cell[]

interface EasyTableProps {
  columnNames: string[]
  rowKeys?: string[]
  data: Row[]
  tableProps?: Partial<TableProps>
  headerCellProps?: Partial<TableHeaderCellProps>
  cellProps?: Partial<TableCellProps>
  striped?: boolean
}

const EasyTable: React.FC<EasyTableProps> = ({
  columnNames,
  rowKeys = [],
  data = [],
  tableProps = {},
  headerCellProps = {},
  cellProps = {},
  striped = true,
}) => {
  const keys =
    rowKeys.length === data.length
      ? rowKeys
      : range(data.length).map(n => n + '')

  return (
    <Table {...tableProps}>
      <TableHead>
        <TableRow>
          {columnNames.map(name => (
            <TableHeaderCell key={name} {...headerCellProps}>
              {name}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow key={keys[rowIndex]} striped={striped}>
            {row.map((cellContent, columnIndex) => {
              const updatedCellContent = isFunction(cellContent)
                ? cellContent(row, rowIndex)
                : cellContent
              return (
                <TableCell
                  key={keys[rowIndex] + '-' + columnNames[columnIndex]}
                  {...cellProps}
                >
                  {updatedCellContent}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default EasyTable
