import { useQuery } from '@apollo/client'
import 'chart.js/auto'
import { ChartData, ChartOptions } from 'chart.js'
import CenteredErrorMessage from 'components/CenteredErrorMessage'
import { Loader } from 'components/Loader/Loader'
import { YearSelect } from 'components/Select/YearSelect'
import { OFFER_STATUS_PER_QUARTER_QUERY } from 'modules/offers/queries'
import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import {
  OfferStatusPerQuarterQueryParameters,
  OfferStatusPerQuarterQueryReturn,
} from 'types/graphql/offers'
import { useCompany } from 'util/hooks'

interface BarProps {
  inputYear: number
  dashboard?: boolean
}

interface AreaProp {
  area?: string
}

const GraphCard = styled.div`
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 0 56px 46px 32px;
  display: grid;
  grid-template-areas:
    'title select'
    'graph graph';
  grid-template-columns: 2fr 1fr;
`

const GraphTitle = styled.h2<AreaProp>`
  grid-area: ${props => props.area};
  color: ${props => props.theme.colors.darkBlue};
  margin: 0;
  padding: 14px 0 14px 30px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  white-space: nowrap;
`

const GraphWrapper = styled.div<AreaProp>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const SelectWrapper = styled.div<AreaProp>`
  grid-area: ${props => props.area};
  padding-top: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

export const BarChart: React.UFC<BarProps> = ({
  inputYear,
  dashboard = true,
}) => {
  const [year, setYear] = useState(inputYear)
  const company = useCompany()

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<
    OfferStatusPerQuarterQueryReturn,
    OfferStatusPerQuarterQueryParameters
  >(OFFER_STATUS_PER_QUARTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: company.id,
      year: dashboard ? year : inputYear,
    },
  })

  const openData: number[] = queryData?.company.offerStatusPerQuarter.map(
    quarter => quarter.sumOpened ?? 0
  )

  const createdData: number[] = queryData?.company.offerStatusPerQuarter.map(
    quarter => quarter.sumCreated ?? 0
  )

  const archivedData: number[] = queryData?.company.offerStatusPerQuarter.map(
    quarter => quarter.sumArchived ?? 0
  )

  if (queryLoading || !queryData)
    return (
      <GraphCard>
        <Loader />
      </GraphCard>
    )
  if (queryError) return <CenteredErrorMessage />

  const offerData: ChartData = {
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    datasets: [
      {
        label: 'Åpne',
        data: openData,
        stack: 'open',
        backgroundColor: '#8087cf',
      },
      {
        label: 'Opprettet',
        data: createdData,
        stack: 'created',
        backgroundColor: '#93dbed',
      },
      {
        label: 'Arkivert',
        data: archivedData,
        stack: 'archived',
        backgroundColor: '#d5b2f6',
      },
    ],
  }

  const offerOptions: ChartOptions = {
    scales: {
      grid: { display: false },
      yAxes: {
        stacked: true,
        grid: { display: false },
      },
      xAxes: {
        display: false,
        grid: { display: false },
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <GraphCard>
      <GraphTitle area="title">Antall tilbud</GraphTitle>
      {!isMobile && (
        <SelectWrapper area="select">
          {dashboard && (
            <YearSelect
              iconProps={{ type: 'solid' }}
              placeholder={dashboard ? year.toString() : inputYear.toString()}
              onChange={(value: number) => setYear(value)}
              value={dashboard ? year : inputYear}
            />
          )}
        </SelectWrapper>
      )}

      <GraphWrapper area="graph">
        <Bar data={offerData} options={offerOptions} type="horizontalBar" />
      </GraphWrapper>
    </GraphCard>
  )
}
