import React from 'react'
import styled from 'styled-components'
import { Flipper, Flipped } from 'react-flip-toolkit'

import { useToasts } from 'store/toasts'
import Toast from './Toast'

export const ToastWrapper = styled.div`
  position: fixed;
  bottom: 1em;
  right: 2em;
`

const ToastContainer: React.FC = () => {
  const toasts = useToasts('toasts')

  return (
    <div>
      <Flipper flipKey={toasts.map(toast => toast.id).join('')}>
        <ToastWrapper>
          {toasts.map(toast => (
            <Flipped key={toast.id} flipId={toast.id}>
              <div>
                <Toast toast={toast} />
              </div>
            </Flipped>
          ))}
        </ToastWrapper>
      </Flipper>
    </div>
  )
}

export default ToastContainer
