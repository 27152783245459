import {
  FormWrapper,
  Header,
  LoginSection,
  LoginWrapper,
  LogoSection,
  LoginField,
  LoginLabel,
  LoginLink,
  SubmitButton,
} from './components'
import Form from 'components/Form'
import { Input } from 'components/Input'
import { useState } from 'react'
import styled from 'styled-components'
import { useForm } from '@ur/react-hooks'
import { useMutation } from '@apollo/client'
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from 'modules/users/types.graphql'
import { RESET_MY_PASSWORD_MUTATION } from 'modules/users/mutations'
import useOnErrorAuto from 'util/hooks/error-handling'

const JustifyRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ForgottenPassword: React.UFC = () => {
  const {
    formValues: form,
    formChangeHandler: handler,
    submitHandler,
  } = useForm({
    values: {
      username: '',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const [passwordSent, setPasswordSent] = useState(false)

  const [resetPassword] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_MY_PASSWORD_MUTATION, {
    onCompleted: ({ resetMyPassword: { ok } }) => {
      if (ok) setPasswordSent(true)
    },
    onError: onErrorAuto(),
  })

  function onSubmit() {
    resetPassword({ variables: { ...form } })
  }

  const successText =
    'Hvis kontoen din finnes, er en e-post på vei med instruksjoner for tilbakestilling av passord.'
  const subtitleText =
    'Hvis du har glemt passordet ditt, vennligst skriv inn e-posten din. Om vi finner kontoen din, vil en e-post med et nytt passord bli sent til deg.'

  return (
    <LoginWrapper>
      <LoginSection>
        <FormWrapper>
          <Header
            title="Glemt passord"
            subtitle={passwordSent ? successText : subtitleText}
          />
          <Form preventDefault onSubmit={submitHandler(onSubmit)}>
            <LoginField>
              <LoginLabel className="login-label" htmlFor="email">
                Epost
              </LoginLabel>
              <Input
                id="email"
                value={form.username}
                onChange={handler('username')}
                width="100%"
              />
            </LoginField>
            <SubmitButton type="submit" primary width="100%">
              Send inn
            </SubmitButton>
            <JustifyRight>
              <LoginLink to="/login" className="forgotten-link">
                Tilbake til innlogging
              </LoginLink>
            </JustifyRight>
          </Form>
        </FormWrapper>
      </LoginSection>
      <LogoSection />
    </LoginWrapper>
  )
}
