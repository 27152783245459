import { Modal } from '@ur/react-components'
import { useGlobal } from '@ur/react-hooks'
import { Button as BaseButton } from 'components/Button'
import React, { useState } from 'react'
import styled from 'styled-components'
import { OfferPreviewModal } from '../../OfferPreview'

const Wrapper = styled.div<OfferPreviewProps>`
  margin-top: 0.32px;

  ${props => props.theme.media.mobile} {
    margin-top: 20px;
    padding: 0 15px;
    width: 100%;
  }
`

const Button = styled(BaseButton)`
  justify-content: space-between;
  flex-direction: row-reverse;
  i {
    margin-left: 1rem;
  }
  ${props => props.theme.media.mobile} {
    width: 100%;
    margin-bottom: 8px;
  }
`

interface OfferPreviewProps {
  mobileOnly?: boolean
}

export const OfferPreview: React.UFC<OfferPreviewProps> = ({ mobileOnly }) => {
  const [offerDisplayMode] = useGlobal('offer.displayMode')
  const [wizardState] = useGlobal('offer.wizardState')
  const [openModal, setOpenModal] = useState(false)
  const [offer] = useGlobal('offer')

  if (wizardState !== 'Oppsummering') return null

  return (
    <Wrapper id="summary-preview" mobileOnly={mobileOnly}>
      <Button
        icon={{ icon: 'eye', type: 'solid' }}
        onClick={() => setOpenModal(true)}
        disabled={!offerDisplayMode}
      >
        Forhåndsvis
      </Button>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {openModal && (
          <OfferPreviewModal
            offer={offer}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Modal>
    </Wrapper>
  )
}
