import { RelayEdgesWithPageInfo } from './common'
import { CustomerNode } from './customers'
import { CompanyNode } from '../../modules/companies/types.graphql'
import { ArticleNode } from './articles'
import { DisplayMode } from 'modules/offers/Offer/OfferCreatedOptions'
import { EmailTemplateNode } from './emailTemplates'

const OfferStatusLabel = {
  CREATED: 'CREATED',
  OPEN: 'OPEN',
  SENT: 'SENT',
  ARCHIVED: 'ARCHIVED',
  OPENED: 'OPENED',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
} as const

export const OfferStatusArray: OfferStatus[] = [
  'CREATED',
  'OPEN',
  'SENT',
  'ARCHIVED',
  'OPENED',
  'ACCEPTED',
  'DECLINED',
]

// TODO: The graphql query seems to only accept lower case statuses,
// but way to many helper functions and logic depends on capitalized letters
// Should probably be a consolidation at some point.
export type OfferStatusGraphQLQueryNotation =
  | 'created'
  | 'open'
  | 'sent'
  | 'archived'
  | 'accepted'
  | 'declined'

export type OfferStatus =
  | typeof OfferStatusLabel[keyof typeof OfferStatusLabel]
  | ''
export type OfferDisplayMode =
  | 'NO_PRICES'
  | 'SUMMARY_ONLY'
  | 'PER_ROOM'
  | 'PER_ARTICLE'

export interface OfferNode {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  internalReferenceId: string
  previousVersion: Pick<OfferFullNode, 'id'>
  status: OfferStatus
  discount: number
  markup: number
  displayMode: OfferDisplayMode
  conditions: string
  totalCost: number
  version: number
}
export interface OfferFullNode extends OfferNode {
  customer: CustomerNode
  company: CompanyNode
  rooms: OfferRoomNode[]
}
export interface OfferRoomNode {
  id: string
  localId?: string
  name: string
  floor: number
  area: number
  comment: string
  totalCost: number
  articles: OfferRoomArticleNode[]
  freeArticles: OfferRoomFreeArticleNode[]
  versionId?: string
}
export interface OfferRoomArticleNode {
  id: string
  /**
   * Id of room
   */
  room: OfferRoomNode
  /**
   * Id of article
   */
  article: ArticleNode
  priceOverride: number | null
  count: number
  autoGenerated: boolean
  versionId?: string
}
export interface OfferRoomFreeArticleNode {
  id: string
  /**
   * Id of room
   */
  room: string
  name: string
  price: number
  count: number
  versionId?: string
}

export type AllOffersOfferNode = OfferNode & {
  createdBy: {
    id: string
    initials: string
  }
  customer: CustomerNode
}

export interface OfferStatusNode {
  sumCreated: number
  sumOpened: number
  sumSent: number
  sumArchived: number
}

export interface OfferStatusPerQuarterQueryParameters {
  id: string
  year: number
}
export interface OfferStatusPerQuarterQueryReturn {
  company: {
    offerStatusPerQuarter: [OfferStatusNode]
  }
}

export interface AllOffersQueryReturn {
  allOffers: RelayEdgesWithPageInfo<AllOffersOfferNode>
}
export interface AllOffersQueryParameters {
  q: string
  createdBy: string[]
  status: OfferStatusGraphQLQueryNotation[]
  first?: number
  after?: string
}

export interface OfferFullQueryReturn {
  offer: OfferFullNode
}
export interface OfferFullQueryParameters {
  id: string
}

type OfferRoomForCreateOfferMutation = Pick<
  OfferRoomNode,
  'name' | 'floor' | 'area' | 'comment'
>
type ArticlesForCreateOfferMutation = Pick<
  OfferRoomArticleNode,
  'id' | 'count'
> & { price: number }
type FreeArticlesForCreateOfferMutation = Pick<
  OfferRoomFreeArticleNode,
  'name' | 'count' | 'price'
>

export interface CreateOfferMutationParameters {
  name: string
  conditions: string | null
  customer: string
  discount: number
  markup: number
  internalReferenceId?: string | null
  previousVersion?: string | null
  displayMode: OfferDisplayMode
  rooms: (OfferRoomForCreateOfferMutation & {
    articles: ArticlesForCreateOfferMutation[]
    freeArticles: FreeArticlesForCreateOfferMutation[]
  })[]
}
export interface CreateOfferMutationReturn {
  createOffer: {
    offer: OfferNode
  }
}

export interface CloneOfferMutationParameters {
  offerId: string
}

export interface CloneOfferMutationReturn {
  cloneOffer: {
    clonedOffer: {
      id: string
    }
  }
}
type ArticlesForUpdateOfferMutation = {
  articles: (Pick<OfferRoomArticleNode, 'id' | 'count'> & {
    price: number
  })[]
}
type FreeArticlesForUpdateOfferMutation = {
  freeArticles: Pick<OfferRoomFreeArticleNode, 'name' | 'count' | 'price'>[]
}
type OfferRoomForUpdateOfferMutation = Pick<
  OfferRoomNode,
  'name' | 'floor' | 'area' | 'comment'
> &
  ArticlesForUpdateOfferMutation &
  FreeArticlesForUpdateOfferMutation
export interface UpdateOfferMutationParameters {
  id: string
  status: OfferStatus
  name: string
  conditions: string | null
  customer: string
  rooms: OfferRoomForUpdateOfferMutation[]
  discount: number
  markup: number
  displayMode: DisplayMode
}
export interface UpdateOfferMutationReturn {
  updateOffer: {
    offer: OfferNode
  }
}

interface PatchOfferInput {
  internalReferenceId?: String
  name?: String
  company?: string
  customer?: string
  status?:
    | 'CREATED'
    | 'SENT'
    | 'OPENED'
    | 'ACCEPTED'
    | 'DECLINED'
    | 'ARCHIVED'
    | ''
  previousVersion?: string
  discount?: number
  displayMode?: DisplayMode
  conditions?: String
  rooms?: OfferRoomForUpdateOfferMutation[]
}
export interface PatchOfferMutationReturn {
  offer: OfferNode
}

export interface PatchOfferMutationParameters {
  id: string
  input: PatchOfferInput
}

export interface SendOfferEmailMutationReturn {
  sendOfferEmail: {
    ok: boolean
    smsOk: boolean
    offer: OfferNode
  }
}
export interface SendOfferEmailMutationParameters {
  id: string
  body: string
  recipientEmail: string
}

export interface EmailTemplateParameters {
  companyId: string
}
export interface EmailTemplateNodeReturn {
  emailTemplate: Pick<EmailTemplateNode, 'id' | 'templateText'>
}
