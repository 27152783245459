import {
  OfferRoomFreeArticleNode,
  OfferRoomNode,
  OfferRoomArticleNode,
  OfferStatus,
  OfferFullNode,
  OfferStatusGraphQLQueryNotation,
} from 'types/graphql/offers'
import { safeParseJson } from 'util/parsing'
import { OfferRoomArticleObject, OfferRoomObject } from './types'
import { OfferData } from './EditOffer/EditOfferInner'
import { css } from 'styled-components'
import { OfferClone } from 'store/offer'
import {
  OfferWizardArticle,
  WizardOffer,
  WizardRoom,
} from './OfferWizard/types'
import { v4 as uuidv4 } from 'uuid'

type ExtractedArticles = {
  uniqueArticles: OfferRoomArticleObject[]
  totalSum: number
}
/**
 *
 * @param {Object} rooms An object with all the unique articles in the rooms,
 *                       as well as their count, and the total cost of all articles.
 */
export function extractArticlesFromViewRooms(
  rooms: OfferRoomNode[]
): ExtractedArticles {
  if (rooms.length === 0) {
    return {
      uniqueArticles: [],
      totalSum: 0,
    }
  }
  const result = {
    uniqueArticles: [
      /* {id: <id>, identifier: <identifier>, name: <name>, price: <price>, count: <count> }*/
    ],
    totalSum: 0,
  }

  const articleLookup: {
    [id: string]: OfferRoomArticleNode
  } = {}
  rooms.forEach(room => {
    if (!room.articles || room.articles.length === 0) {
      return
    }

    room.articles.forEach(article => {
      if (article.id in articleLookup) {
        articleLookup[article.id].count += article.count
      } else {
        articleLookup[article.id] = {
          ...article,
          count: article.count,
        }
      }
      result.totalSum += article.priceOverride * article.count
    })
  })

  result.uniqueArticles = Object.values(articleLookup)

  return result
}

/**
 *
 * @param {*} rooms
 */
export function extractFreeArticlesFromViewRooms(rooms: OfferRoomObject[]) {
  return rooms.reduce<OfferRoomFreeArticleNode[]>((articles, room) => {
    return articles.concat(room.freeArticles)
  }, [])
}

/**
 *
 * @param {Object} rooms An object with all the unique articles in the rooms,
 *                       as well as their count, and the total cost of all articles.
 */
export function extractArticlesFromRooms(
  rooms: OfferRoomObject[]
): ExtractedArticles {
  if (rooms.length === 0) {
    return {
      uniqueArticles: [],
      totalSum: 0,
    }
  }
  const result = {
    uniqueArticles: [
      /* {id: <id>, identifier: <identifier>, name: <name>, price: <price>, count: <count> }*/
    ],
    totalSum: 0,
  }

  const articleLookup: {
    [id: string]: OfferRoomArticleObject
  } = {}
  rooms.forEach(room => {
    if (!room.articles || room.articles.length === 0) {
      return
    }

    room.articles.forEach(article => {
      if (article.id in articleLookup) {
        articleLookup[article.id].count += article.count
      } else {
        articleLookup[article.id] = {
          ...article,
          count: article.count,
        }
      }
      result.totalSum += article.price * article.count
    })
  })

  result.uniqueArticles = Object.values(articleLookup)

  return result
}

/**
 *
 * @param {Object} rooms An object with cost aggregated per room and in total.
 */
export function roomSummary(rooms) {
  if (rooms.length === 0) {
    return {
      rooms: [],
      totalSum: 0,
    }
  }
  const result = {
    rooms: [],
    totalSum: 0,
  }

  rooms.forEach(room => {
    let roomSum = 0

    roomSum += room.articles.reduce(
      (sum, article) => sum + article.price * article.count,
      0
    )
    roomSum += room.freeArticles.reduce(
      (sum, article) => sum + article.price * article.count,
      0
    )

    result.totalSum += roomSum
    result.rooms.push({ name: room.name, sum: roomSum })
  })

  return result
}

/**
 *
 * @param {Object} rooms An object with cost aggregated per room and in total.
 */
export function roomViewSummary(rooms) {
  if (rooms.length === 0) {
    return {
      rooms: [],
      totalSum: 0,
    }
  }
  const result = {
    rooms: [],
    totalSum: 0,
  }

  rooms.forEach(room => {
    let roomSum = 0

    roomSum += room.articles.reduce(
      (sum, article) => sum + article.priceOverride * article.count,
      0
    )
    roomSum += room.freeArticles.reduce(
      (sum, article) => sum + article.price * article.count,
      0
    )

    result.totalSum += roomSum
    result.rooms.push({ id: room.id, name: room.name, sum: roomSum })
  })

  return result
}

export function humanizeStatus(
  status: string,
  t: (descriptor: { id: string; defaultMessage: string }) => string
) {
  if (!status || status.constructor !== String) {
    return ''
  }
  switch (status.toLowerCase()) {
    case 'open':
      return t({ id: 'offers.status-open', defaultMessage: 'Åpen' })
    case 'created':
      return t({ id: 'offers.status-open', defaultMessage: 'Opprettet' })
    case 'archived':
      return t({ id: 'offers.status-archived', defaultMessage: 'Arkivert' })
    case 'sent':
      return t({ id: 'offers.status-sent', defaultMessage: 'Sendt' })
    default:
      return ''
  }
}

const CREATE_OFFER_DRAFT_KEY = 'create-last-offer-draft'

export function hasLastCreateOfferDraft() {
  return localStorage.getItem(CREATE_OFFER_DRAFT_KEY) !== null
}

export function getLastCreateOfferDraft() {
  return safeParseJson<OfferClone>(localStorage.getItem(CREATE_OFFER_DRAFT_KEY))
}

export function setLastCreateOfferDraft(data) {
  return localStorage.setItem(CREATE_OFFER_DRAFT_KEY, JSON.stringify(data))
}

export function clearLastCreateOfferDraft() {
  return localStorage.removeItem(CREATE_OFFER_DRAFT_KEY)
}

const EDIT_OFFER_DRAFT_KEY = 'edit-last-offer-draft'

export function hasLastEditOfferDraft(offerId: string) {
  const finalKey = offerId + EDIT_OFFER_DRAFT_KEY
  return localStorage.getItem(finalKey) !== null
}

export function getLastEditOfferDraft(offerId: string) {
  const finalKey = offerId + EDIT_OFFER_DRAFT_KEY
  return safeParseJson<OfferData>(localStorage.getItem(finalKey))
}

export function setLastEditOfferDraft(offerId: string, data: object) {
  const finalKey = offerId + EDIT_OFFER_DRAFT_KEY
  return localStorage.setItem(finalKey, JSON.stringify(data))
}

export function clearLastEditOfferDraft(offerId) {
  const finalKey = offerId + EDIT_OFFER_DRAFT_KEY
  return localStorage.removeItem(finalKey)
}

export function statusStyleHelper(status: OfferStatus) {
  switch (status) {
    case 'OPEN':
      return css`
        ${props => props.theme.colors.success};
      `
    case 'CREATED':
      return css`
        ${props => props.theme.colors.information};
      `
    case 'SENT':
      return css`
        ${props => props.theme.colors.warning};
      `
    case 'ARCHIVED':
      return css`
        ${props => props.theme.colors.gray3};
      `
    case 'OPENED':
      return css`
        ${props => props.theme.colors.information};
      `
    case 'ACCEPTED':
      return css`
        ${props => props.theme.colors.success};
      `
    case 'DECLINED':
      return css`
        ${props => props.theme.colors.red};
      `
  }
}

export function decapitalizeOfferStatus(
  offerStatusList: OfferStatus[]
): OfferStatusGraphQLQueryNotation[] {
  return offerStatusList.map(status => {
    return status.toLowerCase() as OfferStatusGraphQLQueryNotation
  })
}

export function getTranslatedStatus(status: string) {
  const statuses: Record<string, string> = {
    OPEN: 'Åpen',
    ARCHIVED: 'Arkivert',
    SENT: 'Sendt',
    CREATED: 'Opprettet',
    OPENED: 'Åpnet',
    ACCEPTED: 'Godtatt',
    DECLINED: 'Avvist',
  }
  return statuses[status] ?? 'Ugyldig'
}

export function normalizeOffer(
  offer: OfferFullNode
): Omit<
  WizardOffer,
  'wizardActive' | 'wizardInProgress' | 'wizardState' | 'isNewVersion'
> {
  // Accepts an offer from a query and parses it to a wizard-ready offer object
  const flattenedRooms = offer.rooms.map(room => {
    const parsedRoom: WizardRoom = {
      name: room.name,
      area: room.area,
      floor: room.floor,
      comment: room.comment,
      localID: uuidv4(),
      versionId: room.versionId ?? null,
      articles: [
        ...room.articles.map(article => {
          const flattenedArticle: OfferWizardArticle = {
            id: article.article.id,
            price: article.priceOverride,
            type: 'ARTICLE',
            autoGenerated: article.autoGenerated,
            count: article.count,
            localID: uuidv4(),
            name: article.article.name,
            versionId: article.versionId,
          }
          flattenedArticle.price = article.priceOverride
          return flattenedArticle
        }),
        ...room.freeArticles.map(freeArticle => {
          const flattenedFreeArticle: OfferWizardArticle = {
            id: null,
            autoGenerated: false,
            count: freeArticle.count,
            localID: uuidv4(),
            versionId: freeArticle.versionId,
            name: freeArticle.name,
            price: freeArticle.price,
            type: 'FREE_ARTICLE',
          }
          return flattenedFreeArticle
        }),
      ],
    }
    return parsedRoom
  })

  const parsedOffer = {
    name: offer.name,
    id: offer.id,
    customer: offer.customer,
    conditions: offer.conditions,
    discount: offer.discount,
    markup: offer.markup,
    internalReferenceId: offer.internalReferenceId,
    displayMode: offer.displayMode,
    previousVersion: offer.id,
    rooms: flattenedRooms,
    status: offer.status,
  }

  return parsedOffer
}
