import { gql } from '@apollo/client'

export const CompanyFragments = {
  info: gql`
    fragment CompanyInfo on CompanyNode {
      id
      shortName
      fullName

      address
      contactEmailAddress
      contactPhoneNumber
      city
      postalCode

      companyLogo
    }
  `,
  userTypes: gql`
    fragment CompanyUserTypes on CompanyNode {
      userTypes {
        id
        name
        permissions
        defaultPricelist {
          id
        }
        pricelists {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
  settings: gql`
    fragment CompanySettings on CompanyNode {
      settings {
        id
        key
        value
      }
    }
  `,
}

export const MY_COMPANIES = gql`
  query MyCompanies {
    me {
      id
      companies {
        edges {
          node {
            ...CompanyInfo
          }
        }
      }
    }
  }
  ${CompanyFragments.info}
`

export const CURRENT_COMPANY_QUERY = gql`
  query CurrentCompany {
    currentCompany {
      ...CompanyInfo
      ...CompanyUserTypes
      ...CompanySettings
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.userTypes}
  ${CompanyFragments.settings}
`

export const COMPANY_SALES_STATISTICS_QUERY = gql`
  query CompanySalesStatistics($year: Int!) {
    companySalesStatistics(year: $year) {
      id
      total
      users {
        id
        fullName
        totalSales
      }
    }
  }
`
