import React, { useState } from 'react'
import styled from 'styled-components'
import { Query, Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import invariant from 'invariant'
import queryString from 'query-string'

import { useIntl } from 'react-intl'

import { Text } from 'components/Text'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components-old/Input'
import Label from 'components/Label'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import FullLoader from 'components/Loader/FullLoader'

import { MANUAL_FOLDER_QUERY } from './queries'
import { UPDATE_MANUAL_FOLDER_MUTATION } from './mutations'

import { validateField, notEmptyValidation } from 'util/forms'
import { useToast } from '@ur/react-components'

export const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}

  display: flex;
  flex-direction: column;
  align-items: center;
`

function HandbookUpdateFolder({ history }) {
  const toasts = useToast()
  const intl = useIntl()
  const t = intl.formatMessage
  const parsedSearch = queryString.parse(history.location.search)
  const folderId = parsedSearch.id

  invariant(folderId, 'Must specify folder id')

  const [formData, setFormData] = useState(null)

  const [errors, setErrors] = useState({
    name: null,
    description: null,
    richtext: null,
  })

  function onSubmit(updateHandbookFolderMutation) {
    const newErrors = {}
    newErrors['name'] = validateField(
      formData,
      'name',
      notEmptyValidation,
      intl
    )

    const canSubmit = !Object.values(newErrors).some(x => x !== null)

    if (!canSubmit) {
      setErrors(newErrors)
      return
    }

    updateHandbookFolderMutation({
      variables: {
        id: folderId,
        input: {
          ...formData,
        },
      },
    })
  }

  return (
    <Query query={MANUAL_FOLDER_QUERY} variables={{ id: folderId }}>
      {({ loading, data, error }) => {
        if (loading) {
          return <FullLoader />
        }

        const handbookFolder = data.handbookFolder

        if (!handbookFolder) {
          invariant(
            handbookFolder,
            `Handbook folder ${folderId} does not exist.`
          )
          return null
        }

        if (formData === null) {
          // Set initial data
          setFormData({
            name: handbookFolder.name,
            description: handbookFolder.description,
            parentFolder: handbookFolder.parentFolder
              ? handbookFolder.parentFolder.id
              : null,
            company: handbookFolder.company.id,
          })
          return null
        }

        return (
          <>
            <Wrapper>
              <h1>
                <Text color="primary">
                  {t({
                    id: 'common.update-folder',
                    defaultMessage: 'Change folder',
                  })}
                </Text>
              </h1>
              <Mutation
                onCompleted={({ updateHandbookFolder }) => {
                  invariant(
                    updateHandbookFolder,
                    'Expected a result back from the createHandbookFolder mutation.'
                  )

                  toasts(
                    'success',
                    t({
                      id: 'driverhandbook.update-folder-success',
                      defaultMessage: 'Successfully updated folder',
                    })
                  )

                  history.push(
                    `/handbook/folder/${updateHandbookFolder.handbookFolder.slug}`
                  )
                }}
                onError={error => {
                  if (error.message.includes('UNIQUE')) {
                    setErrors({
                      ...errors,
                      name: t({
                        id: 'errors.name-exists',
                        defaultMessage: 'Name already exists.',
                      }),
                    })
                    return
                  }
                  toasts(
                    'error',
                    t({
                      id: 'errors.generic',
                      defaultMessage: 'Error on server.',
                    })
                  )
                }}
                mutation={UPDATE_MANUAL_FOLDER_MUTATION}
              >
                {updateHandbookFolderMutation => (
                  <Form
                    preventDefault
                    onSubmit={() => onSubmit(updateHandbookFolderMutation)}
                  >
                    <Margin bottom="1em">
                      <FormField inline>
                        <Label htmlFor="name">
                          {t({ id: 'common.name', defaultMessage: 'Name' })}
                        </Label>
                        <Input
                          id="name"
                          error={errors.name}
                          value={formData.name}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              name: e.target.value.slice(0, 128),
                            })
                          }
                        />
                      </FormField>
                    </Margin>
                    <Margin bottom="1em">
                      <FormField>
                        <Label htmlFor="description">
                          {t({ id: 'common.name', defaultMessage: 'Name' })}
                        </Label>
                        <Input
                          id="description"
                          error={errors.description}
                          value={formData.description}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        />
                      </FormField>
                    </Margin>
                    <Button primary>Lagre</Button>
                  </Form>
                )}
              </Mutation>
            </Wrapper>
          </>
        )
      }}
    </Query>
  )
}

export default withRouter(HandbookUpdateFolder)
