import React from 'react'
import UserContext from 'context/UserContext'

export default function PermissionsRequired({
  permissions,
  children
}) {
  
  return (
    <UserContext.Consumer>
      {user => {
        const havePermission = [].concat(permissions).every(permission => user.allPermissions.includes(permission))
        if (!havePermission){
          return null
        }
        return children
      }}
    </UserContext.Consumer>
  )
}
