import { Button } from 'components/Button'
import { SearchBar } from 'components/SearchBar'
import PermissionsRequired from 'containers/PermissionsRequired/PermissionsRequired'

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DelayUntil } from 'util/time'
import { PricelistsTable } from './PricelistsTable'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: grid;
  grid-template-areas:
    'title  search create'
    'table  table  table';
  grid-column-gap: 48px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr 2fr auto;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`

const TableWrapper = styled.div`
  grid-area: table;
  padding-bottom: 40px;
`

const PricelistsSearchBar = styled(SearchBar)`
  grid-area: search;
  justify-self: end;
  align-self: center;
  width: 80%;
  min-height: 48px;
  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const Title = styled.span`
  grid-area: title;
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const CreatePricelistWrapper = styled.div`
  grid-area: create;
`

const delay = DelayUntil()

export const Pricelists: React.FC = () => {
  const [query, setQuery] = useState('')
  // This is the debounced version of query
  const [search, setSearch] = useState('')
  const history = useHistory()

  function handleQueryChanged(newValue) {
    setSearch(newValue)
  }

  function handleSearchChanged(event) {
    setQuery(event)
    delay(handleQueryChanged, 500, event)
  }

  return (
    <Wrapper>
      <Title>Prislister</Title>
      <PricelistsSearchBar
        value={query}
        onChange={event => handleSearchChanged(event)}
        placeholder="Søk etter prisliste..."
      />
      <PermissionsRequired permissions="articles.add_pricelist">
        <CreatePricelistWrapper>
          <Button
            icon={{ icon: 'plus' }}
            onClick={() => history.push('/pricelists/create')}
          >
            Ny prisliste
          </Button>
        </CreatePricelistWrapper>
      </PermissionsRequired>
      <TableWrapper>
        <PricelistsTable search={search} />
      </TableWrapper>
    </Wrapper>
  )
}
