import React from 'react'
import styled from 'styled-components'

import Loader from './Loader'

const FullLoaderWrapper = styled.div<FullLoaderProps>`
  position: ${props => (props.cover === 'page' ? 'fixed' : 'absolute')};
  width: ${props => (props.cover === 'page' ? '100vw' : '100%')};
  height: ${props => (props.cover === 'page' ? '100vh' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.visible ? 0.5 : 0)};
  top: 0;
  left: 0;
  z-index: 1;

  ${props =>
    props.blur &&
    `
    backdrop-filter: blur(2px);
  `}
`

export interface FullLoaderProps {
  cover?: 'page' | 'parent'
  visible?: boolean
  blur?: boolean
}

FullLoaderWrapper.defaultProps = {
  cover: 'parent',
  visible: true,
}

const Inner = styled.div`
  position: relative;
  margin-top: -6em;
`

const FullLoader: React.FunctionComponent<FullLoaderProps> = ({
  cover,
  visible,
  blur,
}) => {
  return (
    <FullLoaderWrapper cover={cover} visible={visible} blur={blur}>
      <Inner>
        <Loader size={80} />
      </Inner>
    </FullLoaderWrapper>
  )
}

export default FullLoader
