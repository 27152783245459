import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/Button'
import { Icon } from '@ur/react-components'

const Wrapper = styled.div`
  padding: 20px;
  width: 400px;
  height: 200px;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';

  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;

  ${props => props.theme.media.mobile} {
    width: 350px;
  }
`

const HeaderWrapper = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const BodyWrapper = styled.div`
  grid-area: body;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const FooterWrapper = styled.div`
  grid-area: footer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

interface DeleteOfferRoomModalProps {
  deleteCallback: () => void
  closeModalCallback: () => void
}

export const DeleteOfferRoomModal: React.FC<DeleteOfferRoomModalProps> = ({
  deleteCallback,
  closeModalCallback,
}) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Icon icon="times" size="26px" onClick={closeModalCallback} />
      </HeaderWrapper>
      <BodyWrapper>
        Sikker på at du har lyst til å slette dette rommet?
      </BodyWrapper>
      <FooterWrapper>
        <Button buttonStyle="cancel" onClick={closeModalCallback}>
          Gå tilbake
        </Button>
        <Button buttonStyle="danger" onClick={deleteCallback}>
          Slett Rom
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}
