import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Card from 'components/Card'
import Margin from 'components/Margin'
import Padding from 'components/Padding'
import Flex from 'components/Flex'
import Table from 'components/Table/Table'
import TableBody from 'components/Table/TableBody'
import TableRow from 'components/Table/TableRow'
import Rule from 'components/Rule'
import CustomerSelector from '../Selectors/CustomerSelector'
import { Button } from 'components/Button'
const EditWrapper = styled.button`
  color: ${props => props.theme.colors.gray};

  ${props => props.theme.resetButton};
  cursor: pointer;
`

const CustomerHeader = styled.h3`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

const CustomerInfoCell = styled.td`
  font-weight: 500;
  text-align: right;
`

export default function CustomerInfoCard({
  basePath,
  customer,
  onCustomerChanged,
}) {
  const [editMode, setEditMode] = useState(false)

  return (
    <Card>
      {(!customer || editMode) && (
        <Margin bottom="1em">
          <Margin bottom="1em">
            <CustomerSelector
              customer={customer && customer.id ? customer.id : null}
              onChange={(_, customer) => {
                onCustomerChanged(customer)
                setEditMode(false)
              }}
              placeholder="Velg kunde"
            />
          </Margin>

          <Margin bottom="1em">
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Link
                to={{
                  pathname: `${basePath}/add-customer`,
                  state: { returnTo: basePath },
                }}
              >
                <Button primary>Ny kunde</Button>
              </Link>
              {customer && (
                <Link
                  to={`${basePath}/edit-customer/${customer.id}?returnTo=${basePath}`}
                >
                  <Button primary>Rediger kunde</Button>
                </Link>
              )}
            </Flex>
          </Margin>
          <Rule />
        </Margin>
      )}
      {customer ? (
        <React.Fragment>
          <Flex justifyContent="space-between" alignItems="center">
            <CustomerHeader>{customer.name}</CustomerHeader>
            <EditWrapper onClick={() => setEditMode(!editMode)}>
              <i className={'fa ' + (editMode ? 'fa-times' : 'fa-edit')}></i>
            </EditWrapper>
          </Flex>
          <Padding right="1em">
            <Table fullWidth>
              <TableBody>
                <TableRow>
                  <Table.Cell>Adresse</Table.Cell>
                  <CustomerInfoCell>{customer.address}</CustomerInfoCell>
                </TableRow>
                <TableRow>
                  <Table.Cell>Sted</Table.Cell>
                  <CustomerInfoCell>
                    {customer.postalCode}, {customer.city}
                  </CustomerInfoCell>
                </TableRow>
                <TableRow>
                  <Table.Cell>Telefonnummer</Table.Cell>
                  <CustomerInfoCell>{customer.phoneNumber}</CustomerInfoCell>
                </TableRow>
                <TableRow>
                  <Table.Cell>E-post</Table.Cell>
                  <CustomerInfoCell>{customer.email}</CustomerInfoCell>
                </TableRow>
              </TableBody>
            </Table>
          </Padding>
        </React.Fragment>
      ) : (
        <div>Ingen kunde er valgt enda</div>
      )}
    </Card>
  )
}
