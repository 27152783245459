import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.5rem;

  h4 {
    color: ${props => props.theme.colors.gray};
    font-weight: 500;
    margin: 0;
  }
  div {
    i  {
      &.delete {
        margin-right: 2rem;
      }
      &.arrow {
        margin: 0 0.5rem;
      }
    }
  }
`

interface OptionSubsectionHeaderProps {
  text: string

  showSortUp: boolean
  showSortDown: boolean

  onDelete: () => void
  onSortUp: () => void
  onSortDown: () => void
}

const OptionSubsectionHeader: React.FC<OptionSubsectionHeaderProps> = ({
  text,

  showSortUp,
  showSortDown,

  onDelete,
  onSortUp,
  onSortDown,
}) => {
  return (
    <Wrapper>
      <h4>{text}</h4>
      <div>
        <Icon
          className="delete"
          icon="trash-alt"
          size="1.2em"
          color="lightGray"
          hoverColor="lighterGray"
          cursor="pointer"
          onClick={onDelete}
        />
        {showSortUp && (
          <Icon
            className="arrow"
            icon="arrow-up"
            size="1.2em"
            color="lightGray"
            hoverColor="lighterGray"
            cursor="pointer"
            onClick={onSortUp}
          />
        )}
        {showSortDown && (
          <Icon
            className="arrow"
            icon="arrow-down"
            size="1.2em"
            color="lightGray"
            hoverColor="lighterGray"
            cursor="pointer"
            onClick={onSortDown}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default OptionSubsectionHeader
