import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useGlobal } from '@ur/react-hooks'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  CreateOfferMutationParameters,
  CreateOfferMutationReturn,
  UpdateOfferMutationParameters,
  UpdateOfferMutationReturn,
} from 'types/graphql/offers'
import { CREATE_OFFER_MUTATION, UPDATE_OFFER_MUTATION } from '../mutations'

export function useOfferMutations() {
  const [, setOffer] = useGlobal('offer')
  const addToast = useToast()
  const history = useHistory()

  const resetWizard = useCallback(() => {
    setOffer({
      name: '',
      wizardInProgress: false,
      wizardActive: false,
      conditions: '',
      customer: null,
      discount: 0,
      markup: 0,
      displayMode: null,
      rooms: [],
      wizardState: null,
      isNewVersion: false,
      internalReferenceId: null,
      previousVersion: null,
      status: '',
      id: null,
    })
  }, [setOffer])

  const [createOffer, { loading: createLoading, error: createError }] =
    useMutation<CreateOfferMutationReturn, CreateOfferMutationParameters>(
      CREATE_OFFER_MUTATION,
      {
        onCompleted(data) {
          addToast('success', 'Tilbudet har blitt opprettet')
          resetWizard()
          history.push(`/offers/${data.createOffer.offer.id}`)
        },
        onError() {
          addToast('error', 'En feil har opptstått')
        },
      }
    )

  const [updateOffer, { loading: updateLoading, error: updateError }] =
    useMutation<UpdateOfferMutationReturn, UpdateOfferMutationParameters>(
      UPDATE_OFFER_MUTATION,
      {
        onCompleted(data) {
          resetWizard()
          addToast('success', 'Tilbudet har blitt oppdatert')
          history.push(`/offers/${data.updateOffer.offer.id}`)
        },
      }
    )

  return {
    createOffer,
    updateOffer,
    loading: createLoading || updateLoading,
    error: createError || updateError,
  }
}
