import isNumber from 'lodash/isNumber'

/**
 * Tries to parse json, and if the passed in string is not
 * legal json, it returns the empty object
 */
export function safeParseJson<T extends object>(
  jsonString: string,
  fallback: Partial<T> = {}
): Partial<T> {
  try {
    const parsed = JSON.parse(jsonString)
    return parsed as T
  } catch {
    return fallback
  }
}

export function safeParseFloat(
  potentialFloat?: string | number,
  fallback?: number
) {
  if (!potentialFloat) {
    return fallback ?? NaN
  }

  if (isNumber(potentialFloat)) {
    return potentialFloat
  }

  const result = parseFloat(potentialFloat.replace(',', '.'))

  if (fallback && isNaN(result)) {
    return fallback
  }

  return result
}

export function numberWithSpaces(x) {
  if (x === undefined) return ''
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}
