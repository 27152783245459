import { useCallback, useContext, useLayoutEffect } from 'react'
import throttle from 'lodash/throttle'
import ScrollContext from 'context/ScrollContext'

/**
 * Detect when we have hit bottom
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 */
export function useScrollBottom(
  callback: Function,
  offsetTrigger = 100,
  throttleAmount = 500,
  domElement?: HTMLElement | null
) {
  const scrollRef = useContext(ScrollContext)

  const getScrollProperties = useCallback(
    (container: typeof window | HTMLElement) => {
      if ('scrollY' in container) {
        const body = container.document.body
        return [
          container.scrollY,
          body.scrollHeight - body.clientHeight || Number.EPSILON,
        ]
      }

      return [
        container.scrollTop,
        container.scrollHeight - container.clientHeight || Number.EPSILON,
      ]
    },
    []
  )

  useLayoutEffect(() => {
    const element = domElement ?? scrollRef?.current
    if (!element) return
    function handler() {
      if (!element) return

      const [scrollDistance, scrollMaxDistance] = getScrollProperties(element)

      if (scrollMaxDistance - scrollDistance <= offsetTrigger) callback()
    }

    //The throttle function delivers a type that is incompatible with an eventlistener. This should be researched some more.
    const throttledHandler = throttle(handler, throttleAmount)

    element.addEventListener('scroll', throttledHandler, {
      capture: false,
      passive: true,
    })

    return () => element.removeEventListener('scroll', throttledHandler)
  }, [
    callback,
    scrollRef,
    offsetTrigger,
    throttleAmount,
    domElement,
    getScrollProperties,
  ])
}
