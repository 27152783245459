import { gql } from '@apollo/client'
import { OfferFragments } from './fragments'

export const CREATE_OFFER_MUTATION = gql`
  mutation CreateOffer(
    $name: String!
    $customer: ID!
    $rooms: [CreateOfferRoomInput]!
    $conditions: String
    $displayMode: OfferDisplayMode
    $discount: Float
    $markup: Float
    $internalReferenceId: String
    $previousVersion: ID
  ) {
    createOffer(
      name: $name
      customer: $customer
      rooms: $rooms
      conditions: $conditions
      displayMode: $displayMode
      discount: $discount
      markup: $markup
      internalReferenceId: $internalReferenceId
      previousVersion: $previousVersion
    ) {
      offer {
        ...FullOfferInfo
      }
    }
  }
  ${OfferFragments.fullOfferInfo}
`

export const UPDATE_OFFER_MUTATION = gql`
  mutation UpdateOffer(
    $id: ID!
    $status: OfferStatus!
    $name: String!
    $customer: ID!
    $rooms: [CreateOfferRoomInput]!
    $conditions: String
    $discount: Float!
    $markup: Float!
    $displayMode: OfferDisplayMode
  ) {
    updateOffer(
      id: $id
      status: $status
      name: $name
      customer: $customer
      rooms: $rooms
      conditions: $conditions
      discount: $discount
      markup: $markup

      displayMode: $displayMode
    ) {
      offer {
        ...FullOfferInfo
      }
    }
  }
  ${OfferFragments.fullOfferInfo}
`

export const CLONE_OFFER_MUTATION = gql`
  mutation CloneOffer($offerId: ID!) {
    cloneOffer(offerId: $offerId) {
      clonedOffer {
        id
      }
    }
  }
`

export const PATCH_OFFER_MUTATION = gql`
  mutation PatchOffer($id: ID!, $input: PatchOfferInput!) {
    patchOffer(id: $id, input: $input) {
      offer {
        ...OfferInfo
      }
    }
  }
  ${OfferFragments.offerInfo}
`

export const SEND_OFFER_EMAIL = gql`
  mutation SendOfferEmail($id: ID!, $body: String!, $recipientEmail: String) {
    sendOfferEmail(id: $id, body: $body, recipientEmail: $recipientEmail) {
      ok
      smsOk
      offer {
        ...OfferInfo
      }
    }
  }
  ${OfferFragments.offerInfo}
`
