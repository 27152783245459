import styled from 'styled-components'
import PropTypes from 'prop-types'
import { overloadColorProp } from 'util/style'

const Color = styled.span`
  color: ${props =>
    props.primary ? props.theme.colors.primary : overloadColorProp(props)};
`

Color.propTypes = {
  color: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
}

export default Color
