import { ApolloError } from '@apollo/client'
import intlMessages from 'locale/messages'
import isFunction from 'lodash/isFunction'
import { createIntl, createIntlCache } from 'react-intl'
import { useToast } from '@ur/react-components'

type ToastType = 'success' | 'error' | 'warning' | 'info'

export const ERROR_TYPES = {
  UNIQUE_CONSTRAINT: 'uniqueConstraint',
  FOREIGN_KEY_CONSTRAINT: 'foreignKeyConstraint',
  INVALID_VALUE: 'invalidValue',
  NOT_PERMITTED: 'notPermitted',
  DOES_NOT_EXIST: 'doesNotExist',
}
export const COMPARATORS = {
  [ERROR_TYPES.UNIQUE_CONSTRAINT]: (msg: string) =>
    msg.includes('UNIQUE constraint failed'),
  [ERROR_TYPES.FOREIGN_KEY_CONSTRAINT]: (msg: string) =>
    /(violates foreign key constraint)|(FOREIGN KEY constraint failed)/g.test(
      msg
    ),
  [ERROR_TYPES.INVALID_VALUE]: (msg: string) =>
    msg.includes('got invalid value'),
  [ERROR_TYPES.NOT_PERMITTED]: (msg: string) =>
    /(permitted to access)|(do not have access)/g.test(msg),
  [ERROR_TYPES.DOES_NOT_EXIST]: (msg: string) =>
    msg.includes('matching query does not exist'),
}

const DEFAULT_MESSAGES = {
  [ERROR_TYPES.UNIQUE_CONSTRAINT]: 'errors.defaults.unique-constraint',
  [ERROR_TYPES.FOREIGN_KEY_CONSTRAINT]:
    'errors.defaults.foreign-key-constraint',
  [ERROR_TYPES.INVALID_VALUE]: 'errors.defaults.invalid-value',
  [ERROR_TYPES.NOT_PERMITTED]: 'errors.defaults.not-permitted',
  [ERROR_TYPES.DOES_NOT_EXIST]: 'errors.defaults.does-not-exist',
}

type MessagesFunctionType = (
  error: string,
  errorObj: ApolloError,
  code: number,
  errorKey: keyof typeof ERROR_TYPES | null
) => string
type MessagesDefaultType = Partial<
  {
    [T in keyof typeof ERROR_TYPES]: string | MessagesFunctionType
  }
>
type MessagesType = MessagesDefaultType | string | MessagesFunctionType

function getErrorMessage(
  message: string,
  errors: string[],
  code: number,
  messages: MessagesDefaultType,
  errorObj: ApolloError
) {
  const cache = createIntlCache()
  const intl = createIntl(
    {
      locale: 'en',
      messages: intlMessages['en'],
    },
    cache
  )

  let errorKey: keyof typeof ERROR_TYPES | null = null
  for (let [key, comp] of Object.entries(COMPARATORS)) {
    if (COMPARATORS.hasOwnProperty(key)) {
      const errorMsgComp = errors.length
        ? errors.some(_err => comp(_err))
        : false
      if (errorMsgComp || comp(message)) {
        errorKey = key as keyof typeof ERROR_TYPES
        break
      }
    }
  }

  if (!errorKey || !messages.hasOwnProperty(errorKey))
    return ['Server error', null]

  const msg: string | MessagesFunctionType =
    messages[errorKey] ?? 'common.error'
  if (isFunction(msg)) {
    const returnedMessage =
      msg(message, errorObj, code, errorKey) ??
      intl.formatMessage({ id: DEFAULT_MESSAGES[errorKey] })
    return [returnedMessage, errorKey]
  } else {
    return [intl.formatMessage({ id: msg }), errorKey]
  }
}

type ShowToastFn = (error: ApolloError, errorKey: string | null) => ToastType
export interface Options {
  showToast?: boolean | ShowToastFn
  logError?: boolean
  callback?: Function | null
}

const DEFAULT_OPTIONS = {
  showToast: true,
  logError: true,
  callback: null,
}

function useOnErrorAuto() {
  const addToast = useToast()

  function shouldOverrideMessages(
    messages: MessagesType
  ): messages is string | ((...args: any[]) => any) {
    return typeof messages === 'string' || typeof messages === 'function'
  }

  /**
   * Automatic handling of apollo onError.
   * @param {Object.<string, string|function> | string | function} messages Override what messages are shown for different errors.
   * Get object keys from the ERROR_TYPES object. Each value can be either an intl id (string),
   * or a function. The function gets four arguments: error message, error object (ApolloError),
   * code (http status code) and errorKey (key in ERROR_TYPES). Usually only the first is necessary.
   * Can also be a string, which will be used for any error message.
   * @param {Object.<string, any>} options Object defining options
   * @param {boolean | string | function} options.showToast Whether to show a toast with the given error. Defaults to
   * toast type 'error', but argument can be a string specifying other type. Default true.
   * @param {boolean} [options.logError=true] Whether to log error to console. Default true.
   * @param {function} [options.callback=null] Callback function. Is called with error object as argument.
   * @returns {function} onError function
   */
  function onErrorAuto(
    messages: MessagesType = DEFAULT_MESSAGES,
    options: Options = DEFAULT_OPTIONS
  ) {
    if (!shouldOverrideMessages(messages))
      messages = {
        ...DEFAULT_MESSAGES,
        ...messages,
      }
    options = {
      ...DEFAULT_OPTIONS,
      ...options,
    }

    return (error: ApolloError) => {
      const message = error.message

      //Need to use any here, since Apollo-GraphQL does noe properly implement the GraphQL network error interface
      const networkError = error.networkError as any

      let code = 500
      let errors: string[] = []
      if (networkError) {
        code = networkError.statusCode
        if (networkError.result && networkError.result.errors)
          errors = networkError.result.errors.map((_err: Error) => _err.message)
      }

      if (options.logError) console.error(error)
      const [errorMsg, errorKey] = shouldOverrideMessages(messages)
        ? [messages, null]
        : getErrorMessage(message, errors, code, messages, error)

      const showToast = isFunction(options.showToast)
        ? options.showToast(error, errorKey)
        : options.showToast

      if (showToast) {
        addToast(
          typeof showToast === 'string' ? showToast : 'error',
          isFunction(errorMsg)
            ? errorMsg(
                error.message,
                error,
                code,
                errorKey as keyof typeof ERROR_TYPES
              )
            : errorMsg ?? 'Error'
        )
      }

      if (isFunction(options.callback)) {
        options.callback(error, errorKey)
      }
    }
  }

  return onErrorAuto
}

export default useOnErrorAuto
