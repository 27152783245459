import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function randomId() {
  const chars = []
  for (let i = 32; i--;)
    chars.push(Math.floor(Math.random() * 26) + 97)
  return chars.reduce((acc, cur) => {
    acc += String.fromCharCode(cur)
    return acc
  }, '')
}

const Wrapper = styled.div`
  position: relative;
  display: ${props => props.inline ? 'inline-block' : 'block'};
  width: ${props => props.width};
  color: ${props => props.color};

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 12px);
    height: 1px;
    left: 6px;
    bottom: 0;
    background-color: ${props => props.color};
    transition: all .2s ease-out;
  }
  &:focus-within::after {
    background-color: ${props => props.focusColor};
    height: 2px;
  }
`
const Input = styled.input`
  width: 100%;
  padding: 6px 6px 4px 6px;

  outline: none;
  border: none;
  background-color: inherit;

  & + label {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s ease-out;
  }
  &:focus + label, &:not([value=""]) + label {
    font-size: 0.6em;
    top: 0;
    transform: translateY(-6px);
  }
  &:focus + label {
    color: ${props => props.focusColor};
  }
`

function LabeledInput({
  inline,
  width,
  color,
  focusColor,

  id,
  type,
  value,
  label,
  onChange
}) {
  const _id = id || randomId()
  return (
    <Wrapper inline={inline} width={width} color={color} focusColor={focusColor}>
      <Input
        focusColor={focusColor}

        id={_id}
        type={type}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={_id}>{label}</label>
    </Wrapper>
  )
}

LabeledInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  id: PropTypes.string,
  inline: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  width: PropTypes.string,
  color: PropTypes.string,
  focusColor: PropTypes.string,
}
LabeledInput.defaultProps = {
  id: null,
  inline: false,
  type: 'text',
  width: '280px',
  color: 'black',
  focusColor: '#5aa4ce',
}

export default LabeledInput
