import { gql } from '@apollo/client'

export const HandbookFragments = {
  handbookFolderInfo: gql`
    fragment HandbookFolderInfo on HandbookFolderNode {
      id
      name
      slug
      description
      createdAt
      updatedAt
      company {
        id
      }
    }
  `,
  handbookFolderParent: gql`
    fragment HandbookFolderParent on HandbookFolderNode {
      parentFolder {
        id
        name
        slug
        description
        createdAt
        updatedAt
      }
    }
  `,
  handbookEntryInfo: gql`
    fragment HandbookEntryInfo on HandbookEntryNode {
      id
      name
      slug
      description
      richtext
      createdAt
      updatedAt
    }
  `,
  handbookEntryParent: gql`
    fragment HandbookEntryParent on HandbookEntryNode {
      folder {
        id
        name
        slug
        description
        createdAt
        updatedAt
      }
    }
  `,
}

HandbookFragments.handbookFolderChildren = gql`
  fragment HandbookFolderChildren on HandbookFolderNode {
    childrenFolders {
      edges {
        node {
          ...HandbookFolderInfo
        }
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
`

HandbookFragments.handbookFolderEntries = gql`
  fragment HandbookFolderEntries on HandbookFolderNode {
    entries {
      edges {
        node {
          ...HandbookEntryInfo
        }
      }
    }
  }
  ${HandbookFragments.handbookEntryInfo}
`

HandbookFragments.handbookEntryAttachments = gql`
  fragment HandbookEntryAttachments on HandbookEntryNode {
    attachments {
      id
      file
      name
    }
  }
`

export const ALL_MANUAL_FOLDERS_QUERY = gql`
  query AllHandbookFolders($slug: String, $parentFolderSlug: String) {
    allHandbookFolders(slug: $slug, parentFolder_Slug: $parentFolderSlug) {
      edges {
        node {
          ...HandbookFolderInfo
          ...HandbookFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
  ${HandbookFragments.handbookFolderChildren}
`

export const ALL_ROOT_MANUAL_FOLDERS_QUERY = gql`
  query AllRootHandbookFolders {
    allRootHandbookFolders {
      edges {
        node {
          ...HandbookFolderInfo
        }
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
`

export const MANUAL_FOLDER_BY_SLUG_QUERY = gql`
  query handbookFolderBySlug($slug: String!, $parentFolder: String) {
    handbookFolder: handbookFolderBySlug(
      slug: $slug
      parentFolder: $parentFolder
    ) {
      ...HandbookFolderInfo
      ...HandbookFolderChildren
      ...HandbookFolderEntries
      slugHierarchy
      parentFolder {
        id
        slug
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
  ${HandbookFragments.handbookFolderChildren}
  ${HandbookFragments.handbookFolderEntries}
`

export const MANUAL_FOLDER_QUERY = gql`
  query HandbookFolder($id: ID!) {
    handbookFolder(id: $id) {
      ...HandbookFolderInfo
      ...HandbookFolderChildren
      ...HandbookFolderEntries
      ...HandbookFolderParent
      slugHierarchy
    }
  }
  ${HandbookFragments.handbookFolderInfo}
  ${HandbookFragments.handbookFolderChildren}
  ${HandbookFragments.handbookFolderEntries}
  ${HandbookFragments.handbookFolderParent}
`

export const MANUAL_ENTRY_BY_SLUG_QUERY = gql`
  query handbookEntryBySlug($slug: String!, $folder: String) {
    handbookEntry: handbookEntryBySlug(slug: $slug, folder: $folder) {
      ...HandbookEntryInfo
      ...HandbookEntryParent
      ...HandbookEntryAttachments
      slugHierarchy
    }
  }
  ${HandbookFragments.handbookEntryInfo}
  ${HandbookFragments.handbookEntryParent}
  ${HandbookFragments.handbookEntryAttachments}
`

export const MANUAL_ENTRY_QUERY = gql`
  query HandbookEntry($id: ID!) {
    handbookEntry(id: $id) {
      ...HandbookEntryInfo
      ...HandbookEntryParent
      ...HandbookEntryAttachments
      slugHierarchy
    }
  }
  ${HandbookFragments.handbookEntryInfo}
  ${HandbookFragments.handbookEntryParent}
  ${HandbookFragments.handbookEntryAttachments}
`
