import React, { useContext, useState, useCallback } from 'react'
import { NoFunctions } from 'types/util'

interface Crumb {
  link: string
  render: string | JSX.Element
}
interface CrumbOverrides {
  [path: string]: string
}
interface BreadcrumbsStoreData {
  sliceStart: number
  setSliceStart: (sliceStart: number) => void

  sliceEnd: number | null
  setSliceEnd: (sliceEnd: number | null) => void

  suffixCrumbs: Crumb[]
  setSuffixCrumbs: (crumbs: Crumb[]) => void

  overrides: CrumbOverrides
  addOverride: (key: string, value: string) => void
}
const BreadcrumbsContext = React.createContext<BreadcrumbsStoreData | null>(
  null
)

function useBreadcrumbs(): BreadcrumbsStoreData
function useBreadcrumbs<T extends keyof BreadcrumbsStoreData>(
  value?: T
): BreadcrumbsStoreData[T]
function useBreadcrumbs<T extends keyof BreadcrumbsStoreData>(value?: T) {
  const ctx = useContext(BreadcrumbsContext)
  if (ctx === null)
    throw new Error(
      'useBreadcrumbs can only be used within a BreadcrumbsProvider.'
    )

  if (typeof value === 'undefined') return ctx

  return ctx[value]
}
export { useBreadcrumbs }

export type InitialBreadcrumbsState = Partial<NoFunctions<BreadcrumbsStoreData>>

function useBreadcrumbsState(
  initialState: InitialBreadcrumbsState = {}
): BreadcrumbsStoreData {
  const [sliceStart, setSliceStart] = useState(initialState.sliceStart ?? 0)
  const [sliceEnd, setSliceEnd] = useState<number | null>(
    initialState.sliceEnd ?? null
  )
  const [suffixCrumbs, setSuffixCrumbs] = useState<Crumb[]>(
    initialState.suffixCrumbs ?? []
  )
  const [overrides, setOverrides] = useState<CrumbOverrides>(
    initialState.overrides ?? {}
  )

  const addOverride = useCallback(
    (key: string, value: string) => {
      if (
        Object.entries(overrides).findIndex(
          ([k, v]) => k === key && v === value
        ) > -1
      )
        return
      setOverrides(o => ({
        ...o,
        [key]: value,
      }))
    },
    [setOverrides, overrides]
  )

  return {
    sliceStart,
    setSliceStart,

    sliceEnd,
    setSliceEnd,

    suffixCrumbs,
    setSuffixCrumbs,

    overrides,
    addOverride,
  }
}

const BreadcrumbsProvider: React.FC<{
  initialState?: InitialBreadcrumbsState
}> = ({ children, initialState = {} }) => {
  const state = useBreadcrumbsState(initialState)

  return (
    <BreadcrumbsContext.Provider value={state}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export default BreadcrumbsProvider
