import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { InlineInput, Input } from 'components/Input'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  ArticleShallowNode,
  CreateArticleMutationParameters,
  CreateArticleMutationReturn,
  CreatePricelistMutationParameters,
  CreatePricelistMutationReturn,
  PatchArticleMutationParameters,
  PatchArticleMutationReturn,
  PatchPricelistMutationParameters,
  PatchPricelistMutationReturn,
} from 'types/graphql/articles'
import useOnErrorAuto from 'util/hooks/error-handling'
import { ArticleTable } from './ArticleTable'
import {
  CREATE_ARTICLE_MUTATION,
  CREATE_PRICELIST_MUTATION,
  PATCH_ARTICLE_MUTATION,
  PATCH_PRICELIST_MUTATION,
} from './mutations'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
  display: grid;
  grid-template-areas:
    'title   title'
    'form    overview'
    'buttons overview';
  grid-template-columns: 1fr 2fr;

  gap: 40px;

  ${props => props.theme.media.mobile} {
    grid-template-areas:
      'title  '
      'form  '
      'buttons '
      'overview';
  }
`
interface SectionProps {
  area: string
}

const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.colors.darkBlue};
  margin: 0;
`
const FormWrapper = styled.div<SectionProps>`
  grid-area: form;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TableWrapper = styled.div<SectionProps>`
  grid-area: overview;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
`
const ButtonWrapper = styled.div<SectionProps>`
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  gap: 20px;
`
export interface ArticleInitialData {
  id: string
  identifier: string
  name: string
  cost: number
  price: number
}

export interface PricelistInitialData {
  id: string
  name: string
  articles: ArticleInitialData[]
}

type ArticleForm = Omit<ArticleInitialData, 'id'>

interface CreatePricelistProps {
  initialData?: PricelistInitialData
}
export const CreatePricelist: React.UFC<CreatePricelistProps> = ({
  initialData,
}) => {
  const pricelistNameRef = useRef<HTMLInputElement>(null)
  const articleIdentifierRef = useRef<HTMLInputElement>(null)
  const editing = !!initialData

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [pricelistId, setPricelistId] = useState<string | null>(
    initialData?.id ?? null
  )
  const [prevPricelistName, setPrevPricelistName] = useState<string | null>(
    initialData?.name ?? null
  )
  const [pricelistName, setPricelistName] = useState(initialData?.name ?? '')
  const [editingArticleId, setEditingArticleId] = useState<string | null>(null)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
    formChangeHandler,
    submitHandler,
    resetForm,
  } = useForm<ArticleForm>({
    values: {
      identifier: '',
      name: '',
      cost: 0,
      price: 0,
    },
    validators: {
      identifier: val => (!!val ? null : 'Varen må ha en ID'),
      name: val => (!!val ? null : 'Varen må ha et navn'),
      cost: val => (!!val ? null : 'Må være et tall'),
      price: val => (!!val ? null : 'Må være et tall'),
    },
  })

  const [createPricelist] = useMutation<
    CreatePricelistMutationReturn,
    CreatePricelistMutationParameters
  >(CREATE_PRICELIST_MUTATION, {
    refetchQueries: ['Pricelist'],
    onCompleted(data) {
      if (!data) {
        addToast('error', 'Noe gikk galt ved oppretting av prisliste')
        return
      }
      setPricelistId(data.createPricelist.pricelist.id)
      addToast('success', 'Prisliste opprettet')
    },
    onError: onErrorAuto('Noe gikk galt ved oppretting av prisliste'),
  })
  const [createArticle] = useMutation<
    CreateArticleMutationReturn,
    CreateArticleMutationParameters
  >(CREATE_ARTICLE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllArticlesShallow'],
    onCompleted() {
      resetForm()
      addToast('success', 'Vare opprettet')
    },
    onError: onErrorAuto('Noe gikk galt ved oppretting av vare'),
  })

  const [patchPricelist] = useMutation<
    PatchPricelistMutationReturn,
    PatchPricelistMutationParameters
  >(PATCH_PRICELIST_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', 'Navnet på prislisten er endret')
    },
    onError: onErrorAuto('Noe gikk galt ved endring av prisliste'),
  })
  const [patchArticle] = useMutation<
    PatchArticleMutationReturn,
    PatchArticleMutationParameters
  >(PATCH_ARTICLE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllArticlesShallow'],
    onCompleted() {
      resetForm()
      setEditingArticleId(null)
      addToast('success', 'Vare endret')
    },
    onError: onErrorAuto('Noe gikk galt ved endring av vare'),
  })

  function handleBeginEditArticle(article: ArticleShallowNode) {
    setEditingArticleId(article.id)
    updateForm({
      identifier: article.identifier,
      name: article.name,
      cost: article.cost,
      price: article.price,
    })

    articleIdentifierRef.current?.focus()
  }

  function handleArticleDone() {
    if (editingArticleId !== null) {
      resetForm()
      setEditingArticleId(null)
      return
    }

    history.push(`/pricelists/`)
  }

  function handleSubmitPricelistName() {
    if (!pricelistName) {
      !!pricelistNameRef.current && pricelistNameRef.current.focus()
      addToast('warning', 'Vennligst angi et navn for prislisten')
      return
    }
    if (pricelistName === prevPricelistName) {
      return
    }

    if (editing) {
      patchPricelist({
        variables: {
          id: pricelistId,
          input: {
            name: pricelistName,
          },
        },
      })
    } else if (pricelistId === null) {
      createPricelist({
        variables: {
          input: {
            name: pricelistName,
          },
        },
      })
    }
  }

  function handleSubmitArticle(values: ArticleForm) {
    if (pricelistId === null) return

    if (editingArticleId !== null) {
      patchArticle({
        variables: {
          id: editingArticleId,
          input: {
            ...values,
          },
        },
      })
    } else {
      createArticle({
        variables: {
          input: {
            pricelist: pricelistId,
            ...values,
          },
        },
      })
    }
  }

  const editPriclistName = initialData === undefined

  return (
    <Wrapper>
      <InlineInput
        ref={pricelistNameRef}
        value={pricelistName}
        placeholder="Navn på prisliste"
        autoFocus={editPriclistName}
        onFocus={() => setPrevPricelistName(pricelistName)}
        onBlur={handleSubmitPricelistName}
        onChange={setPricelistName}
      />

      <FormWrapper area="form">
        <SectionTitle>Legg til vare</SectionTitle>

        <Input
          ref={articleIdentifierRef}
          value={form.identifier}
          disabled={pricelistId === null}
          placeholder="Eksempel: Stikkontakt-liten"
          width="100%"
          label="ID"
          onChange={formChangeHandler('identifier')}
          error={errors.identifier}
        />
        <Input
          value={form.name}
          disabled={pricelistId === null}
          placeholder="Fullt navn"
          width="100%"
          label="Navn"
          onChange={formChangeHandler('name')}
          error={errors.name}
        />
        <Input
          type="number"
          value={form.cost + ''}
          disabled={pricelistId === null}
          width="100%"
          label="Kostpris"
          onChange={value => updateForm({ cost: parseFloat(value) })}
          error={errors.cost}
        />
        <Input
          type="number"
          value={form.price + ''}
          disabled={pricelistId === null}
          width="100%"
          label="Salgspris"
          onChange={value => updateForm({ price: parseFloat(value) })}
          error={errors.price}
        />
      </FormWrapper>

      <TableWrapper area="overview">
        <SectionTitle>Vareoversikt</SectionTitle>
        <ArticleTable
          id={pricelistId}
          showData={pricelistId !== null}
          miniTable
          onEditArticle={handleBeginEditArticle}
        />
      </TableWrapper>

      <ButtonWrapper area="buttons">
        <Button
          icon={{ icon: editingArticleId === null ? 'plus' : 'check' }}
          fullWidth
          disabled={!formValid || pricelistId === null}
          onClick={submitHandler(handleSubmitArticle)}
        >
          {editingArticleId === null ? 'Legg til vare' : 'Lagre endringer'}
        </Button>

        <Button fullWidth disabled={!formValid} onClick={handleArticleDone}>
          {editingArticleId === null ? 'Ferdig' : 'Avbryt'}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
