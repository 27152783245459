import React from 'react'
import { useQuery } from '@apollo/client'
import Select from 'components/Select'
import { ALL_ROOM_TEMPLATES_QUERY } from '../../queries'
import {
  AllRoomTemplatesQueryReturn,
  RoomTemplate,
} from 'types/graphql/roomTemplates'
import { useTheme } from 'styled-components'

export interface RoomTemplateSelectorProps {
  selectedRoomTemplate: string
  onChange: (value: string, extra: RoomTemplate) => void
}

const RoomTemplateSelector: React.FC<RoomTemplateSelectorProps> = ({
  selectedRoomTemplate,
  onChange,
}) => {
  const { loading, data } = useQuery<AllRoomTemplatesQueryReturn>(
    ALL_ROOM_TEMPLATES_QUERY
  )
  let options = []
  const theme = useTheme()

  if (!loading && data && data.allRoomTemplates) {
    options = data.allRoomTemplates.edges.map(edge => ({
      label: edge.node.name,
      value: edge.node.id,
      extra: edge.node,
    }))
  }

  return (
    <Select
      value={selectedRoomTemplate}
      options={options}
      onChange={onChange}
      shadow={null}
      border={`1px solid ${theme.colors.darkBlue}`}
      inputHeight="40px"
      width="100%"
      placeholder="Velg en rommal"
    />
  )
}

export default RoomTemplateSelector
