import Checkbox from 'components/Checkbox'
import React from 'react'
import styled from 'styled-components'
import { OfferStatus, OfferStatusArray } from 'types/graphql/offers'
import { getTranslatedStatus } from 'modules/offers/util'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 400px;
  background-color: ${props => props.theme.colors.white};
`

interface StatusFilterListProps {
  setStatusFilterCallback: React.Dispatch<React.SetStateAction<OfferStatus[]>>
  statuses: OfferStatus[]
}

export const StatusFilterList: React.FC<StatusFilterListProps> = ({
  setStatusFilterCallback,
  statuses,
}) => {
  const handleToggle = (value: OfferStatus) => {
    setStatusFilterCallback(prevState => {
      if (prevState.length === OfferStatusArray.length) {
        return [value]
      } else if (prevState.includes(value)) {
        return prevState.filter(status => status !== value)
      } else {
        return [...prevState, value]
      }
    })
  }

  const showCheckBoxToggle = (status: OfferStatus): boolean => {
    if (statuses.length === OfferStatusArray.length) {
      return false
    } else if (statuses.includes(status)) {
      return true
    } else {
      return false
    }
  }

  return (
    <Wrapper>
      {OfferStatusArray.map(status => (
        <Checkbox
          key={status}
          value={showCheckBoxToggle(status)}
          label={getTranslatedStatus(status)}
          onChange={() => handleToggle(status)}
          filterLabel={true}
        />
      ))}
    </Wrapper>
  )
}
