import Grid from './Grid'
import GridItem from './GridItem'

type GridWithItem = {
  Item: typeof GridItem
} & typeof Grid

;(Grid as GridWithItem).Item = GridItem

export default Grid as GridWithItem
