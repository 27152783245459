import CenteredErrorMessage from 'components/CenteredErrorMessage'
import FullLoader from 'components/Loader/FullLoader'
import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useBreadcrumbs } from 'store/breadcrumbs-old'
import { PricelistQueryReturn } from 'types/graphql/articles'
import { IdParameter } from 'types/graphql/common'
import useOnErrorAuto from 'util/hooks/error-handling'
import { CreatePricelist, PricelistInitialData } from './CreatePricelist'
import { PRICELIST_QUERY } from './queries'

export const EditPricelist: React.UFC = () => {
  const { pricelistId } = useParams<{ pricelistId: string }>()

  const onErrorAuto = useOnErrorAuto()
  const breadcrumbs = useBreadcrumbs()

  const { loading, data, error } = useQuery<PricelistQueryReturn, IdParameter>(
    PRICELIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: pricelistId,
      },
      onCompleted(data) {
        if (!data) return
        breadcrumbs.addOverride(data.pricelist.id, data.pricelist.name)
      },
      onError: onErrorAuto(),
    }
  )

  const initialData = useMemo<PricelistInitialData | undefined>(
    () =>
      !data
        ? undefined
        : {
            id: data.pricelist.id,
            name: data.pricelist.name,
            articles: data.pricelist.articles,
          },
    [data]
  )

  if (!data || loading) return <FullLoader />
  if (!!error) return <CenteredErrorMessage message="Prisliste ikke funnet" />

  return <CreatePricelist initialData={initialData} />
}
