import { Button } from 'components/Button'
import Card from 'components/Card'
import Grid from 'components/Grid/Grid'
import Icon from 'components/Icon'
import { Input } from 'components/Input'
import Label from 'components/Label'
import Margin from 'components/Margin'
import RadioGroup, { Radio } from 'components/RadioGroup/RadioGroup'
import { MultiSelect } from 'components/Select'
import ArticleSelector from 'modules/offers/CreateOffer/Selectors/ArticleSelector'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import {
  CreateRoomTemplateMutationParameters,
  CreateRoomTemplateMutationReturn,
  RoomTemplateOptionFieldType,
  RoomTemplateQuery,
  UpdateRoomTemplateMutationParameters,
  UpdateRoomTemplateMutationReturn,
} from 'types/graphql/roomTemplates'
import { isUndefined } from 'util/typechecks'
import { v4 as uuid } from 'uuid'
import FormulaSubsectionHeader from './FormulaSubsectionHeader'
import {
  CREATE_ROOM_TEMPLATE_MUTATION,
  UPDATE_ROOM_TEMPLATE_MUTATION,
} from './mutations'
import OptionSubsectionHeader from './OptionSubsectionHeader'
import FullLoader from 'components/Loader/FullLoader'
import { Link } from 'react-router-dom'
import pick from 'lodash/pick'
import { PricelistSelector } from 'modules/offers/CreateOffer/Selectors/PricelistSelector'
import theme from 'theme'
import { useToast } from '@ur/react-components'

const SectionHeader = styled.h3`
  color: ${props => props.theme.colors.gray};
  font-weight: 500;
  margin-top: 0;
`

const Wrapper = styled.div`
  width: 100%;
`

const BasisConditionWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding-top: 10px;
  column-gap: 10px;
`
const BasisConditionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  column-gap: 10px;
`

type OptionType = RoomTemplateOptionFieldType
interface RoomTemplateOption {
  shortName: string
  text: string
  fieldType: OptionType
  optionConditions: Condition[]
  order: number
}

interface RoomTemplateTitle {
  title: string
  order: number
}

interface FormData {
  roomTemplateName: string
  floor: string
  floorConditions: Condition[]
  area: string
  areaConditions: Condition[]
  pricelist: string
  properties: (RoomTemplateTitle | RoomTemplateOption)[]
}

interface Condition {
  identifier: string
  valueCondition: string
  label?: string
}

interface Equation {
  optionConditions: Condition[]
  areaConditions: Condition[]
  floorConditions: Condition[]
  formula: string
  article: string | null
}

interface RoomTemplateFormProps {
  initialData?: RoomTemplateQuery
}

const RoomTemplateForm: React.FC<RoomTemplateFormProps> = ({ initialData }) => {
  const toasts = useToast()
  const editing = initialData !== undefined

  function transformRoomTemplate(initial?: RoomTemplateQuery): FormData {
    if (!editing) {
      return {
        roomTemplateName: '',
        floor: '',
        floorConditions: [],
        area: '',
        areaConditions: [],
        pricelist: '',
        properties: [
          {
            title: '',
            order: 0,
          },
          {
            shortName: '',
            text: '',
            fieldType: 'BOOL',
            optionConditions: [],
            order: 1,
          },
        ],
      }
    }

    const { roomTemplate: template } = initial

    return {
      roomTemplateName: template.name,
      area: template.area + '',
      areaConditions: template.areaConditions.map(condition => {
        return {
          identifier: condition.id,
          valueCondition: condition.valueCondition,
        }
      }),
      floor: template.floor + '',
      floorConditions: template.floorConditions.map(condition => {
        return {
          identifier: condition.id,
          valueCondition: condition.valueCondition,
        }
      }),
      pricelist: template.pricelist.id,
      properties: [...template.options, ...template.titles].map(property => {
        if ('title' in property)
          return {
            title: property.title,
            order: property.order,
          }

        return {
          shortName: property.shortName,
          text: property.text,
          fieldType: property.fieldType,
          order: property.order,
          optionConditions: property.optionConditions.map(condition => ({
            identifier: condition.id,
            valueCondition: condition.valueCondition,
          })),
        }
      }),
    }
  }
  function transformEquations(initial?: RoomTemplateQuery): Equation[] {
    if (!editing)
      return [
        {
          optionConditions: [],
          areaConditions: [],
          floorConditions: [],
          formula: '',
          article: null,
        },
      ]

    return initial.roomTemplate.formulas.map(formula => ({
      article: formula.articleActions[0]?.article.id ?? null,
      formula: formula.formula,
      optionConditions: formula.optionConditions.map(condition => ({
        identifier: condition.id,
        valueCondition: condition.valueCondition,
      })),
      floorConditions: formula.floorConditions.map(condition => ({
        identifier: condition.id,
        valueCondition: condition.valueCondition,
      })),
      areaConditions: formula.areaConditions.map(condition => ({
        identifier: condition.id,
        valueCondition: condition.valueCondition,
      })),
    }))
  }

  const [formData, setFormData] = useState<FormData>(() =>
    transformRoomTemplate(initialData)
  )

  const [equations, setEquations] = useState<Equation[]>(() =>
    transformEquations(initialData)
  )

  const sortedProperties: (RoomTemplateTitle | RoomTemplateOption)[] =
    formData.properties.sort((a, b) => a.order - b.order)

  const [createRoomTemplateMutation, { loading: createLoading }] = useMutation<
    CreateRoomTemplateMutationReturn,
    CreateRoomTemplateMutationParameters
  >(CREATE_ROOM_TEMPLATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllRoomTemplates', 'RoomTemplate'],
    onCompleted({ createRoomTemplate: { roomTemplate } }) {
      toasts('success', `Malen ${roomTemplate.name} ble opprettet.`)
    },
    onError(err) {
      toasts('error', 'Klarte ikke opprette rommal')
    },
  })
  const [updateRoomTemplateMutation, { loading: patchLoading }] = useMutation<
    UpdateRoomTemplateMutationReturn,
    UpdateRoomTemplateMutationParameters
  >(UPDATE_ROOM_TEMPLATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['AllRoomTemplates', 'RoomTemplate'],
    onCompleted({ updateRoomTemplate: { roomTemplate } }) {
      toasts('success', `Malen ${roomTemplate.name} ble endret.`)
    },
    onError(err) {
      toasts('error', 'Klarte ikke endre rommal')
    },
  })

  function addProperty(type: 'title' | 'option') {
    let newProperty: RoomTemplateTitle | RoomTemplateOption
    if (type === 'title') {
      newProperty = {
        title: '',
        order: formData.properties.length,
      }
    } else {
      newProperty = {
        shortName: '',
        text: '',
        fieldType: 'BOOL',
        optionConditions: [],
        order: formData.properties.length,
      }
    }

    let newProperties = [...formData.properties, newProperty]
    setFormData({
      ...formData,
      properties: newProperties,
    })
  }

  function addEquation() {
    const newEquation: Equation = {
      optionConditions: [],
      areaConditions: [],
      floorConditions: [],
      formula: '',
      article: null,
    }

    setEquations([...equations, newEquation])
  }

  function isRoomTemplateTitle(
    arg: RoomTemplateTitle | RoomTemplateOption
  ): arg is RoomTemplateTitle {
    return 'title' in arg
  }
  function isRoomTemplateOption(
    arg: RoomTemplateTitle | RoomTemplateOption
  ): arg is RoomTemplateOption {
    return 'shortName' in arg
  }

  function addOptionCondition(optionIndex: number) {
    const option = formData.properties[optionIndex]
    if (isUndefined(option) || isRoomTemplateTitle(option)) return
    const newConditions = [
      ...option.optionConditions,
      {
        identifier: uuid() as string,
        valueCondition: '',
      },
    ]

    const newOption: RoomTemplateOption = {
      ...option,
      optionConditions: newConditions,
    }

    let newOptions = [...formData.properties]
    newOptions.splice(optionIndex, 1, newOption)
    setFormData({
      ...formData,
      properties: newOptions,
    })
  }

  function addBasisCondition(conditions: Condition[], type: 'floor' | 'area') {
    const newConditions = [
      ...conditions,
      {
        identifier: uuid() as string,
        valueCondition: '',
      },
    ]
    if (type === 'floor')
      setFormData({
        ...formData,
        floorConditions: newConditions,
      })
    if (type === 'area')
      setFormData({
        ...formData,
        areaConditions: newConditions,
      })
  }

  function handleTitleChange(titleIndex: number, value: string) {
    const titleComponent = formData.properties[titleIndex]
    if (isUndefined(titleComponent)) return
    let newProperties = [...formData.properties]
    newProperties[titleIndex] = { title: value, order: titleIndex }

    setFormData({
      ...formData,
      properties: newProperties,
    })
  }

  function handleOptionChange<K extends keyof RoomTemplateOption>(
    optionIndex: number,
    field: K,
    value: RoomTemplateOption[K]
  ) {
    const option = formData.properties[optionIndex]
    if (isUndefined(option) || isRoomTemplateTitle(option)) return
    let newOptions = [...formData.properties]
    newOptions.splice(optionIndex, 1, {
      ...option,
      [field]: value,
    })

    setFormData({
      ...formData,
      properties: newOptions,
    })
  }

  function handleConditionChange(
    optionIndex: number,
    conditionIndex: number,
    value: string
  ) {
    const option = formData.properties[optionIndex]
    if (isUndefined(option) || isRoomTemplateTitle(option)) return
    const condition = option.optionConditions[conditionIndex]
    if (isUndefined(condition)) return

    let newConditions = [...option.optionConditions]
    newConditions.splice(conditionIndex, 1, {
      identifier: condition.identifier,
      valueCondition: value,
    })
    handleOptionChange(optionIndex, 'optionConditions', [...newConditions])
  }

  function handleEquationChange<K extends keyof Equation>(
    equationIndex: number,
    field: K,
    value: Equation[K]
  ) {
    const equation = equations[equationIndex]
    if (isUndefined(equation)) return
    let newEquations = [...equations]
    newEquations.splice(equationIndex, 1, {
      ...equation,
      [field]: value,
    })

    setEquations(newEquations)
  }

  function handleSort(index: number, delta: number) {
    if (delta === 0) return
    if (index < 0 || index >= formData.properties.length) return

    const newIndex = index + delta
    if (newIndex < 0 || newIndex >= formData.properties.length) return

    let newProperties = [...sortedProperties]
    newProperties[newIndex].order = index
    newProperties[index].order = newIndex

    setFormData({
      ...formData,
      properties: newProperties,
    })
  }

  function handleDelete(index: number) {
    let newProperties = [...formData.properties]
    newProperties.splice(index, 1)

    setFormData({
      ...formData,
      properties: newProperties,
    })
  }

  function handleDeleteEquation(index: number) {
    let newEquations = [...equations]
    newEquations.splice(index, 1)
    setEquations(newEquations)
  }

  function handleConditionDelete(
    propertyIndex: number,
    conditionIndex: number
  ) {
    const property = formData.properties[propertyIndex]
    if (isRoomTemplateTitle(property)) return
    let newConditions = [...property.optionConditions]
    newConditions.splice(conditionIndex, 1)
    handleOptionChange(propertyIndex, 'optionConditions', [...newConditions])
  }

  const submit = useCallback(() => {
    if (editing) {
      updateRoomTemplateMutation({
        variables: {
          id: initialData.roomTemplate.id,
          name: formData.roomTemplateName,
          area: parseInt(formData.area),
          floor: parseInt(formData.floor),
          floorConditions: formData.floorConditions.map(condition =>
            pick(condition, 'identifier', 'valueCondition')
          ),
          areaConditions: formData.areaConditions.map(condition =>
            pick(condition, 'identifier', 'valueCondition')
          ),
          pricelist: formData.pricelist,
          formulas: equations.map(equation => ({
            ...equation,
            article: equation.article ?? null,
            optionConditions: equation.optionConditions.map(
              condition => condition.identifier
            ),
            areaConditions: equation.areaConditions.map(
              condition => condition.identifier
            ),
            floorConditions: equation.floorConditions.map(
              condition => condition.identifier
            ),
          })),
          options: formData.properties
            .filter<RoomTemplateOption>(isRoomTemplateOption)
            .map(option => ({
              ...option,
              fieldType:
                option.fieldType.toLowerCase() as RoomTemplateOptionFieldType,
            })),
          titles:
            formData.properties.filter<RoomTemplateTitle>(isRoomTemplateTitle),
        },
      })
    } else {
      createRoomTemplateMutation({
        variables: {
          name: formData.roomTemplateName,
          area: parseInt(formData.area),
          floor: parseInt(formData.floor),
          floorConditions: formData.floorConditions.map(condition =>
            pick(condition, 'identifier', 'valueCondition')
          ),
          areaConditions: formData.areaConditions.map(condition =>
            pick(condition, 'identifier', 'valueCondition')
          ),
          pricelist: formData.pricelist,
          formulas: equations.map(equation => ({
            ...equation,
            article: equation.article ?? null,
            optionConditions: equation.optionConditions.map(
              condition => condition.identifier
            ),
            areaConditions: equation.areaConditions.map(
              condition => condition.identifier
            ),
            floorConditions: equation.floorConditions.map(
              condition => condition.identifier
            ),
          })),
          options:
            formData.properties.filter<RoomTemplateOption>(
              isRoomTemplateOption
            ),
          titles:
            formData.properties.filter<RoomTemplateTitle>(isRoomTemplateTitle),
        },
      })
    }
  }, [
    editing,
    updateRoomTemplateMutation,
    initialData,
    formData.roomTemplateName,
    formData.pricelist,
    formData.area,
    formData.floor,
    formData.floorConditions,
    formData.areaConditions,
    formData.properties,
    equations,
    createRoomTemplateMutation,
  ])

  const allOptionConditions = useMemo(
    () =>
      formData.properties.reduce<Condition[]>((acc, property) => {
        if (isRoomTemplateTitle(property)) return acc
        return acc.concat(
          property.optionConditions.map(condition => ({
            ...condition,
            label: `${property.shortName} ${condition.valueCondition}`,
          }))
        )
      }, []),
    [formData.properties]
  )
  const allConditions = useMemo(
    () =>
      allOptionConditions
        .concat(
          formData.areaConditions.map(condition => ({
            ...condition,
            label: `area ${condition.valueCondition}`,
          }))
        )
        .concat(
          formData.floorConditions.map(condition => ({
            ...condition,
            label: `floor ${condition.valueCondition}`,
          }))
        ),

    [allOptionConditions, formData.areaConditions, formData.floorConditions]
  )

  const multiselectEquations = useMemo(
    () =>
      allConditions.map(condition => ({
        value: condition.identifier,
        label: condition.label ?? 'N/A',
      })),
    [allConditions]
  )

  const displayModeRadios: Radio<OptionType>[] = [
    {
      value: 'BOOL',
      label: 'Ja/nei',
    },
    {
      value: 'RANGE',
      label: 'Tall',
    },
  ]

  function handleBasisConditionChange(
    conditions: Condition[],
    conditionIndex: number,
    value: string
  ) {
    const condition = conditions[conditionIndex]
    if (isUndefined(condition)) return
    let newBasisConditions = [...conditions]
    newBasisConditions.splice(conditionIndex, 1, {
      ...condition,
      valueCondition: value,
    })
    if (conditions === formData.floorConditions)
      setFormData({
        ...formData,
        floorConditions: newBasisConditions,
      })
    if (conditions === formData.areaConditions)
      setFormData({
        ...formData,
        areaConditions: newBasisConditions,
      })
  }

  function handleBasisConditionDelete(
    conditions: Condition[],
    conditionIndex: number
  ) {
    let newBasisConditions = [...conditions]
    newBasisConditions.splice(conditionIndex, 1)
    if (conditions === formData.floorConditions)
      setFormData({
        ...formData,
        floorConditions: newBasisConditions,
      })
    if (conditions === formData.areaConditions)
      setFormData({
        ...formData,
        areaConditions: newBasisConditions,
      })
  }

  function getSelectedConditions(equation: Equation) {
    const mapper = (cond: Condition) => cond.identifier
    return equation.optionConditions
      .map(mapper)
      .concat(equation.areaConditions.map(mapper))
      .concat(equation.floorConditions.map(mapper))
  }

  interface ReducedIdentifiers {
    options: Condition[]
    areas: Condition[]
    floors: Condition[]
  }
  function reduceConditionIdentifiers(
    accumulator: ReducedIdentifiers,
    identifier: string
  ) {
    const option = allOptionConditions.find(
      condition => condition.identifier === identifier
    )
    if (option) {
      accumulator.options.push(option)
      return accumulator
    }

    const area = formData.areaConditions.find(
      condition => condition.identifier === identifier
    )
    if (area) {
      accumulator.areas.push(area)
      return accumulator
    }

    const floor = formData.floorConditions.find(
      condition => condition.identifier === identifier
    )
    if (floor) {
      accumulator.floors.push(floor)
      return accumulator
    }
  }

  function handleFormulaConditionChange(selected: string[], index: number) {
    let equation = equations[index]
    if (isUndefined(equation)) return

    const { options, areas, floors } = selected.reduce(
      reduceConditionIdentifiers,
      {
        options: [],
        areas: [],
        floors: [],
      }
    )

    equation = {
      ...equation,
      optionConditions: options,
      areaConditions: areas,
      floorConditions: floors,
    }
    let newEquations = [...equations]
    newEquations.splice(index, 1, equation)
    setEquations(newEquations)
  }

  return (
    <Wrapper>
      {(patchLoading || createLoading) && <FullLoader />}
      <Margin bottom="5em">
        <SectionHeader>BASISINFO</SectionHeader>
        <Card overflow="visible">
          <Margin bottom="1rem">
            <Label htmlFor="name">Rommalsnavn</Label>
            <Input
              value={formData.roomTemplateName}
              width="100%"
              onChange={(_, event) =>
                setFormData({
                  ...formData,
                  roomTemplateName: event.target.value,
                })
              }
            />
          </Margin>
          <Margin bottom="1rem">
            <Label htmlFor="floor">Etasje</Label>
            <Input
              value={formData.floor}
              width="100%"
              border={`1px solid ${theme.colors.lightGray}`}
              shadow="none"
              onChange={(_, event) =>
                setFormData({ ...formData, floor: event.target.value })
              }
            />
            <BasisConditionWrapper>
              <Button
                onClick={() =>
                  addBasisCondition(formData.floorConditions, 'floor')
                }
                primary
                icon={{ icon: 'plus' }}
              >
                Ny betingelse
              </Button>
              <BasisConditionListWrapper>
                {formData.floorConditions.map((condition, index) => (
                  <Margin key={index} bottom="0.5rem">
                    <Grid
                      columns="1fr auto"
                      columnGap="10px"
                      alignItems="center"
                    >
                      <Input
                        value={condition.valueCondition}
                        shadow="none"
                        border={`1px solid ${theme.colors.lightGray}`}
                        width="100%"
                        onChange={(_, event) =>
                          handleBasisConditionChange(
                            formData.floorConditions,
                            index,
                            event.target.value
                          )
                        }
                      />
                      <Icon
                        className="delete"
                        icon="trash-alt"
                        size="1.2em"
                        color="lightGray"
                        hoverColor="lighterGray"
                        cursor="pointer"
                        onClick={() =>
                          handleBasisConditionDelete(
                            formData.floorConditions,
                            index
                          )
                        }
                      />
                    </Grid>
                  </Margin>
                ))}
              </BasisConditionListWrapper>
            </BasisConditionWrapper>
          </Margin>
          <Label htmlFor="area">Areal</Label>
          <Input
            value={formData.area}
            fullWidth
            onChange={(_, event) =>
              setFormData({ ...formData, area: event.target.value })
            }
          />
          <BasisConditionWrapper>
            <Button
              onClick={() => addBasisCondition(formData.areaConditions, 'area')}
              icon={{ icon: 'plus' }}
            >
              Ny betingelse
            </Button>
            <BasisConditionListWrapper>
              {formData.areaConditions.map((condition, index) => (
                <Margin key={index} bottom="0.5rem">
                  <Grid columns="1fr auto" columnGap="10px" alignItems="center">
                    <Input
                      value={condition.valueCondition}
                      shadow="none"
                      border={`1px solid ${theme.colors.lightGray}`}
                      fullWidth
                      onChange={(_, event) =>
                        handleBasisConditionChange(
                          formData.areaConditions,
                          index,
                          event.target.value
                        )
                      }
                    />
                    <Icon
                      className="delete"
                      icon="trash-alt"
                      size="1.2em"
                      color="lightGray"
                      hoverColor="lighterGray"
                      cursor="pointer"
                      onClick={() =>
                        handleBasisConditionDelete(
                          formData.areaConditions,
                          index
                        )
                      }
                    />
                  </Grid>
                </Margin>
              ))}
            </BasisConditionListWrapper>
          </BasisConditionWrapper>
          <Label htmlFor="area">Prisliste</Label>
          <PricelistSelector
            pricelist={formData.pricelist}
            width="100%"
            onChange={id => setFormData({ ...formData, pricelist: id })}
          />
        </Card>
      </Margin>
      <Margin bottom="5em">
        <SectionHeader>VALG</SectionHeader>
        {sortedProperties.map((option, index) => (
          <Margin key={index} bottom="3rem">
            {isRoomTemplateTitle(option) ? (
              <>
                <OptionSubsectionHeader
                  text={`${index + 1}: tittel`}
                  showSortUp={index > 0}
                  showSortDown={index < formData.properties.length - 1}
                  onSortUp={() => handleSort(index, -1)}
                  onSortDown={() => handleSort(index, 1)}
                  onDelete={() => handleDelete(index)}
                />
                <Card>
                  <Label htmlFor="title">Tittel</Label>
                  <Input
                    value={option.title}
                    width="100%"
                    onChange={(_, event) =>
                      handleTitleChange(index, event.target.value)
                    }
                  />
                </Card>
              </>
            ) : (
              <>
                <OptionSubsectionHeader
                  text={`${index + 1}: valg`}
                  showSortUp={index > 0}
                  showSortDown={index < formData.properties.length - 1}
                  onSortUp={() => handleSort(index, -1)}
                  onSortDown={() => handleSort(index, 1)}
                  onDelete={() => handleDelete(index)}
                />
                <Card>
                  <Margin bottom="1rem">
                    <Label>Kort navn</Label>
                    <Input
                      value={option.shortName}
                      width="100%"
                      onChange={(_, event) =>
                        handleOptionChange(
                          index,
                          'shortName',
                          event.target.value
                        )
                      }
                    />
                  </Margin>
                  <Margin bottom="1rem">
                    <Label>Tekst</Label>
                    <Input
                      value={option.text}
                      width="100%"
                      onChange={(_, event) =>
                        handleOptionChange(index, 'text', event.target.value)
                      }
                    />
                  </Margin>
                  <Margin bottom="1rem">
                    <Label>Type</Label>
                    <RadioGroup
                      value={option.fieldType}
                      radios={displayModeRadios}
                      onChange={val => {
                        handleOptionChange(index, 'fieldType', val)
                      }}
                    />
                  </Margin>
                  <Margin bottom="1rem">
                    <Label>Tilgjengelige betingelser</Label>
                    {option.optionConditions.map(
                      (condition, conditionIndex) => (
                        <Margin key={conditionIndex} bottom="0.5rem">
                          <Grid
                            columns="1fr auto"
                            columnGap="2rem"
                            alignItems="center"
                          >
                            <Input
                              value={condition.valueCondition}
                              width="100%"
                              onChange={(_, event) =>
                                handleConditionChange(
                                  index,
                                  conditionIndex,
                                  event.target.value
                                )
                              }
                            />
                            <Icon
                              className="delete"
                              icon="trash-alt"
                              size="1.2em"
                              color="lightGray"
                              hoverColor="lighterGray"
                              cursor="pointer"
                              onClick={() =>
                                handleConditionDelete(index, conditionIndex)
                              }
                            />
                          </Grid>
                        </Margin>
                      )
                    )}
                    <Margin top="1rem">
                      <Button
                        onClick={() => addOptionCondition(index)}
                        primary
                        icon={{ icon: 'plus' }}
                      >
                        Ny betingelse
                      </Button>
                    </Margin>
                  </Margin>
                </Card>
              </>
            )}
          </Margin>
        ))}

        <Margin top="1rem">
          <Margin right="1rem" inline>
            <Button
              onClick={() => addProperty('option')}
              primary
              icon={{ icon: 'plus' }}
            >
              Nytt valg
            </Button>
          </Margin>
          <Button
            onClick={() => addProperty('title')}
            primary
            icon={{ icon: 'plus' }}
          >
            Ny tittel
          </Button>
        </Margin>
      </Margin>

      <Margin bottom="3em">
        <SectionHeader>FORMLER</SectionHeader>
        {equations.map((equation, index) => (
          <Margin key={index} bottom="3rem">
            <FormulaSubsectionHeader
              text={`Formel ${index + 1}`}
              onDelete={() => handleDeleteEquation(index)}
            />
            <Margin bottom="3rem">
              <Card overflow="visible">
                <Label htmlFor="conditions">Avhengig av betingelser</Label>
                <MultiSelect
                  selected={getSelectedConditions(equation)}
                  options={multiselectEquations}
                  onChange={sel => handleFormulaConditionChange(sel, index)}
                  height="48px"
                />
                <Margin top="1rem">
                  <Label htmlFor="formula">Formel</Label>
                  <Input
                    value={equation.formula}
                    width="100%"
                    shadow="none"
                    onChange={(_, event) =>
                      handleEquationChange(index, 'formula', event.target.value)
                    }
                  />
                </Margin>
                <Margin top="1rem">
                  <Label htmlFor="prodChoice">Velger produkter</Label>
                  <ArticleSelector
                    selectedArticle={equation.article ?? null}
                    onChange={articleId =>
                      handleEquationChange(index, 'article', articleId)
                    }
                    pricelist={formData.pricelist}
                    width="100%"
                  />
                </Margin>
              </Card>
            </Margin>
          </Margin>
        ))}

        <Button onClick={addEquation} primary icon={{ icon: 'plus' }}>
          Ny formel
        </Button>
      </Margin>

      <Margin bottom="3rem">
        <Link to="/roomtemplates">
          <Button
            buttonStyle="success"
            onClick={submit}
            icon={{ icon: 'arrow-right' }}
          >
            {editing ? 'Lagre endringer' : 'Opprett ny rommal'}
          </Button>
        </Link>
      </Margin>
    </Wrapper>
  )
}

export default RoomTemplateForm
