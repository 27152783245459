import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Quill from 'quill/dist/quill.min.js'
import 'quill/dist/quill.snow.css'

const Wrapper = styled.div`
  border: none;

  .ql-toolbar{
    border: none;
  }

  .ql-container{
    border: none !important;
  }
  
  .ql-editor{
    padding: 0;
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;

    color: ${props => props.theme.colors.darkGray}
  }
`

export default function QuillRenderer({
  contents
}) {
  const [quill, setQuill] = useState(null)
  const quillRef = useRef(null)

  useEffect(() => {

    if (quill){
      quill.setContents(contents)
      return
    }

    if (!quillRef) {
      return
    }

    const newQuill = new Quill(quillRef.current, {
      theme: 'snow',
      modules: {
        toolbar: false
      },
      readOnly: true
    })
    newQuill.setContents(contents.ops)

    setQuill(newQuill)
  }, [quill, contents])

  return (
    <Wrapper>
      <div ref={quillRef}></div>
    </Wrapper>
  )
}
