import React from 'react'
import { Query } from '@apollo/client/react/components'

import NoCompanyRoutes from './NoCompanyRoutes'
import { CURRENT_COMPANY_QUERY } from 'modules/companies/queries'
import { IS_LOGGED_IN_QUERY } from 'modules/login/queries'
import FullLoader from 'components/Loader/FullLoader'

import CompanyDoesNotExist from 'modules/companies/CompanyDoesNotExist'
import CompanyContext from 'context/CompanyContext'

import Routes from './Routes'

const companyBasePathRegex = /^\/c\/[\w-]+/

// The primary role of this component is to bootstrap
// all necessary information about the current user and company,
// and then add this to different contexts
export default function Bootstrap() {
  // First check if are currently accessing a company. If we are not,
  // we will render the routes specific for non-company access. This is
  // primarily the login view, and the select company view.
  const match = window.location.pathname.match(companyBasePathRegex)
  if (!match) {
    return <NoCompanyRoutes />
  }

  // Render the primary view. As we are not potentially accessing a
  // company. First we will try to fetch the company info. If the company
  // does not exist, we render an error page.
  return (
    <Query query={CURRENT_COMPANY_QUERY}>
      {({ loading, data, error }) => {
        if (loading || !data) {
          return <FullLoader />
        }

        const company = data.currentCompany

        if (company === null) {
          return (
            <Query query={IS_LOGGED_IN_QUERY}>
              {({ loading, data, error }) => {
                if (loading || !data) {
                  return <FullLoader />
                }

                if (data.isLoggedIn) {
                  return <CompanyDoesNotExist />
                } else {
                  window.location.href = '/login'
                  return <span>Redirecting</span>
                }
              }}
            </Query>
          )
        }

        // Okey, create, we have a company. Now we can render the primary routes. Wrapping
        // it in the Company Context

        return (
          <CompanyContext.Provider value={company}>
            <Routes />
          </CompanyContext.Provider>
        )
      }}
    </Query>
  )
}
