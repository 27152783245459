import styled from 'styled-components'
import UserContext from 'context/UserContext'
import CompanyContext from 'context/CompanyContext'
import React from 'react'

const Wrapper = styled.div`
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  span:first-child {
    margin-bottom: 5px;
  }
`

const NameSpan = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`

const CompanySpan = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`

export const SidebarUserInfo: React.UFC = () => {
  return (
    <UserContext.Consumer>
      {user => (
        <Wrapper>
          <NameSpan>{user.fullName}</NameSpan>
          <CompanySpan>
            <CompanyContext.Consumer>
              {company => company.fullName}
            </CompanyContext.Consumer>
          </CompanySpan>
        </Wrapper>
      )}
    </UserContext.Consumer>
  )
}
