import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'components/Flex'
import Margin from 'components/Margin/index'
import { Button } from 'components/Button'
import Input from 'components-old/Input'
import { Text } from 'components/Text'

import CustomerInfoCard from './CustomerInfoCard'
import ArticleSummary from './ArticleSummary'
import RoomSummary from './RoomSummary'
import { PricelistSelector } from '../Selectors/PricelistSelector'

import Padding from 'components/Padding'
import OfferSummary from './OfferSummary'
import TextArea from 'components/TextArea'
import Card from 'components/Card'

const FirstColumn = styled.div`
  flex-basis: 30%;
  padding: 0.5em 0.75em;

  ${props => props.theme.media.smallScreen} {
    flex-basis: 100%;
  }
  section:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`

const SecondColumn = styled.div`
  padding: 0.5em 0.75em;
  flex: 1;

  ${props => props.theme.media.smallScreen} {
    flex-basis: 100%;
  }
`

const SectionHeader = styled.header`
  color: ${props => props.theme.colors.gray};
  font-weight: 500;
  margin-top: 1eem;
  margin-bottom: 0.5em;

  ${props => props.theme.media.smallScreen} {
    flex-basis: 100%;
  }
`

const ConditionsCard = styled(Card)`
  padding: 0;

  textarea {
    padding: 0.75rem;
  }
`

export default function CreateOfferOverview({
  basePath,
  continueText,

  name,
  conditions,
  customer,
  rooms,
  pricelist,
  priceOverrides,
  onNameChanged,
  onConditionsChanged,
  onPricelistChanged,
  onRoomAdded,
  onRoomChanged,
  onRoomDeleted,
  onArticleChanged,
  onCustomerChanged,
  onSubmit,
}) {
  // Check that we have valid information
  const canContinue =
    name &&
    pricelist &&
    customer &&
    rooms.reduce(
      (acc, room) => acc.concat(room.articles).concat(room.freeArticles),
      []
    ).length > 0
  return (
    <Padding bottom="5em">
      <Padding>
        <Flex justifyContent="space-between" alignItems="center">
          <Margin bottom="1em">
            <Margin bottom="0.35em">
              <Text color="gray">Bruk prisliste</Text>
            </Margin>
            <PricelistSelector
              pricelist={pricelist}
              onChange={onPricelistChanged}
              width="200px"
            />
          </Margin>
          <Flex>
            <Margin left="1em">
              <Link to={`${basePath}/add-room`}>
                <Button primary>Legg til rom</Button>
              </Link>
            </Margin>
            <Margin left="1em">
              <Link to={`${basePath}/add-room-template`}>
                <Button primary>Legg til rom fra mal</Button>
              </Link>
            </Margin>
            {canContinue && (
              <Margin left="1em">
                <Button
                  success
                  onClick={onSubmit}
                  icon="fa fa-arrow-right"
                  iconPosition="right"
                >
                  {continueText}
                </Button>
              </Margin>
            )}
          </Flex>
        </Flex>
      </Padding>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <FirstColumn>
          <section>
            <SectionHeader>Navn</SectionHeader>
            <Input
              value={name}
              onChange={e => onNameChanged(e.target.value)}
              width="100%"
            />
          </section>

          <section>
            <SectionHeader>Kunde</SectionHeader>
            <CustomerInfoCard
              basePath={basePath}
              customer={customer}
              onCustomerChanged={onCustomerChanged}
            />
          </section>

          <section>
            <SectionHeader>Vilkår</SectionHeader>
            <ConditionsCard>
              <TextArea
                value={conditions}
                width="100%"
                noShadow
                padding="0"
                onChange={e => onConditionsChanged(e.target.value)}
              />
            </ConditionsCard>
          </section>

          <section>
            <SectionHeader>Oppsummering</SectionHeader>
            <OfferSummary rooms={rooms} />
          </section>
          <section>
            <SectionHeader>Vareoversikt</SectionHeader>
            <ArticleSummary rooms={rooms} />
          </section>
        </FirstColumn>

        <SecondColumn>
          <SectionHeader>Rom</SectionHeader>
          <RoomSummary
            rooms={rooms}
            pricelist={pricelist}
            priceOverrides={priceOverrides}
            onRoomAdded={onRoomAdded}
            onRoomChanged={onRoomChanged}
            onRoomDeleted={onRoomDeleted}
            onArticleChanged={onArticleChanged}
          />
        </SecondColumn>
      </Flex>
    </Padding>
  )
}

CreateOfferOverview.defaultProps = {
  basePath: '/offers/create',
  continueText: 'Opprett tilbud og gå videre',
}
