import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from '@apollo/client/react/components'
import styled from 'styled-components'
import CompanyContext from 'context/CompanyContext'

import Table from 'components/Table'
import { Text } from 'components/Text'
import Flex from 'components/Flex'
import { Button } from 'components/Button'
import CompanyUserTypeTitle from './CompanyUserTypeTitle'
import { UPDATE_COMPANY_USER_TYPES_MUTATION } from '../mutations'
import PermissionsRequired from 'containers/PermissionsRequired/PermissionsRequired'
import { useToast } from '@ur/react-components'

const Wrapper = styled.div`
  overflow: auto;
  min-height: 100%;
`

// Used so we can get a scroll bar at the bottom for the Wrapper
const InnerWrapper = styled.div`
  padding: 1em 2em;
`

const UserSettingTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
`

const UserSettingHeaderCell = styled.th`
  padding: 0 2em;
  font-size: 18px;
  color: ${props => props.theme.colors.primary};

  /* Make sure titles never break lines */
  white-space: nowrap;

  i {
    cursor: pointer;
  }
`

const UserSettingNameCell = styled.td`
  background-color: white;

  padding: 0.25em 1em;
  font-weight: 300;
  border-bottom: 1px solid ${props => props.theme.colors.veryLightGray};

  ${props =>
    props.isTitle &&
    `
    color: ${props.theme.colors.darkGray};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1em;
    padding-bottom: 0.25em;
    padding-left: 0.25em;
    background-color: transparent;
  `}
`

const UserSettingCell = styled.td`
  color: ${props =>
    props.hasPermission ? props.theme.colors.green : props.theme.colors.red};
  background-color: white;
  padding: 0.5em 2em;
  border-bottom: 1px solid ${props => props.theme.colors.veryLightGray};

  i {
    cursor: pointer;
  }
`

const SettingRow = styled.tr``

const UserSettingCheck = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  ${props => props.theme.resetButton};
  color: inherit;
`

const StyledFlex = styled(Flex)`
  button {
    margin-right: 1rem;
  }
`

const permissionDomains = [
  {
    title: 'Brukere',
    permissions: {
      Se: 'users.view_user',
      Opprette: 'users.add_user',
      Endre: 'users.change_user',
      Slette: 'users.delete_user',
    },
  },
  {
    title: 'Tilbud',
    permissions: {
      Se: 'calculator.view_offer',
      Opprette: 'calculator.add_offer',
      Endre: 'calculator.change_offer',
    },
  },
  {
    title: 'Prislister',
    permissions: {
      Se: 'articles.view_pricelist',
      Opprette: 'articles.add_pricelist',
      Endre: 'articles.change_pricelist',
      Slette: 'articles.delete_pricelist',
    },
  },
  {
    title: 'Artikler',
    permissions: {
      Se: 'articles.view_article',
      Opprette: 'articles.add_article',
      Endre: 'articles.change_article',
      Slette: 'articles.delete_article',
    },
  },
  {
    title: 'Innstillinger',
    permissions: {
      Se: ['companies.view_companysetting', 'companies.view_globalsetting'],
      Endre: [
        'companies.change_companysetting',
        'companies.change_globalsetting',
        'companies.change_company',
      ],
      Slette: [
        'companies.delete_companysetting',
        'companies.delete_globalsetting',
      ],
    },
  },
  {
    title: 'Håndbok',
    permissions: {
      Se: ['handbook.view_handbookfolder', 'handbook.view_handbookentry'],
      Opprette: ['handbook.add_handbookfolder', 'handbook.add_handbookentry'],
      Endre: [
        'handbook.change_handbookfolder',
        'handbook.change_handbookentry',
      ],
    },
  },
]

export default function CompanyUserTypes() {
  const toasts = useToast()

  const [updatedPermissions, setUpdatedPermissions] = useState({})
  const [updatedUserTypeNames, setUpdatedUserTypesNames] = useState({})
  // Dirty is true if we have something to save
  const [dirty, setDirty] = useState(false)

  function handleSave(updateCompanyUserTypesMutation) {
    if (!dirty) {
      return
    }

    // Prepare data for the UpdateCompanyUserTypesMutation
    const userTypeIdsWithUpdates = Object.keys(updatedPermissions).concat(
      Object.keys(updatedUserTypeNames)
    )

    // First we make a nested object, with ids as the top-level index
    const mutationData = userTypeIdsWithUpdates.reduce((acc, next) => {
      acc[next] = { permissions: [] }
      return acc
    }, {})

    // Add data
    Object.keys(updatedUserTypeNames).forEach(id => {
      mutationData[id].name = updatedUserTypeNames[id]
    })

    Object.keys(updatedPermissions).forEach(id => {
      Object.keys(updatedPermissions[id]).forEach(permission => {
        const value = updatedPermissions[id][permission]
        mutationData[id].permissions.push({ permission, value })
      })
    })

    // Flatten the object to an array of objects
    const finalData = Object.keys(mutationData).map(id => ({
      id,
      ...mutationData[id],
    }))

    updateCompanyUserTypesMutation({
      variables: {
        updatedUserTypes: finalData,
      },
    })
      .then(() => {
        toasts('success', 'Lagret')
      })
      .catch(() => {
        toasts('error', 'Feil ved lagring.')
      })
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <StyledFlex>
          <Mutation mutation={UPDATE_COMPANY_USER_TYPES_MUTATION}>
            {updateCompanyUserTypes => (
              <Button
                disabled={!dirty}
                success
                icon="fa fa-save"
                onClick={() => handleSave(updateCompanyUserTypes)}
              >
                Lagre
              </Button>
            )}
          </Mutation>
          <PermissionsRequired permissions="companies.change_company">
            <Link to="/company-user-types/company-pricelists/">
              <Button primary icon="far fa-edit">
                Endre prislistetilganger
              </Button>
            </Link>
          </PermissionsRequired>
        </StyledFlex>
        <CompanyContext.Consumer>
          {company => (
            <UserSettingTable>
              <Table.Head>
                <SettingRow>
                  <Table.Cell padding="0.5em 0.25em 0em">
                    <Text
                      fontWeight="500"
                      fontSize="12px"
                      textTransform="uppercase"
                    >
                      Tilganger
                    </Text>
                  </Table.Cell>
                  {company.userTypes.map(type => (
                    <UserSettingHeaderCell key={type.id}>
                      <CompanyUserTypeTitle
                        name={updatedUserTypeNames[type.id] || type.name}
                        onNameChanged={newName => {
                          setDirty(true)
                          setUpdatedUserTypesNames({
                            ...updatedUserTypeNames,
                            [type.id]: newName,
                          })
                        }}
                      />
                    </UserSettingHeaderCell>
                  ))}
                  <Table.Cell>
                    <Link to="/company-user-types/create">
                      <Button primary icon="far fa-plus">
                        Lag ny brukertype
                      </Button>
                    </Link>
                  </Table.Cell>
                </SettingRow>
              </Table.Head>
              <Table.Body>
                {permissionDomains.map((permissionDomain, i) => (
                  <React.Fragment key={i}>
                    <SettingRow>
                      <UserSettingNameCell isTitle>
                        {permissionDomain.title}
                      </UserSettingNameCell>
                    </SettingRow>
                    {Object.keys(permissionDomain.permissions).map(
                      (permissionName, i) => {
                        return (
                          <SettingRow
                            key={permissionDomain.title + permissionName}
                          >
                            <UserSettingNameCell>
                              {permissionName}
                            </UserSettingNameCell>
                            {company.userTypes.map((type, j) => {
                              const permission =
                                permissionDomain.permissions[permissionName]

                              // If the permission is an array, we need to check multiple individual permissions.
                              // So we hedge ourselves here by checking array-wise regardles
                              const permissionsRequired = [].concat(permission)

                              const hasPermission = permissionsRequired.every(
                                permission => {
                                  const potentialUpdatedPermission =
                                    (updatedPermissions[type.id] || {})[
                                      permission
                                    ]
                                  return potentialUpdatedPermission !==
                                    undefined
                                    ? potentialUpdatedPermission
                                    : type.permissions.includes(permission)
                                }
                              )

                              return (
                                <UserSettingCell
                                  key={j}
                                  hasPermission={hasPermission}
                                  onClick={() => {
                                    setDirty(true)
                                    const newUpdatedPermissions =
                                      permissionsRequired.reduce(
                                        (acc, permission) => {
                                          acc[permission] = !hasPermission
                                          return acc
                                        },
                                        {}
                                      )
                                    setUpdatedPermissions({
                                      ...updatedPermissions,
                                      [type.id]: {
                                        ...(updatedPermissions[type.id] || {}),
                                        ...newUpdatedPermissions,
                                      },
                                    })
                                  }}
                                >
                                  <UserSettingCheck>
                                    <i
                                      className={
                                        'fal ' +
                                        (hasPermission
                                          ? 'fa-check'
                                          : 'fa-times')
                                      }
                                    ></i>
                                  </UserSettingCheck>
                                </UserSettingCell>
                              )
                            })}
                          </SettingRow>
                        )
                      }
                    )}
                  </React.Fragment>
                ))}
              </Table.Body>
            </UserSettingTable>
          )}
        </CompanyContext.Consumer>
      </InnerWrapper>
    </Wrapper>
  )
}
