import styled from 'styled-components'

interface GridItemProps {
  area?: string
  columnStart?: string
  columnEnd?: string
  column?: string
  alignSelf?: string
  justifySelf?: string
}

const GridItem = styled.div<GridItemProps>`
  ${props => (props.area ? `grid-area: ${props.area};` : '')}
  grid-column: ${props => props.column};
  grid-column-start: ${props => props.columnStart};
  grid-column-end: ${props => props.columnEnd};

  align-self: ${props => props.alignSelf};
  justify-self: ${props => props.justifySelf};
`

export default GridItem
