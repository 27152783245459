import styled from 'styled-components'

export interface TableBodyProps {
  fontSize?: string
}

export const TableBody = styled.tbody.attrs((props: TableBodyProps) => ({
  fontSize: '12px',
  boxSizing: 'border-box',
  ...props,
}))`
  font-size: ${props => props.fontSize};
  box-sizing: ${props => props.boxSizing};
`
