import React, { useState } from 'react'

import { Text } from 'components/Text'
import Flex from 'components/Flex'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import Input from 'components-old/Input'
import Form from 'components/Form/Form'

export default function CompanyUserTypeTitle({ name, onNameChanged }) {
  const [edit, setEdit] = useState(false)
  const [newName, setNewName] = useState(name)

  return edit ? (
    <Form
      preventDefault
      onSubmit={() => {
        setEdit(false)
        onNameChanged(newName)
      }}
    >
      <Flex alignItems="center">
        <Input
          value={newName}
          onChange={e => setNewName(e.target.value)}
          width="150px"
          height="30px"
        />
        <Margin left="0.75rem">
          <Text fontSize="10px">
            <Button reset>
              <i
                className="fa fa-check"
                onClick={() => {
                  setEdit(false)
                  onNameChanged(newName)
                }}
              ></i>
            </Button>
          </Text>
        </Margin>
      </Flex>
    </Form>
  ) : (
    <Flex alignItems="center">
      {name}
      <Margin left="0.75rem">
        <Text fontSize="10px" onClick={() => setEdit(true)}>
          <i className="fa fa-pencil"></i>
        </Text>
      </Margin>
    </Flex>
  )
}
