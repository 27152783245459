import React from 'react'
import styled from 'styled-components'
import { Toast as ToastObj } from 'store/toasts'

const ToastIcon = styled.i`
  margin-right: 1em;
`

interface ToastProps {
  className?: string
  toast: ToastObj
}
const Toast: React.FC<ToastProps> = ({ className, toast }) => {
  function getIcon() {
    switch (toast.type) {
      case 'success':
        return 'fas fa-check'
      case 'error':
        return 'fas fa-times'
      case 'info':
        return 'fas fa-info'
      default:
        return 'fas fa-exclamation'
    }
  }
  return (
    <div className={className}>
      <ToastIcon className={getIcon()} />
      {toast.message}
    </div>
  )
}

const StyledToast = styled(Toast)`
  background: white;
  padding: 1em 2em;
  width: 400px;
  margin: 0.5em;
  border-radius: 4px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;

  color: ${props =>
    props.toast.type === 'success'
      ? props.theme.colors.green
      : props.toast.type === 'error'
      ? props.theme.colors.red
      : props.toast.type === 'info'
      ? props.theme.colors.primary
      : props.theme.colors.darkGray};
`

export default StyledToast
