import styled from 'styled-components'

export interface TableCellProps {
  padding?: string
  width?: string
  borderLeft?: string
  borderRight?: string
  borderTop?: string
  borderBottom?: string
  border?: string
}

const TableCell = styled.td<TableCellProps>`
  border: none;
  padding: ${props => props.padding ?? '0.75em'};
  width: ${props => props.width ?? 'auto'};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
  border: ${props => props.border};
`

export default TableCell
