import styled from 'styled-components'

interface CardProps {
  margin?: string
  padding?: string
  hoverEffect?: boolean
  overflow?: 'auto' | 'hidden' | 'scroll' | 'visible'
}
const Card = styled.div<CardProps>`
  position: relative;

  background: white;
  padding: ${props => props.padding ?? '1em 2em'};
  margin: ${props => props.margin};
  overflow: ${props => props.overflow ?? 'auto'};

  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  box-sizing: border-box;

  ${props =>
    props.hoverEffect &&
    `
    &:hover{
      cursor: pointer;
      border: 1px solid ${props.theme.colors.primary};
      transition: box-shadow 0.1s ease;
      box-shadow: 0 2px 12px 0 rgba(49, 78, 255, 0.2);
    }
  `}
`

export default Card
