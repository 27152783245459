import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import FullLoader from 'components/Loader/FullLoader'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components'
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from 'types/graphql/users'
import { passwordValidation } from 'util/forms'
import useOnErrorAuto from 'util/hooks/error-handling'
import { CHANGE_PASSWORD_MUTATION } from './mutations'

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${props => props.theme.colors.background};
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const StyledTitle = styled.h1`
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  color: ${props => props.theme.colors.darkBlue};
`

const StyledText = styled(Text)`
  color: ${props => props.theme.colors.darkGray};
  padding-bottom: 20px;
`

export const NeedPasswordChange: React.UFC = () => {
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const validatePassword = (value: string) =>
    passwordValidation(value) ? null : 'For svakt passord'
  const validatePasswordAgain = (value: string, other: string) =>
    value === other ? null : 'Passordene er ulike'

  const {
    formValues: form,
    formValid,
    formErrors: errors,
    formChangeHandler: handler,
    resetForm,
    submitHandler,
  } = useForm({
    values: {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    validators: {
      currentPassword: val => (!!val ? null : 'Påkrevd'),
      newPassword: validatePassword,
      newPasswordAgain: (value, { newPassword }) =>
        validatePasswordAgain(value, newPassword),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const [changePassword, { loading: changePasswordLoading }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Me'],
    onCompleted(data) {
      if (data?.changePassword.ok) {
        addToast('success', 'Passordet ditt ble endret ')
        window.location.reload()
      } else addToast('error', 'Kunne ikke endre passordet')
    },
    onError: onErrorAuto('Kunne ikke endre passordet'),
  })

  async function submitPassword(values: typeof form) {
    try {
      await changePassword({
        variables: {
          password: values.currentPassword,
          newPassword: values.newPassword,
        },
      })
      resetForm()
    } catch {}
  }

  return (
    <Wrapper>
      <StyledTitle>Endre passord</StyledTitle>
      <StyledText>
        Av sikkerhetsgrunner må du bytte passordet ditt før du logger inn på
        nytt.
      </StyledText>
      <FormWrapper>
        <Input
          type="password"
          label="Nåværende passord"
          value={form.currentPassword}
          error={errors.currentPassword}
          onChange={handler('currentPassword')}
        />

        <Input
          type="password"
          label="Nytt passord"
          value={form.newPassword}
          error={form.newPassword === '' ? null : errors.newPassword}
          onChange={handler('newPassword', {
            includeValidation: ['newPasswordAgain'],
          })}
        />

        <Input
          type="password"
          label="Nytt passord igjen"
          value={form.newPasswordAgain}
          error={form.newPasswordAgain === '' ? null : errors.newPasswordAgain}
          onChange={handler('newPasswordAgain', {
            includeValidation: ['newPassword'],
          })}
        />

        {changePasswordLoading ? (
          <FullLoader />
        ) : (
          <Button
            type="submit"
            disabled={!formValid}
            onClick={submitHandler(submitPassword)}
          >
            Oppdater passord
          </Button>
        )}
      </FormWrapper>
    </Wrapper>
  )
}
