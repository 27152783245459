import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import {
  Input as BaseInput,
  InputProps as BaseInputProps,
} from '@ur/react-components'

interface StyledInputProps {
  fontSize: string
  shadow?: string
  padding?: string
  noBorders: boolean
  fontWeight?: string | number
  hasLeftIcon: boolean
  hasRightIcon: boolean
}

const StyledInput = styled(BaseInput)<StyledInputProps>`
  height: ${props => props.height};
  ${props => props.background && `background: ${props.background};`}

  input {
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    padding: ${props => props.padding};

    border: ${props => `0.5px solid ${props.theme.colors.darkBlue}`};
    border-radius: 5px;

    outline: none;

    width: ${props => (props.fullWidth ? '100%' : props.width)};
    height: 100%;
    color: ${props => props.theme.colors.text.normal};
    line-height: 22px;

    ${props =>
      props.error &&
      css`
        color: ${props.theme.colors.red};
        border: 1px solid ${props.theme.colors.red};
      `}

    ${props =>
      props.type === 'number' &&
      css`
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}

    ${props =>
      props.hasLeftIcon &&
      css`
        border-left: 0;
        padding-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}

    ${props =>
      props.hasRightIcon &&
      css`
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `}

    ::placeholder {
      color: ${props => props.theme.colors.gray3};
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
    &:focus {
      color: ${props => props.theme.colors.text.normal};
      border: 1px solid ${props => props.theme.colors.primary};
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }
  .--input-icon-left,
  .--input-icon-right {
    font-size: 1.2em;
    padding: 0 1.4rem 0 2rem;
    border-color: ${props => props.theme.colors.darkBlue};
    padding: 0;
    transition: border-color 0.1s linear, color 0.1s linear;
    color: ${props => props.theme.colors.darkBlue};
  }
  &:focus-within {
    .--input-icon-left,
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
  }
  ${props =>
    props.noBorders &&
    css`
      input {
        border: 0 !important;
      }
      .--input-icon-left,
      .--input-icon-right {
        border: 0 !important;
      }
      &:focus-within {
        .--input-icon-left,
        .--input-icon-right {
          border: 0 !important;
        }
      }
    `}
`
interface LabelProps {
  label?: string
  error?: string
}

const Label = styled.h1<LabelProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.darkBlue};
  margin: 0 0 7px 0;

  ${props =>
    props.error &&
    css`
      color: ${props.theme.colors.red};
    `}
`

type InputMode =
  | 'text'
  | 'none'
  | 'decimal'
  | 'email'
  | 'tel'
  | 'url'
  | 'numeric'
  | 'search'

interface InputProps extends Omit<BaseInputProps, 'type'> {
  type?: 'text' | 'password' | 'number' | 'search'
  inputMode?: InputMode
  padding?: string
  noBorders?: boolean
  shadow?: string
  fontWeight?: string | number
  error?: string | null
  label?: string

  onEnter?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  onEscape?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      inputMode,
      label,

      background,
      error = null,
      height = '40px',
      width = '300px',
      shadow = theme.shadow.default,
      fontSize = '14px',
      fontWeight,
      padding = '8px 0 8px 11px',
      noBorders = false,

      onEnter,
      onEscape,
      ...props
    },
    ref
  ) => {
    const searchIcon: typeof props.iconLeftProps = useMemo(
      () =>
        type !== 'search'
          ? undefined
          : {
              icon: 'search',
            },
      [type]
    )

    function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
      props.onKeyDown?.(evt)
      if (evt.defaultPrevented) return
      switch (evt.key) {
        case 'Enter':
          onEnter?.(evt)
          return
        case 'Escape':
          onEscape?.(evt)
          return
      }
    }

    return (
      <div>
        {label && <Label error={error}>{label}</Label>}
        <StyledInput
          ref={ref}
          error={error}
          type={type}
          inputMode={inputMode}
          // Size and color props
          background={background}
          noBorders={noBorders}
          height={height}
          width={width}
          padding={padding}
          // Font props
          fontSize={fontSize}
          fontWeight={fontWeight}
          // Icon props
          hasLeftIcon={
            typeof searchIcon !== 'undefined' ||
            typeof props.iconLeftProps !== 'undefined'
          }
          hasRightIcon={typeof props.iconRightProps !== 'undefined'}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </div>
    )
  }
)
