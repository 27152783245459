import { useMutation } from '@apollo/client'
import { Button as BaseButton, TextArea, useToast } from '@ur/react-components'
import { useForm } from '@ur/react-hooks'
import { Button } from 'components/Button'
import Image from 'components/Image'
import { Input } from 'components/Input'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useCompany } from 'util/hooks'
import useOnErrorAuto from 'util/hooks/error-handling'
import {
  PATCH_COMPANY_MUTATION,
  UPDATE_COMPANY_SETTINGS_MUTATION,
} from '../mutations'
import {
  UpdateCompanySettingsMutation,
  UpdateCompanySettingsMutationVariables,
} from '../types.graphql'
import {
  CompanySettingKeys,
  PatchCompanyMutation,
  PatchCompanyMutationVariables,
} from './types'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
`
interface MobileProps {
  mobile?: boolean
}

const FormWrapper = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: max(433px, 50%);
  padding-bottom: 20px;

  ${props => props.theme.media.mobile} {
    max-width: 100%;
  }
`
const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  height: 80px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
const ButtonWrapper = styled.div<MobileProps>`
  display: flex;
  flex-direction: row;
  gap: ${props => (props.mobile ? '0' : '5px')};
`

const CityPostalCodeWrapper = styled.div`
  display: grid;
  grid-template-areas: 'code city';
  grid-template-columns: 1fr 2fr;
  width: 100%;
  gap: 20px;
`

interface StyledInputProps {
  area: string
}

const StyledInput = styled(Input)<StyledInputProps>`
  display: area;
  grid-area: ${props => props.area};
`
const StyledButton = styled(BaseButton)`
  background-color: inherit;
  color: ${props => props.theme.colors.gray3};

  .--button-content {
    padding: 0;
    font-size: 12px;
  }
  .--button-icon-left {
    min-width: auto;
  }
  &:hover {
    background-color: ${props => props.theme.colors.gray5};
  }
`

const Label = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.darkBlue};
  margin: 0 0 7px 0;
`

const TextAreaWrapper = styled.div`
  width: 100%;
`

export interface CompanyLogoFormData {
  id: string
  companyLogo: File | undefined
}

interface CompanySettingsFormData {
  address: string
  city: string
  contactEmailAddress: string
  contactPhoneNumber: string
  fullName: string
  nextInternalOfferReference: string
  offerConditions: string
  postalCode: string
}

export const CompanySettings: React.FC = () => {
  const company = useCompany()
  const toasts = useToast()
  const onErrorAuto = useOnErrorAuto()
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const nextInternalOfferReferenceSetting = company.settings.find(
    setting => setting.key === CompanySettingKeys.NextInternalOfferReference
  )
  const nextInternalOfferReference = nextInternalOfferReferenceSetting
    ? nextInternalOfferReferenceSetting.value
    : ''

  const offerConditionsSetting = company.settings.find(
    setting => setting.key === CompanySettingKeys.OfferConditions
  )
  const offerConditions = offerConditionsSetting
    ? offerConditionsSetting.value
    : ''

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<CompanySettingsFormData>({
    values: {
      fullName: company.fullName ?? '',
      address: company.address ?? '',
      postalCode: company.postalCode ?? '',
      city: company.city ?? '',
      contactEmailAddress: company.contactEmailAddress ?? '',
      contactPhoneNumber: company.contactPhoneNumber ?? '',
      nextInternalOfferReference: nextInternalOfferReference,
      offerConditions: offerConditions,
    },
    validators: {
      fullName: val => (!!val ? null : 'Kan ikke være tom'),
      address: val => (!!val ? null : 'Kan ikke være tom'),
      postalCode: val => (!!val ? null : 'Kan ikke være tom'),
      city: val => (!!val ? null : 'Kan ikke være tom'),
    },
  })

  const [patchCompanyLogo] = useMutation<
    PatchCompanyMutation,
    PatchCompanyMutationVariables
  >(PATCH_COMPANY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['CurrentCompany'],
    onError: onErrorAuto('Noe gikk galt ved endring av innstillingene'),
  })

  const [patchCompanySettings] = useMutation<
    UpdateCompanySettingsMutation,
    UpdateCompanySettingsMutationVariables
  >(UPDATE_COMPANY_SETTINGS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['CurrentCompany'],
    onError: onErrorAuto('Noe gikk galt ved endring av innstillingene'),
  })

  async function handleSubmitForm(values: CompanySettingsFormData) {
    try {
      await patchCompanySettings({
        variables: {
          ...values,
        },
      })
      toasts('success', 'Innstillingene er lagret')
    } catch (e) {
      toasts('error', 'Noe gikk galt ved lagring av innstillingene')
    }
  }

  async function handleDeleteLogo() {
    try {
      await patchCompanyLogo({
        variables: {
          id: company.id,
          input: {
            companyLogo: null,
          },
        },
      })
      toasts('success', 'Logoen er slettet')
    } catch (e) {
      toasts('error', 'Noe gikk galt ved sletting av logoen')
    }
  }

  async function handleUploadImage(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = Array.from(evt.target.files ?? [])[0]
    if (!file) return

    try {
      await patchCompanyLogo({
        variables: {
          id: company.id,
          input: {
            companyLogo: file,
          },
        },
      })
      evt.target.value = ''
      toasts('success', 'Logoen er endret')
    } catch (e) {
      toasts('error', 'Noe gikk galt ved opplasting av logoen')
    }
  }

  return (
    <Wrapper>
      <FormWrapper mobile={isMobile}>
        <PictureWrapper>
          <Image
            src={company.companyLogo}
            maxWidth={isMobile ? '40%' : '100%'}
            maxHeight="100%"
          />
          <ButtonWrapper mobile={isMobile}>
            <StyledButton
              onClick={() => fileInputRef.current?.click()}
              iconLeftProps={{
                icon: 'edit',
                size: '13px',
                type: 'solid',
                background: 'inherit',
                color: 'gray3',
              }}
            >
              Endre
            </StyledButton>
            <input
              ref={fileInputRef}
              hidden
              type="file"
              accept="image/*"
              onChange={handleUploadImage}
            />
            <StyledButton
              iconLeftProps={{
                icon: 'trash-alt',
                size: '13px',
                type: 'solid',
                background: 'inherit',
                color: 'gray3',
              }}
              onClick={handleDeleteLogo}
            >
              Slett
            </StyledButton>
          </ButtonWrapper>
        </PictureWrapper>

        <Input
          value={form.fullName}
          placeholder="Firmanavn"
          label="Firmanavn"
          error={errors.fullName}
          fullWidth
          onChange={handler('fullName')}
        />
        <Input
          value={form.address}
          placeholder="Adresse"
          label="Adresse"
          error={errors.address}
          fullWidth
          onChange={handler('address')}
        />
        <CityPostalCodeWrapper>
          <StyledInput
            area="code"
            value={form.postalCode}
            placeholder="Postnummer"
            label="Postnummer"
            error={errors.postalCode}
            width="100%"
            onChange={handler('postalCode')}
          />
          <StyledInput
            area="city"
            value={form.city}
            placeholder="By"
            label="By"
            error={errors.city}
            width="100%"
            onChange={handler('city')}
          />
        </CityPostalCodeWrapper>
        <Input
          value={form.contactEmailAddress}
          placeholder="E-post"
          label="E-post"
          error={errors.contactEmailAddress}
          fullWidth
          onChange={handler('contactEmailAddress')}
        />
        <Input
          value={form.contactPhoneNumber}
          placeholder="Telefonnummer"
          label="Telefonnummer"
          error={errors.contactPhoneNumber}
          fullWidth
          onChange={handler('contactPhoneNumber')}
        />
        <Input
          type="number"
          value={form.nextInternalOfferReference}
          placeholder={nextInternalOfferReference}
          label="Neste interne tilbudsreferanse"
          fullWidth
          onChange={handler('nextInternalOfferReference')}
        />
        <TextAreaWrapper>
          <Label>Vilkår</Label>
          <TextArea
            value={form.offerConditions}
            border={'0.5px solid #1f2461'}
            fullWidth
            fontSize={'14px'}
            color="#1f2461"
            onChange={handler('offerConditions')}
          />
        </TextAreaWrapper>
        <Button
          width="100%"
          disabled={!formValid}
          onClick={submitHandler(handleSubmitForm)}
        >
          Lagre
        </Button>
      </FormWrapper>
    </Wrapper>
  )
}
