import { getOrderStatus, getStatusColor } from 'modules/services'
import { OrderNode, ShallowOrderNode } from 'modules/services/types.graphql'
import React from 'react'
import styled from 'styled-components'

interface StatusProp {
  color: string
  justifyContent?: string
}

const Wrapper = styled.div<StatusProp>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.color};
`

const StatusCircle = styled.div<StatusProp>`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 7px;

  background-color: ${props => props.color};
`

interface OrderStatusCellProps {
  order: OrderNode | ShallowOrderNode
  justifyContent?: string
}

export const OrderStatusCell: React.UFC<OrderStatusCellProps> = ({
  order,
  justifyContent = 'flex-start',
}) => {
  const color = getStatusColor(order.status)
  return (
    <Wrapper color={color} justifyContent={justifyContent}>
      <StatusCircle color={color} />
      {getOrderStatus(order)}
    </Wrapper>
  )
}
