import React from 'react'
import styled from 'styled-components'

import Card from 'components/Card'
import Padding from 'components/Padding'
import Flex from 'components/Flex'
import Table from 'components/Table/Table'
import TableBody from 'components/Table/TableBody'
import TableRow from 'components/Table/TableRow'

const CustomerHeader = styled.h3`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

const CustomerInfoCell = styled.td`
  font-weight: 500;
  text-align: right;
`

export default function CustomerInfoCard({ customer, className = undefined }) {
  return (
    <Card className={className}>
      <Flex justifyContent="space-between" alignItems="center">
        <CustomerHeader>{customer.name}</CustomerHeader>
      </Flex>
      <Padding right="1em">
        <Table fullWidth>
          <TableBody>
            <TableRow>
              <Table.Cell>Adresse</Table.Cell>
              <CustomerInfoCell>{customer.address}</CustomerInfoCell>
            </TableRow>
            <TableRow>
              <Table.Cell>Sted</Table.Cell>
              <CustomerInfoCell>
                {customer.postalCode}, {customer.city}
              </CustomerInfoCell>
            </TableRow>
            <TableRow>
              <Table.Cell>Telefonnummer</Table.Cell>
              <CustomerInfoCell>{customer.phoneNumber}</CustomerInfoCell>
            </TableRow>
            <TableRow>
              <Table.Cell>E-post</Table.Cell>
              <CustomerInfoCell>{customer.email}</CustomerInfoCell>
            </TableRow>
          </TableBody>
        </Table>
      </Padding>
    </Card>
  )
}
