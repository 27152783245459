import { useMutation } from '@apollo/client'
import { useToast } from '@ur/react-components'
import { IdParameter } from 'types/graphql/common'
import useOnErrorAuto from 'util/hooks/error-handling'
import {
  CREATE_SERVICE_MUTATION,
  CREATE_SERVICE_OPTION_ITEM_MUTATION,
  CREATE_SERVICE_OPTION_MUTATION,
  DELETE_SERVICE_MUTATION,
  DELETE_SERVICE_OPTION_ITEM_MUTATION,
  DELETE_SERVICE_OPTION_MUTATION,
  PATCH_SERVICE_MUTATION,
  PATCH_SERVICE_OPTION_ITEM_MUTATION,
  PATCH_SERVICE_OPTION_MUTATION,
} from './mutations'
import {
  CreateServiceMutation,
  CreateServiceMutationVariables,
  CreateServiceOptionItemMutation,
  CreateServiceOptionItemMutationVariables,
  CreateServiceOptionMutation,
  CreateServiceOptionMutationVariables,
  DeleteServiceMutation,
  DeleteServiceOptionItemMutation,
  DeleteServiceOptionMutation,
  PatchServiceMutation,
  PatchServiceMutationVariables,
  PatchServiceOptionItemMutation,
  PatchServiceOptionItemMutationVariables,
  PatchServiceOptionMutation,
  PatchServiceOptionMutationVariables,
} from './types.graphql'

export function useServiceMutations() {
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createService, { loading: createServiceLoading }] = useMutation<
    CreateServiceMutation,
    CreateServiceMutationVariables
  >(CREATE_SERVICE_MUTATION, {
    onCompleted() {
      addToast('success', 'Tjeneste opprettet')
    },
    onError: onErrorAuto('Noe gikk galt ved lagring av tjenesten.'),
    refetchQueries: ['AllServices', 'AllTags'],
  })

  const [patchService, { loading: patchServiceLoading }] = useMutation<
    PatchServiceMutation,
    PatchServiceMutationVariables
  >(PATCH_SERVICE_MUTATION, {
    onCompleted() {
      addToast('success', 'Tjeneste endret')
    },
    onError: onErrorAuto('Noe gikk galt ved endring av tjenesten.'),
    refetchQueries: ['AllServices', 'GetService', 'AllTags'],
  })

  const [deleteService, { loading: deleteServiceLoading }] = useMutation<
    DeleteServiceMutation,
    IdParameter
  >(DELETE_SERVICE_MUTATION, {
    onCompleted() {
      addToast('success', 'Tjeneste slettet')
    },
    onError: onErrorAuto('Noe gikk galt ved sletting av tjenesten.'),
    refetchQueries: ['AllServices', 'AllTags'],
  })

  return {
    service: {
      create: createService,
      patch: patchService,
      delete: deleteService,
    },
    loading:
      createServiceLoading || patchServiceLoading || deleteServiceLoading,
  }
}

export function useServiceOptionMutations() {
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createServiceOption, { loading: createServiceOptionLoading }] =
    useMutation<
      CreateServiceOptionMutation,
      CreateServiceOptionMutationVariables
    >(CREATE_SERVICE_OPTION_MUTATION, {
      onCompleted() {
        addToast('success', 'Valgkategori opprettet')
      },
      onError: onErrorAuto('Noe gikk galt ved lagring av valgkategorien.'),
      refetchQueries: ['GetService'],
    })

  const [patchServiceOption, { loading: patchServiceOptionLoading }] =
    useMutation<
      PatchServiceOptionMutation,
      PatchServiceOptionMutationVariables
    >(PATCH_SERVICE_OPTION_MUTATION, {
      onCompleted() {
        addToast('success', 'Valgkategori endret')
      },
      onError: onErrorAuto('Noe gikk galt ved endring av valgkategorien.'),
      refetchQueries: ['GetService'],
    })

  const [deleteServiceOption, { loading: deleteServiceOptionLoading }] =
    useMutation<DeleteServiceOptionMutation, IdParameter>(
      DELETE_SERVICE_OPTION_MUTATION,
      {
        onCompleted() {
          addToast('success', 'Valgkategori slettet')
        },
        onError: onErrorAuto('Noe gikk galt ved sletting av valgkategorien.'),
        refetchQueries: ['GetService'],
      }
    )

  return {
    serviceOption: {
      create: createServiceOption,
      patch: patchServiceOption,
      delete: deleteServiceOption,
    },
    loading:
      createServiceOptionLoading ||
      patchServiceOptionLoading ||
      deleteServiceOptionLoading,
  }
}

export function useServiceOptionItemMutations() {
  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()

  const [createServiceOptionItem, { loading: createServiceOptionItemLoading }] =
    useMutation<
      CreateServiceOptionItemMutation,
      CreateServiceOptionItemMutationVariables
    >(CREATE_SERVICE_OPTION_ITEM_MUTATION, {
      onCompleted() {
        addToast('success', 'Valgalternativ opprettet')
      },
      onError: onErrorAuto('Noe gikk galt ved lagring av valgalternativet.'),
      refetchQueries: ['GetService'],
    })

  const [patchServiceOptionItem, { loading: patchServiceOptionItemLoading }] =
    useMutation<
      PatchServiceOptionItemMutation,
      PatchServiceOptionItemMutationVariables
    >(PATCH_SERVICE_OPTION_ITEM_MUTATION, {
      onCompleted() {
        addToast('success', 'Valgalternativ endret')
      },
      onError: onErrorAuto('Noe gikk galt ved endring av valgalternativet.'),
      refetchQueries: ['GetService'],
    })

  const [deleteServiceOptionItem, { loading: deleteServiceOptionItemLoading }] =
    useMutation<DeleteServiceOptionItemMutation, IdParameter>(
      DELETE_SERVICE_OPTION_ITEM_MUTATION,
      {
        onCompleted() {
          addToast('success', 'Valgalternativ slettet')
        },
        onError: onErrorAuto('Noe gikk galt ved sletting av valgalternativet.'),
        refetchQueries: ['GetService,'],
      }
    )

  return {
    serviceOptionItem: {
      create: createServiceOptionItem,
      patch: patchServiceOptionItem,
      delete: deleteServiceOptionItem,
    },
    loading:
      createServiceOptionItemLoading ||
      patchServiceOptionItemLoading ||
      deleteServiceOptionItemLoading,
  }
}
