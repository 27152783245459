import styled from 'styled-components'
import { overloadColorProp } from 'util/style'
import {
  CSSTextAlign,
  CSSFontWeight,
  CSSFontStyle,
  CSSTextTransform,
} from 'types/style'

export interface TextProps {
  fontSize?: string
  fontWeight?: CSSFontWeight
  fontStyle?: CSSFontStyle

  textAlign?: CSSTextAlign
  textTransform?: CSSTextTransform
  textDecoration?: string
  hoverTextDecoration?: string
  lineHeight?: string | number

  block?: boolean
  color?: string
  cursor?: string

  userSelect?: string
}

export const Text = styled.span.attrs((props: TextProps) => ({
  color: props.color || 'white',

  textAlign: props.textAlign || 'left',
  textTransform: props.textTransform || 'none',
  textDecoration: props.textDecoration || 'none',

  fontWeight: props.fontWeight || 'normal',
  fontSize: props.fontSize || '1rem',
  fontStyle: props.fontStyle || 'normal',

  userSelect: props.userSelect || 'auto',
  block: props.block || 'inline',
  cursor: props.cursor || 'auto',
  ...props,
}))`
  display: ${props => (props.block ? 'block' : 'inline')};
  color: ${overloadColorProp};

  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  text-transform: ${props => props.textTransform};
  text-decoration: ${props => props.textDecoration};
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};
  user-select: ${props => props.userSelect};

  :hover {
    text-decoration: ${props =>
      props.hoverTextDecoration ?? props.textDecoration ?? 'none'};
  }
`
