import React from 'react'
import styled from 'styled-components'
import { Input as BaseInput, InputProps } from '@ur/react-components'
import { CSSFontWeight, CSSTextAlign, CSSTextTransform } from 'types/style'
import { overloadColorProp } from 'util/style'

export interface InlineInputProps {
  color?: string
  colorPlaceholder?: string
  backgroundColor?: string
  autoFocus?: boolean
  fontWeight?: CSSFontWeight
  textAlign?: CSSTextAlign
  textTransform?: CSSTextTransform
  fontSize?: string
  lineHeight?: string | number
  border?: string
  padding?: string | number
  height?: string
}

const StyledInput = styled(BaseInput).attrs((props: InlineInputProps) => ({
  color: props.color || 'darkBlue',
  colorPlaceholder: props.colorPlaceholder || '#818181',
  backgroundColor: props.backgroundColor || 'inherit',
  autoFocus: props.autoFocus || true,
  fontWeight: props.fontWeight || 600,
  fontSize: props.fontSize || '32px',
  lineHeight: props.lineHeight || '40px',
  border: props.border || 'none',
  padding: props.padding || 0,
  height: props.height || '40px',
  ...props,
}))`
  input::placeholder {
    color: ${props => props.colorPlaceholder};
  }
  .--input-input {
    color: ${overloadColorProp};
    background-color: ${props => props.backgroundColor};
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
    border: ${props => props.border};
    padding: ${props => props.padding};
    height: ${props => props.height};
  }
`

export const InlineInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    return <StyledInput ref={ref} {...props} />
  }
)
