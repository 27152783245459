import { useQuery } from '@apollo/client'
import { QuoTable } from 'components/QuoTable'
import { Column } from 'components/QuoTable/types'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  AllPricelistsQueryParameters,
  AllPricelistsQueryReturn,
} from 'types/graphql/articles'
import { PricelistDropdown } from './PricelistDropdown'
import { ALL_PRICELISTS_QUERY } from './queries'

const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.input};
  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`

const NameCell = styled(Link)`
  font-size: 18px;
  font-weight: 600;
`
interface PricelistsTableProps {
  search: string
}

export const PricelistsTable: React.UFC<PricelistsTableProps> = ({
  search,
}) => {
  const [openMenu, setOpenMenu] = useState('')

  const { data, error } = useQuery<
    AllPricelistsQueryReturn,
    AllPricelistsQueryParameters
  >(ALL_PRICELISTS_QUERY, {
    variables: { q: search },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if (error) {
    return <span>Feil ved innlasting av prislister</span>
  }
  const columns: Column[] = [
    {
      id: 'name',
      label: 'navn',
      width: '1px',
      cellStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '44ch',
      },
    },
    {
      id: 'articles',
      label: 'antall varer',
      hide: isMobile,
      headerProps: {
        style: { whiteSpace: 'nowrap' },
      },
    },
    {
      id: 'rowMenu',
      label: '',
      isMenu: true,
    },
  ]
  const pricelists = data?.allPricelists.edges.map(edge => edge.node) ?? []

  const pricelistsData = pricelists.map(pricelist => {
    return {
      id: pricelist.id,
      data: {
        name: (
          <NameCell
            key={pricelist.name}
            to={`/pricelists/${pricelist.id}`}
            title={pricelist.name}
          >
            {pricelist.name}
          </NameCell>
        ),
        articles: pricelist.articleCount,
        rowMenu: (
          <PricelistDropdown
            pricelist={pricelist}
            open={openMenu === pricelist.id}
            onBarsClick={() => setOpenMenu(pricelist.id)}
            onClose={() => setOpenMenu('')}
          />
        ),
      },
    }
  })

  return (
    <StyledQuotable
      columns={columns}
      tableProps={{ fullWidth: true, tableLayout: 'fixed' }}
      data={pricelistsData}
      loadingProps={{
        rows: 3,
        expectedWidths: [400, 400, 10],
      }}
      headerProps={{}}
    />
  )
}
