import React from 'react'
import styled, { css } from 'styled-components'
import { HTMLButtonType } from 'types/html'
import { Icon, IconProps } from '@ur/react-components'
import { Loader } from '@ur/react-components'

type ButtonStyleType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'cancel'

const buttonStyleHelper = (type: ButtonStyleType = 'primary') => {
  switch (type) {
    case 'primary':
      return css`
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        &:hover {
          background-color: ${props => props.theme.colors.highlight};
        }
      `
    case 'secondary':
      return css`
        background-color: ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.white};
        &:hover {
          filter: brightness(0.9);
        }
      `
    case 'success':
      return css`
        background-color: ${props => props.theme.colors.success};
        color: ${props => props.theme.colors.white};
        &:hover {
          filter: brightness(0.9);
        }
      `
    case 'danger':
      return css`
        background-color: ${props => props.theme.colors.danger};
        color: ${props => props.theme.colors.white};
        &:hover {
          filter: brightness(0.9);
        }
      `

    case 'warning':
      return css`
        background-color: ${props => props.theme.colors.warning};
        color: ${props => props.theme.colors.white};
        &:hover {
          filter: brightness(0.9);
        }
      `
    case 'cancel':
      return css`
        background-color: ${props => props.theme.colors.gray3};
        color: ${props => props.theme.colors.white};
        &:hover {
          filter: brightness(0.9);
        }
      `
  }
}
export interface StyledButtonProps {
  buttonStyle: ButtonStyleType
  reset: boolean

  width: string
  height: string
  fontWeight: number
  borderRadius?: string
  icon: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  outline: none;
  border: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 0 26px;

  ${props => buttonStyleHelper(props.buttonStyle)}

  font-size: 18px;
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;

  width: ${props => props.width};
  height: ${props => props.height};

  cursor: pointer;

  appearance: none;
  overflow: hidden;
  user-select: none;

  &:active {
    box-shadow: inset 0 -1px 2px 1px rgba(0, 0, 0, 0.2);
    filter: brightness(0.9);
  }
  &:focus {
    box-shadow: inset 0 -1px 2px 1px rgba(0, 0, 0, 0.2);
    filter: brightness(0.9);
  }

  ${props =>
    props.reset &&
    `
    background-color: inherit;
    color: inherit;
    border: none;
    border-radius: inherit;
    box-shadow: none;
  `}

  /* Disabled buttons */
  ${props =>
    props.disabled &&
    `
    filter: grayscale(100%) contrast(70%);
    cursor: not-allowed;
    :hover{
      filter: grayscale(100%) contrast(70%);
    }
  `}
`

export interface ButtonProps {
  className?: string
  type?: HTMLButtonType
  disabled?: boolean
  primary?: boolean
  reset?: boolean
  buttonStyle?: ButtonStyleType

  fullWidth?: boolean
  width?: string
  height?: string
  fontWeight?: number
  borderRadius?: string
  icon?: IconProps

  loading?: boolean
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  type = 'submit',
  buttonStyle = 'primary',

  disabled = false,
  reset = false,

  fullWidth = false,
  width = 'auto',
  height = '48px',
  fontWeight = 600,
  borderRadius = '10px',

  icon,

  loading,
  onClick = () => void 0,
}) => {
  if (loading) {
    return (
      <StyledButton
        className={className}
        type={type}
        disabled={true}
        buttonStyle={buttonStyle}
        reset={reset}
        width={fullWidth ? '100%' : width}
        height={height}
        icon={!!icon}
        fontWeight={fontWeight}
      >
        <Loader.Spinner size={20} colorPrimary={'white'} />
      </StyledButton>
    )
  }
  return (
    <StyledButton
      className={className}
      type={type}
      disabled={disabled}
      buttonStyle={buttonStyle}
      reset={reset}
      width={fullWidth ? '100%' : width}
      height={height}
      fontWeight={fontWeight}
      borderRadius={borderRadius}
      icon={!!icon}
      onClick={onClick}
    >
      {icon && <Icon size="19px" type="light" margin="0 10px 0 0" {...icon} />}

      {children}
    </StyledButton>
  )
}
