export enum ZIndexRange {
  Globals = 3000,
  Sidebar = 3100,
  Header = 3200,
  Dropdowns = 4000,
  FormFields = 5000,
  Modals = 6000,
  Loaders = 7000,
  Toasts = 8000,
  Extra = 9000,
  InformationBox = 9500,
  Prompts = 10000,
}
