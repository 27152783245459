import React from 'react'
import range from 'lodash/range'
import ContentLoader from 'react-content-loader'
import { TableRow } from 'components/QuoTable/TableRow'
import { TableCell } from 'components/QuoTable/TableCell'

export interface TableContentLoaderProps {
  columns?: number
  rows?: number
  expectedWidths?: Array<string | number>
  expectedHeights?: Array<string | number>
}

export const TableContentLoader: React.FC<TableContentLoaderProps> = ({
  columns = 3,
  rows = 3,
  expectedWidths = [],
  expectedHeights = [],
}) => {
  const rowRange = range(0, rows)
  const columnRange = range(0, columns)

  return (
    <>
      {rowRange.map(i => (
        <TableRow key={i}>
          {columnRange.map(j => (
            <TableCell key={j} textAlign="left">
              <ContentLoader
                style={{
                  width: expectedWidths[j] || 'auto',
                  height: expectedHeights[j] || '60px',
                }}
              >
                <rect x="0" y="20" rx="2" ry="2" width="145" height="10" />
              </ContentLoader>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
