import Tippy from '@tippyjs/react/headless'
import Thumbnail from 'components/Thumbnail'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUser } from 'util/hooks'
import { MenuItem } from './components/MenuItem'
import { HeaderRightMenuDropdown } from './HeaderRightMenuDropdown'

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: red;
  width: 200px;
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadow.default};
  overflow: hidden;
`

export function HeaderRightIcons() {
  const user = useUser()
  const [showCompanies, setShowCompanies] = useState(false)

  return (
    <MenuWrapper>
      <Tippy
        interactive
        onHide={() => setShowCompanies(false)}
        trigger="mouseenter click"
        render={attrs => (
          <Menu tabIndex={-1} {...attrs}>
            <MenuItem as={Link} to={`/users/${user.id}`}>
              <i className="far fa-user"></i>
              <span>Profil</span>
            </MenuItem>
            <HeaderRightMenuDropdown
              open={showCompanies}
              onClick={() => setShowCompanies(!showCompanies)}
            />
            <MenuItem as={Link} to="/logout" color="gray3">
              <i className="far fa-sign-out" />
              <span>Logg ut</span>
            </MenuItem>
          </Menu>
        )}
      >
        <Thumbnail src={user.profilePicture} size={40} />
      </Tippy>
    </MenuWrapper>
  )
}

export default HeaderRightIcons
