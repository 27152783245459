import { gql } from '@apollo/client'
import { HandbookFragments } from './queries'

export const CREATE_MANUAL_ENTRY_MUTATION = gql`
  mutation CreateHandbookEntry($input: CreateHandbookEntryInput!) {
    createHandbookEntry(input: $input) {
      handbookEntry {
        ...HandbookEntryInfo
        folder {
          id
          name
          slug
          description
          ...HandbookFolderChildren
          ...HandbookFolderEntries
        }
      }
    }
  }
  ${HandbookFragments.handbookEntryInfo}
  ${HandbookFragments.handbookFolderChildren}
  ${HandbookFragments.handbookFolderEntries}
`

export const CREATE_MANUAL_FOLDER_MUTATION = gql`
  mutation CreateHandbookFolder($input: CreateHandbookFolderInput!) {
    createHandbookFolder(input: $input) {
      handbookFolder {
        ...HandbookFolderInfo
        ...HandbookFolderChildren
        ...HandbookFolderEntries
        parentFolder {
          id
          ...HandbookFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
  ${HandbookFragments.handbookFolderChildren}
  ${HandbookFragments.handbookFolderEntries}
`

export const UPDATE_MANUAL_ENTRY_MUTATION = gql`
  mutation UpdateHandbookEntry($id: ID!, $input: UpdateHandbookEntryInput!) {
    updateHandbookEntry(id: $id, input: $input) {
      handbookEntry {
        ...HandbookEntryInfo
        ...HandbookEntryAttachments
        folder {
          id
          name
          slug
          description
          ...HandbookFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.handbookEntryInfo}
  ${HandbookFragments.handbookFolderChildren}
  ${HandbookFragments.handbookEntryAttachments}
`

export const UPDATE_MANUAL_FOLDER_MUTATION = gql`
  mutation UpdateHandbookFolder($id: ID!, $input: UpdateHandbookFolderInput!) {
    updateHandbookFolder(id: $id, input: $input) {
      handbookFolder {
        ...HandbookFolderInfo
        parentFolder {
          id
          name
          slug
          description
          ...HandbookFolderChildren
        }
      }
    }
  }
  ${HandbookFragments.handbookFolderInfo}
  ${HandbookFragments.handbookFolderChildren}
`
