export const clamp = (num: number, min: number, max: number) =>
  Math.min(max, Math.max(min, num))

export function preventDefaults(evt: React.SyntheticEvent) {
  evt.preventDefault()
  evt.stopPropagation()
}

export function ifKeyDown<E extends HTMLElement>(
  keys: string | string[],
  callback: (event: React.KeyboardEvent<E>) => void
) {
  if (typeof keys === 'string') keys = [keys]

  return (evt: React.KeyboardEvent<E>) => {
    if (keys.includes(evt.key)) callback(evt)
  }
}

/**
 * Check if a number is between two other numbers (inclusive)
 * @param num Number to check
 * @param min Minimum (inclusive)
 * @param max Maximum (inclusive)
 */
export function isBetween(num: number, min: number, max: number) {
  if (min > num || max < num) return false
  return true
}

/**
 * Checks if a string is longer than a minimum length, check is inclusive
 * @param input String to be validated
 * @param minimumLength Minimum length of string (inclusive)
 */
export function stringIsLongerThan(input: string, minimumLength: number) {
  if (input.length >= minimumLength) return true
  return false
}

/**
 * Checks if two strings are equal by object deep compariosn
 * @param firstString first string to be compared
 * @param seondString  second string to be compared
 */
export function stringsAreEqual(firstString: string, seondString: string) {
  return firstString === seondString
}

export function stringContainsOnlyNumbers(inputString: string) {
  return inputString.match(/^[0-9]+$/) !== null
}

export function isValidPhoneNumber(value: string) {
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)
}

export function notEmptyValidation(x: any) {
  return !!x
}
