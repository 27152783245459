import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import {
  FormWrapper,
  Header,
  LoginField,
  LoginLabel,
  LoginLink,
  LoginSection,
  LoginWrapper,
  LogoSection,
  SubmitButton,
} from './components'
import { fromUnixTime, isBefore, isValid } from 'date-fns'
import Form from 'components/Form'
import { useForm } from '@ur/react-hooks'
import { Input } from 'components/Input'
import { RESET_PASSWORD_BY_TOKEN_MUTATION } from 'modules/users/mutations'
import { useMutation } from '@apollo/client'
import { ResetPasswordByTokenMutation } from 'modules/users/types.graphql'
import { setLoginToken } from './util'
import client from 'apollo'
import { useToast } from '@ur/react-components'

interface ResetPasswordForm {
  newPassword: string
  newPasswordAgain: string
}

export const ResetPassword: React.UFC = () => {
  const addToast = useToast()
  const history = useHistory()
  const location = useLocation()
  const search = queryString.parse(location.search)
  const token = search.token as string

  const {
    formValues: form,
    formErrors,
    formChangeHandler: handler,
    submitHandler,
    formValid,
  } = useForm({
    values: {
      newPassword: '',
      newPasswordAgain: '',
    },
    validators: {
      newPassword: (value, formValues) =>
        value && value === formValues.newPasswordAgain
          ? null
          : 'Passordene er ulike',
      newPasswordAgain: (value, formValues) =>
        value && value === formValues.newPassword
          ? null
          : 'Passordene er ulike',
    },
  })

  const [resetPasswordByToken] = useMutation<ResetPasswordByTokenMutation>(
    RESET_PASSWORD_BY_TOKEN_MUTATION,
    {
      onCompleted: ({ resetPasswordByToken: { loginToken } }) => {
        setLoginToken(loginToken)
        client.resetStore()
        history.push('/')
        addToast('success', 'Passordet er blitt resatt')
      },
    }
  )

  async function submitForm(values: ResetPasswordForm) {
    await resetPasswordByToken({
      variables: {
        newPassword: values.newPassword,
        token: token,
      },
    })
  }

  /*
   * We can't do a full token verification here, as the token is issued
   * on the backend with a secret key. We can, however, ensure the token has
   * not expired, and contains the correct action
   *
   * Perform a very shallow token validation check. It must be non-empty, and contain
   * exactly two dots.
   */
  if (!token || (token.match(/\./g) || []).length !== 2)
    return (
      <LoginWrapper>
        <LoginSection>
          <FormWrapper>
            <Header title="Nøkkelen er ugyldig" />
            <LoginLink to="/login">Tilbake</LoginLink>
          </FormWrapper>
        </LoginSection>
        <LogoSection />
      </LoginWrapper>
    )

  const tokenData = JSON.parse(
    // Base64-decode
    atob(
      // Data is contained after the first dot
      token.split('.')[1]
    )
  )
  // The expiration date for the token
  const expiration = tokenData.exp
  const expiration_date = fromUnixTime(expiration)
  if (
    expiration &&
    (!isValid(expiration_date) || isBefore(expiration_date, new Date()))
  ) {
    return (
      <LoginWrapper>
        <LoginSection>
          <FormWrapper>
            <Header title="Tilbakestillingsnøkkelen har gått ut på dato." />
            <LoginLink to="/login">Tilbake</LoginLink>
          </FormWrapper>
        </LoginSection>
        <LogoSection />
      </LoginWrapper>
    )
  }

  return (
    <LoginWrapper>
      <LoginSection>
        <FormWrapper>
          <Header title="Nullstill passord" />
          <Form preventDefault onSubmit={submitHandler(submitForm)}>
            <LoginField>
              <LoginLabel htmlFor="newPassword">Nytt passord</LoginLabel>
              <Input
                id="password"
                value={form.newPassword}
                error={formErrors.newPassword}
                onChange={handler('newPassword')}
                width="100%"
                type="password"
              />
            </LoginField>
            <LoginField>
              <LoginLabel htmlFor="newPasswordAgain">Gjenta passord</LoginLabel>
              <Input
                id="passwordAgain"
                value={form.newPasswordAgain}
                error={formErrors.newPasswordAgain}
                onChange={handler('newPasswordAgain')}
                width="100%"
                type="password"
              />
            </LoginField>
            <SubmitButton
              type="submit"
              primary
              width="100%"
              disabled={!formValid}
            >
              Send inn
            </SubmitButton>
          </Form>
        </FormWrapper>
      </LoginSection>
      <LogoSection />
    </LoginWrapper>
  )
}
