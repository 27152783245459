import { OrderNode } from 'modules/services/types.graphql'
import React from 'react'
import styled from 'styled-components'
import Card from 'components/Card'
import Table from 'components/Table/Table'
import TableRow from 'components/Table/TableRow'
import TableCell from 'components/Table/TableCell'
import TableBody from 'components/Table/TableBody'
import { OrderStatusCell } from 'modules/services'

const Wrapper = styled(Card)`
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  h3 {
    margin: 0.5rem 0;
  }
`

const CellWrapper = styled.td`
  font-weight: 500;
  text-align: right;
`

interface OrderInfoCardProps {
  order: OrderNode
}

export const OrderInfoCard: React.UFC<OrderInfoCardProps> = ({ order }) => {
  return (
    <Wrapper>
      <h3>Ordreinformasjon</h3>
      <Table fullWidth>
        <TableBody>
          <TableRow>
            <TableCell padding="10px 10px 10px 0px">Ordrestatus</TableCell>
            <CellWrapper>
              <OrderStatusCell order={order} justifyContent="flex-end" />
            </CellWrapper>
          </TableRow>
          <TableRow>
            <TableCell padding="10px 10px 10px 0px">Kjøretillegg</TableCell>
            <CellWrapper>0 ,-</CellWrapper>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  )
}
