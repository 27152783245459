import React from 'react'
import EasyForm from 'components/Form/EasyForm'

export default function AddRoomTemplateBasicInfo({
  roomTemplateData,
  onFieldChange
}) {
  return (
    <EasyForm 
      fields={[
        {
          name: "name", 
          label: "Navn", 
          value: roomTemplateData.name
        },
        {
          name: "floor", 
          type: "number",
          label: "Etasje", 
          value: roomTemplateData.floor
        },
        {
          name: "area", 
          label: "Areal (m²)",
          value: roomTemplateData.area
        },
      ]}
      controlled={true}
      renderSubmitButton={false}
      onFieldChange={onFieldChange}
    />
  )
}
