import { QuoTable } from 'components/QuoTable'
import { Column } from 'components/QuoTable/types'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { UserNode } from 'types/graphql/users'
import { UsersDropdown } from './UsersDropdown'

const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.input};
  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`

const NameCell = styled(Link)`
  font-size: 18px;
  font-weight: 600;
`

interface UsersTableProps {
  users: UserNode[]
}

export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const [openMenu, setOpenMenu] = useState('')

  const columns: Column[] = [
    {
      id: 'name',
      label: 'navn',
      cellStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '34ch',
      },
    },
    {
      id: 'position',
      label: 'Stilling/Avdeling',
      hide: isMobile,
      cellStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '34ch',
      },
    },
    {
      id: 'phoneNumber',
      label: 'telefon',
      hide: isMobile,
    },
    {
      id: 'email',
      label: 'E-Post',
      cellStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '34ch',
      },
      hide: isMobile,
    },

    {
      id: 'options',
      label: '',
      isMenu: true,
    },
  ]

  const data = users.map(user => {
    const row = {
      id: user.id,
      data: {
        name: (
          <NameCell
            key={user.id}
            to={`/users/${user.id}`}
            title={user.fullName}
          >
            {user.fullName}
          </NameCell>
        ),
        position: user.position,
        phoneNumber: <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>,
        email: user.email,
        options: (
          <UsersDropdown
            user={user}
            open={openMenu === user.id}
            onBarsClick={() => setOpenMenu(user.id)}
            onClose={() => setOpenMenu('')}
          />
        ),
      },
    }
    return row
  })

  return (
    <StyledQuotable
      columns={columns}
      data={data}
      tableProps={{ fullWidth: true, tableLayout: 'fixed' }}
    />
  )
}
