import styled from 'styled-components'
import { useCompany, useUser } from 'util/hooks'
import { MenuButton } from './components/MenuItem'

const MenuDropdown = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'block' : 'none')};
  color: ${props => props.theme.colors.darkBlue};
`

const Companies = styled.div<{ open: boolean }>`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
`

const Company = styled(MenuButton)`
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary};
  }
`

interface DropdownProps {
  onClick: () => void
  open: boolean
}

export const HeaderRightMenuDropdown: React.FC<DropdownProps> = ({
  onClick,
  open,
}) => {
  const user = useUser()
  const currentCompany = useCompany()

  const companies = user.companies?.edges
    ?.map(other => other.node)
    .filter(otherNode => otherNode.shortName !== currentCompany.shortName)

  return (
    <MenuDropdown show={companies.length > 0}>
      <MenuButton onClick={onClick}>
        <i className="far fa-briefcase" />
        <span>{currentCompany.fullName}</span>
        <i className={`fas ${open ? 'fa-angle-up' : 'fa-angle-down'}`} />
      </MenuButton>
      <Companies open={open}>
        {companies.map(company => (
          <Company
            key={company.shortName}
            onClick={() => {
              window.location.href = `/c/${company.shortName}/dashboard`
            }}
          >
            {company.fullName}
          </Company>
        ))}
      </Companies>
    </MenuDropdown>
  )
}
