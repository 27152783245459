import Icon from 'components/Icon'
import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useCompany, useUser } from 'util/hooks'
import { Loader } from '@ur/react-components'
import { WizardOffer } from '../OfferWizard/types'
import { Button } from 'components/Button'
import { API_URL } from 'util/env'
import { UserNode } from 'types/graphql/users'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  width: 800px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${props => props.theme.media.mobile} {
    width: 342px;
    height: 525px;
  }
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 14px 24px;
  & > * + * {
    margin-left: 38px;
  }
`
const ModalIcon = styled(Icon)`
  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`
const LoaderWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 1rem;
    }
  }
`

const PdfWrapper = styled.div`
  width: 100%;
  flex: 1;
  object {
    width: 100%;
    height: 100%;
  }
  .--pdf-fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
`

interface OfferPreviewModalProps {
  offer?: WizardOffer
  onClose: () => void
}

interface PreviewBody {
  offer: WizardOffer
  company: string
  createdBy: string
  customer: string
}

export const OfferPreviewModal: React.UFC<OfferPreviewModalProps> = ({
  offer,
  onClose,
}) => {
  const company = useCompany()
  const user = useUser()
  const [pdfFile, setPdfFile] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const loadPreview = useCallback((data: PreviewBody, user: UserNode) => {
    const url = `${API_URL}/calculator/preview-offer`
    const fetchBody = JSON.stringify(data)

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/pdf' },
      body: fetchBody,
    })
      .then(response => response.blob())
      .then(data => {
        const file = URL.createObjectURL(data)
        setPdfFile(file)
        setLoading(false)
      })
      .catch(err => console.dir(err))
  }, [])

  function downloadPDF() {
    if (!pdfFile || loading) return
    const link = document.createElement('a')
    link.href = pdfFile
    link.setAttribute(
      'download',
      `${offer.internalReferenceId ?? 'nytt tilbud'} - ${offer.name} - ${
        offer.customer.name
      }.pdf`
    )
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  useEffect(() => {
    if (!loading) return
    loadPreview(
      {
        offer,
        company: company.id,
        customer: offer.customer.id,
        createdBy: user.id,
      },
      user
    )
  }, [company.id, loadPreview, loading, offer, user])

  return (
    <Wrapper>
      <ModalHeader>
        <ModalIcon
          icon="download"
          solid
          color="darkBlue"
          size="25px"
          onClick={downloadPDF}
        />
        <ModalIcon
          icon="times"
          color="darkBlue"
          size="25px"
          light
          onClick={onClose}
        />
      </ModalHeader>
      {loading ? (
        <LoaderWrapper>
          <div>
            <h3>Laster forhåndsvisning</h3>
            <Loader.Spinner size={20} colorPrimary={'white'} />
          </div>
        </LoaderWrapper>
      ) : (
        pdfFile && (
          <PdfWrapper>
            <object
              data={pdfFile}
              title={`Forhåndsvisning - ${offer.name}`}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <div className="--pdf-fallback">
                <h3>Kunne ikke laste forhåndsvisning.</h3>
                <Button onClick={downloadPDF}>Last ned PDF</Button>
              </div>
            </object>
          </PdfWrapper>
        )
      )}
    </Wrapper>
  )
}
