import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Mutation } from '@apollo/client/react/components'

import Title from 'components/Title'
import EasyForm from 'components/Form/EasyForm'
import { CREATE_USER_TYPE_MUTATION } from 'modules/users/mutations'
import { notEmptyValidation } from 'util/forms'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper};
  display: flex;
  align-items: center;
  flex-direction: column;
`

export default function CreateCompanyUserType({ history }) {
  return (
    <Wrapper>
      <div>
        <div>
          <Link to="/company-user-types">
            <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
          </Link>
        </div>
        <Title.H2>Opprett brukertype</Title.H2>
        <Mutation
          mutation={CREATE_USER_TYPE_MUTATION}
          refetchQueries={['CurrentCompany']}
          onCompleted={() => {
            history.push('/company-user-types')
          }}
        >
          {createUserType => (
            <EasyForm
              fields={[
                {
                  name: 'name',
                  label: 'Navn',
                  validate: notEmptyValidation,
                },
              ]}
              submitButtonText="Opprett"
              submitButtonProps={{ width: '100%' }}
              onSubmit={formData =>
                createUserType({ variables: { input: { ...formData } } })
              }
            />
          )}
        </Mutation>
      </div>
    </Wrapper>
  )
}
