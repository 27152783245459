import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { WizardRoom } from 'modules/offers/OfferWizard/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  ${props => props.theme.media.mobile} {
    border-radius: 0;
  }

  > div {
    padding: 0 30px;
  }
`

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 3px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 80px;
    text-align: left;
  }
`

const DetailsHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 300px;
  color: ${props => props.theme.colors.gray3};
  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`

const DetailsContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 80px;
    text-align: left;
  }
`

const DetailsContentRowCell = styled.div`
  width: 300px;
  font-size: 16px;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`

interface CreateOfferSummaryArticlesCardProps {
  rooms: WizardRoom[]
  className?: string
}

interface ArticleSummaryItem {
  name: string
  count: number
}

export const CreateOfferSummaryArticlesCard: React.FC<
  CreateOfferSummaryArticlesCardProps
> = ({ rooms, className }) => {
  const [articleSummary, setArticleSummary] = useState<ArticleSummaryItem[]>([])

  useEffect(() => {
    const allArticles = rooms.map(room => room.articles)
    const flattenedArticles = allArticles.reduce(
      (flattenedArticles, roomArticles) => [
        ...flattenedArticles,
        ...roomArticles,
      ],
      []
    )
    const articleNames = flattenedArticles.map(article => article.name)

    // We want to remove duplicate names so we can use this array to count articles.
    // This array spread is due to TS not being entierly up to speed, see
    // the following thread for more info https://stackoverflow.com/a/33464709
    const articleNamesClean = [...Array.from(new Set(articleNames))]

    const finalArticleSummary = articleNamesClean.map(name => {
      const articles = flattenedArticles.filter(
        article => article.name === name
      )
      const articleCount = articles.reduce(
        (totalCount, article) => totalCount + article.count,
        0
      )
      const articleInfo: ArticleSummaryItem = {
        name: name,
        count: articleCount,
      }
      return articleInfo
    })

    setArticleSummary(finalArticleSummary)
  }, [rooms, setArticleSummary])

  return (
    <Wrapper className={className}>
      <DetailsHeader>
        <DetailsHeaderCell>VARE</DetailsHeaderCell>
        <DetailsHeaderCell>ANTALL</DetailsHeaderCell>
      </DetailsHeader>
      {articleSummary.map((article, index) => (
        <DetailsContentRow key={index}>
          <DetailsContentRowCell>{article.name}</DetailsContentRowCell>
          <DetailsContentRowCell>{article.count}</DetailsContentRowCell>
        </DetailsContentRow>
      ))}
    </Wrapper>
  )
}
