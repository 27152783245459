import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useGlobal } from '@ur/react-hooks'
import { Input } from 'components/Input'

const TitleWrapper = styled.div<OfferNameInputProps>`
  grid-area: name;
  flex-direction: column;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;

  ${props => props.theme.media.mobile} {
    margin-top: 20px;
    padding: 10px 15px;
    width: 100vw;

    ${props =>
      props.isWizardConditionState &&
      css`
        padding: 0;
        height: 10vh;
      `}
  }
`
const StyledInput = styled(Input)`
  input {
    background-color: ${props => props.theme.colors.white};
  }

  ${props => props.theme.media.mobile} {
    display: block;
    width: calc(100vw - 30px);
  }
`

const NameLabel = styled.label`
  font-size: 20px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.darkBlue};
`

interface OfferNameInputProps {
  isWizardConditionState: boolean
}

export const OfferNameInput: React.FC = () => {
  const [offer, setOffer] = useGlobal('offer')
  const [{ name }] = useGlobal('offer')
  const [wizardState] = useGlobal('offer.wizardState')

  const isWizardConditionState = useMemo(
    () => wizardState === 'Vilkår',
    [wizardState]
  )

  return (
    <TitleWrapper isWizardConditionState={isWizardConditionState}>
      <NameLabel>Navn på tilbud</NameLabel>
      <StyledInput
        value={name}
        tabIndex={0}
        placeholder="Skriv inn navn"
        fullWidth
        onChange={value => {
          setOffer({ ...offer, name: value })
        }}
      />
    </TitleWrapper>
  )
}
