import React from 'react'
import EasyForm from 'components/Form/EasyForm'

export default function AddRoomTemplateBasicInfo({
  roomTemplateData,
  onFieldChange,
}) {
  return (
    <EasyForm
      fields={[
        {
          name: 'name',
          label: 'Navn',
          value: roomTemplateData.name,
          extra: {
            props: {
              width: '100%',
            },
          },
        },
        {
          name: 'floor',
          type: 'number',
          label: 'Etasje',
          value: roomTemplateData.floor,
          extra: {
            props: {
              width: '100%',
            },
          },
        },
        {
          name: 'area',
          label: 'Areal (m²)',
          value: roomTemplateData.area,
          style: 'width: 100%;',
          extra: {
            props: {
              width: '100%',
            },
          },
        },
      ]}
      controlled={true}
      renderTitle={false}
      renderSubmitButton={false}
      onFieldChange={onFieldChange}
    />
  )
}
