import styled from 'styled-components'

interface ImageProps {
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  filter?: string
  src: string
}

const Image = styled.img<ImageProps>`
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};

  filter: ${props => props.filter};
`

export default Image
