import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.5rem;

  h4 {
    color: ${props => props.theme.colors.gray};
    font-weight: 500;
    margin: 0;
  }
  div {
    i  {
      &.delete {
        margin-right: 0rem;
      }
    }
  }
`

interface OptionSubsectionHeaderProps {
  text: string

  onDelete: () => void
}

const FormulaSubsectionHeader: React.FC<OptionSubsectionHeaderProps> = ({
  text,

  onDelete,
}) => {
  return (
    <Wrapper>
      <h4>{text}</h4>
      <div>
        <Icon
          className="delete"
          icon="trash-alt"
          size="1.2em"
          color="lightGray"
          hoverColor="lighterGray"
          cursor="pointer"
          onClick={onDelete}
        />
      </div>
    </Wrapper>
  )
}

export default FormulaSubsectionHeader
