import { Icon } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'

interface AccordionButtonProps {
  className?: string
  show: boolean
  toggle: () => void
}

const ResetButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  &:focus {
    border: 0;
    padding: 0;
  }
`

const DetailsButton = styled(ResetButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
`

const DetailsIcon = styled(Icon)<{ show: boolean }>`
  transform: ${props => (props.show ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.25s ease-out;
`

export const AccordionButton: React.UFC<AccordionButtonProps> = ({
  toggle,
  show,
}) => {
  return (
    <DetailsButton className="--accordion-button" onClick={toggle}>
      <DetailsIcon icon="chevron-down" size="18px" type="solid" show={show} />
    </DetailsButton>
  )
}
