import { Modal } from '@ur/react-components'
import { Button } from 'components/Button'
import { useState } from 'react'
import styled from 'styled-components'
import { OptionFormModal, OptionCardItem } from '.'
import { useServiceOptionMutations } from '../mutations.hooks'
import { ServiceOptionNode } from '../types.graphql'
import { CardHeader } from './CardHeader'
import { ButtonWrapper } from './common'
import { OptionCardMenu } from './OptionCardMenu'
import { OptionItemFormModal } from './OptionItemFormModal'

const Wrapper = styled.div`
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.default};
  padding: 1.5rem;
  margin-bottom: 2rem;
`

const CardItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

interface OptionCardProps {
  option: ServiceOptionNode
}

export const OptionCard: React.UFC<OptionCardProps> = ({ option }) => {
  const [editOpenModal, setEditOpenModal] = useState(false)
  const [newItemOpenModal, setNewItemOpenModal] = useState(false)
  const optionItems = option.serviceOptionItems.edges.map(edge => edge.node)

  const { serviceOption: optionMutation } = useServiceOptionMutations()

  function handleDelete() {
    optionMutation.delete({ variables: { id: option.id } })
  }

  return (
    <Wrapper>
      <CardHeader
        title={option.name}
        description={option.description}
        menu={
          <OptionCardMenu
            option={option}
            onEdit={() => setEditOpenModal(true)}
            onDelete={handleDelete}
          />
        }
      />
      <CardItems>
        {optionItems.map(item => (
          <OptionCardItem item={item} key={item.id} />
        ))}
      </CardItems>
      <ButtonWrapper>
        <Button
          icon={{ icon: 'plus' }}
          onClick={() => setNewItemOpenModal(true)}
        >
          Nytt valg
        </Button>
      </ButtonWrapper>
      <Modal open={editOpenModal} onClose={() => setEditOpenModal(false)}>
        <OptionFormModal
          option={option}
          closeModalCallback={() => setEditOpenModal(false)}
        />
      </Modal>
      <Modal open={newItemOpenModal} onClose={() => setNewItemOpenModal(false)}>
        <OptionItemFormModal
          optionId={option.id}
          closeModalCallback={() => setNewItemOpenModal(false)}
        />
      </Modal>
    </Wrapper>
  )
}
