import React from 'react'
import styled from 'styled-components'

interface WrapperProps {
  border: string
  open: boolean
  shadow: string
  width: string
  height?: string
  searchable: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: ${props => props.border};
  border-radius: 4px;
  outline: none;

  ${props =>
    props.open &&
    `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}

  box-shadow: ${props => props.shadow};

  width: ${props => props.width};
  height: ${props => props.height};

  color: ${props => props.theme.colors.darkBlue};

  box-sizing: border-box;
  background: white;
  cursor: pointer;
  text-align: left;

  ${props =>
    props.searchable &&
    `
    padding: 0;
    border: none;
  `}

  input {
    border: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border: ${props => props.border};
    border-radius: 4px;
    outline: none;
    font-size: 16px;

    padding-left: 17px;

    ${props =>
      props.open &&
      `
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `}
  }
`

type SelectIconProps = Pick<WrapperProps, 'open' | 'searchable'>

const SelectIcon = styled.span<SelectIconProps>`
  transform: rotate(
    ${props => (props.open && !props.searchable ? '180deg' : '0deg')}
  );
  transition: transform 0.1s ease-in-out, top 0.1s ease-in-out;
`

interface SelectMainInputProps {
  open: boolean
  setOpen: (open: boolean) => void

  labelOfSelected: string
  placeholder: string
  searchable: boolean

  width: string
  height?: string
  border: string
  shadow: string

  searchValue?: string
  onSearchChanged: (query: string) => void
}

const SelectMainInput: React.FC<SelectMainInputProps> = ({
  open,
  setOpen,

  labelOfSelected,
  placeholder,
  searchable,

  width,
  height,
  border,
  shadow,

  searchValue,
  onSearchChanged,
}) => {
  return (
    <Wrapper
      open={open}
      onClick={() => !searchable && setOpen(!open)}
      width={width}
      height={height}
      border={border}
      shadow={shadow}
      searchable={searchable}
    >
      {searchable ? (
        <React.Fragment>
          <input
            value={searchValue}
            placeholder={placeholder || 'Søk..'}
            onFocus={() => setOpen(true)}
            onChange={e => {
              onSearchChanged(e.target.value)
            }}
          />
          <SelectIcon open={open} searchable={searchable}>
            <i className="fal fa-search"></i>
          </SelectIcon>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {labelOfSelected || <span>{placeholder || 'Gjør et valg..'}</span>}
          <SelectIcon open={open} searchable={searchable}>
            <i className="fa fa-chevron-down"></i>
          </SelectIcon>
        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default SelectMainInput
