import React, { useState } from 'react'
import { OrderedMap } from 'immutable'
import isString from 'lodash/isString'
import identity from 'lodash/identity'
import isFunction from 'lodash/isFunction'
import { Table, TableHead, TableRow, TableHeaderCell } from '.'
import { TableHeaderCellSortIcon } from './TableHeaderCellSortIcon'
import { Card, CardProps } from '@ur/react-components'
import { TableBody } from './TableBody'
import { TableCell } from './TableCell'
import { useEffect } from 'react'
import { TableContentLoader } from './TableContentLoader'
import { TableProps } from './types'
import { BaseProps } from 'types/props'
import styled from 'styled-components'

const NoData = styled.div`
  text-align: center;
`

export enum SORT {
  NONE,
  ASCENDING = 1,
  DESCENDING = -1,
}

export interface QuoTableCardProps extends TableProps {
  cardProps?: CardProps
}

function getNextSortStatus(current?: number) {
  switch (current) {
    case SORT.ASCENDING:
      return SORT.DESCENDING
    case SORT.DESCENDING:
      return SORT.NONE
    default:
    case SORT.NONE:
      return SORT.ASCENDING
  }
}

interface QuoTableProps extends TableProps, BaseProps {
  /** Text to show in own row if no data. If null or undefined, no such row is displayed. */
  noDataText?: string | null
}

export const QuoTable: React.FC<QuoTableProps> = ({
  className,
  id,

  columns,
  data,
  sort,

  tableProps,
  headerProps,
  bodyProps,
  loading,
  loadingProps,
  noDataText,

  onSortChanged,
}) => {
  const visibleColumns = columns.filter(column => !column.hide)

  /* SORT HANDLING */
  const initialSortValue =
    isString(sort) && sort.split('').includes('-')
      ? SORT.DESCENDING
      : SORT.ASCENDING
  const initialSortKey =
    isString(sort) && sort.split('').includes('-') ? sort.slice(1) : sort
  // Importantly we have to use an OrderedMap here to preserve the order
  const [sortStatus, setSortStatus] = useState(
    OrderedMap<string, number>().set(initialSortKey, initialSortValue)
  )

  useEffect(() => {
    if (isString(sort)) {
      // Convert sort string to ordered map
      const map = sort.split(',').reduce((acc, entry) => {
        if (entry.includes('-')) {
          return acc.set(entry.slice(1), SORT.DESCENDING)
        } else {
          return acc.set(entry, SORT.ASCENDING)
        }
      }, OrderedMap<string, number>())

      setSortStatus(map)
    }
    // eslint-disable-next-line
  }, [sort])

  function updateSortStatus(name: string) {
    const next = getNextSortStatus(sortStatus.get(name))

    const newStatus = next
      ? sortStatus.set(name, next)
      : sortStatus.remove(name)

    const sortString = newStatus.reduce((acc, value, key) => {
      const prefix = acc ? ',' : ''

      if (value === SORT.DESCENDING) {
        return acc + prefix + '-' + key
      } else {
        return acc + prefix + key
      }
    }, '')
    setSortStatus(newStatus)

    /* onSortChange is possibly undefined as it isn't a required prop if the
    Table isn't using any sort columns. Though if we at any point would invoke
    updateSortStatus, it has to exist. */
    onSortChanged?.(sortString)
  }
  /* END SORT HANDLING */
  return (
    <Table {...tableProps} className={className} id={id}>
      <TableHead>
        <TableRow rowProps={{ head: true }}>
          {visibleColumns.map(column => (
            <TableHeaderCell
              key={column.id}
              sortedStatus={sortStatus.get(column.sortKey || column.id)}
              cursor={column.sortable ? 'pointer' : ''}
              onClick={() => {
                if (column.sortable) {
                  updateSortStatus(column.sortKey || column.id)
                }
              }}
              width={column.isMenu ? '1px' : 'auto'}
              padding={column.isMenu ? '0  1.5625rem 0 0' : '0  1.5625rem'}
              {...(column.headerProps || {})}
              {...headerProps}
            >
              {column.sortable && (
                <TableHeaderCellSortIcon
                  sortStatus={sortStatus.get(column.sortKey || column.id)}
                />
              )}
              {column.filterComponent ? column.filterComponent : column.label}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody {...bodyProps}>
        {data.map(row => {
          if (isFunction(row.data)) {
            return <TableRow key={row.id}>{row.data(visibleColumns)}</TableRow>
          }

          return (
            <TableRow key={row.id} rowProps={{ ...row.props }}>
              {visibleColumns.map(column => {
                const columnValue = (row.data as { [key: string]: number })[
                  column.id
                ]
                if (isFunction(columnValue)) {
                  return columnValue()
                }
                return (
                  <TableCell
                    key={column.id}
                    width={column.width ?? (column.isMenu ? '1px' : undefined)}
                    padding={column.isMenu ? '0 1.5625rem 0 0' : '0  1.5625rem'}
                    style={column.cellStyle}
                    onClick={row.props?.onClick}
                  >
                    {columnValue}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        {loading && (
          <TableContentLoader columns={columns.length} {...loadingProps} />
        )}
        {!loading && data.length === 0 && noDataText && (
          <TableRow>
            <TableCell colSpan={9999}>
              <NoData>{noDataText}</NoData>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export const QuoTableCard: React.FC<QuoTableCardProps> = ({
  cardProps,
  tableProps = {},
  onSortChanged = identity,
  ...rest
}) => {
  return (
    <Card {...cardProps}>
      <QuoTable {...rest} />
    </Card>
  )
}
