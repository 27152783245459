import styled from 'styled-components'

import { useGlobal, useForm, useDebounce } from '@ur/react-hooks'
import { OrSeparator } from './OrSeparator'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_CUSTOMER_MUTATION,
  PATCH_CUSTOMER_MUTATION,
} from 'modules/customers/mutations'
import { Input } from 'components/Input'
import {
  CreateCustomerMutationParameters,
  CreateCustomerMutationReturn,
  CustomerNode,
  PatchCustomerMutationParameters,
  PatchCustomerMutationReturn,
} from 'types/graphql/customers'
import { Button } from 'components/Button'
import { useEffect, useState } from 'react'
import { ALL_CUSTOMERS_WITH_PHONELOOKUP_QUERY } from 'modules/customers/queries'
import { RadioGroup, RadioItem, Icon } from '@ur/react-components'
import { isValidPhoneNumber } from 'util/helpers'
import { useToast } from '@ur/react-components'
import {
  AllCustomersQueryParameters,
  AllCustomersWithPhoneLookupQueryReturn,
} from 'types/graphql/customers'
import { searchForOrganization } from './util'
import SearchBar2 from 'components/SearchBar/SearchBar2'
import useOnErrorAuto from 'util/hooks/error-handling'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'search search . .'
    'selected selected . .'
    'orseparator orseparator . .'
    'label label . .'
    'form form . .';
  width: 800px;
  grid-column-gap: 39px;
  grid-template-columns: 215px 215px 2fr 2fr;
  height: auto;

  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 0 15px;
    grid-template-areas:
      'search search'
      'selected selected'
      'orseparator orseparator'
      'label label'
      'form form';
    grid-template-columns: 1fr 2fr;
  }
`

const SearchWrapper = styled.div`
  grid-area: search;
`

const SelectedCustomerCard = styled.div`
  grid-area: selected;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`

const SelectedCustomerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SelectedCustomerLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0 10px;
  color: ${props => props.theme.colors.darkBlue};
`

const SelectedCustomerName = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.darkBlue};
`

const NewCustomerLabel = styled.label`
  grid-area: label;
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  margin: 25px 0;
`

const FormWrapper = styled.div`
  grid-area: form;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  > :nth-child(2n) {
    margin: 15px 0;
  }

  > :last-child {
    margin: 30px 0px;
  }
`

type CustomerType = 'COMPANY' | 'PRIVATE' | 'ELEKSPRESS'
interface NewCustomerForm {
  name: string
  address: string
  email: string
  phoneNumber: string
  postalCode: string
  city: string
  type: CustomerType
  organizationNumber: string
}

const CustomerTypeWrapper = styled.div`
  display: flex;
  height: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

interface StyledInputProps {
  hide?: boolean
}

const StyledInput = styled(Input)<StyledInputProps>`
  display: ${props => (props.hide ? 'none' : 'inline-flex')};
  input {
    background-color: ${props => props.theme.colors.white};
  }
`

const customerType: RadioItem<CustomerType>[] = [
  {
    value: 'PRIVATE',
    label: 'Privat',
  },
  {
    value: 'COMPANY',
    label: 'Selskap',
  },
]

export const AddOrCreateCustomer = () => {
  const [search, setSearch] = useState('')
  const [customer, setCustomer] = useGlobal('offer.customer')
  const [customerResults, setCustomerResult] = useState<CustomerNode[]>([])
  const [, setWizardState] = useGlobal('offer.wizardState')
  const debouncedSearch = useDebounce(search)

  const addToast = useToast()

  const {
    formValid,
    formValues: form,
    updateForm,
    formErrors: errors,
    submitHandler,
    updateFormErrors,
  } = useForm<NewCustomerForm>({
    values: {
      name: customer?.name ?? '',
      address: customer?.address ?? '',
      email: customer?.email ?? '',
      phoneNumber: customer?.phoneNumber ?? '',
      postalCode: customer?.postalCode ?? '',
      city: customer?.city ?? '',
      type: customer?.type ?? 'PRIVATE',
      organizationNumber: customer?.organizationNumber ?? '',
    },
    validators: {
      organizationNumber: (value, { type }) => {
        if (type === 'PRIVATE') return null

        return !!value
          ? value.length === 9
            ? null
            : 'Må være 9 siffer langt'
          : 'Kan ikke være tom'
      },
      name: value => (!!value ? null : 'Kan ikke være tom'),
      address: value => (!!value ? null : 'Kan ikke være tom'),
      email: value => (!!value ? null : 'Kan ikke være tom'),
      phoneNumber: value =>
        isValidPhoneNumber(value) ? null : 'Må være gydlig telefonnummer',
      postalCode: value => (!!value ? null : 'Kan ikke være tom'),
      city: value => (!!value ? null : 'Kan ikke være tom'),
    },
  })

  const [customerResultQuery, { loading: loadingCustomers }] = useLazyQuery<
    AllCustomersWithPhoneLookupQueryReturn,
    AllCustomersQueryParameters
  >(ALL_CUSTOMERS_WITH_PHONELOOKUP_QUERY, {
    onError: useOnErrorAuto,
    variables: { q: debouncedSearch, typeIn: ['private', 'company'] },
    onCompleted(data) {
      const customers =
        data.allCustomersWithPhoneLookup.edges.map(edge => edge.node) ?? []
      setCustomerResult(customers)
    },
  })

  useEffect(() => {
    if (debouncedSearch === '') {
      setCustomerResult([])
      return
    }

    customerResultQuery({
      variables: { q: debouncedSearch, typeIn: ['private', 'company'] },
    })
  }, [debouncedSearch, customerResultQuery, setCustomerResult])

  const [createCustomer, { loading: mutationLoading }] = useMutation<
    CreateCustomerMutationReturn,
    CreateCustomerMutationParameters
  >(CREATE_CUSTOMER_MUTATION, {
    onCompleted: ({ createCustomer }) => {
      const { customer } = createCustomer
      const newCustomer = { id: customer.id, name: customer.name }
      setCustomer(newCustomer)
      addToast('success', `Kunde ${newCustomer.name} opprettet`)
      setWizardState('Vilkår')
    },
    onError: () => {
      addToast('error', 'En feil har oppstått')
    },
    refetchQueries: ['AllCustomers', 'AllCustomersShallow'],
  })

  const [patchCustomer, { loading: patchLoading }] = useMutation<
    PatchCustomerMutationReturn,
    PatchCustomerMutationParameters
  >(PATCH_CUSTOMER_MUTATION, {
    onCompleted: ({ patchCustomer }) => {
      const { customer } = patchCustomer
      setCustomer(customer)
      addToast('success', `Kunde ${customer.name} oppdatert`)
      setWizardState('Vilkår')
    },
    onError: () => {
      addToast('error', 'En feil har oppstått')
    },
    refetchQueries: ['AllCustomers', 'AllCustomersShallow'],
  })

  const submit = () => {
    if (form.type === 'PRIVATE') {
      updateForm({ organizationNumber: null })
    }

    createCustomer({
      variables: {
        input: form,
      },
    })
  }

  const submitUpdate = () => {
    if (form.type === 'PRIVATE') {
      updateForm({ organizationNumber: null })
    }

    // Otherwise patch the customer
    patchCustomer({
      variables: {
        id: customer.id,
        input: form,
      },
    })
  }

  const handleResetCustomer = () => {
    setCustomer(null)
    // Updating instead of resetting as the initial values may be a selected customer
    updateForm({
      name: '',
      address: '',
      email: '',
      phoneNumber: '',
      postalCode: '',
      city: '',
      type: 'PRIVATE',
      organizationNumber: '',
    })
    updateFormErrors({}) // Clear errors which will be set by the form on updating
  }

  const handleNext = () => {
    if (customer) {
      // Check if the formData is equivalent to the customer data
      if (
        form.name === customer.name &&
        form.address === customer.address &&
        form.email === customer.email &&
        form.phoneNumber === customer.phoneNumber &&
        form.postalCode === customer.postalCode &&
        form.city === customer.city
      ) {
        setWizardState('Vilkår')
      } else {
        submitHandler(submitUpdate)()
      }
    } else {
      submitHandler(submit)()
    }
  }

  function handleOrganizationNumberChange(organizationNumber: string) {
    if (organizationNumber.length === 9) {
      searchForOrganization(organizationNumber)
        .then(data => {
          updateForm({
            ...data,
            organizationNumber,
          })
        })
        .catch(() => {
          updateFormErrors({
            organizationNumber: 'Selskapet finnes ikke.',
          })
        })
    }
    updateForm({ organizationNumber })
  }

  return (
    <Wrapper>
      <SearchWrapper>
        <SearchBar2
          search={search}
          onSearchChanged={setSearch}
          loading={loadingCustomers}
          onSelect={(id, customer) => {
            setCustomer(customer)
            updateForm({
              name: customer.name,
              address: customer.address,
              email: customer.email,
              phoneNumber: customer.phoneNumber,
              postalCode: customer.postalCode,
              city: customer.city,
              type: customer.organizationNumber ? 'COMPANY' : 'PRIVATE',
              organizationNumber: customer.organizationNumber ?? '',
            })
          }}
          placeholder="Søk etter kunde..."
          layoutStyling={{
            margin: '0 0 1rem 0',
          }}
          options={customerResults.map(customer => ({
            value: customer.id,
            label: customer.name,
            extra: customer,
          }))}
        />
      </SearchWrapper>
      {customer && (
        <SelectedCustomerCard>
          <SelectedCustomerInfoWrapper>
            <SelectedCustomerLabel>Valgt kunde</SelectedCustomerLabel>
            <SelectedCustomerName>{customer.name}</SelectedCustomerName>
          </SelectedCustomerInfoWrapper>

          <Icon
            icon="times"
            size="25px"
            type="light"
            color="darkBlue"
            margin="0 5px 0 0"
            hoverColor="highlight"
            cursor="pointer"
            onClick={handleResetCustomer}
          />
        </SelectedCustomerCard>
      )}
      <OrSeparator gridArea="orseparator" />
      <NewCustomerLabel>
        {customer ? 'Endre kunde' : 'Opprett ny kunde'}
      </NewCustomerLabel>
      <FormWrapper>
        <CustomerTypeWrapper>
          <RadioGroup
            value={form.type}
            fontSize="14px"
            layout={{ direction: 'horizontal', type: 'flex', gap: '5px' }}
            onChange={type => updateForm({ type })}
            radios={customerType}
          />
          <StyledInput
            error={errors.organizationNumber}
            fullWidth
            hide={form.type === 'PRIVATE'}
            type="number"
            maxLength={9}
            placeholder="Organisasjonsnummer"
            value={form.organizationNumber}
            onChange={handleOrganizationNumberChange}
          />
        </CustomerTypeWrapper>
        <StyledInput
          fullWidth
          label="Navn"
          value={form.name}
          error={errors.name}
          onChange={name => updateForm({ name })}
        />
        <StyledInput
          fullWidth
          label="Adresse"
          value={form.address}
          error={errors.address}
          onChange={address => updateForm({ address })}
        />
        <StyledInput
          fullWidth
          label="E-post"
          value={form.email}
          error={errors.email}
          onChange={email => updateForm({ email })}
        />
        <StyledInput
          fullWidth
          label="Telefonnummer"
          value={form.phoneNumber}
          error={errors.phoneNumber}
          onChange={phoneNumber => updateForm({ phoneNumber })}
        />
        <StyledInput
          fullWidth
          label="Postkode"
          type="number"
          maxLength={4}
          value={form.postalCode}
          error={errors.postalCode}
          onChange={postalCode => updateForm({ postalCode })}
        />
        <StyledInput
          fullWidth
          label="By"
          value={form.city}
          error={errors.city}
          onChange={city => updateForm({ city })}
        />
        <Button
          buttonStyle="secondary"
          onClick={handleNext}
          fullWidth
          loading={mutationLoading || patchLoading}
          disabled={!formValid && !customer}
        >
          Neste
        </Button>
      </FormWrapper>
    </Wrapper>
  )
}
