import { Button, Checkbox } from '@ur/react-components'
import ScrollBar from 'react-perfect-scrollbar'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'

interface FilterButtonProps {
  open?: boolean
}
export const FilterButton = styled(Button).attrs({
  centerContent: false,
})<FilterButtonProps>`
  position: relative;

  width: 100%;
  padding: 0;
  background: inherit;
  border: none;
  color: ${props => props.theme.colors.gray3};
  font-weight: 400;
  font-size: 0.875rem;
  white-space: nowrap;
  margin-bottom: 20px;

  ${props => props.theme.media.mobile} {
    .--button-content {
      display: none;
    }
  }

  .--button-icon-left {
    border: none;
    background-color: ${props => props.theme.colors.gray3};
    color: ${props => props.theme.colors.white};
    border-radius: 4px;
    size: 0.875rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.gray5};
  }

  &:focus {
    filter: none;
    color: ${props => props.theme.colors.highlight};
  }
  ${props =>
    props.open &&
    css`
      background-color: ${props => props.theme.colors.gray5};

      ${props => props.theme.media.mobile} {
        .--button-content {
          display: flex;
        }
      }
    `}
`
export const Popup = styled.aside`
  position: absolute;
  z-index: ${ZIndexRange.Dropdowns};
  top: -2rem;
  right: -2rem;
  right: -2rem;

  display: grid;
  grid-template-rows: auto 1fr;

  min-width: calc(100% + 4rem);
  padding: 2rem 2rem 1rem;

  text-align: left;
  background: white;
  border-radius: 10px;
  box-shadow: ${props => props.theme.shadow.default};
  cursor: default;
  user-select: text;
`
export interface CheckBoxProps {
  bold?: boolean
}

export const StyledCheckbox = styled(Checkbox)<CheckBoxProps>`
  .--checkbox-label {
    line-height: 40px;
    font-size: 0.875rem;
    margin-left: 8px;
    color: ${props => props.theme.colors.darkBlue};
    ${props =>
      props.checked &&
      css`
        font-weight: 600;
      `}
  }
`

export const FilterCheckmarksWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  overscroll-behavior: none;
`

export const StyledScrollBar = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 0.3rem;
  }
`
