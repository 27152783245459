import { Thumbnail } from '@ur/react-components'
import { OrderItemAttachmentNode } from 'modules/services/types.graphql'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 75px);
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  .--placeholder {
    grid-column: span 2;
  }
`

const Attachment = styled(Thumbnail)`
  img {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.gray5};
    &:hover {
      box-shadow: ${props => props.theme.shadow.input};
    }
  }
`

interface OrderItemAttachmentsCellProps {
  attachments: OrderItemAttachmentNode[]
}

export const OrderItemAttachmentsCell: React.UFC<
  OrderItemAttachmentsCellProps
> = ({ attachments }) => {
  return (
    <Wrapper>
      {attachments.length > 0 ? (
        attachments.map(attachment => (
          <a href={attachment.url} target="_blank" rel="noreferrer">
            <Attachment
              key={attachment.id}
              src={attachment.url}
              alt={attachment.name}
              width="75"
              height="50"
              shape="rectangle"
            />
          </a>
        ))
      ) : (
        <div className="--placeholder">Ingen vedlegg.</div>
      )}
    </Wrapper>
  )
}
