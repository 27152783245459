import PrivateRoute from 'containers/PrivateRoute'
import { SelectCompany } from 'modules/companies/SelectCompany'
import Login, { ForgottenPassword, ResetPassword } from 'modules/login'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

export default function NoCompanyRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute path="/select-company" component={SelectCompany} />
        <Redirect from="" to="/select-company" />
      </Switch>
    </Router>
  )
}
