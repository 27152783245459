import { useQuery } from '@apollo/client'
import Loader from 'components/Loader'
import Margin from 'components/Margin'
import { NoCompanies, SelectCompanyTile } from 'modules/companies/SelectCompany'
import styled from 'styled-components'
import { MY_COMPANIES } from '../queries'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  background: ${props => props.theme.colors.background};
  min-height: 100vh;
`
const TilesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
`

export const SelectCompany: React.UFC = () => {
  const { loading, data } = useQuery(MY_COMPANIES)

  if (loading) {
    return (
      <Margin top="3em">
        <Loader />
      </Margin>
    )
  }

  const me = data.me

  if (!me) {
    window.location.href = '/login'
  }

  const companies = me.companies.edges

  if (companies.length === 0) {
    return <NoCompanies />
  }

  // Redirect here automatically
  if (companies.length === 1) {
    const company = companies[0].node
    window.location.href = `/c/${company.shortName}`
    return
  }

  return (
    <Wrapper>
      <h2>Velg selskap</h2>
      <TilesWrapper>
        {companies.map(edge => (
          <SelectCompanyTile company={edge.node} key={edge.node.id} />
        ))}
      </TilesWrapper>
    </Wrapper>
  )
}
