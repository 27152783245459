import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

const InputError = styled.div`
  position: absolute;
  top: -16px;
  height: 16px;
  line-height: 16px;
  right: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.red};
`

const InputWrapper = styled.span`
  display: block;
  position: relative;
  *,
  *::before,
  *::after {
    font-family: 'Work Sans';
  }
`

interface StyledInputProps {
  fontSize: string
  border: string
  shadow?: string
  width: string
  height: string
  background?: string
  error: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  font-size: ${props => props.fontSize};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 11px;

  border: ${props => `1px solid ${props.theme.colors.darkBlue}`};
  border-radius: 5px;
  outline: none;

  width: ${props => props.width};
  height: ${props => props.height || '40px'};

  color: ${props => props.theme.colors.text.normal};
  font-weight: 400;
  line-height: 22px;

  ${props => (props.background ? `background: ${props.background};` : '')}

  ${props =>
    props.error
      ? `
    color: ${props.theme.colors.red};
    border-color: ${props.theme.colors.red};
  `
      : ''}

  ::placeholder {
    color: ${props => props.theme.colors.gray3};
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text.normal};
  }
`

type InputMode =
  | 'text'
  | 'none'
  | 'decimal'
  | 'email'
  | 'tel'
  | 'url'
  | 'numeric'
  | 'search'

interface InputProps {
  id?: string
  className?: string
  value: string
  placeholder?: string
  type?: string
  autoFocus?: boolean
  error?: string | null
  inputMode?: InputMode

  background?: string
  width?: string
  height?: string
  shadow?: string
  border?: string
  fontSize?: string

  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      className,
      value,
      placeholder,
      type = 'text',
      autoFocus = false,
      error = null,
      inputMode,

      background,
      width = '300px',
      height,
      shadow = theme.shadow.default,
      border,
      fontSize = '14px',

      onKeyDown,
      onChange,
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper>
        {error !== null && <InputError>{error}</InputError>}
        <StyledInput
          ref={ref}
          className={className}
          id={id}
          value={value}
          placeholder={placeholder}
          type={type}
          inputMode={inputMode}
          autoFocus={autoFocus}
          background={background}
          width={width}
          height={height}
          shadow={shadow}
          border={border}
          fontSize={fontSize}
          error={!!error}
          onChange={onChange}
          onKeyDown={onKeyDown}
          {...props}
        />
      </InputWrapper>
    )
  }
)

export default Input
