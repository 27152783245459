import React, { useState } from 'react'
import styled from 'styled-components'
import { TagsDisplay } from '.'

const Wrapper = styled.div`
  & > * + * {
    margin-top: 10px;
  }
`

const TagInput = styled.input`
  background: none;
  border: none;
  font-size: 14px;
  margin-bottom: 10px;
  min-width: 33%;
  &:focus {
    outline: none;
  }
`

interface TagsInputProps {
  tags: string[]
  placeholder?: string
  onChange: (tags: string[]) => void
}

export const TagsInput: React.UFC<TagsInputProps> = ({
  tags,
  placeholder = 'Legg til en ny tag',
  onChange: onTagsChange,
}) => {
  const [input, setInput] = useState('')
  const [isKeyReleased, setIsKeyReleased] = useState(false)

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    const { value } = e.currentTarget
    setInput(value.toLowerCase())
  }

  function onKeyDown(e: React.KeyboardEvent) {
    const { key } = e
    const trimmedInput = input.trim()
    const validInput = trimmedInput.length && !tags.includes(trimmedInput)

    if (key === ',' && validInput) {
      e.preventDefault()
      onTagsChange([...tags, trimmedInput])
      setInput('')
    }

    if (key === 'Enter' && validInput) {
      e.preventDefault()
      onTagsChange([...tags, trimmedInput])
      setInput('')
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      e.preventDefault()
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()

      onTagsChange(tagsCopy)
      setInput(poppedTag)
    }
    setIsKeyReleased(false)
  }

  function onKeyUp() {
    setIsKeyReleased(true)
  }

  function deleteTag(index: number) {
    onTagsChange(tags.filter((_tag, i) => i !== index))
  }

  return (
    <Wrapper>
      <TagsDisplay tags={tags} onDelete={deleteTag}>
        <TagInput
          value={input}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      </TagsDisplay>
    </Wrapper>
  )
}
