import { gql } from '@apollo/client'

export const UserFragments = {
  info: gql`
    fragment UserInfo on UserNode {
      id
      firstName
      lastName
      fullName
      hasRegistered
      needsPasswordChange
      isSuperuser
      isActive
      isStaff
      address
      postalCode
      city
      phoneNumber
      initials
      username
      email
      profilePicture
      position
      companies {
        edges {
          node {
            id
            shortName
            fullName
          }
        }
      }
    }
  `,
  userTypeInfo: gql`
    fragment UserTypeInfo on UserTypeNode {
      id
      name
      defaultPricelist {
        id
      }
      permissions
    }
  `,
  types: gql`
    fragment UserTypes on UserNode {
      userTypes {
        id
        name
      }
    }
  `,
  companies: gql`
    fragment Companies on UserNode {
      companies {
        edges {
          node {
            id
            shortName
            fullName
          }
        }
      }
    }
  `,
}

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserInfo
      userTypes {
        ...UserTypeInfo
      }
      allPermissions
    }
  }
  ${UserFragments.info}
  ${UserFragments.userTypeInfo}
`

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserInfo
      ...UserTypes
      ...Companies
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
  ${UserFragments.companies}
`

export const USER_QUERY_DEEP = gql`
  query UserDeep($id: ID!) {
    user(id: $id) {
      ...UserInfo
      ...UserTypes
    }
  }
`

export const USERS_QUERY = gql`
  query Users($q: String, $isActive: Boolean) {
    allUsers(q: $q, isActive: $isActive) {
      edges {
        node {
          ...UserInfo
          ...UserTypes
          ...Companies
        }
      }
    }
  }
  ${UserFragments.info}
  ${UserFragments.types}
  ${UserFragments.companies}
`

export const USER_TYPES_QUERY = gql`
  query UserTypes {
    allUserTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const ALL_USERS_SHALLOW_QUERY = gql`
  query AllUsersShallow($q: String) {
    allUsers(q: $q) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`
