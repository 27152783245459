import { gql } from '@apollo/client'
import { RoomTemplateFragments } from './fragments'

export const CREATE_ROOM_TEMPLATE_MUTATION = gql`
  mutation CreateRoomTemplate(
    $name: String!
    $floor: Int!
    $area: Int!
    $floorConditions: [CreateRoomTemplateConditionInput]!
    $areaConditions: [CreateRoomTemplateConditionInput]!
    $pricelist: ID!
    $titles: [CreateRoomTemplateTitleInput]!
    $options: [CreateRoomTemplateOptionInput]!
    $formulas: [CreateRoomTemplateFormulaInput]!
  ) {
    createRoomTemplate(
      name: $name
      floor: $floor
      area: $area
      floorConditions: $floorConditions
      areaConditions: $areaConditions
      pricelist: $pricelist

      titles: $titles
      options: $options
      formulas: $formulas
    ) {
      ok
      roomTemplate {
        id
        name
        area
        floor
      }
    }
  }
`

export const UPDATE_ROOM_TEMPLATE_MUTATION = gql`
  mutation UpdateRoomTemplate(
    $id: ID!
    $name: String!
    $floor: Int!
    $area: Int!
    $floorConditions: [CreateRoomTemplateConditionInput]!
    $areaConditions: [CreateRoomTemplateConditionInput]!
    $pricelist: ID!
    $titles: [CreateRoomTemplateTitleInput]!
    $options: [CreateRoomTemplateOptionInput]!
    $formulas: [CreateRoomTemplateFormulaInput]!
  ) {
    updateRoomTemplate(
      id: $id
      name: $name
      floor: $floor
      area: $area
      floorConditions: $floorConditions
      areaConditions: $areaConditions
      pricelist: $pricelist

      titles: $titles
      options: $options
      formulas: $formulas
    ) {
      roomTemplate {
        ...RoomTemplateInfo
      }
    }
  }
  ${RoomTemplateFragments.roomTemplateInfo}
`

export const DELETE_ROOM_TEMPLATE_MUTATION = gql`
  mutation DeleteRoomTemplate($id: ID!) {
    deleteRoomTemplate(id: $id) {
      found
      deletedId
    }
  }
`
