import { OrderStatusCell, StyledQuotable } from 'modules/services'
import { OrderNodeMeta, ShallowOrderNode } from 'modules/services/types.graphql'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'util/date-fns'

interface OrdersTableProps {
  orders: ShallowOrderNode[]
}

export const OrdersTable: React.UFC<OrdersTableProps> = ({ orders }) => {
  const columns = [
    { id: 'orderNumber', label: 'NR' },
    { id: 'createdAt', label: 'Tid' },
    { id: 'status', label: 'Status' },
    { id: 'services', label: 'Tjenester' },
    { id: 'customer', label: 'Kunde' },
    { id: 'price', label: 'Totalpris' },
  ]

  function getFormattedServices(
    services: OrderNodeMeta[],
    threshold: number = 2
  ) {
    const serviceNames = services.map(service => service.name)
    if (services.length > threshold) {
      const displayServices = serviceNames.slice(0, threshold)
      return displayServices.join(', ') + `, +${services.length - threshold}`
    }
    return serviceNames.join(', ')
  }

  const ordersData = useMemo(() => {
    return orders.map(order => {
      const serviceNames = getFormattedServices(
        order.items.edges.map(edge => edge.node.service),
        2
      )
      return {
        id: order.id,
        data: {
          orderNumber: (
            <Link to={`/orders/${order.uniqueId}`}>{order.orderNumber}</Link>
          ),
          createdAt: (
            <Link to={`/orders/${order.uniqueId}`}>
              {format(new Date(order.createdAt), 'dd. MMM, yyyy, hh:mm')}
            </Link>
          ),
          status: (
            <Link to={`/orders/${order.uniqueId}`}>
              <OrderStatusCell order={order} />
            </Link>
          ),
          services: (
            <Link to={`/orders/${order.uniqueId}`}>{serviceNames}</Link>
          ),
          customer: (
            <Link to={`/orders/${order.uniqueId}`}>{order.customer.name}</Link>
          ),
          price: (
            <Link to={`/orders/${order.uniqueId}`}>
              {order.items.edges.reduce((acc, edge) => {
                return acc + edge.node.price
              }, 0)}
              {' kr'}
            </Link>
          ),
        },
      }
    })
  }, [orders])

  return (
    <StyledQuotable
      columns={columns}
      data={ordersData}
      tableProps={{ fullWidth: true, tableLayout: 'auto' }}
    />
  )
}
