import React from 'react'
import { CompanyNode } from 'modules/companies/types.graphql'

export const CompanyContext = React.createContext<CompanyNode | null>(null)

export function withCompany<T>(Component: React.FC<T>) {
  return function WrapperComponent(props: T) {
    return (
      <CompanyContext.Consumer>
        {company => <Component {...props} company={company} />}
      </CompanyContext.Consumer>
    )
  }
}

export default CompanyContext
