import React from 'react'
import styled, { css } from 'styled-components'
import { CSSResize } from 'types/style'

interface WrapperProps {
  width: string
}
const Wrapper = styled.div<WrapperProps>`
  width: ${props => props.width};
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
`

interface StyledTextAreaProps {
  resize: CSSResize
  minHeight: string
  padding: string
  border: string
  borderRadius: string
  noShadow: boolean
  disabled?: boolean
}
const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  width: 100%;
  min-height: ${props => props.minHeight};

  ${props => !props.noShadow && `box-shadow: ${props.theme.shadow.default};`}
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};

  resize: ${props => props.resize};
  padding: ${props => props.padding};

  box-sizing: border-box;
  outline: none;

  color: ${props => props.theme.colors.primary};

  :focus {
    border: 1px solid ${props => props.theme.colors.primary};
  }

  ${props =>
    props.disabled &&
    css`
      background-color: white;
      overflow: hidden;
    `}
`

interface TextAreaProps {
  value: string

  resize?: CSSResize
  minHeight?: string
  width?: string
  padding?: string
  border?: string
  borderRadius?: string
  noShadow?: boolean
  disabled?: boolean

  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void
}
const TextArea: React.FC<TextAreaProps> = ({
  value,

  resize = 'vertical',
  minHeight = '80px',
  width = '300px',
  padding = '14px 17px',
  noShadow = false,
  border = 'none',
  borderRadius = '4px',
  disabled = false,

  onChange,
}) => {
  return (
    <Wrapper width={width}>
      <StyledTextArea
        value={value}
        resize={resize}
        minHeight={minHeight}
        padding={padding}
        noShadow={noShadow}
        border={border}
        borderRadius={borderRadius}
        disabled={disabled}
        onChange={onChange}
      />
    </Wrapper>
  )
}
export default TextArea
