import { Icon } from '@ur/react-components'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/enums'
import { OfferStatus, OfferStatusArray } from 'types/graphql/offers'
import useClickOutside from 'util/hooks/useClickOutside'
import { StatusFilterList } from './StatusFilterList'

const Wrapper = styled.div<TableHeaderFilterIconProps>`
  margin-left: 3px;
  grid-area: filter;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  color: ${props =>
    props.activeFilter ? props.theme.colors.purple : props.theme.colors.gray3};

  :hover {
    color: ${props => props.theme.colors.purple};
    cursor: pointer;
  }
`

const PopupContainer = styled.aside`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: absolute;
  top: 32px;
  right: 0;
  width: 250px;
  height: 300px;
  z-index: ${ZIndexRange.Dropdowns};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.default};
`

const PopupControllerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px 15px;

  i {
    color: ${props => props.theme.colors.gray3};
    align-self: flex-end;
  }

  i:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.primary};
  }
`

const ActiveFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -130%;
  right: 50%;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.purple};

  &:hover {
    div {
      display: flex;
      cursor: pointer;
    }
  }
`
const StyledIcon = styled.i`
  font-size: 1rem;
`

const RemoveFilters = styled.div`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.purple};
  border-radius: 100%;
  width: 100%;
  height: 100%;
  z-index: ${ZIndexRange.Dropdowns};
`

interface FilterIconButtonProps {
  label: string
  statuses: OfferStatus[]
  setStatusFilter: React.Dispatch<React.SetStateAction<OfferStatus[]>>
}

interface TableHeaderFilterIconProps {
  activeFilter: boolean
}

export const FilterIconButton: React.VFC<FilterIconButtonProps> = ({
  label,
  statuses,
  setStatusFilter,
}) => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const handleClosePopup = () => {
    setOpen(false)
  }
  useClickOutside(menuRef, handleClosePopup)

  const togglePopup = () => {
    setOpen(!open)
  }

  const handleClearFilters = () => {
    handleClosePopup()
    setStatusFilter(OfferStatusArray)
  }

  return (
    <Wrapper
      ref={menuRef}
      onClick={togglePopup}
      activeFilter={statuses.length !== OfferStatusArray.length}
    >
      {label}
      <StyledIcon className={'fa fa-filter'} />
      {statuses.length > 0 && statuses.length !== OfferStatusArray.length && (
        <ActiveFilters>
          {statuses.length}
          <RemoveFilters onClick={handleClearFilters}>
            <Icon icon="times" size="18px" />
          </RemoveFilters>
        </ActiveFilters>
      )}
      {open && (
        <PopupContainer>
          <PopupControllerWrapper>
            <Icon
              margin="4px"
              icon="times"
              size="24px"
              type="light"
              onClick={togglePopup}
            />
          </PopupControllerWrapper>
          <StatusFilterList
            setStatusFilterCallback={setStatusFilter}
            statuses={statuses}
          />
        </PopupContainer>
      )}
    </Wrapper>
  )
}
