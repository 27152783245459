import { useMutation } from '@apollo/client'
import { Icon, useToast } from '@ur/react-components'
import { RequiredOnly } from '@ur/react-components/build/types/util'
import PermissionsRequired from 'containers/PermissionsRequired/PermissionsRequired'

import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/enums'
import { ArticleNode } from 'types/graphql/articles'
import useClickOutside from 'util/hooks/useClickOutside'
import { DELETE_ARTICLE_MUTATION } from './mutations'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  cursor: pointer;
`
const CloseMenuContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px 0 0;
`

const Menu = styled.aside`
  position: absolute;
  display: flex;
  width: 300px;
  flex-direction: column;
  font-weight: 600;
  top: calc(100% + 8px);
  right: calc(100% - 24px);
  z-index: ${ZIndexRange.Dropdowns};
  user-select: none;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f7f8fc;
`

interface MobileHighlight {
  mobileHighlight: boolean
}

const MenuItem = styled.div<MobileHighlight>`
  display: flex;
  flex-direction: row;
  padding: 0 0 34px 20px;
  cursor: pointer;
  align-items: center;
  border: 0px;
  color: ${props => props.theme.colors.gray2} !important;

  &:hover {
    color: ${props => props.theme.colors.darkBlue} !important;
  }

  i {
    padding-right: 7px;
  }

  ${props => props.theme.media.mobile} {
    ${props =>
      props.mobileHighlight &&
      css`
        color: ${props => props.theme.colors.darkBlue} !important;
      `}
  }
`

interface ArticleDropdownProps {
  article: RequiredOnly<ArticleNode, 'id'>
  open: boolean
  onBarsClick: () => void
  onEdit: () => void
  onClose: () => void
}

export const ArticleDropdown: React.FC<ArticleDropdownProps> = ({
  open,
  article,
  onBarsClick,
  onEdit,
  onClose,
}) => {
  const toasts = useToast()
  const menuRef = useRef<HTMLElement | null>(null)

  useClickOutside(menuRef, onClose)

  const [deleteArticle] = useMutation(DELETE_ARTICLE_MUTATION, {
    refetchQueries: ['Pricelist'],
    onCompleted: () => {
      toasts('success', 'Varen er slettet')
    },
    onError: () => {
      toasts('error', 'Kunne ikke slette varen')
    },
  })

  function handleDeleteArticle() {
    deleteArticle({
      variables: {
        id: article.id,
      },
    })
  }

  return (
    <Wrapper>
      <Icon
        icon="ellipsis-v"
        color="black"
        size="20px"
        type="solid"
        onClick={onBarsClick}
      />

      {open && (
        <Menu ref={menuRef}>
          <CloseMenuContainer onClick={onClose}>
            <Icon color="darkBlue" icon="times" size="24px" type="light" />
          </CloseMenuContainer>

          <PermissionsRequired permissions="articles.change_article">
            <MenuItem mobileHighlight={true} onClick={onEdit}>
              <Icon icon="edit" type="solid" /> Endre
            </MenuItem>
          </PermissionsRequired>

          <PermissionsRequired permissions="articles.delete_article">
            <MenuItem mobileHighlight={false} onClick={handleDeleteArticle}>
              <Icon icon="trash-alt" type="solid" />
              Slett
            </MenuItem>
          </PermissionsRequired>
        </Menu>
      )}
    </Wrapper>
  )
}
