type CSSBaseValues = 'inherit' | 'unset' | 'initial'

export type CSSTextAlign = 'left' | 'center' | 'right' | CSSBaseValues

export type CSSFontWeight =
  | 'lighter'
  | 'normal'
  | 'bolder'
  | 'bold'
  | number
  | CSSBaseValues

export type CSSFontStyle = 'normal' | 'italic' | 'oblique' | CSSBaseValues

export type CSSTextTransform =
  | 'none'
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'inherit'

export type CSSBorderCollapse = 'collapse' | 'separate' | CSSBaseValues

export type CSSCursor =
  | 'auto'
  | 'default'
  | 'none'
  | 'context-menu'
  | 'help'
  | 'pointer'
  | 'progress'
  | 'wait'
  | 'cell'
  | 'crosshair'
  | 'text'
  | 'vertical-text'
  | 'alias'
  | 'copy'
  | 'move'
  | 'no-drop'
  | 'not-allowed'
  | 'all-scroll'
  | 'col-resize'
  | 'row-resize'
  | 'n-resize'
  | 'e-resize'
  | 's-resize'
  | 'w-resize'
  | 'ns-resize'
  | 'ew-resize'
  | 'ne-resize'
  | 'nw-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'nesw-resize'
  | 'nwse-resize'
  | CSSBaseValues

export type CSSResize =
  | 'none'
  | 'both'
  | 'horizontal'
  | 'vertical'
  | 'block'
  | 'inline'
  | CSSBaseValues

export enum ZIndexRange {
  Loaders = 3000,
  Globals = 4000,
  Sidebar = 4100,
  Header = 4200,
  Dropdowns = 5000,
  FormFields = 6000,
  Overlays = 6500,
  Modals = 7000,
  Toasts = 8000,
  Extra = 9000,
}
