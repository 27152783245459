import backgroundImage from 'assets/images/elman_login_image.jpg'
import styled from 'styled-components'
import FormField from 'components/FormField'
import Label from 'components/Label'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'

export const LoginWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
`

export const LoginSection = styled.div`
  padding: 4rem 3rem;
  background-color: ${props => props.theme.colors.white};
  font-family: 'Work Sans';
  position: relative;
  width: 551px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.theme.media.mobile} {
    padding: 48px 24px;
    width: 100%;
    align-items: flex-start;
  }
`

export const LogoSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center center;
  ${props => props.theme.media.mobile} {
    display: none;
  }
`

export const FormWrapper = styled.div`
  width: 360px;
`

export const LoginLabel = styled(Label)`
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 600;
  font-family: 'Work Sans';
  line-height: 22px;
  text-align: left;
  height: 22px;
`

export const LoginField = styled(FormField)`
  &:focus-within {
    .login-label {
      color: ${props => props.theme.colors.primary};
    }
  }
  margin-bottom: 1em;
`

export const LoginLink = styled(Link)`
  font-size: 14px;
  font-family: 'Work Sans';
  line-height: 22px;
  text-align: right;
  color: ${props => props.theme.colors.darkBlue};
  &:hover {
    text-decoration: underline;
  }
`

export const SubmitButton = styled(Button)`
  border-radius: 10px;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  height: 48px;
  font-family: 'Work Sans';
  line-height: 26px;
  font-size: 18px;
  height: 47px;
  text-transform: none;
  margin-bottom: 1em;
`
