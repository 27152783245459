import React, { useMemo, useCallback, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/Icon'
import useClickOutside from 'util/hooks/useClickOutside'
import { useDebounce } from 'util/hooks'

interface WrapperProps {
  width: string
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  box-sizing: border-box;
  width: ${props => props.width};
`
interface DisplayProps {
  open: boolean
  height: string
}
const Display = styled.div<DisplayProps>`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;

  width: 100%;
  height: ${props => props.height};
  min-height: 35px;
  padding: 6px 6px 2px;

  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  background-color: white;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;

    &.selected {
      flex-wrap: wrap;
    }
    &.icon {
      justify-content: center;
      min-width: 32px;

      i {
        transform: translateY(-2px);
      }
    }
  }

  &:hover {
    div.icon i {
      color: ${props => props.theme.colors.lighterGray};
    }
  }

  ${props =>
    props.open &&
    css`
      border-width: 1px 1px 0;
      border-radius: 4px 4px 0 0;
    `};
`
const SelectedItem = styled.div`
  padding: 6px 8px;
  border-radius: 8px;
  color: white;
  margin-bottom: 4px;
  background: ${props => props.theme.colors.primary};
  cursor: default;

  &:not(:last-child) {
    margin-right: 6px;
  }
  span {
    margin-right: 8px;
  }
  i {
    transform: translateY(1px);
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
`
interface CurtainProps {
  maxHeigth: string
}
const Curtain = styled.div<CurtainProps>`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  max-height: ${props => props.maxHeigth};
  z-index: 4900;
  overflow: hidden;

  background-color: white;
  border-radius: 0 0 4px 4px;
  border: solid ${props => props.theme.colors.lightGray};
  border-width: 0 1px 1px;

  div.search {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 6px;
    width: 100%;

    i {
      padding-left: 8px;
      align-self: center;
      transform: translateY(1px);
    }
    input {
      padding: 4px;
      border: none;
      outline: none;
      font-size: 1rem;
    }
  }
  div.inner {
    max-height: ${props => props.maxHeigth};
    overflow-y: auto;
  }
`
interface CurtainItemProps {
  noHover?: boolean
}
const CurtainItem = styled.div<CurtainItemProps>`
  box-sizing: border-box;
  padding: 8px;
  cursor: default;

  &:hover {
    ${props =>
      !props.noHover &&
      css`
        background-color: ${props => props.theme.colors.veryLightGray};
      `}
  }
`

export interface MultiSelectOption<ValueType extends string | number> {
  value: ValueType
  label: string
  color?: string
  background?: string
}

interface MultiSelectProps<ValueType extends string | number> {
  selected: ValueType[]
  options: MultiSelectOption<ValueType>[]

  width?: string
  height?: string
  curtainMaxHeight?: string

  onChange: (
    selectedItems: ValueType[],
    clickedItem: ValueType,
    type: 'remove' | 'add'
  ) => void
}

const MultiSelect = <ValueType extends string | number>({
  selected,
  options,

  width = 'auto',
  height = 'auto',
  curtainMaxHeight = '300px',

  onChange,
}: MultiSelectProps<ValueType>) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)

  const [selectedItems, nonSelectedItems] = useMemo(
    () =>
      options.reduce<
        [MultiSelectOption<ValueType>[], MultiSelectOption<ValueType>[]]
      >(
        ([sel, nonSel], option) => {
          if (selected.includes(option.value)) sel.push(option)
          else nonSel.push(option)
          return [sel, nonSel]
        },
        [[], []]
      ),
    [selected, options]
  )

  const displayItems = useMemo(
    () =>
      nonSelectedItems.filter(item => {
        const queryLower = debouncedQuery.toLowerCase()
        return item.label.toLowerCase().includes(queryLower)
      }),
    [nonSelectedItems, debouncedQuery]
  )

  useClickOutside(wrapperRef, () => setOpen(false))

  const handleAdd = useCallback(
    (value: ValueType) => {
      const newSelectedItemValues = [...selected, value]
      onChange(newSelectedItemValues, value, 'add')
    },
    [selected, onChange]
  )
  const handleRemove = useCallback(
    (value: ValueType) => {
      const newSelectedItemValues = selected.filter(v => v !== value)
      onChange(newSelectedItemValues, value, 'remove')
    },
    [selected, onChange]
  )

  return (
    <Wrapper ref={wrapperRef} width={width}>
      <Display height={height} open={open} onClick={() => setOpen(!open)}>
        <div className="selected">
          {selectedItems.map(item => (
            <SelectedItem key={item.value} onClick={e => e.stopPropagation()}>
              <span>{item.label}</span>
              <Icon
                light
                icon="times"
                color="lightGray"
                onClick={() => handleRemove(item.value)}
              />
            </SelectedItem>
          ))}
        </div>
        <div className="icon">
          <Icon icon="chevron-down" color="lightGray" />
        </div>
      </Display>
      {open && (
        <Curtain maxHeigth={curtainMaxHeight}>
          <div className="search">
            <Icon icon="search" color="lightGray" />
            <input
              value={query}
              placeholder="Søk..."
              autoFocus
              onChange={e => setQuery(e.target.value)}
            />
          </div>
          <div className="inner">
            {displayItems.length === 0 && (
              <CurtainItem noHover>Ingen elementer</CurtainItem>
            )}
            {displayItems.map(item => (
              <CurtainItem
                key={item.value}
                onClick={() => handleAdd(item.value)}
              >
                {item.label}
              </CurtainItem>
            ))}
          </div>
        </Curtain>
      )}
    </Wrapper>
  )
}

export default MultiSelect
