const theme = {
  colors: {
    /* New colors */
    primary: '#000f9f',
    secondary: '#9099fc',
    darkBlue: '#1f2461',
    highlight: '#2a3383',
    background: '#f5f6ff',

    black: '#000000',
    white: '#FFFFFF',

    gray1: '#333333',
    gray2: '#4f4f4f',
    gray3: '#818181',
    gray4: '#bdbdbd',
    gray5: '#f2f2f2',
    gray6: '#fdfdfd',

    success: '#209958',
    danger: '#b52041',
    warning: '#eece24',
    information: '#5ea9bc',

    purple: '#8087cf',
    pink: '#d5b2f6',
    teal: '#93dbed',

    text: {
      placeholder: '#BDBDBD', // gray4
      normal: '#1f2461', // darkBlue
      gray: '#4f4f4f', // gray2
    },
    tableRowColor: '#fdfdfd', // gray6

    /* Deprecated */
    lightPrimary: '#6C76FA',
    darkGray: '#363841',
    gray: '#646570',
    lightGray: '#9ea2b6',
    lighterGray: '#ced0da',
    veryLightGrayVariant: '#f0f1f8',
    veryLightGray: '#f7f8fc',
    link: '#5067FF',
    red: '#B51E42',
    green: '#209958',
    orange: '#FE8607',
  },
  borders: {
    standard: '1px solid #ced0da',
  },
  layout: {
    defaultBorder: '1px solid #ced0da',
  },
  shadow: {
    default: '0 2px 6px 0 rgba(0, 0, 0, 0.2);',
    input: '0 4px 15px rgba(0, 0, 0, 0.1)',
  },
  media: {
    mobile: '@media screen and (max-width: 768px)',
    smallScreen: '@media screen and (max-width: 400px)',
    largeScreen: '@media screen and (max-width: 1600px)',
  },
  standardBorderRadius: '4px',
  sizes: {
    defaultBorderRadius: '4px',
  },
  defaultContentWrapper: `
    position: relative;
    max-width: 1400px;
    margin: 32px 80px auto 80px;
    
    @media screen and (max-width: 768px) {
      margin: 0;
      width: 100%;
      padding: 1.5rem 2rem;
      box-sizing: border-box;
      height: calc(100vh - 80px);
    }
  `,
  resetButton: `
    appearance: none;
    background: none;
    border: none;
    outline: none;
  `,
}

export default theme
