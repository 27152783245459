import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useGlobal } from '@ur/react-hooks'
import { Icon, Button, Loader } from '@ur/react-components'

import { Input } from '@ur/react-components'
import { useCallback } from 'react'
import { numberWithSpaces } from 'util/parsing'

const IncrementWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  width: 96px;
`

const ArticleCountButton = styled(Button)`
  ${props => props.theme.resetButton}
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.colors.gray5};
  cursor: 'pointer';
`

const FreeArticleInput = styled(Input)`
  input {
    padding: 0 0 0 1px;
    font-size: 14px;
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  margin: 1px 0;
  color: ${props => props.theme.colors.darkBlue};
  background-color: ${props => props.theme.colors.white};

  > :nth-child(1) {
    width: 180px;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
  }
  > :nth-child(2) {
    width: 100px;
    margin-right: auto;
    font-size: 14px;
  }
  > :nth-child(3) {
    width: 100px;
    margin-right: auto;
    font-size: 14px;
    justify-content: flex-start;

    ${props => props.theme.media.mobile} {
      margin: 0;
      width: auto;
      justify-content: flex-end;
    }
  }
  > :nth-child(4) {
    width: 120px;
    margin-right: 0;
    ${props => props.theme.media.mobile} {
      width: 100%;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  ${props => props.theme.media.mobile} {
    display: grid;
    height: 100px;
    grid-row-gap: 10px;
    grid-template-areas:
      'articlename sum sum .'
      'increment price price icons';

    > {
      display: flex;
      margin: 0;
    }
  }
`

const ArticleName = styled.div`
  grid-area: articlename;
  ${props => props.theme.media.mobile} {
    margin: 0;
  }
`

const ArticleIncrement = styled.div`
  grid-area: increment;
`

const ArticlePrice = styled.div`
  grid-area: price;
  ${props => props.theme.media.mobile} {
    margin: 0;
  }
`

const ArticleSum = styled.div`
  grid-area: sum;
  text-align: right;
  ${props => props.theme.media.mobile} {
    margin: 0;
    width: 100%;
    justify-content: flex-end;
  }
`

const ArticleMobileIcons = styled.div`
  grid-area: icons;
  justify-content: flex-end;
`

interface RoomArticleCardRowProps {
  roomID: string
  localArticle: LocalArticle
}

interface LocalArticle {
  autoGenerated: boolean
  count: any
  id: string
  localID: string
  name: string
  price: any
  type: 'ARTICLE' | 'FREE_ARTICLE'
  versionId: string
}

export const RoomArticleCardRow: React.FC<RoomArticleCardRowProps> = ({
  roomID,
  localArticle,
}) => {
  const [offerRooms, setOfferRooms] = useGlobal('offer.rooms')
  const [price, setPrice] = useState(localArticle.price)
  const [priceValidationFallback, setPriceValidationFallback] = useState(price)

  const [count, setCount] = useState(localArticle.count)
  const [countValidationFallback, setCountValidationFallback] = useState(count)

  const [isLoading, setIsLoading] = useState(true)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (localArticle === null) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [localArticle, setIsLoading])

  // Use Effect handles downstream changes for article prices set for an article with the same ID in a different room.
  // Example: Change the price of a electrical power outlet, it should be the same price for the kitchen and the bathroom.
  // isFocused prevents the useEffect from running on any price you are actually editing.
  useEffect(() => {
    if (localArticle === null) return
    if (isFocused) return

    if (price !== localArticle.price) {
      const parsedPrice = parseInt(localArticle.price)
      setPrice(parsedPrice)
    }
  }, [setPrice, localArticle, price, isFocused])

  const handlePriceValidation = () => {
    // Triggered on input onBlur event. If value is not valid it fallbacks to the
    // previous valid value, otherwise it propagates the state change and sets a new fallback state
    const parsedPrice = parseInt(price)

    if (isNaN(parsedPrice)) {
      setPrice(priceValidationFallback)
      return
    }

    if (price === priceValidationFallback) return
    // If Number is valid, we propagate the new changes

    // If the article is not a free_article, we should make sure the price changes happends to all the rooms the article exists in
    if (localArticle.id) {
      const updatedRooms = offerRooms.map(room => {
        const updatedArticles = room.articles.map(article => {
          if (article.id === localArticle.id) {
            return { ...article, price: parsedPrice }
          }
          return article
        })
        return { ...room, articles: updatedArticles }
      })

      setOfferRooms(updatedRooms)
    } else {
      // Handling free_articles - only the article in question is handled
      const localRoom = offerRooms.filter(room => room.localID === roomID)[0]

      const articleIndex = localRoom.articles.findIndex(
        article => article.localID === localArticle.localID
      )
      const newArticle = {
        ...localArticle,
        price: parsedPrice,
      }
      const newArticles = [...localRoom.articles]
      newArticles[articleIndex] = newArticle

      const newRoom = {
        ...localRoom,
        articles: newArticles,
      }

      const roomIndex = offerRooms.findIndex(room => room.localID === roomID)

      const updatedRooms = [...offerRooms]
      updatedRooms[roomIndex] = newRoom
      setOfferRooms(updatedRooms)
    }

    setPriceValidationFallback(parsedPrice)
    setPrice(parsedPrice)
    setIsFocused(false)
  }

  const handleCountValidation = useCallback(() => {
    const parsedCount = parseInt(count)

    if (isNaN(parsedCount)) {
      setCount(countValidationFallback)
      return
    }

    if (count === countValidationFallback) return

    // Number is valid and new, we propagate the changes
    const localRoom = offerRooms.filter(item => item.localID === roomID)[0]

    const articleIndex = localRoom.articles.findIndex(
      item => item.localID === localArticle.localID
    )
    const newArticle = {
      ...localArticle,
      count: parsedCount,
    }
    const newArticles = [...localRoom.articles]
    newArticles[articleIndex] = newArticle

    const newRoom = {
      ...localRoom,
      articles: newArticles,
    }

    const roomIndex = offerRooms.findIndex(room => room.localID === roomID) // this index replacement crap needs to be substituted by some helper function

    const updatedRooms = [...offerRooms]
    updatedRooms[roomIndex] = newRoom
    setCount(parsedCount)
    setCountValidationFallback(parsedCount)
    setOfferRooms(updatedRooms)
  }, [
    count,
    setCount,
    roomID,
    setOfferRooms,
    countValidationFallback,
    setCountValidationFallback,
    offerRooms,
    localArticle,
  ])

  useEffect(() => {
    if (count === '') return
    handleCountValidation()
  }, [count, handleCountValidation])

  const handleDeleteArticle = (localID: string) => {
    const localRoom = offerRooms.filter(room => room.localID === roomID).pop()
    const updatedRoom = {
      ...localRoom,
      articles: localRoom.articles.filter(
        article => article.localID !== localID
      ),
    }
    const roomIndex = offerRooms.findIndex(room => room.localID === roomID)

    const updatedRooms = [...offerRooms]
    updatedRooms[roomIndex] = updatedRoom
    setOfferRooms(updatedRooms)
  }

  return (
    <Wrapper>
      {isLoading ? (
        <Loader.Spinner size={10} />
      ) : (
        <>
          <ArticleName>{localArticle.name}</ArticleName>
          <ArticleIncrement>
            <IncrementWrapper>
              <ArticleCountButton
                borderRadius="4px 0 0 4px"
                onClick={() => {
                  setCount(count - 1)
                }}
                centerContent
              >
                <Icon
                  icon="minus"
                  color="darkBlue"
                  size="14px"
                  margin="0 5px"
                />
              </ArticleCountButton>
              <Input
                value={count}
                textAlign="center"
                width="36px"
                onChange={setCount}
              />
              <ArticleCountButton
                borderRadius="0 4px 4px 0"
                centerContent
                onClick={() => {
                  setCount(count + 1)
                }}
              >
                <Icon icon="plus" color="darkBlue" size="14px" margin="0 5px" />
              </ArticleCountButton>
            </IncrementWrapper>
          </ArticleIncrement>
          <ArticlePrice>
            <FreeArticleInput
              value={price}
              width="45px"
              onChange={setPrice}
              onFocus={() => setIsFocused(true)}
              onBlur={handlePriceValidation}
            />
            <span>,- NOK</span>
          </ArticlePrice>
          <ArticleSum>{numberWithSpaces(price * count)},- NOK</ArticleSum>

          <ArticleMobileIcons>
            <Icon
              icon="trash-alt"
              type="solid"
              size="17px"
              color="gray3"
              cursor="pointer"
              onClick={() => {
                handleDeleteArticle(localArticle.localID)
              }}
            />
          </ArticleMobileIcons>
        </>
      )}
    </Wrapper>
  )
}
