import { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { WizardRoom } from 'modules/offers/OfferWizard/types'
import { numberWithSpaces } from 'util/parsing'
import { useEffect } from 'react'
import { OfferDisplayMode } from 'types/graphql/offers'
import { AccordionButton } from '../common'
import { formatNumber } from '../util'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
  background-color: ${props => props.theme.colors.white};
`

interface SummaryLineProps {
  displaySeperator: boolean
}

const SummaryLine = styled.div<SummaryLineProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: ${props => props.theme.colors.darkBlue};
  border-bottom: ${props =>
    props.displaySeperator
      ? `1px solid ${props.theme.colors.background}`
      : 'none'};

  padding: 0;
  padding-left: 16px;

  > :first-child {
    font-weight: 600;
  }
`

const SummaryLineCell = styled.span`
  width: 80px;
`

interface ShowDetailsProps {
  show: boolean
}

const DetailsWrapper = styled.div<ShowDetailsProps>`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  transition: transform 0.5 ease;
`

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 3px solid ${props => props.theme.colors.background};

  > :first-child {
    padding-left: 40px;
  }

  > :last-child {
    width: 100px;
    padding-right: 40px;
    text-align: left;
  }
`

const DetailsHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 300px;
  color: ${props => props.theme.colors.gray3};
  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`

const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  > div {
    padding: 0 40px;
  }
`

const DetailsContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 60px;
    text-align: left;
  }
`

const DetailsSummaryRow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  height: 48px;
  align-items: center;
  width: 100%;
  font-weight: 600;

  > :last-child {
    width: 150px;
    text-align: left;
    font-weight: 400;
  }
`

const DetailsContentRowCell = styled.div<DetailsContentRowCellProps>`
  width: 300px;
  font-size: 16px;
  color: ${props => props.theme.colors.darkBlue};

  ${props =>
    props.price &&
    css`
      margin-right: 50px;
    `};

  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`
const CommentDetails = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: ${props => props.theme.colors.text.normal};
`
const CommentTitle = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.darkBlue};
  font-weight: 600;
`
const CommentWrapper = styled.div`
  margin: 5% 0;
  padding: 0;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  height: auto;
`
interface CreateOfferSummaryRoomRowProps {
  room: WizardRoom
  markup: number
  displayMode: OfferDisplayMode
}

interface DetailsContentRowCellProps {
  price?: boolean
}

export const CreateOfferSummaryRoomRow: React.FC<
  CreateOfferSummaryRoomRowProps
> = ({ room, displayMode, markup }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showRoomTotal, setShowRoomTotal] = useState(false)
  const [showArticlePrice, setShowArticlePrice] = useState(false)
  const [roomTotal, setRoomTotal] = useState(0)
  const markupFactor = (100 + markup) / 100

  const markUpArticlePrice = useCallback(
    (articlePrice: number) => {
      return formatNumber(articlePrice * markupFactor)
    },
    [markupFactor]
  )

  useEffect(() => {
    const total = room.articles
      .map(article => markUpArticlePrice(article.price) * article.count)
      .reduce((acc, val) => acc + val, 0)
    setRoomTotal(total)
  }, [room.articles, markUpArticlePrice])

  useEffect(() => {
    setShowRoomTotal(
      displayMode === 'PER_ROOM' || displayMode === 'PER_ARTICLE'
    )
    setShowArticlePrice(displayMode === 'PER_ARTICLE')
  }, [displayMode, setShowArticlePrice, setShowRoomTotal])

  const toggle = () => setShowDetails(!showDetails)
  return (
    <Wrapper>
      <SummaryLine displaySeperator={showDetails}>
        <SummaryLineCell>{room.name}</SummaryLineCell>
        <SummaryLineCell>{room.floor} etg.</SummaryLineCell>
        <SummaryLineCell>{room.area}m2</SummaryLineCell>
        <AccordionButton toggle={toggle} show={showDetails} />
      </SummaryLine>
      <DetailsWrapper show={showDetails}>
        <DetailsHeader>
          <DetailsHeaderCell>VARE</DetailsHeaderCell>
          {showArticlePrice && <DetailsHeaderCell>PRIS</DetailsHeaderCell>}
          <DetailsHeaderCell>ANTALL</DetailsHeaderCell>
        </DetailsHeader>
        <DetailsContent>
          {room.articles.map((article, index) => (
            <DetailsContentRow key={index}>
              <DetailsContentRowCell>{article.name}</DetailsContentRowCell>
              {showArticlePrice && (
                <DetailsContentRowCell price={true}>
                  {numberWithSpaces(markUpArticlePrice(article.price))}
                  ,- NOK
                </DetailsContentRowCell>
              )}
              <DetailsContentRowCell>{article.count}</DetailsContentRowCell>
            </DetailsContentRow>
          ))}
          {showRoomTotal && (
            <DetailsSummaryRow key={room.articles.length}>
              <DetailsContentRowCell>Totalsum</DetailsContentRowCell>
              <DetailsContentRowCell>
                {numberWithSpaces(formatNumber(roomTotal))},- NOK
              </DetailsContentRowCell>
            </DetailsSummaryRow>
          )}
        </DetailsContent>
      </DetailsWrapper>
      {!!room.comment && room.comment.length && (
        <CommentWrapper>
          <CommentTitle>Kommentar: </CommentTitle>
          <CommentDetails>{room.comment}</CommentDetails>
        </CommentWrapper>
      )}
    </Wrapper>
  )
}
