import { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'
import { isMobile } from 'react-device-detect'
import { SidebarNavItem } from './SidebarNavItem'
import PermissionsRequired from 'containers/PermissionsRequired/PermissionsRequired'
import SuperuserPermissionsRequired from 'containers/SuperuserPermissionsRequired/SuperuserPermissionsRequired'
import { useUser } from 'util/hooks'

const SidebarNavList = styled.ul`
  list-style: none;
  padding-left: 0;
`

const NavItemGroupMargin = styled.div`
  margin-top: 2em;
`

interface SidebarNavnProps {
  onClick?: () => void
}

export const SidebarNav: React.UFC<SidebarNavnProps> = ({ onClick }) => {
  const themeContext = useContext(ThemeContext)
  const user = useUser()

  return (
    <nav>
      <SidebarNavList>
        <SidebarNavItem
          icon="fa fa-home"
          to="/dashboard"
          text="Forsiden"
          onClick={onClick}
        />
        <PermissionsRequired permissions="users.view_user">
          <SidebarNavItem
            icon="fa fa-fw fa-users"
            to="/users"
            text="Ansatte"
            onClick={onClick}
          />
        </PermissionsRequired>
        <NavItemGroupMargin>
          <PermissionsRequired permissions="calculator.view_offer">
            <SidebarNavItem
              icon="fa fa-cart-plus"
              to="/offers"
              text="Tilbud"
              onClick={onClick}
            />
          </PermissionsRequired>
          <SidebarNavItem
            icon="fa fa-chart-pie-alt"
            to="/statistics"
            text="Statistikk"
            onClick={onClick}
          />
          <PermissionsRequired permissions="articles.view_pricelist">
            <SidebarNavItem
              icon="fa fa-clipboard-list"
              to="/pricelists"
              text="Prislister"
              onClick={onClick}
            />
          </PermissionsRequired>
          <PermissionsRequired permissions="handbook.view_handbookfolder">
            <SidebarNavItem
              icon="fa fa-book"
              to="/handbook"
              text="Håndbok"
              onClick={onClick}
            />
          </PermissionsRequired>
        </NavItemGroupMargin>
        <NavItemGroupMargin>
          <PermissionsRequired permissions="companies.change_company">
            <SidebarNavItem
              icon="fa fa-cogs"
              to="/company-settings"
              text="Firmainnstillinger"
              onClick={onClick}
            />
            <SidebarNavItem
              icon="fa fa-user-cog"
              to="/company-user-types"
              text="Brukertyper"
              onClick={onClick}
            />
          </PermissionsRequired>

          <PermissionsRequired permissions="services.view_service">
            <SidebarNavItem
              icon="fa fa-shipping-fast"
              to="/services"
              text="Tjenester"
              onClick={onClick}
            />
          </PermissionsRequired>

          <PermissionsRequired permissions="services.view_service">
            <SidebarNavItem
              icon="fa fa-bags-shopping"
              to="/orders"
              text="Bestillinger"
              onClick={onClick}
            />
          </PermissionsRequired>

          <SuperuserPermissionsRequired>
            <SidebarNavItem
              icon="fa fa-warehouse"
              to="/roomtemplates"
              text="Rommaler"
              onClick={onClick}
            />
          </SuperuserPermissionsRequired>
        </NavItemGroupMargin>
        {isMobile && (
          <NavItemGroupMargin>
            <PermissionsRequired permissions="users.change_user">
              <SidebarNavItem
                icon="far fa-user"
                to={`/users/${user.id}`}
                text="Profil"
                onClick={onClick}
              />
            </PermissionsRequired>
          </NavItemGroupMargin>
        )}
        {isMobile && (
          <NavItemGroupMargin>
            <SidebarNavItem
              icon="fa fa-sign-out fa-rotate-180"
              to="/logout"
              text="Logg ut"
              color={themeContext.colors.gray4}
            />
          </NavItemGroupMargin>
        )}
      </SidebarNavList>
    </nav>
  )
}
