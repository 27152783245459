import styled from 'styled-components'
import { CSSBorderCollapse } from 'types/style'

export interface TableProps {
  borderCollapse?: CSSBorderCollapse
  fullWidth?: boolean
  fontSize?: string
}

const Table = styled.table<TableProps>`
  border-collapse: ${props => props.borderCollapse};

  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  font-size: ${props => props.fontSize};
`

Table.defaultProps = {
  fontSize: '14px',
  borderCollapse: 'collapse',
}

export default Table
