import React from 'react'
import styled from 'styled-components'
import { Query } from '@apollo/client/react/components'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { IS_LOGGED_IN_QUERY } from 'modules/login/queries'

import Margin from 'components/Margin'
import Center from 'components/Center'

const Error = styled.div`
  font-size: 24px;
  color: ${props => props.theme.colors.red};
`

export const PrivateRoute = ({ component: Component, render, ...rest }) => (
  <Query query={IS_LOGGED_IN_QUERY}>
    {({ loading, data, error }) => {
      if (error) {
        return (
          <Margin top="20em">
            <Center>
              <Error>
                Feil på tjeneren. Vennligst prøv å laste inn siden på nytt.
              </Error>
            </Center>
          </Margin>
        )
      }

      if (loading || !data) {
        return null
      }

      const isLoggedIn = data.isLoggedIn

      return (
        <Route
          {...rest}
          render={props =>
            isLoggedIn ? (
              render ? (
                render(props)
              ) : (
                <Component {...props} />
              )
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )
    }}
  </Query>
)

PrivateRoute.propTypes = {
  component: PropTypes.any,
}

PrivateRoute.defaultProps = {}

export default PrivateRoute
