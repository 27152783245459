import React from 'react'
import { getFileIcon } from 'util/file'

import styled from 'styled-components'
import Icon from 'components/Icon'

const Wrapper = styled.div`
  display: flex;
  padding: 0.5em;

  :hover {
    color: ${props => props.theme.colors.primary};
  }
`

const Name = styled.div`
  margin-left: 2em;
`

export default function HandbookEntryAttachment({ attachment }) {
  return (
    <a href={attachment.file} target="_blank" rel="noopener noreferrer">
      <Wrapper>
        <Icon icon={getFileIcon(attachment.file)} />
        <Name>{attachment.name}</Name>
      </Wrapper>
    </a>
  )
}
