import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Form from './Form'

import FormField from 'components/FormField/index'
import Margin from 'components/Margin'
import Label from 'components/Label'
import Color from 'components/Color'
import { Button } from 'components/Button'
import { getRenderedFieldFromFieldSpec } from './util'
import isFunction from 'lodash/isFunction'
import { FormattedMessage } from 'react-intl'
import { getLikelyDefaultValueFromFieldSpec } from './util'
import { validateField } from 'util/forms'

export default function EasyForm({
  fields,
  groups,
  preventDefault,
  validate,
  onSubmit,
  onFieldChange,

  renderSubmitButton,
  submitButtonText,
  submitButtonProps,

  renderTitle,
  title,

  inline,

  controlled,

  formProps,
}) {
  const initialData = fields.reduce(
    (acc, field) => ({
      [field.name]: getLikelyDefaultValueFromFieldSpec(field),
      ...acc,
    }),
    {}
  )

  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(() => initialData)

  const handleFieldChange = (name, newValue, event) => {
    if (onFieldChange) {
      onFieldChange(name, newValue, event)
    }

    setErrors({
      ...errors,
      [name]: null,
    })
    setFormData({
      ...formData,
      [name]: newValue,
    })
  }

  function performValidation() {
    const newErrors = {}

    fields.forEach(field => {
      if (field.validate) {
        newErrors[field.name] = validateField(
          formData,
          field.name,
          field.validate
        )
        if (field.errorMessage && newErrors[field.name]) {
          newErrors[field.name] = isFunction(field.errorMessage)
            ? field.errorMessage(formData[field.name], formData, field)
            : field.errorMessage
        }
      }
    })

    setErrors(newErrors)
    return !Object.values(newErrors).some(x => x !== null)
  }

  function handleSubmit(e) {
    if (validate) {
      if (!performValidation()) {
        return
      }
    }
    if (onSubmit) {
      onSubmit(formData, errors, e)
    }
  }

  function renderFormGroups() {
    return fields.map((field, fieldIndex) => {
      const fieldErrors = errors[field.name]

      if (field.renderField) {
        return field.renderField(field, fieldErrors, fieldIndex)
      }

      return (
        <Margin
          inline={inline}
          key={field.name}
          bottom={inline ? 'auto' : '1em'}
          right={inline ? '1em' : 'auto'}
        >
          <FormField>
            <Label htmlFor={field.name}>{field.label}</Label>
            {getRenderedFieldFromFieldSpec(
              field,
              controlled ? field.value : formData[field.name],
              (newValue, event) =>
                handleFieldChange(field.name, newValue, event),
              errors[field.name]
            )}
          </FormField>
        </Margin>
      )
    })
  }

  function renderFormFieldsWithoutGroups() {
    return fields.map((field, fieldIndex) => {
      const fieldErrors = errors[field.name]

      if (field.renderField) {
        return field.renderField(field, fieldErrors, fieldIndex)
      }

      // const fieldValueParser = field.parseValue || identity

      return (
        <Margin
          inline={inline}
          key={field.name}
          bottom={inline ? 'auto' : '1em'}
          right={inline ? '1em' : 'auto'}
        >
          <FormField>
            <Label htmlFor={field.name}>{field.label}</Label>
            {getRenderedFieldFromFieldSpec(
              field,
              controlled ? field.value : formData[field.name],
              (newValue, event) =>
                handleFieldChange(field.name, newValue, event),
              errors[field.name]
            )}
          </FormField>
        </Margin>
      )
    })
  }

  return (
    <Form
      {...formProps}
      preventDefault={preventDefault}
      onSubmit={handleSubmit}
    >
      {renderTitle &&
        (isFunction(renderTitle) ? (
          renderTitle()
        ) : (
          <h2>
            <Color primary>{title}</Color>
          </h2>
        ))}
      {groups && groups.length > 0
        ? renderFormGroups()
        : renderFormFieldsWithoutGroups()}
      {renderSubmitButton &&
        (isFunction(renderSubmitButton) ? (
          renderSubmitButton()
        ) : (
          <Margin
            inline={inline}
            top={inline ? 'auto' : '2em'}
            bottom={inline ? 'auto' : '1em'}
          >
            <Button type="submit" primary {...(submitButtonProps || {})}>
              {submitButtonText ? (
                submitButtonText
              ) : (
                <FormattedMessage id="common.submit" defaultMessage="Submit" />
              )}
            </Button>
          </Margin>
        ))}
    </Form>
  )
}

EasyForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      initialValue: PropTypes.any,
      value: PropTypes.any,
      type: PropTypes.string,
      widget: PropTypes.string,
      validate: PropTypes.func,
      errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      className: PropTypes.string,
      style: PropTypes.string,
      renderField: PropTypes.func,
      extra: PropTypes.any,
      parseValue: PropTypes.func,
    })
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.string),
      props: PropTypes.object,
    })
  ),
  errors: PropTypes.object,
  inline: PropTypes.bool,
  preventDefault: PropTypes.bool,
  validate: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFieldChange: PropTypes.func,
  renderSubmitButton: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  submitButtonText: PropTypes.string,
  submitButtonProps: PropTypes.object,
  renderTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
  controlled: PropTypes.bool,
  formProps: PropTypes.object,
}

EasyForm.defaultProps = {
  fields: [],
  inline: false,
  errors: {},
  preventDefault: true,
  validate: true,
  renderSubmitButton: true,
  renderTitle: true,
  controlled: false,
}
