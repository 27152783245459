import { DropdownMenu, MenuItem } from 'components/DropdownMenu'
import Image from 'components/Image'
import { QuoTable } from 'components/QuoTable'
import { useCallback, useState } from 'react'
import { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useServiceMutations } from '../mutations.hooks'
import { ShallowServiceNode } from '../types.graphql'

const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`

interface ServicesTableProps {
  services: ShallowServiceNode[]
  onEdit: (service: ShallowServiceNode) => void
}

export const ServicesTable: React.UFC<ServicesTableProps> = ({
  services,
  onEdit,
}) => {
  const history = useHistory()
  const { colors } = useTheme()
  const { service: serviceMutation } = useServiceMutations()

  const [openMenu, setOpenMenu] = useState('')

  const handleEdit = useCallback(
    (service: ShallowServiceNode) => {
      onEdit(service)
      setOpenMenu('')
    },
    [setOpenMenu, onEdit]
  )

  const handleMenuItems = useCallback(
    (service: ShallowServiceNode): MenuItem[] => {
      return [
        {
          displayText: 'Åpne',
          icon: {
            icon: 'eye',
            size: '17px',
            type: 'solid',
          },
          colorOverride: colors.darkBlue,
          mobileOnly: false,
          onClick: () => {
            history.push(`/services/${service.id}`)
          },
        },
        {
          displayText: 'Endre',
          icon: {
            icon: 'edit',
            size: '17px',
            type: 'solid',
          },
          colorOverride: colors.darkBlue,
          mobileOnly: false,
          onClick: () => handleEdit(service),
        },
        {
          displayText: 'Slett',
          icon: {
            icon: 'trash-alt',
            size: '17px',
            type: 'solid',
          },
          mobileOnly: false,
          onClick: () => {
            serviceMutation.delete({ variables: { id: service.id } })
          },
        },
      ]
    },
    [colors.darkBlue, history, serviceMutation, handleEdit]
  )

  const servicesData = useMemo(() => {
    return services.map(service => {
      return {
        id: service.id,
        data: {
          image: (
            <Link to={`/services/${service.id}`}>
              {service.image ? (
                <Image src={service.image} height="30px" />
              ) : (
                '-'
              )}
            </Link>
          ),
          name: <Link to={`/services/${service.id}`}>{service.name}</Link>,
          price: <Link to={`/services/${service.id}`}>{service.price}</Link>,
          tags: (
            <Link to={`/services/${service.id}`}>
              {service.tags.join(', ')}
            </Link>
          ),
          menu: (
            <DropdownMenu
              menuItems={handleMenuItems(service)}
              loading={false}
              open={openMenu === service.id}
              onBarsClick={() => setOpenMenu(service.id)}
              onClose={() => setOpenMenu('')}
            />
          ),
        },
      }
    })
  }, [services, handleMenuItems, openMenu])

  const columns = [
    { id: 'image', label: 'Bilde' },
    { id: 'name', label: 'Navn' },
    { id: 'price', label: 'Pris' },
    { id: 'tags', label: 'Tags' },
    {
      id: 'menu',
      label: '',
      isMenu: true,
    },
  ]

  return (
    <StyledQuotable
      columns={columns}
      data={servicesData}
      tableProps={{ fullWidth: true, tableLayout: 'auto' }}
    />
  )
}
