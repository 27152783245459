import React from 'react'
import { FormattedMessage } from 'react-intl'

import Margin from 'components/Margin'
import FormField from 'components/FormField'
import Input from 'components-old/Input'
import Label from 'components/Label'

export default function RegisterUserField({
  name,
  labelId,
  labelDefaultMessage,
  errors,
  value,
  onChange,
}) {
  return (
    <Margin bottom="1em" left="1em" right="1em">
      <FormField>
        <Label htmlFor={name}>
          <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage} />
        </Label>
        <Input id={name} error={errors} value={value} onChange={onChange} />
      </FormField>
    </Margin>
  )
}
