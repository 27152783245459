import styled from 'styled-components'
import FlexItem from './FlexItem'

interface FlexProps {
  inline?: boolean,
  justifyContent?: string,
  alignItems?: string,
  flexDirection?: string,
  flexWrap?: string,
}

const Flex = styled.div<FlexProps>`
  display: ${props => props.inline ? "inline-flex" : "flex"};

  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};

  flex-direction: ${props => props.flexDirection};

  flex-wrap: ${props => props.flexWrap};
`

type FlexWithItem = {
  Item: typeof FlexItem
} & typeof Flex

(Flex as FlexWithItem).Item = FlexItem

export default Flex as FlexWithItem
