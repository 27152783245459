import React, { useState } from 'react'
import styled from 'styled-components'
import { Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import invariant from 'invariant'
import queryString from 'query-string'

import { Text } from 'components/Text'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Input } from 'components/Input'
import Label from 'components/Label'
import Margin from 'components/Margin'
import { Button } from 'components/Button'
import QuillEditor from 'components/QuillEditor'

import { CREATE_MANUAL_ENTRY_MUTATION } from './mutations'

import { validateField, notEmptyValidation } from 'util/forms'
import FileInput from 'components/Input/FileInput'
import { useIntl } from 'react-intl'
import { useToast } from '@ur/react-components'

export const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
`

function HandbookCreateEntry({ history }) {
  const toasts = useToast()

  const intl = useIntl()
  const t = intl.formatMessage
  const parsedSearch = queryString.parse(history.location.search)
  const parentFolder = parsedSearch.parentFolder
  invariant(parentFolder, 'Parent folder must be specified.')

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    files: [],
  })
  // This has to live in its own container due to race-conditions
  const [richtext, setRichtext] = useState({})

  const [errors, setErrors] = useState({
    name: null,
    description: null,
    richtext: null,
  })

  if (!parentFolder) {
    // TODO: Return error
  }

  function onSubmit(createHandbookEntryMutation) {
    const newErrors = {}
    newErrors['name'] = validateField(
      formData,
      'name',
      notEmptyValidation,
      intl
    )

    const canSubmit = !Object.values(newErrors).some(x => x !== null)

    if (!canSubmit) {
      setErrors(newErrors)
      return
    }

    createHandbookEntryMutation({
      variables: {
        input: {
          name: formData.name,
          description: formData.name,
          richtext: richtext,
          folder: parentFolder,
          attachmentsAdd: formData.files.map(file => ({
            name: file.name,
            file,
          })),
        },
      },
    })
  }

  return (
    <>
      <Wrapper>
        <h1>
          <Text color="primary">
            {t({
              id: 'common.create-entry',
              defaultMessage: 'Create new entry',
            })}
          </Text>
        </h1>
        <Mutation
          onCompleted={({ createHandbookEntry }) => {
            invariant(
              createHandbookEntry,
              'Expected a result back from the createHandbookEntry mutation.'
            )

            toasts(
              'success',
              t({
                id: 'driverhandbook.create-entry-success',
                defaultMessage: 'Successfully created entry',
              })
            )

            history.push(
              `/handbook/entry/${createHandbookEntry.handbookEntry.slug}`
            )
          }}
          onError={error => {
            if (error.message.includes('UNIQUE')) {
              setErrors({
                ...errors,
                name: t({
                  id: 'errors.name-exists',
                  defaultMessage: 'Name already exists.',
                }),
              })
              return
            }
            toasts(
              'error',
              t({
                id: 'errors.generic',
                defaultMessage: 'Error on server.',
              })
            )
          }}
          mutation={CREATE_MANUAL_ENTRY_MUTATION}
        >
          {createHandbookEntryMutation => (
            <Form
              preventDefault
              onSubmit={() => onSubmit(createHandbookEntryMutation)}
            >
              <Margin bottom="1em">
                <FormField inline>
                  <Label htmlFor="name">
                    {t({ id: 'common.name', defaultMessage: 'Name' })}
                  </Label>
                  <Input
                    id="name"
                    error={errors.name}
                    value={formData.name}
                    onChange={value =>
                      setFormData({
                        ...formData,
                        name: value.slice(0, 128),
                      })
                    }
                  />
                </FormField>
              </Margin>
              <Margin bottom="1em">
                <FormField>
                  <Label htmlFor="description">
                    {t({
                      id: 'common.description',
                      defaultMessage: 'Description',
                    })}
                  </Label>
                  <Input
                    id="description"
                    error={errors.description}
                    value={formData.description}
                    onChange={value =>
                      setFormData({ ...formData, description: value })
                    }
                  />
                </FormField>
              </Margin>
              <Margin bottom="1em">
                <FormField>
                  <Label htmlFor="richtext">
                    {t({ id: 'common.content', defaultMessage: 'Content' })}
                  </Label>
                  <QuillEditor
                    onChange={contents => setRichtext(JSON.stringify(contents))}
                  />
                </FormField>
              </Margin>
              <Margin bottom="1.75em">
                <FileInput
                  multiple
                  label={t({
                    id: 'common.add-files',
                    defaultMessage: 'Legg til filer',
                  })}
                  onChange={files => {
                    setFormData({
                      ...formData,
                      files: Array.from(files),
                    })
                  }}
                />
              </Margin>
              <Button primary>
                {t({ id: 'common.create', defaultMessage: 'Create' })}
              </Button>
            </Form>
          )}
        </Mutation>
      </Wrapper>
    </>
  )
}

export default withRouter(HandbookCreateEntry)
