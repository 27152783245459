import { useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { Button } from 'components/Button'
import CenteredErrorMessage from 'components/CenteredErrorMessage'
import RoomTemplateSelector from 'modules/offers/CreateOffer/Selectors/RoomTemplateSelector'
import { recalculateActions } from 'modules/offers/CreateOffer/util'
import AddRoomTemplateActionResults from 'modules/offers/OfferWizard/AddRooms/AddRoomTemplateActionResults'
import AddRoomTemplateBasicInfo from 'modules/offers/OfferWizard/AddRooms/AddRoomTemplateBasicInfo'
import AddRoomTemplateOption from 'modules/offers/OfferWizard/AddRooms/AddRoomTemplateOption'
import { ROOM_TEMPLATE_QUERY } from 'modules/roomtemplates/queries'
import { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import {
  RoomTemplate,
  RoomTemplateQuery,
  RoomTemplateQueryParameters,
  RoomTemplateTitle,
} from 'types/graphql/roomTemplates'
import { v4 as uuid } from 'uuid'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  width: 1120px;
  height: 850px;
  padding: 32px;
  border-radius: 12px;

  ${props => props.theme.media.mobile} {
    width: 90vw;
    height: 95vh;
    padding: 0;
  }
`

const ExitIconWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;

  cursor: pointer;

  ${props => props.theme.media.mobile} {
    top: 16px;
    right: 8px;
    z-index: 1000;

    background: white;
    padding: 4px 8px;
    border-radius: 4px;
  }
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme.colors.darkBlue};

  margin-bottom: 2rem;

  ${props => props.theme.media.mobile} {
    font-size: 28px;
  }
`

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  grid-template-areas: 'left right';
  grid-template-columns: 390px auto;
  grid-column-gap: 2rem;

  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 1rem;
    grid-template-columns: auto;
    grid-template-areas:
      'left'
      'right'
      'submit';
    overflow-y: scroll;
  }
`
const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionLabel = styled.label`
  margin-top: 3rem;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray2};
  margin-bottom: 15px;

  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
  }
`

const RoomDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ConditionalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1rem;

  ${props => props.theme.media.mobile} {
    overflow-y: visible;
    margin-bottom: 25px;
  }
`

const SubmitButton = styled(Button)`
  position: absolute;
  bottom: 2rem;
  width: calc(390px - 2rem);

  ${props => props.theme.media.mobile} {
    position: relative;
    bottom: 0;
    width: 100%;
    grid-area: submit;
  }
`

const SelectTemplateWrapper = styled.div``

const OptionsHeaderTitle = styled.div`
  margin-top: 2rem;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray2};
`

const Left = styled.div`
  grid-area: left;
  position: relative;
  padding-bottom: 4rem;
  overflow-y: auto;
  height: 100%;
  padding-right: 2rem;

  ${props => props.theme.media.mobile} {
    width: 100%;
    height: auto;
    overflow-y: visible;
    padding-right: 0rem;
    padding-bottom: 0;
  }
`

const Right = styled.div`
  grid-area: right;

  padding-bottom: 2rem;

  ${props => props.theme.media.mobile} {
    margin-top: 0;
  }
`

function isTitle(property): property is RoomTemplateTitle {
  return 'title' in property
}

interface AddRoomFromTemplateModalProps {
  template: RoomTemplate
  addRoomCallback: (room) => void
  closeModalCallback: () => void
}

export const AddRoomFromTemplateModal: React.FC<
  AddRoomFromTemplateModalProps
> = ({ template, addRoomCallback, closeModalCallback }) => {
  const [selectedRoomTemplateId, setSelectedRoomTemplate] = useState<string>(
    template.id
  )

  const [selectedOptions, setSelectedOptions] = useState({})
  const [articleActions, setArticleActions] = useState([])

  const [basicInfo, setBasicInfo] = useState({
    name: '',
    floor: 1,
    area: '',
    comment: '',
  })

  // We only use the data after the onComplete meaning we don't need to save the data state
  // in the component.
  const { data, loading, error } = useQuery<
    RoomTemplateQuery,
    RoomTemplateQueryParameters
  >(ROOM_TEMPLATE_QUERY, {
    variables: { id: selectedRoomTemplateId },
  })

  useEffect(() => {
    if (loading || !data.roomTemplate) {
      return
    }

    const newOptions = data.roomTemplate.options.reduce((acc, option) => {
      acc[option.id] = option.fieldType === 'BOOL' ? false : 0
      return acc
    }, {})

    setSelectedOptions(newOptions)
    setArticleActions(recalculateActions(data.roomTemplate, newOptions))
  }, [data, loading])

  const roomTemplate = {
    ...(data?.roomTemplate || {}),
    ...basicInfo,
  }

  const properties = useMemo(() => {
    const allProperties = [
      ...(roomTemplate.titles || []),
      ...(roomTemplate.options || []),
    ]

    const sortedProperties = allProperties.sort((a, b) => a.order - b.order)
    return sortedProperties
  }, [roomTemplate.titles, roomTemplate.options])

  function handleBasicInfoUpdated(name, value, roomTemplate) {
    setBasicInfo(info => ({
      ...info,
      [name]: value,
    }))

    const newRoomTemplateFullData = {
      ...roomTemplate,
      [name]: value,
    }

    const articleActions = recalculateActions(
      roomTemplate,
      selectedOptions,
      newRoomTemplateFullData
    )
    setArticleActions(articleActions)
  }

  function handleOptionUpdated(id, newValue, roomTemplate) {
    const newOptions = {
      ...selectedOptions,
      [id]: newValue,
    }
    setSelectedOptions(newOptions)

    const articleActions = recalculateActions(
      roomTemplate,
      newOptions,
      roomTemplate
    )

    setArticleActions(articleActions)
  }

  const handleAddRoomWithArticles = () => {
    const parsedArticles = articleActions
      .filter(article => !(parseInt(article.count) <= 0))
      .map(article => {
        return {
          name: article.name,
          count: article.count,
          price: article.price,
          id: article.id,
          localID: uuid(),
          // This is added to keep track of which articles are automatically added to the room
          // and which are manually added. Not used per now, but an indicator for the user was thought added.
          autoGenerated: true,
          versionId: null,
          type: 'ARTICLE',
        }
      })

    const room = {
      ...basicInfo,
      articles: parsedArticles,
      versionId: null,
      localID: uuid(),
    }

    addRoomCallback(room)
    closeModalCallback()
  }

  if (loading) {
    return (
      <Wrapper>
        <Loader.Spinner size={100} />
      </Wrapper>
    )
  }

  if (error) {
    return (
      <Wrapper>
        <CenteredErrorMessage message={error.toString()} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <ExitIconWrapper>
        <Icon
          size={isMobile ? '24px' : '40px'}
          type="light"
          icon="times"
          onClick={closeModalCallback}
        />
      </ExitIconWrapper>
      <ModalContentWrapper>
        <Left>
          <Title>Legg til rommal</Title>
          <SelectTemplateWrapper>
            <RoomTemplateSelector
              onChange={setSelectedRoomTemplate}
              selectedRoomTemplate={selectedRoomTemplateId}
            />
          </SelectTemplateWrapper>
          <RoomDataWrapper>
            <SectionLabel>Informasjon</SectionLabel>
            <AddRoomTemplateBasicInfo
              // ToDo rewrite without using EasyForm
              roomTemplateData={roomTemplate}
              onFieldChange={(name, value) => {
                handleBasicInfoUpdated(name, value, data.roomTemplate)
              }}
            />
          </RoomDataWrapper>
          <ConditionalsWrapper>
            {properties.map(property => {
              if (isTitle(property)) {
                return (
                  <OptionsHeaderTitle key={property.id}>
                    {property.title}
                  </OptionsHeaderTitle>
                )
              } else {
                return (
                  <AddRoomTemplateOption
                    value={selectedOptions[property.id]}
                    option={property}
                    onChange={value =>
                      handleOptionUpdated(property.id, value, data.roomTemplate)
                    }
                  />
                )
              }
            })}
          </ConditionalsWrapper>
        </Left>
        <Right>
          <ArticlesWrapper>
            <SectionLabel>Varer automatisk lagt til</SectionLabel>
            <AddRoomTemplateActionResults articles={articleActions} />
          </ArticlesWrapper>
        </Right>
        <SubmitButton buttonStyle="primary" onClick={handleAddRoomWithArticles}>
          Legg til
        </SubmitButton>
      </ModalContentWrapper>
    </Wrapper>
  )
}
