export const API_URL = isLocal()
  ? 'http://localhost:8005'
  : isDev()
  ? 'https://api-elman-dev.ur-internal.com'
  : isStage('staging')
  ? 'https://api-elman-staging.ur-internal.com'
  : isProd()
  ? 'https://api.intra.elman.no'
  : ''

export const BACKEND_URL = API_URL

export const MAPBOX_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk.eyJ1IjoidG9ybW9kaCIsImEiOiJjazM0MGtvemIwdm03M2xwOTNsMGQ4ZXV1In0.cm4S-qMVaz2siwlP2R7QOA'
    : 'pk.eyJ1IjoidG9ybW9kaCIsImEiOiJjazFxcGV2dnAxYWF1M25wazA2aDByMXRyIn0.bLTGUrujW6pPFpHcX_er4w'

export const GOOGLE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyB2OAqGkxjlFV_gYoBJsYGvhDcXNfD-M0I'
    : 'AIzaSyB2OAqGkxjlFV_gYoBJsYGvhDcXNfD-M0I'

export function isLocal() {
  return process.env.NODE_ENV !== 'production'
}

export function isStage(stage) {
  return process.env.REACT_APP_STAGE === stage
}

export function isDev() {
  return isStage('development')
}

export function isProd() {
  return isStage('production')
}

export function getStage() {
  return process.env.REACT_APP_STAGE
}
