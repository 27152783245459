import styled from 'styled-components'
import { TableCell } from './TableCell'
import { TableHeaderCell } from './TableHeaderCell'
import { RowProps } from './types'

export interface TableRowProps {
  rowProps?: RowProps
}

export const TableRow = styled.tr<TableRowProps>`
  box-sizing: border-box;
  border: none;
  outline: none;
  background: #fff;
  ${props =>
    props.rowProps?.summation &&
    `
    ${TableCell} {
      background: ${props.theme.colors.tableRowColor};
      font-weight: bold;
    }
  `}
  ${props =>
    props.rowProps?.disabled &&
    `
    ${TableCell} {
      color: ${props.theme.colors.darkGray};  
      background-color: ${props.theme.colors.veryLightGray};
    }
    `}
    
  ${props =>
    props.rowProps?.striped &&
    `
    :nth-of-type(2n + 1){
      background: rgba(0, 0, 0, 0.025);
    }
  `}

  ${props =>
    !props.rowProps?.head &&
    !props.rowProps?.noHover &&
    `

    :hover {
      background: #f9f9f9;
      color: ${props.theme.colors.primary};
    }
  `}


  :last-of-type {
    ${TableCell} {
      border-bottom: none;
    }
  }

  ${TableHeaderCell} {
    border-bottom: 2px solid ${props => props.theme.colors.veryLightGray};
  }
`
