import React from 'react'
import { Icon } from '@ur/react-components'
import styled, { css } from 'styled-components'
import { useGlobal } from '@ur/react-hooks'
import { isMobile } from 'react-device-detect'
import truncate from 'lodash/truncate'

interface WrapperProps {
  width: string
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.width ? props.width : '100%')};

  > div:first-child {
    display: none;
  }

  > div:last-child {
    display: none;
  }

  ${props => props.theme.media.mobile} {
    > div:first-child {
      display: flex;
    }

    > div:last-child {
      display: flex;
    }
  }
`

interface ActiveProps {
  active: boolean
}

const StepSeparator = styled.div<ActiveProps>`
  content: '';
  display: flex;
  flex-direction: row;
  height: 1px;
  border: 1px solid;
  border-color: ${props =>
    props.active ? props.theme.colors.green : props.theme.colors.gray5};
  flex-grow: 1;
`

const stepStyleHelper = (state: 'finished' | 'active' | 'notActive') => {
  switch (state) {
    case 'finished':
      return css`
        background-color: ${props => props.theme.colors.white};
        border: 2px solid ${props => props.theme.colors.green};
        color: ${props => props.theme.colors.green};
      `

    case 'active':
      return css`
        background-color: ${props => props.theme.colors.green};
        border-color: ${props => props.theme.colors.green};
        color: ${props => props.theme.colors.white};
      `

    case 'notActive':
      return css`
        background-color: ${props => props.theme.colors.gray5};
        border-color: ${props => props.theme.colors.gray5};
        color: ${props => props.theme.colors.gray3};
      `
  }
}

interface ActiveState {
  active: 'finished' | 'active' | 'notActive'
}

const Step = styled.div<ActiveState>`
  display: flex;
  width: 32px;
  height: 32px;
  font-size: 19px;
  font-weight: 600;
  border-radius: 100%;
  justify-content: center;
  align-items: center;

  ${props => stepStyleHelper(props.active)}
`

const StepLabel = styled.label<ActiveProps>`
  display: flex;
  width: auto;
  margin-top: 5px;
  margin-left: 5px;
  color: ${props =>
    props.active ? props.theme.colors.green : props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;

  ${props => props.theme.media.mobile} {
    position: absolute;
    margin: 0;
    top: 100%;
    left: 50%;
    text-overflow: ellipsis;
    transform: translate(-50%);
  }
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;

  ${props => props.theme.media.mobile} {
    position: relative;
  }
`

interface StepIndicatorProps {
  width?: string
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  width = '100%',
}) => {
  const [wizardState] = useGlobal('offer.wizardState')
  const steps = ['Kunde', 'Vilkår', 'Rom', 'Artikler', 'Oppsummering']
  const activeIndex = steps.indexOf(wizardState)
  function truncateIfMobile(step: string) {
    if (!isMobile) return step
    return truncate(step, { length: 8 })
  }

  return (
    <Wrapper width={width}>
      {steps.map((step, index) => {
        // We compute the "state" of each step in the indicator as a function of
        // what step we are iterating over and the index of the "active" step
        const activeState =
          activeIndex > index
            ? 'finished'
            : activeIndex === index
            ? 'active'
            : 'notActive'

        const stepContent =
          activeState === 'finished' ? <Icon icon="check" /> : index + 1

        // The seperators are given display: none on desktop, but we want to
        // style them green on the last step if we are on mobile
        const isLastIterationAndLastStep =
          index === steps.length - 1 && activeIndex === steps.length - 1

        const stepString = truncateIfMobile(step)

        return (
          <React.Fragment key={`${step}-${index}`}>
            {index === 0 && (
              <StepSeparator active={activeState !== 'notActive'} />
            )}
            <StepContainer>
              <Step active={activeState}>{stepContent}</Step>
              <StepLabel active={activeState !== 'notActive'}>
                {stepString}
              </StepLabel>
            </StepContainer>

            <StepSeparator
              active={
                (activeState !== 'notActive' && index !== activeIndex) ||
                isLastIterationAndLastStep
              }
            />
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
}
