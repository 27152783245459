import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { ALL_PRICELISTS_QUERY } from 'modules/articles/queries'
import CompanyContext from 'context/CompanyContext'

import Table from 'components/Table'
import Flex from 'components/Flex'
import { Button } from 'components/Button'
import { UPDATE_COMPANY_PRICELISTS_MUTATION } from '../mutations'
import { useCompany } from 'util/hooks'
import Select from 'components/Select'
import { useToast } from '@ur/react-components'

const Wrapper = styled.div`
  overflow: auto;
  min-height: 100%;
`

// Used so we can get a scroll bar at the bottom for the Wrapper
const InnerWrapper = styled.div`
  padding: 1em 2em;
`

const UserSettingTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
`

const UserSettingHeaderCell = styled.th`
  padding: 0.5rem 2em;
  font-size: 18px;
  color: ${props => props.theme.colors.primary};

  /* Make sure titles never break lines */
  white-space: nowrap;

  i {
    cursor: pointer;
  }
`

const UserSettingNameCell = styled.td`
  background-color: white;

  padding: 0.25em 1em;
  font-weight: 300;
  border-bottom: 1px solid ${props => props.theme.colors.veryLightGray};

  ${props =>
    props.isTitle &&
    `
    color: ${props.theme.colors.darkGray};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1em;
    padding-bottom: 0.25em;
    padding-left: 0.25em;
    background-color: transparent;
  `}
`

const UserSettingCell = styled.td`
  color: ${props =>
    props.hasPricelist ? props.theme.colors.green : props.theme.colors.red};
  background-color: white;
  padding: 0.5em 2em;
  border-bottom: 1px solid ${props => props.theme.colors.veryLightGray};

  i {
    cursor: pointer;
  }
`

const UserSettingSelectCell = styled.td`
  background-color: white;
  padding: 1em 2em;
`

const SettingRow = styled.tr``

const UserSettingCheck = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  ${props => props.theme.resetButton};
  color: inherit;
`

export default function CompanyPricelists() {
  const toasts = useToast()

  // Dirty is true if we have something to save
  const [dirty, setDirty] = useState(false)

  const { data: pricelistsData, loading } = useQuery(ALL_PRICELISTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      const pricelists = loading
        ? null
        : data?.allPricelists?.edges?.map(edge => edge.node)
      pricelists.map(pricelist =>
        pricelist.userTypes.edges.map(edge =>
          setUpdatedUserTypes({
            ...updatedUserTypes,
            [edge.node.id]: {
              defaultPricelist: updatedUserTypes[edge.node.id].defaultPricelist,
              pricelists: [
                ...updatedUserTypes[edge.node.id].pricelists,
                pricelist.id,
              ],
            },
          })
        )
      )
    },
  })

  const [updateCompanyPricelistsMutation] = useMutation(
    UPDATE_COMPANY_PRICELISTS_MUTATION,
    {
      refetchQueries: ['CurrentCompany'],
    }
  )
  const company = useCompany()
  const initialUserTypePricelists = company.userTypes
    .map(type => {
      const newType = {
        [type.id]: {
          defaultPricelist: type?.defaultPricelist?.id ?? '',
          pricelists: type.pricelists.edges.map(edge => edge.node.id),
        },
      }
      return newType
    })
    .reduce((acc, type) => {
      return {
        ...acc,
        ...type,
      }
    })

  const [updatedUserTypes, setUpdatedUserTypes] = useState(
    initialUserTypePricelists
  )

  const pricelists = loading
    ? null
    : pricelistsData?.allPricelists?.edges?.map(edge => edge.node)

  function handleSave(updateCompanyPricelistsMutation) {
    if (!dirty) {
      return
    }

    const mutationData = []

    Object.keys(updatedUserTypes).forEach(id => {
      const entry = {
        id: id,
        defaultPricelist: updatedUserTypes[id].defaultPricelist,
        pricelists: updatedUserTypes[id].pricelists,
      }
      mutationData.push(entry)
    })

    updateCompanyPricelistsMutation({
      variables: {
        updatedUserTypePricelistsInput: mutationData,
      },
    })
      .then(() => {
        toasts('success', 'Lagret')
      })
      .catch(() => {
        toasts('error', 'Feil ved lagring.')
      })
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <Flex>
          <Button
            disabled={!dirty}
            success
            icon="fa fa-save"
            onClick={() => handleSave(updateCompanyPricelistsMutation)}
          >
            Lagre
          </Button>
        </Flex>
        <CompanyContext.Consumer>
          {company => (
            <UserSettingTable>
              <Table.Head>
                <SettingRow>
                  <UserSettingHeaderCell>Prisliste</UserSettingHeaderCell>
                  {company.userTypes.map(type => (
                    <UserSettingHeaderCell key={type.id}>
                      {type.name}
                    </UserSettingHeaderCell>
                  ))}
                </SettingRow>
              </Table.Head>
              <Table.Body>
                <React.Fragment>
                  <SettingRow>
                    <UserSettingNameCell>
                      Standard prisliste
                    </UserSettingNameCell>
                    {company.userTypes.map(type => (
                      <UserSettingSelectCell key={type.id}>
                        {!loading && (
                          <Select
                            options={pricelists
                              ?.map(pricelist => ({
                                value: pricelist.id,
                                id: pricelist.id,
                                label: pricelist.name,
                              }))
                              .filter(pricelistItem => {
                                return updatedUserTypes[
                                  type.id
                                ].pricelists.includes(pricelistItem.id)
                              })}
                            value={updatedUserTypes[type.id].defaultPricelist}
                            onChange={value => {
                              setDirty(true)
                              setUpdatedUserTypes({
                                ...updatedUserTypes,
                                [type.id]: {
                                  defaultPricelist: value,
                                  pricelists:
                                    updatedUserTypes[type.id].pricelists,
                                },
                              })
                            }}
                            shadow={false}
                            border="1px solid"
                          />
                        )}
                      </UserSettingSelectCell>
                    ))}
                  </SettingRow>
                  {!loading &&
                    pricelists.map(pricelist => {
                      return (
                        <SettingRow key={pricelist.name}>
                          <UserSettingNameCell>
                            {pricelist.name}
                          </UserSettingNameCell>
                          {company.userTypes.map((type, j) => {
                            const hasPricelist = updatedUserTypes[
                              type.id
                            ].pricelists.includes(pricelist.id)
                            return (
                              <UserSettingCell
                                key={j}
                                hasPricelist={hasPricelist}
                                onClick={() => {
                                  setDirty(true)
                                  if (hasPricelist) {
                                    setUpdatedUserTypes({
                                      ...updatedUserTypes,
                                      [type.id]: {
                                        defaultPricelist:
                                          updatedUserTypes[type.id]
                                            .defaultPricelist === pricelist.id
                                            ? ''
                                            : updatedUserTypes[type.id]
                                                .defaultPricelist,
                                        pricelists: updatedUserTypes[
                                          type.id
                                        ].pricelists.filter(
                                          value => value !== pricelist.id
                                        ),
                                      },
                                    })
                                  } else {
                                    setUpdatedUserTypes({
                                      ...updatedUserTypes,
                                      [type.id]: {
                                        defaultPricelist:
                                          updatedUserTypes[type.id]
                                            .defaultPricelist,
                                        pricelists: [
                                          ...updatedUserTypes[type.id]
                                            .pricelists,
                                          pricelist.id,
                                        ],
                                      },
                                    })
                                  }
                                }}
                              >
                                <UserSettingCheck>
                                  <i
                                    className={
                                      'fal ' +
                                      (hasPricelist ? 'fa-check' : 'fa-times')
                                    }
                                  ></i>
                                </UserSettingCheck>
                              </UserSettingCell>
                            )
                          })}
                        </SettingRow>
                      )
                    })}
                </React.Fragment>
              </Table.Body>
            </UserSettingTable>
          )}
        </CompanyContext.Consumer>
      </InnerWrapper>
    </Wrapper>
  )
}
