import invariant from 'invariant'
import { safeParseFloat } from 'util/parsing'

const OP_NEQ = '!='
const OP_EQ = '='
const OP_GT = '>'
const OP_LT = '<'
const OP_GTE = '>='
const OP_LTE = '<='

const OP_MUL = '*'
const OP_ADD = '+'
const OP_SUB = '-'
const OP_DIV = '/'

const ONE_STRING_OPERATORS = [OP_EQ, OP_GT, OP_LT]
const TWO_STRING_OPERATORS = [OP_NEQ, OP_GTE, OP_LTE]

const TOKEN_REGEXP = /\s*([#0-9\w\pL.]+|\S)\s*/g
const NUMBER_REGEXP = /^\d+\.?\d*$/g
const NAME_REGEXP = /[#0-9-\w\pL]+/g

function isNumber(token) {
  return token && token.match(NUMBER_REGEXP) !== null
}

function isName(token) {
  return token && token.match(NAME_REGEXP) !== null
}

const AST_NODE_TYPE_NUMBER = 'number'
const AST_NODE_TYPE_NAME = 'name'

const MULEXPR_OPERATORS = [OP_MUL, OP_DIV]
const EXPR_OPERATORS = [OP_ADD, OP_SUB]

class AstNode {
  constructor({ type, value, lhs, rhs }) {
    this.type = type
    this.value = value
    this.lhs = lhs
    this.rhs = rhs
  }

  evaluate(variables) {
    // Interpolate variable if needed
    if (this.type === AST_NODE_TYPE_NAME) {
      if (this.value in variables) {
        return variables[this.value]
      } else {
        return 0
      }
    } else if (this.type === AST_NODE_TYPE_NUMBER) {
      return this.value
    } else {
      invariant(this.lhs, 'Operator ' + this.type + ' is missing a lhs')
      invariant(this.rhs, 'Operator ' + this.type + ' is missing a rhs')
      switch (this.type) {
        case OP_ADD:
          return this.lhs.evaluate(variables) + this.rhs.evaluate(variables)
        case OP_SUB:
          return this.lhs.evaluate(variables) - this.rhs.evaluate(variables)
        case OP_MUL:
          return this.lhs.evaluate(variables) * this.rhs.evaluate(variables)
        case OP_DIV:
          return this.lhs.evaluate(variables) / this.rhs.evaluate(variables)
        default:
          throw SyntaxError('Unknown operator ' + this.type)
      }
    }
  }
}

export class ArithmeticParser {
  constructor(formula) {
    this.formula = formula
    this.position = 0
    this.tokenized = this._tokenize()
    this.ast = {}

    this._parseToAst()
  }

  _tokenize() {
    let results = []
    let nextToken
    while ((nextToken = TOKEN_REGEXP.exec(this.formula)) !== null) {
      results.push(nextToken[1])
    }
    return results
  }

  _parseToAst() {
    this.ast = this._parseExpression()
  }

  _peek() {
    return this.tokenized[this.position]
  }

  _pop() {
    return this.tokenized[this.position++]
  }

  _consume() {
    this.position++
  }

  _parseTerm() {
    let next = this._pop()
    if (isNumber(next)) {
      return new AstNode({
        type: AST_NODE_TYPE_NUMBER,
        value: safeParseFloat(next),
      })
    } else if (isName(next)) {
      return new AstNode({ type: AST_NODE_TYPE_NAME, value: next })
    } else if (next === '(') {
      const expression = this._parseExpression()
      next = this._pop()
      invariant(next === ')', 'Expected close of parentheses, found ' + next)
      return expression
    }
  }

  _parseMultiplicationExpression() {
    const lhs = this._parseTerm()
    const operator = this._peek()
    if (MULEXPR_OPERATORS.includes(operator)) {
      this._consume()
      const rhs = this._parseMultiplicationExpression()
      return new AstNode({ type: operator, lhs, rhs })
    }
    return lhs
  }

  _parseExpression() {
    const lhs = this._parseMultiplicationExpression()
    const operator = this._peek()
    if (EXPR_OPERATORS.includes(operator)) {
      this._consume()
      const rhs = this._parseExpression()
      return new AstNode({ type: operator, lhs, rhs })
    }
    return lhs
  }

  evaluate(variables) {
    return this.ast.evaluate(variables)
  }
}

export class ConditionParser {
  constructor(condition) {
    this.condition = condition
    this.operator = '='
    this.value = 0
    this.valid = true
    this._parseCondition()
  }

  _parseCondition() {
    // Eat whitspace
    this.condition = this.condition.trim()

    this._parseOperator()
    this._parseValue()
  }

  _parseOperator() {
    // We peek at the second value to see if we have an equal sign.
    // If this is the case, we eat both
    if (this.condition[1] === '=') {
      const operatorToken = this.condition.slice(0, 2)

      if (!TWO_STRING_OPERATORS.includes(operatorToken)) {
        this.valid = false
        return
      }

      this.condition = this.condition.slice(2).trim()
      this.operator = operatorToken
    } else {
      const operatorToken = this.condition[0]

      if (!ONE_STRING_OPERATORS.includes(operatorToken)) {
        this.operator = OP_EQ
        return
      }

      this.condition = this.condition.slice(1).trim()
      this.operator = operatorToken
    }
  }

  _parseValue() {
    // Is it a number?
    if (this.condition.match(/^\d+\.?\d*$/)) {
      if (this.condition.indexOf('.') >= 0) {
        this.value = safeParseFloat(this.condition)
      } else {
        this.value = parseInt(this.condition)
      }
      // Is it a boolean?
    } else if (this.condition.toLowerCase().match(/^(true|false|t|f)/)) {
      if (this.condition[0].toLowerCase() === 't') {
        this.value = 1
      } else {
        this.value = 0
      }
    } else {
      this.valid = false
    }
  }

  /**
   *
   * @param {Boolean} value True if the value matches the condition
   */
  evaluate(value) {
    switch (this.operator) {
      case OP_NEQ:
        // eslint-disable-next-line
        return value != this.value
      case OP_EQ:
        // eslint-disable-next-line
        return value == this.value
      case OP_GT:
        return value > this.value
      case OP_LT:
        return value < this.value
      case OP_GTE:
        return value >= this.value
      case OP_LTE:
        return value <= this.value
      default:
        return false
    }
  }
}

const conditionParserCache = {}

export function conditionIsSatisfied(value, condition) {
  let parser = null
  if (condition.id in conditionParserCache) {
    parser = conditionParserCache[condition.id]
  } else {
    parser = new ConditionParser(condition.valueCondition)
    conditionParserCache[condition.valueCondition] = parser
  }
  return parser.evaluate(value)
}

export function recalculateActions(
  roomTemplate,
  selectedOptions,
  roomTemplateData = {}
) {
  const { options, formulas } = roomTemplate
  const validConditions = options.reduce((acc, option) => {
    option.optionConditions.forEach(condition => {
      const value = selectedOptions[option.id]
      if (value && conditionIsSatisfied(value, condition)) {
        acc.push(condition.id)
      }
    })
    return acc
  }, [])

  const optionsDictLookup = options.reduce((acc, next) => {
    acc[next.id] = next
    return acc
  }, {})

  const optionValuesByShortname = Object.keys(selectedOptions).reduce(
    (acc, optionId) => {
      const currentValue = selectedOptions[optionId]
      const shortName = '#' + optionsDictLookup[optionId].shortName

      acc[shortName] = currentValue
      return acc
    },
    {}
  )

  optionValuesByShortname['#area'] = parseInt(roomTemplateData.area)
  optionValuesByShortname['#floor'] = safeParseFloat(roomTemplateData.floor)

  const satisfiedFormulas = formulas.filter(formula => {
    return formula.optionConditions.every(condition =>
      validConditions.includes(condition.id)
    )
  })

  let articleActions = []

  // Perform the actual calculations
  satisfiedFormulas.forEach(formula => {
    const expression = formula.formula
    const result = Math.floor(
      new ArithmeticParser(expression).evaluate(optionValuesByShortname)
    )
    console.log(result, expression, optionValuesByShortname)
    const resultArticles = formula.articleActions.map(articleAction => ({
      ...articleAction.article,
      count: result,
      autoGenerated: true,
    }))
    articleActions = articleActions.concat(resultArticles)
  })

  return articleActions
}
