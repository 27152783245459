import { useQuery } from '@apollo/client'
import React from 'react'
import { useMemo } from 'react'
import { HeaderWrapper, Title, ViewWrapper } from '../components'
import { OrdersTable } from '../components/orders'
import { ALL_ORDERS_QUERY } from '../queries'
import { AllOrdersQuery } from '../types.graphql'

interface OrdersProps {}

export const Orders: React.UFC<OrdersProps> = () => {
  const { data } = useQuery<AllOrdersQuery>(ALL_ORDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const orders = useMemo(
    () => data?.allOrders.edges.map(edge => edge.node) ?? [],
    [data]
  )

  return (
    <ViewWrapper maxWidth="1200px">
      <HeaderWrapper>
        <Title>Bestillinger</Title>
      </HeaderWrapper>
      <OrdersTable orders={orders} />
    </ViewWrapper>
  )
}
