import { SelectOption } from '@ur/react-components'
import { eachYearOfInterval, getYear } from 'date-fns'

export function getYearOptions(): SelectOption<number>[] {
  return eachYearOfInterval({
    start: new Date(2018, 1, 1),
    end: new Date(),
  }).map(year => ({ label: String(getYear(year)), value: getYear(year) }))
}

export function fullNameToInitials(fullName: string) {
  const names = fullName.split(' ')

  const label =
    names[0] +
    ' ' +
    names
      .slice(1)
      .map(i => i.charAt(0))
      .join('. ')
      .toUpperCase()

  return label.padEnd(20)
}

export function truncateFirstAndLastName(fullName: string) {
  const [name, ...lastNames] = fullName.split(' ')
  return [name, lastNames[lastNames.length - 1]].join(' ')
}
