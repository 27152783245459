const mimeFileIconMap = [
  ['file-audio', /audio\/.+/],
  ['file-video', /video\/.+/],
  ['file-word',
    /application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.(document|template)/,
  ],
  ['file-powerpoint',
    /application\/vnd\.openxmlformats-officedocument\.presentationml\.(presentation|slide|slideshow|template)/,
    /application\/vnd\.ms-powerpoint(\.(slide|addin|presentation|slideshow|template)\.macroenabled\.12)?/,
  ],
  ['file-excel',
    /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.(sheet|template)/
  ],
  ['file-archive',
    /application\/(zip|x-(rar|7z|ace)-compressed|x-tar)/
  ],
  ['file-pdf', /application\/pdf/],
  ['file-csv', /text\/csv/],
  ['file-alt', /./]
]

const fileNameIconMap = [
  ['file-audio', /(mp3|wav|aiff|ogg)$/],
  ['file-video', /(mp4|wmv|avi|flv)$/],
  ['file-image', /(png|bmp|svg|jpg|jpeg|gif)$/],
  ['file-word', /(docx|doc|odt)$/],
  ['file-powerpoint', /(ppt|pptx|odp)$/,],
  ['file-excel', /(xls|xlsx|ods)$/],
  ['file-archive',/(zip|tar\.bz|tar\.gz|tar|rar|7z)$/],
  ['file-pdf', /(pdf)$/],
  ['file-csv', /(csv)$/],
  ['file-alt', /./]
]


export function getFileIcon(fileName){
  for (let iconMap of fileNameIconMap) {
    const icon = iconMap[0]
    const regexes = iconMap.slice(1)
    if (regexes.some(regex => regex.test(fileName)))
      return icon
  }
  return 'file-alt'
}

export function getFileIconByMime(mime){
  for (let iconMap of mimeFileIconMap) {
    const icon = iconMap[0]
    const regexes = iconMap.slice(1)
    if (regexes.some(regex => regex.test(mime)))
      return icon
  }
  return 'file-alt'
}