import React from 'react'
import { useIntl } from 'react-intl'

import FlexCenter from 'components/FlexCenter'
import Icon from 'components/Icon'
import Margin from 'components/Margin'

interface CenteredErrorMessageProps {
  className?: string

  intlId?: string
  defaultMessage?: string
  message?: string
  icon?: React.ReactNode
  marginTop?: string
  noAccess?: boolean
}

const CenteredErrorMessage: React.FC<CenteredErrorMessageProps> = ({
  className,

  intlId,
  defaultMessage,
  message,
  icon,
  marginTop,
  noAccess,
}) => {
  const intl = useIntl()
  const _message = noAccess
    ? intl.formatMessage({
        id: 'permissions.no-access-to-page',
        defaultMessage: 'No access',
      })
    : intlId
    ? intl.formatMessage({
        id: intlId,
        defaultMessage: defaultMessage,
      })
    : message ||
      intl.formatMessage({
        id: 'server.general-error-update',
        defaultMessage: 'Server error. Please try refreshing the page.',
      })
  const _icon =
    typeof icon === 'string' ? <Icon icon={icon} color="error" /> : icon
  return (
    <FlexCenter fillParent vertical horizontal className={className}>
      <Margin inline right="6px">
        {_icon}
      </Margin>
      {_message}
    </FlexCenter>
  )
}

CenteredErrorMessage.defaultProps = {
  icon: 'exclamation-triangle',
  marginTop: '6em',
  noAccess: false,
}

export default CenteredErrorMessage
