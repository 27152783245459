import { OrderNode, ShallowOrderNode } from 'modules/services/types.graphql'
import theme from 'theme'

const statuses = {
  CREATED: 'Opprettet',
  PENDING: 'Avventer',
  PAID: 'Betalt',
  SCHEDULED: 'Planlagt avtale',
  COMPLETED: 'Ferdiggjort',
}

export function getOrderStatus(order: OrderNode | ShallowOrderNode) {
  return order.status in statuses ? statuses[order.status] : 'Ugyldig status'
}

export function getStatusColor(status: string) {
  switch (status) {
    case 'CREATED':
      return theme.colors.warning
    case 'PENDING':
      return theme.colors.warning
    case 'PAID':
      return theme.colors.information
    case 'SCHEDULED':
      return theme.colors.success
    case 'COMPLETED':
      return theme.colors.success
    default:
      return theme.colors.danger
  }
}
