import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 6px;
    user-select: none;
  }
`
const CheckMark = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 24px;
  height: 24px;

  border: 1px solid ${props => props.theme.colors.darkGray};
  border-radius: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;

    width: calc(100% - 4px);
    height: calc(100% - 4px);

    border-radius: 4px;
    background-color: ${props => props.theme.colors.primary};

    opacity: 0;
    transition: opacity 0.2s ease;
  }

  ${props => props.checked && `
    &::before {
      opacity: 1;
    }
  `}
`

function CheckButton({
  checked,

  label,

  onChange
}) {
  return (
    <Wrapper onClick={() => onChange(!checked)}>
      <CheckMark checked={checked} />
      <span>{label}</span>
    </Wrapper>
  )
}

CheckButton.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,

  label: PropTypes.string,

  onChange: PropTypes.func
}

CheckButton.defaultProps = {
  checked: false,

  label: '',

  onChange: () => void 0
}

export default CheckButton
