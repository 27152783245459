import React from 'react'
import styled from 'styled-components'
import isFunction from 'lodash/isFunction'

import { Button } from 'components/Button'
import { Modal as ModalObj, useModals } from 'store/modals'

const Wrapper = styled.div`
  width: 600px;
  background: white;

  border-radius: 4px;
`

const StandardHeader = styled.div`
  padding: 1em;

  h2 {
    margin: 0;
  }
`

const StandardBody = styled.div`
  padding: 1em;
`

const StandardFooter = styled.div``

interface ModalProps {
  modal: ModalObj
}
const Modal: React.FC<ModalProps> = ({ modal }) => {
  const { id, title, header, body, footer, onClose } = modal

  const removeModal = useModals('removeModal')
  const closeModal = onClose ? onClose : () => removeModal(id)

  return (
    <Wrapper>
      {isFunction(header) ? (
        header({ closeModal })
      ) : (
        <StandardHeader>
          <h2>{title}</h2>
        </StandardHeader>
      )}
      {isFunction(body) ? (
        body({ closeModal })
      ) : (
        <StandardBody>{body}</StandardBody>
      )}
      {isFunction(footer) ? (
        footer({ closeModal })
      ) : (
        <StandardFooter>
          <Button buttonStyle="success">Lukk</Button>
        </StandardFooter>
      )}
    </Wrapper>
  )
}

export default Modal
