import React, { useRef } from 'react'
import { Query } from '@apollo/client/react/components'
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'
import styled, { css } from 'styled-components'

import UserContext from 'context/UserContext'

import Login from 'modules/login'
import { Logout } from 'modules/login'
import PrivateRoute from 'containers/PrivateRoute'
import { Dashboard } from 'modules/dashboard'

import { StyledSidebar as Sidebar } from 'modules/sidebar'
import Header from 'modules/header'
// import Forum from 'modules/forum/Forum'
import { Users } from 'modules/users/Users'
import FullLoader from 'components/Loader/FullLoader'
import { WizardContainer } from 'modules/offers/OfferWizard'
import { ME_QUERY } from 'modules/users/queries'

import RegisterUser from 'modules/users/RegisterUser'

import { NeedPasswordChange } from 'modules/users/NeedPasswordChange'

// import Issues from 'modules/issues/Issues'
// import CreateIssue from 'modules/issues/CreateIssue'
// import Issue from 'modules/issues/Issue'

import ToastContainer from 'modules/toasts/ToastContainer'

import Offers from 'modules/offers/Offers'
import { OfferDetails } from 'modules/offers/OfferDetails'
import EditOffer from 'modules/offers/EditOffer/EditOffer'
import CloneOffer from 'modules/offers/CloneOffer/CloneOffer'

import { User } from 'modules/users/User'
import { EditUser } from 'modules/users/EditUser/EditUser'

import { CompanySettings } from 'modules/companies/CompanySettings/CompanySettings'
import CompanyUserTypes from 'modules/companies/CompanyUserTypes/CompanyUserTypes'
import ContentScrollProvider from 'containers/ContentScrollProvider'

import Handbook from 'modules/handbook/Handbook'
import HandbookFolder from 'modules/handbook/HandbookFolder'
import HandbookEntry from 'modules/handbook/HandbookEntry'
import HandbookCreateEntry from 'modules/handbook/HandbookCreateEntry'
import HandbookUpdateEntry from 'modules/handbook/HandbookUpdateEntry'
import HandbookCreateFolder from 'modules/handbook/HandbookCreateFolder'
import HandbookUpdateFolder from 'modules/handbook/HandbookUpdateFolder'

import {
  CreatePricelist,
  EditPricelist,
  Pricelist,
  Pricelists,
} from 'modules/articles'

import CreateCompanyUserType from 'modules/companies/CompanyUserTypes/CreateCompanyUserType'
import ModalContainer from 'modules/modals/ModalContainer'

import PermissionsRequiredRoute from './PermissionsRequiredRoute/PermissionsRequiredRoute'
import RoomTemplates from 'modules/roomtemplates'
import AddNewRoomTemplate from 'modules/roomtemplates/AddNewRoomTemplate'
import { SidebarGraph } from 'store/sidebar'
import SuperuserPermissionsRequiredRoute from './SuperuserPermissionsRequired/SuperuserPermissionsRequiredRoute'
import EditRoomTemplate from 'modules/roomtemplates/EditRoomTemplate'
import CompanyPricelists from 'modules/companies/CompanyPricelists/CompanyPricelists'

import { Statistics } from 'modules/statistics/Statistics'
import { Services, Service, Orders, Order } from 'modules/services'

const MainLayout = styled.div`
  display: grid;

  ${props =>
    props.sidebarOpen
      ? `
    grid-template-columns: 224px 1fr;
    grid-template-rows: 80px 1fr;

  `
      : `
    grid-template-columns: 0px 1fr;
    grid-template-rows: 80px 1fr;
  `}

  grid-template-areas:
    'sidebar header'
    'sidebar main';

  width: 100%;
  height: 100%;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    ${props =>
      props.sidebarOpen &&
      css`
        overflow-y: auto;
      `};
  }
`

const ContentWrapper = styled.div`
  grid-area: main;
  background-color: ${props => props.theme.colors.veryLightGray};
  overflow-y: auto;
`

const HeaderWrapper = styled.div`
  grid-area: header;
`

const SidebarWrapper = styled.aside`
  grid-area: sidebar;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid white;
    border-radius: 999px;
    background-color: white;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.gray9};
    }
  }
`

const companyBasePathRegex = /^\/c\/[\w-]+/

function Routes() {
  const match = window.location.pathname.match(companyBasePathRegex)
  const contentRef = useRef(null)
  const [{ open }] = SidebarGraph.useGraph()

  if (!match) {
    throw Error(
      'You rendered the Routes component without verifying that a company on the route exists.'
    )
  }

  return (
    <Router basename={match[0]}>
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* This nested functionality makes sure we only render the main layout when logged in */}
        <PrivateRoute
          path=""
          render={() => {
            return (
              <Query query={ME_QUERY}>
                {({ loading, data, error }) => {
                  if (loading) {
                    return <FullLoader />
                  }
                  if (error) {
                    return <span>Something went wrong</span>
                  }

                  const me = data.me
                  if (!me) {
                    window.location.href = '/login'
                    return <span>Redirecting</span>
                  }

                  if (!me.hasRegistered) {
                    return <RegisterUser user={me} />
                  } else if (me.needsPasswordChange) {
                    return <NeedPasswordChange />
                  }

                  return (
                    <UserContext.Provider value={me}>
                      <MainLayout sidebarOpen={open}>
                        <SidebarWrapper>
                          <Sidebar open={open} />
                        </SidebarWrapper>
                        <HeaderWrapper>
                          <Header />
                        </HeaderWrapper>

                        <ContentWrapper ref={contentRef}>
                          <ContentScrollProvider contentRef={contentRef}>
                            <Switch>
                              <PrivateRoute
                                exact
                                path="/dashboard"
                                component={Dashboard}
                              />

                              {/* Users */}
                              <PermissionsRequiredRoute
                                permissions="users.change_user"
                                exact
                                path="/users/:userId/edit"
                                alsoMe="userId"
                                component={EditUser}
                              />
                              <PermissionsRequiredRoute
                                permissions="users.view_user"
                                exact
                                path="/users/:userId"
                                alsoMe="userId"
                                component={User}
                              />
                              <PermissionsRequiredRoute
                                permissions="users.view_user"
                                exact
                                path="/users"
                                component={Users}
                              />

                              {/* Pricelist */}
                              <PermissionsRequiredRoute
                                permissions="users.view_user"
                                exact
                                path="/statistics"
                                component={Statistics}
                              />

                              <PermissionsRequiredRoute
                                permissions="articles.add_pricelist"
                                exact
                                path="/pricelists/create"
                                component={CreatePricelist}
                              />
                              <PermissionsRequiredRoute
                                permissions="articles.change_pricelist"
                                exact
                                path="/pricelists/:pricelistId/edit"
                                component={EditPricelist}
                              />
                              <PermissionsRequiredRoute
                                permissions="articles.view_pricelist"
                                exact
                                path="/pricelists/:pricelistId"
                                component={Pricelist}
                              />
                              <PermissionsRequiredRoute
                                permissions="articles.view_pricelist"
                                exact
                                path="/pricelists"
                                component={Pricelists}
                              />

                              {/* Handbook */}
                              <PrivateRoute
                                exact
                                path="/handbook/folder/:folderId"
                                component={HandbookFolder}
                              />
                              <PrivateRoute
                                exact
                                path="/handbook/entry/:entryId"
                                component={HandbookEntry}
                              />
                              <PrivateRoute
                                exact
                                path="/handbook/create-entry"
                                component={HandbookCreateEntry}
                              />
                              <PrivateRoute
                                exact
                                path="/handbook/create-folder"
                                component={HandbookCreateFolder}
                              />
                              <PrivateRoute
                                exact
                                path="/handbook/update-entry"
                                component={HandbookUpdateEntry}
                              />
                              <PrivateRoute
                                exact
                                path="/handbook/update-folder"
                                component={HandbookUpdateFolder}
                              />
                              <Redirect from="/handbook/:slug" to="/handbook" />
                              <PrivateRoute
                                path="/handbook"
                                component={Handbook}
                              />
                              <PermissionsRequiredRoute
                                permissions="companies.change_company"
                                exact
                                path="/company-user-types/company-pricelists"
                                component={CompanyPricelists}
                              />
                              <PermissionsRequiredRoute
                                permissions="companies.change_company"
                                exact
                                path="/company-settings"
                                component={CompanySettings}
                              />
                              <PermissionsRequiredRoute
                                permissions="companies.change_company"
                                exact
                                path="/company-user-types/create"
                                component={CreateCompanyUserType}
                              />
                              <PermissionsRequiredRoute
                                permissions="companies.change_company"
                                exact
                                path="/company-user-types"
                                component={CompanyUserTypes}
                              />

                              <PermissionsRequiredRoute
                                permissions="calculator.view_offer"
                                exact
                                path="/offers"
                                component={Offers}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.add_offer"
                                path="/offers/:id/new-version"
                                component={WizardContainer}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.add_offer"
                                path="/offers/create"
                                component={WizardContainer}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.change_offer"
                                path="/offers/:id/edit"
                                component={EditOffer}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.add_offer"
                                path="/offers/:id/clone"
                                component={CloneOffer}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.view_offer"
                                exact
                                path="/offers/:id"
                                component={OfferDetails}
                              />
                              <PermissionsRequiredRoute
                                permissions="calculator.view_offer"
                                exact
                                path="/offers/:id/diff"
                                component={OfferDetails}
                              />

                              <SuperuserPermissionsRequiredRoute
                                exact
                                path="/roomtemplates"
                                component={RoomTemplates}
                              />

                              <SuperuserPermissionsRequiredRoute
                                exact
                                path="/roomtemplates/add-new-room-template"
                                component={AddNewRoomTemplate}
                              />

                              <SuperuserPermissionsRequiredRoute
                                exact
                                path="/roomtemplates/edit-room-template/:templateId"
                                component={EditRoomTemplate}
                              />

                              <PrivateRoute
                                exact
                                path="/services"
                                component={Services}
                              />
                              <PrivateRoute
                                exact
                                path="/orders"
                                component={Orders}
                              />
                              <PrivateRoute
                                exact
                                path="/orders/:id"
                                component={Order}
                              />
                              <PrivateRoute
                                exact
                                path="/services/:id"
                                component={Service}
                              />

                              <PrivateRoute
                                exact
                                path="/logout"
                                component={Logout}
                              />

                              <Redirect from="/" to="/dashboard" />
                            </Switch>
                          </ContentScrollProvider>
                        </ContentWrapper>
                        <ToastContainer />
                        <ModalContainer />
                      </MainLayout>
                    </UserContext.Provider>
                  )
                }}
              </Query>
            )
          }}
        />
      </Switch>
    </Router>
  )
}

export default Routes
