import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import { USERS_QUERY } from './queries'

import Loader from 'components/Loader'

import { UsersTable } from './UsersTable'
import {
  AllUsersQueryReturn,
  AllUsersQueryParameters,
} from 'types/graphql/users'

const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`

interface UsersPrimaryListProps {
  search: string
}
const UsersPrimaryList: React.FC<UsersPrimaryListProps> = ({ search }) => {
  const { loading, data, error } = useQuery<
    AllUsersQueryReturn,
    AllUsersQueryParameters
  >(USERS_QUERY, {
    variables: { q: search, isActive: true },
  })

  if (loading) {
    return (
      <ContentWrapper>
        <Loader size={50} thickness={5} />
      </ContentWrapper>
    )
  }

  if (error) {
    return <span>Error fetching users</span>
  }

  const users = data.allUsers.edges.map(edge => ({
    ...edge.node,
    userTypes: edge.node.userTypes,
  }))

  return <UsersTable users={users} />
}

export default UsersPrimaryList
