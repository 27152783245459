import styled from 'styled-components'
import { CSSTextAlign } from 'types/style'

export interface TableHeaderCellProps {
  textAlign?: CSSTextAlign
}

const TableHeaderCell = styled.th<TableHeaderCellProps>`
  text-transform: uppercase;
  font-size: 12px;
  color: ${props => props.theme.colors.lightGray};
  padding: 1rem 1rem;

  text-align: ${props => props.textAlign || 'left'};
`

export default TableHeaderCell
