import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { isUndefined } from 'util/typechecks'

interface WrapperProps {
  direction: RadioGroupDirection
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
`
interface RadioButtonProps {
  checked: boolean
  direction: RadioGroupDirection
  gap: string
}

const RadioButton = styled.div<RadioButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-of-type) {
    ${props =>
      props.direction === 'vertical'
        ? `margin-top: ${props.gap};`
        : `margin-left: ${props.gap}`};
  }

  span {
    margin-left: 6px;
    user-select: none;
  }

  div.check-mark {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 24px;
    height: 24px;

    border: 1px solid ${props => props.theme.colors.darkGray};
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;

      width: calc(100% - 4px);
      height: calc(100% - 4px);

      border-radius: 4px;
      background-color: ${props => props.theme.colors.primary};

      opacity: 0;
      transition: opacity 0.2s ease;
    }

    ${props =>
      props.checked &&
      css`
        &::before {
          opacity: 1;
        }
      `}
  }
`

export interface Radio<ValueType> {
  value: ValueType
  label: string

  onClick?: (value: ValueType, currentValue: ValueType) => boolean | undefined
}
type RadioGroupDirection = 'horizontal' | 'vertical'
interface RadioGroupProps<ValueType> {
  className?: string
  value: ValueType
  radios: Radio<ValueType>[]

  direction?: RadioGroupDirection
  gap?: string

  onChange: (value: ValueType) => void
}
const RadioGroup = <ValueType,>({
  className,
  value,
  radios,

  direction = 'vertical',
  gap = '6px',

  onChange,
}: RadioGroupProps<ValueType>) => {
  const handleRadioClick = useCallback(
    (radio: Radio<ValueType>) => {
      if (
        !isUndefined(radio.onClick) &&
        radio.onClick(radio.value, value) === false
      )
        return

      if (radio.value === value) return
      onChange(radio.value)
    },
    [onChange, value]
  )

  return (
    <Wrapper className={className} direction={direction}>
      {radios.map((radio, index) => (
        <RadioButton
          key={index}
          checked={radio.value === value}
          direction={direction}
          gap={gap}
          onClick={() => handleRadioClick(radio)}
        >
          <div className="check-mark"></div>
          <span>{radio.label}</span>
        </RadioButton>
      ))}
    </Wrapper>
  )
}

export default RadioGroup
