export const defaultBody = `Hei {customer_name},

Vedlagt ligger et tilbud fra {company_name} ved navn {offer_name}.

Klikk på denne lenken for å se på tilbudet: {offer_link}
NB: Det er ikke mulig å svare til denne adressen. Spørsmål eller svar rettes til vår referanse {sending_user_email}.

Med vennlig hilsen,
{company_name}
{company_contact_phone}`
