import styled from 'styled-components'
import { CSSTextTransform, CSSTextAlign } from 'types/css/rules'

export interface TableHeaderCellProps {
  sortedStatus?: number | undefined
  textTransform?: CSSTextTransform
  textAlign?: CSSTextAlign
  fontSize?: string
  fontWeight?: string | number
  color?: string
  lineHeight?: number

  height?: string
  width?: string
  padding?: string
  cursor?: string
}

export const TableHeaderCell = styled.th.attrs(
  (props: TableHeaderCellProps) => ({
    boxSizing: 'border-box',
    position: 'relative',

    textTransform: 'uppercase',
    fontSize: '1rem',
    color: 'black',
    height: '64px',
    padding: '0  1.5625rem',
    fontWeight: 600,
    userSelect: 'none',

    textAlign: 'left',
    ...props,
  })
)`
  box-sizing: ${props => props.boxSizing};
  position: ${props => props.position};

  text-transform: ${props => props.textTransform};
  font-size: ${props => props.fontSize};
  color: ${props =>
    props?.sortedStatus
      ? props.theme.colors.primary
      : props.theme.colors.gray3};
  height: ${props => props.height};
  width: ${props => props.width};
  padding: ${props => props.padding};
  font-weight: ${props => props.fontWeight};
  user-select: none;

  text-align: ${props => props.textAlign};
  line-height: ${props => props.lineHeight};

  cursor: ${props => props.cursor};
`
