import { useForm } from '@ur/react-hooks'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { ServiceOptionItemForm } from '../types'
import { OptionItemNode } from '../types.graphql'
import {
  CloseModalIcon,
  Field,
  FileInputWrapper,
  Label,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ModalWrapper,
} from './common'
import Thumbnail from 'components/Thumbnail'
import FileInput from 'components/Input/FileInput'
import { notEmptyValidation } from 'util/helpers'
import { useServiceOptionItemMutations } from '../mutations.hooks'

interface OptionItemFormModalProps {
  optionItem?: OptionItemNode
  optionId?: string
  closeModalCallback: () => void
}

export const OptionItemFormModal: React.UFC<OptionItemFormModalProps> = ({
  optionItem,
  optionId,
  closeModalCallback,
}) => {
  const {
    formValues: form,
    formErrors,
    formChangeHandler: handler,
    formValid,
    submitHandler,
  } = useForm<ServiceOptionItemForm>({
    values: {
      name: optionItem?.name ?? '',
      description: optionItem?.description ?? '',
      price: optionItem?.price.toString() ?? '',
      image: undefined,
    },
    validators: {
      name: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
      price: val => (notEmptyValidation(val) ? null : 'Kan ikke være tom'),
    },
  })

  const { serviceOptionItem: optionItemMutation } =
    useServiceOptionItemMutations()

  const submitForm = async (values: ServiceOptionItemForm) => {
    if (!optionItem) {
      await optionItemMutation.create({
        variables: {
          input: {
            ...values,
            serviceOption: optionId,
          },
        },
      })
    } else {
      await optionItemMutation.patch({
        variables: {
          id: optionItem.id,
          input: values,
        },
      })
    }
    closeModalCallback()
  }

  return (
    <ModalWrapper>
      <ModalTitle>{optionItem ? 'Endre valg' : 'Nytt valg'}</ModalTitle>
      <CloseModalIcon
        icon="times"
        size="24px"
        type="light"
        color="darkblue"
        margin="14px 0 0 0"
        onClick={closeModalCallback}
      />
      <ModalBody>
        <Field>
          <Label>Navn</Label>
          <Input
            value={form.name}
            error={formErrors.name}
            fullWidth
            onChange={handler('name')}
          />
        </Field>
        <Field>
          <Label>Beskrivelse</Label>
          <Input
            value={form.description}
            error={formErrors.description}
            fullWidth
            onChange={handler('description')}
          />
        </Field>
        <Field>
          <Label>Pris</Label>
          <Input
            value={form.price}
            error={formErrors.price}
            fullWidth
            type="number"
            onChange={handler('price')}
          />
        </Field>
        <Field>
          <Label>Bilde</Label>
          <FileInputWrapper>
            {optionItem?.image && (
              <Thumbnail src={optionItem.image} size={50} />
            )}
            <FileInput
              label={optionItem?.image ? 'Endre bilde' : 'Velg fil'}
              onChange={files => handler('image')(files[0])}
            />
          </FileInputWrapper>
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button
          fullWidth
          primary
          height="48px"
          disabled={!formValid}
          onClick={submitHandler(submitForm)}
        >
          {optionItem ? 'Lagre' : 'Opprett'}
        </Button>
      </ModalFooter>
    </ModalWrapper>
  )
}
