import { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@ur/react-components'
import { RoomArticleRowDiff } from './RoomArticleRowDiff'
import { ArticleDiff } from './types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.white};
`

interface SummaryLineProps {
  displaySeperator: boolean
}

const SummaryLine = styled.div<SummaryLineProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: ${props => props.theme.colors.darkBlue};
  border-bottom: ${props =>
    props.displaySeperator
      ? `1px solid ${props.theme.colors.background}`
      : 'none'};

  padding: 0 30px;
  > :first-child {
    font-weight: 600;
  }
`

const SummaryLineCell = styled.span`
  width: 100px;
  text-align: left;
`

interface ShowDetailsProps {
  show: boolean
}

const DetailsWrapper = styled.div<ShowDetailsProps>`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  transition: transform 0.5 ease;
`

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 3px solid ${props => props.theme.colors.background};

  > :first-child {
    padding-left: 40px;
  }

  > :last-child {
    width: 100px;
    padding-right: 40px;
    text-align: left;
  }
`

const DetailsHeaderCell = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 300px;
  color: ${props => props.theme.colors.gray3};
  ${props => props.theme.media.mobile} {
    width: 200px;
  }
`

const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div {
    padding: 0 20px 0 40px;
  }
`
interface RoomDiff {
  name: string
  floor: number
  area: number
  comment: string
  articleDiffs: ArticleDiff[]
}

interface RoomDiffRowProps {
  room: RoomDiff
  showArticlePrices: boolean
}

const ShowDetailsIcon = styled(Icon)<ShowDetailsProps>`
  transform: ${props => (props.show ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.25s ease-out;
`

export const RoomDiffRow: React.FC<RoomDiffRowProps> = ({
  room,
  showArticlePrices,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const toggle = () => setShowDetails(!showDetails)
  return (
    <Wrapper>
      <SummaryLine displaySeperator={showDetails}>
        <SummaryLineCell>{room.name}</SummaryLineCell>
        <SummaryLineCell>{room.floor} etg.</SummaryLineCell>
        <SummaryLineCell>{room.area}m2</SummaryLineCell>
        <SummaryLineCell>{room.comment}</SummaryLineCell>

        <ShowDetailsIcon
          icon="chevron-down"
          onClick={toggle}
          show={showDetails}
        />
      </SummaryLine>
      <DetailsWrapper show={showDetails}>
        <DetailsHeader>
          <DetailsHeaderCell>VARE</DetailsHeaderCell>
          <DetailsHeaderCell>ANTALL</DetailsHeaderCell>
        </DetailsHeader>
        <DetailsContent>
          {room.articleDiffs.map((article, index) => {
            return (
              <RoomArticleRowDiff
                article={article}
                key={index}
                showArticlePrices={showArticlePrices}
              />
            )
          })}
        </DetailsContent>
      </DetailsWrapper>
    </Wrapper>
  )
}
