import { gql } from '@apollo/client'
import { CompanyFragments } from './queries'

export const PATCH_COMPANY_MUTATION = gql`
  mutation PatchCompany($id: ID!, $input: PatchCompanyInput!) {
    patchCompany(id: $id, input: $input) {
      company {
        ...CompanyInfo
      }
    }
  }
  ${CompanyFragments.info}
`

export const UPDATE_COMPANY_SETTINGS_MUTATION = gql`
  mutation UpdateCompanySettings(
    $fullName: String
    $address: String
    $postalCode: String
    $city: String
    $contactEmailAddress: String
    $contactPhoneNumber: String
    $nextInternalOfferReference: String
    $offerConditions: String
  ) {
    updateCompanySettings(
      fullName: $fullName
      address: $address
      postalCode: $postalCode
      city: $city
      contactEmailAddress: $contactEmailAddress
      contactPhoneNumber: $contactPhoneNumber
      nextInternalOfferReference: $nextInternalOfferReference
      offerConditions: $offerConditions
    ) {
      company {
        ...CompanyInfo
        ...CompanySettings
      }
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.settings}
`

export const UPDATE_COMPANY_USER_TYPES_MUTATION = gql`
  mutation UpdateCompanyUserTypes($updatedUserTypes: [UpdatedUserTypesInput]!) {
    updateCompanyUserTypes(updatedUserTypes: $updatedUserTypes) {
      company {
        ...CompanyInfo
        ...CompanyUserTypes
      }
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.userTypes}
`
export const UPDATE_COMPANY_PRICELISTS_MUTATION = gql`
  mutation UpdateCompanyPricelists(
    $updatedUserTypePricelistsInput: [UpdatedUserTypePricelistsInput]!
  ) {
    updateCompanyPricelists(
      updatedUserTypesPricelists: $updatedUserTypePricelistsInput
    ) {
      updatedUserTypes {
        id
      }
    }
  }
`
