import React, {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { useQuery } from '@apollo/client'
import { ALL_ARTICLES_QUERY } from 'modules/articles/queries'
import Select from 'components/Select'
import { SelectOption } from 'components/Select/Select'
import theme from 'theme'
import {
  AllArticlesQueryReturn,
  AllArticlesQueryParameters,
  ArticleNode,
} from 'types/graphql/articles'
import { isUndefined } from 'util/typechecks'
import { useDebounce } from 'util/hooks'

interface ArticleSelectorProps {
  pricelist?: string | null
  selectedArticle: string | null

  width?: string

  onChange: (articleId: string, article: ArticleNode) => void
}

interface ClearHandle {
  clear: () => void
}

const ArticleSelector: React.ForwardRefRenderFunction<
  ClearHandle,
  ArticleSelectorProps
> = (
  {
    pricelist,
    selectedArticle,

    width = '300px',

    onChange,
  },
  ref
) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const { data } = useQuery<AllArticlesQueryReturn, AllArticlesQueryParameters>(
    ALL_ARTICLES_QUERY,
    {
      variables: {
        pricelist: pricelist ? pricelist : undefined,
        pricelist_Isnull: pricelist === null ? true : undefined,
        q: debouncedSearch,
        first: 20,
      },
    }
  )

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSearch('')
    },
  }))

  let options: SelectOption<string, ArticleNode>[] = useMemo(() => {
    if (isUndefined(data)) return []

    return data.allArticles.edges.map(edge => ({
      label: edge.node.name,
      value: edge.node.id,
      extra: edge.node,
    }))
  }, [data])

  function handleChange(id: string, article: ArticleNode) {
    setSearch(article.name)
    onChange(id, article)
  }

  return (
    <Select
      value={selectedArticle}
      options={options}
      placeholder="Velg en vare"
      shadow="none"
      width={width}
      border={'1px solid ' + theme.colors.lightGray}
      searchValue={search}
      searchable
      onSearchChanged={newValue => setSearch(newValue)}
      onChange={handleChange}
    />
  )
}

export default forwardRef(ArticleSelector)
