import styled from 'styled-components'
import PropTypes from 'prop-types'

const Label = styled.label`
  display: block;
  text-align: left;
  color: ${props => props.theme.colors.darkBlue};
  font-weight: 600;
  margin-bottom: 7px;
`

Label.propTypes = {
  bold: PropTypes.bool,
}

export default Label
