import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useClickOutside from 'util/hooks/useClickOutside'
import { AccordionButton } from '../common/AccordionButton'

const Wrapper = styled.div<{ open: boolean }>`
  ${props => props.theme.media.mobile} {
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.colors.white};
    .--summary-accordion-content {
      box-shadow: none;
      background-color: transparent;
      ${props => !props.open && 'display: none;'}
    }
    margin-bottom: 4px;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .--accordion-button {
    display: none;
  }
  ${props => props.theme.media.mobile} {
    .--accordion-button {
      display: block;
    }
    border-bottom: 1px solid #f7f8fc;
  }
`
const Label = styled.label`
  font-size: 20px;
  color: ${props => props.theme.colors.darkBlue};
  margin-bottom: 10px;

  ${props => props.theme.media.mobile} {
    padding: 0 16px;
    padding-top: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`

interface OfferSummaryAccordionProps {
  title: string
  autoClose?: boolean
  onClose?: () => void
}

export const OfferSummaryAccordion: React.FC<OfferSummaryAccordionProps> = ({
  title,
  children,
  autoClose,
  onClose,
}) => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  function handleClickOutside(_event: unknown) {
    if (!autoClose) return
    setOpen(false)
    onClose?.()
  }

  useClickOutside(menuRef, handleClickOutside)

  return (
    <Wrapper ref={menuRef} open={open} className="--offer-summary-accordion">
      <Header>
        <Label>{title}</Label>
        <AccordionButton show={open} toggle={() => setOpen(!open)} />
      </Header>
      {children}
    </Wrapper>
  )
}
