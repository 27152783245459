import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useContext, useState } from 'react'
import { NoFunctions } from 'types/util'
import { sleep } from 'util/time'

export type ToastType = 'success' | 'error' | 'warning' | 'info'
export interface Toast {
  id: string
  type: ToastType
  message: string | JSX.Element
}

interface ToastStoreData {
  toasts: Toast[]
  addToast: (
    type: ToastType,
    message: string | JSX.Element,
    life?: number
  ) => void
}
const ToastContext = React.createContext<ToastStoreData | null>(null)

function useToasts(): ToastStoreData
function useToasts<T extends keyof ToastStoreData>(value?: T): ToastStoreData[T]
function useToasts<T extends keyof ToastStoreData>(value?: T) {
  const ctx = useContext(ToastContext)
  if (ctx === null)
    throw new Error('useToasts can only be used within a ToastProvider.')

  if (typeof value === 'undefined') return ctx

  return ctx[value]
}
export { useToasts }

export type InitialToastState = Partial<NoFunctions<ToastStoreData>>

function useToastState(initialState: InitialToastState = {}): ToastStoreData {
  const [toasts, setToasts] = useState(initialState.toasts ?? [])

  const addToast = useCallback(
    async (
      type: ToastType,
      message: string | JSX.Element,
      life: number = 3000
    ) => {
      const newId = uniqueId('toast')
      setToasts(t =>
        t.concat([
          {
            id: newId,
            type,
            message,
          },
        ])
      )

      await sleep(life)
      setToasts(ts => ts.filter(t => t.id !== newId))
    },
    []
  )

  return {
    toasts,
    addToast,
  }
}

const ToastProvider: React.FC<{
  initialState?: InitialToastState
}> = ({ children, initialState = {} }) => {
  const state = useToastState(initialState)

  return <ToastContext.Provider value={state}>{children}</ToastContext.Provider>
}

export default ToastProvider
