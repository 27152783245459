import { useQuery } from '@apollo/client'
import FullLoader from 'components/Loader/FullLoader'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useOffers, OfferClone } from 'store/offer'
import {
  OfferFullQueryParameters,
  OfferFullQueryReturn,
} from 'types/graphql/offers'
import { OFFER_FULL_QUERY } from '../queries'

const CloneOffer: React.FC = () => {
  const match = useRouteMatch<{ id: string }>()
  const history = useHistory()

  const offers = useOffers()

  const offerId = match.params.id
  const { loading, data } = useQuery<
    OfferFullQueryReturn,
    OfferFullQueryParameters
  >(OFFER_FULL_QUERY, {
    variables: { id: offerId },
  })

  if (loading || !data) {
    return <FullLoader />
  }

  const offer = data.offer
  // Bootstrap priceOverrides, and flatten articles
  const preparedOfferData: OfferClone = {
    ...offer,
    rooms: offer.rooms.map(room => ({
      ...room,
      localId: room.id,
      articles: room.articles.map(roomArticle => ({
        id: roomArticle.article.id,
        identifier: roomArticle.article.identifier,
        name: roomArticle.article.name,
        price: roomArticle.priceOverride,
        count: roomArticle.count,
      })),
    })),
  }

  offers.setTempCloneData(preparedOfferData)
  history.push('/offers/create')
  return null
}

export default CloneOffer
