import styled from 'styled-components'
import { useGlobal } from '@ur/react-hooks'
import { RoomArticleCard } from './RoomArticleCard'
import { useQuery } from '@apollo/client'
import { USER_PRICELISTS_QUERY } from 'modules/articles/queries'
import { UserPricelistsQueryReturn } from 'types/graphql/articles'
import { useEffect, useState } from 'react'
import Select from 'components/Select'
import { SelectOption } from 'components/Select/Select'
import { useCallback } from 'react'
import { StepControls } from '../common'
import { useWindowDimensions } from 'util/hooks/index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1100px;
`

const PricelistWrapper = styled.div`
  margin: 10px 0 15px;

  ${props => props.theme.media.mobile} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 8px 0;
  }
`

export const AddArticles = () => {
  const [offerRooms] = useGlobal('offer.rooms')
  const [selectedPriceList, setSelectedPriceList] = useState('')
  const [pricelistOptions, setPricelistOptions] = useState<
    SelectOption<string>[]
  >([])
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)

  const { width } = useWindowDimensions()

  const { data: userPricelistsQueryData } = useQuery<UserPricelistsQueryReturn>(
    USER_PRICELISTS_QUERY,
    {
      onCompleted(localData) {
        const { allUserPricelists } = localData
        const pricelists = allUserPricelists.edges.map(edge => edge.node)
        setSelectedPriceList(pricelists.shift()?.id ?? '')
      },
    }
  )

  const roomArticlesAreValid = useCallback((): boolean => {
    let roomsAreValid = true

    offerRooms.forEach(room => {
      const { articles } = room
      if (articles.length === 0) {
        roomsAreValid = false
      }
    })

    return roomsAreValid
  }, [offerRooms])

  useEffect(() => {
    if (roomArticlesAreValid()) {
      setNextButtonDisabled(false)
    } else {
      setNextButtonDisabled(true)
    }
  }, [roomArticlesAreValid, setNextButtonDisabled])

  useEffect(() => {
    if (userPricelistsQueryData === undefined) return

    const { allUserPricelists } = userPricelistsQueryData
    const flattenedPriceLists = allUserPricelists.edges.map(edge => edge.node)

    setPricelistOptions(
      flattenedPriceLists
        .map(pricelist => ({ value: pricelist.id, label: pricelist.name }))
        .reduce(
          (accumulator, currentValue) => [...accumulator, currentValue],
          []
        )
    )
  }, [userPricelistsQueryData])

  return (
    <Wrapper>
      <PricelistWrapper>
        <Select
          value={selectedPriceList}
          onChange={value => {
            setSelectedPriceList(value)
          }}
          options={pricelistOptions}
          width={width > 768 ? '300px' : `${width - 28}px`}
        />
      </PricelistWrapper>
      {offerRooms.map((room, index) => (
        <RoomArticleCard
          key={`${room.name}-${index}`}
          roomID={room.localID}
          pricelist={selectedPriceList}
        />
      ))}
      <StepControls width="400px" nextDisabled={nextButtonDisabled} />
    </Wrapper>
  )
}
