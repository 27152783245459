import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Icon, useToast } from '@ur/react-components'
import styled, { css } from 'styled-components'
import { RESET_PASSWORD_MUTATION, PATCH_USER_MUTATION } from '../mutations'
import { ZIndexRange } from 'types/enums'
import { useHistory } from 'react-router-dom'
import useClickOutside from 'util/hooks/useClickOutside'
import { UserNode } from 'types/graphql/users'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  cursor: pointer;
`
const CloseMenuContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px 0 0;
`

const Menu = styled.aside`
  position: absolute;
  display: flex;
  width: 300px;
  flex-direction: column;
  font-weight: 600;
  top: calc(100% + 8px);
  right: calc(100% - 24px);
  z-index: ${ZIndexRange.Dropdowns};
  user-select: none;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f7f8fc;
`

interface MobileHighlight {
  mobileHighlight?: boolean
  mobileOnly?: boolean
}

const MenuItem = styled.div<MobileHighlight>`
  display: ${props => (props.mobileOnly ? 'none' : 'inline-flex')};
  flex-direction: row;
  padding: 0 0 34px 20px;
  cursor: pointer;
  align-items: center;
  border: 0px;
  color: ${props => props.theme.colors.gray2} !important;

  &:hover {
    color: ${props => props.theme.colors.darkBlue} !important;
  }

  i {
    padding-right: 7px;
  }

  ${props => props.theme.media.mobile} {
    display: inline-flex;

    ${props =>
      props.mobileHighlight &&
      css`
        color: ${props => props.theme.colors.darkBlue} !important;
      `}
  }
`
interface UserDropdownProps {
  user: UserNode
  open: boolean
  onBarsClick: () => void
  onClose: () => void
}

export const UsersDropdown: React.FC<UserDropdownProps> = ({
  open,
  user,
  onBarsClick,
  onClose,
}) => {
  const toasts = useToast()
  const history = useHistory()
  const menuRef = useRef<HTMLElement | null>(null)

  useClickOutside(menuRef, onClose)

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    refetchQueries: ['Users'],
    onCompleted: () => {
      toasts('success', 'Passord tilbakestilt.')
    },
  })
  const [patchUser] = useMutation(PATCH_USER_MUTATION, {
    refetchQueries: ['Users'],
    onCompleted: () => {
      toasts(
        'success',
        user.isActive ? 'Bruker deaktivert.' : 'Bruker aktivert.'
      )
    },
  })

  const handleResetPassword = () => {
    onClose()
    resetPassword({ variables: { user: user.id } })
  }

  const handleDeactivate = () => {
    onClose()
    patchUser({
      variables: { id: user.id, input: { isActive: !user.isActive } },
    })
  }

  return (
    <Wrapper>
      <Icon
        icon="ellipsis-v"
        color="black"
        size="20px"
        type="solid"
        onClick={onBarsClick}
      />
      {open && (
        <Menu ref={menuRef}>
          <CloseMenuContainer onClick={onClose}>
            <Icon color="darkBlue" icon="times" size="24px" type="light" />
          </CloseMenuContainer>

          <MenuItem
            mobileHighlight={true}
            mobileOnly={false}
            onClick={() => {
              history.push(`/users/${user.id}`)
            }}
          >
            <Icon icon="user" type="solid" /> <span>Profil</span>
          </MenuItem>

          <MenuItem
            mobileOnly={true}
            onClick={() => window.open(`tel:${user.phoneNumber}`)}
          >
            <Icon icon="phone" type="solid" size="17px" />
            <span>Ring</span>
          </MenuItem>

          <MenuItem
            mobileOnly={true}
            onClick={() => window.open(`mailto:${user.email}`)}
          >
            <Icon icon="envelope" type="solid" size="17px" />
            <span>E-post</span>
          </MenuItem>

          <MenuItem mobileOnly={false} onClick={handleResetPassword}>
            <Icon icon="history" type="solid" size="17px" />
            <span>Tilbakestill passord</span>
          </MenuItem>
          <MenuItem mobileOnly={false} onClick={handleDeactivate}>
            <Icon icon="trash-alt" type="solid" size="17px" />{' '}
            <span>Inaktiver</span>
          </MenuItem>
        </Menu>
      )}
    </Wrapper>
  )
}
