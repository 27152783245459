import { gql } from '@apollo/client'

export const ArticleFragments = {
  pricelistInfo: gql`
    fragment PricelistInfo on PricelistNode {
      id
      name
      articleCount
      userTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  articleInfo: gql`
    fragment ArticleInfo on ArticleNode {
      id
      name
      identifier
      cost
      price
    }
  `,
}

export const ALL_PRICELISTS_QUERY = gql`
  query AllPricelists($q: String) {
    allPricelists(q: $q) {
      edges {
        node {
          ...PricelistInfo
        }
      }
    }
    rootPricelist {
      ...ArticleInfo
    }
  }
  ${ArticleFragments.pricelistInfo}
  ${ArticleFragments.articleInfo}
`

export const PRICELIST_QUERY = gql`
  query Pricelist($id: ID!) {
    pricelist(id: $id) {
      id
      name
      articles {
        id
        identifier
        name
        cost
        price
      }
    }
  }
`

export const ARTICLE_QUERY = gql`
  query Article($id: ID!) {
    article(id: $id) {
      ...ArticleInfo
    }
  }
  ${ArticleFragments.articleInfo}
`

export const ALL_ARTICLES_QUERY = gql`
  query AllArticles(
    $pricelist: ID
    $pricelist_Isnull: Boolean
    $q: String
    $first: Int
  ) {
    allArticles(
      pricelist: $pricelist
      pricelist_Isnull: $pricelist_Isnull
      q: $q
      first: $first
    ) {
      edges {
        node {
          ...ArticleInfo
        }
      }
    }
  }
  ${ArticleFragments.articleInfo}
`

export const ALL_ARTICLES_SHALLOW_QUERY = gql`
  query AllArticlesShallow($q: String, $pricelistId: ID, $orderBy: String) {
    allArticles(q: $q, pricelist: $pricelistId, orderBy: $orderBy) {
      edges {
        node {
          id
          identifier
          name
          price
          cost
        }
      }
    }
  }
`

export const USER_PRICELISTS_QUERY = gql`
  query AllUserPricelists {
    allUserPricelists {
      edges {
        node {
          id
          name
          articleCount
        }
      }
    }
  }
`
