import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Quill from 'quill/dist/quill.min.js'
import 'quill/dist/quill.snow.css'

const Wrapper = styled.div`

  box-shadow: ${props => props.shadow ? props.theme.shadow.default : ""};
  border-radius: 4px;

  .ql-toolbar{
    border-radius: 4px 4px 0 0;
    background: white;
  }

  .ql-container{
    border-radius: 0 0 4px 4px;
    background: white;
  }

  .ql-editor{
    min-height: 200px;
    color: ${props => props.theme.colors.darkGray};

    font-family: 'Gilroy', sans-serif;
  }
`

Wrapper.defaultProps = {
  shadow: true
}

export default function QuillEditor({
  onInit,
  onChange,
  initialContents,
  wrapperProps
}) {
  const [hasMountedQuill, setHasMountedQuill] = useState(false)
  const quillRef = useRef(null)
  const quillEditorRef = useRef()

  useEffect(() => {
    if (!quillRef || hasMountedQuill) return

    const quill = new Quill(quillRef.current, {
      theme: 'snow',
    })
    

    if (initialContents){
      quill.setContents(initialContents)
    }

    if (onInit) onInit(quill)
    quillEditorRef.current = quill  
    
    setHasMountedQuill(true)

  }, [hasMountedQuill, initialContents, onInit])

  useEffect(() => {
    if (!quillEditorRef || !quillEditorRef.current){
      return
    }

    const quill = quillEditorRef.current
    quill.on('text-change', handleQuillEditorChanged)

    function handleQuillEditorChanged(delta, oldContents, source){
      onChange(quillEditorRef.current.getContents(), delta, source, quillEditorRef)
    }

    return () => quill.off('text-change', handleQuillEditorChanged)
  }, [onChange])


  return (
    <Wrapper {...wrapperProps}>
      <div ref={quillRef}></div>
    </Wrapper>
  )
}

QuillEditor.propTypes = {
  wrapperProps: PropTypes.object
}

QuillEditor.defaultProps ={
  wrapperProps: {}
}
