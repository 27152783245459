import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Button } from 'components/Button'
const FileInputWrapper = styled.div`
  input[type='file'] {
    display: none;
  }
`

const WrappingLabel = styled.label``

const FileInfo = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0.25em;
  }
`

export default function FileInput({
  multiple,
  onChange,
  label,
  listAllFiles,
  preview,
  legalFileTypes,
}) {
  const [fileData, setFileData] = useState([])
  const fileInputRef = useRef(null)

  function handleChange(e) {
    setFileData(e.target.files)
    onChange(e.target.files, e)
  }

  return (
    <FileInputWrapper>
      <WrappingLabel
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click()
          }
        }}
      >
        <Button type="button" primary icon="fa fa-file-upload">
          {label}
        </Button>
        <input
          ref={fileInputRef}
          multiple={multiple}
          type="file"
          onChange={handleChange}
        />
        <FileInfo>
          {Array.from(fileData).map(file => (
            <li>{file.name}</li>
          ))}
        </FileInfo>
      </WrappingLabel>
    </FileInputWrapper>
  )
}

FileInput.propTypes = {}

FileInput.defaultProps = {
  label: 'Velg fil',
  listAllFiles: false,
  multiple: false,
  preview: false,
  legalFileTypes: null,
}
