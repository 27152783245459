import React from 'react'
import styled from 'styled-components'
import Margin from 'components/Margin'
import { Link } from 'react-router-dom'
import RoomTemplateForm from './RoomTemplateForm'

const Wrapper = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
`
const TitleHeader = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  margin-top: 0;
`

interface AddNewRoomTemplateProps {}

const AddNewRoomTemplate: React.FC<AddNewRoomTemplateProps> = () => {
  return (
    <Wrapper>
      <Margin top="3em">
        <Link to={'/roomtemplates'}>
          <i className="fal fa-long-arrow-left"></i>&nbsp; Tilbake
        </Link>
        <TitleHeader>Lag ny rommal</TitleHeader>
      </Margin>
      <RoomTemplateForm />
    </Wrapper>
  )
}

export default AddNewRoomTemplate
