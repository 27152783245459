import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon/index'
import { getFileIcon } from 'util/file'

const Toggle = styled.div`
  cursor: pointer;
  border: none;
  position: absolute;
  top: -10px;
  right: 0;

  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  i {
    display: none;
  }

  color: ${props => props.theme.colors.gray};

  :hover {
    i {
      display: block;
    }
  }

  ${props =>
    props.toggled &&
    `
    color: ${props.theme.colors.darkGray};

    i{
      display: block;
    }
  `}
`

const Wrapper = styled.div`
  text-align: center;
  position: relative;

  color: ${props => (props.toggled ? props.theme.colors.lightGray : 'auto')};

  :hover {
    ${Toggle} {
      border: 1px solid ${props => props.theme.colors.gray};
    }
  }
`

const FileIconWrapper = styled.div`
  font-size: 32px;
`

const Name = styled.div`
  font-size: 12px;
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default function FileToggleTile({ file, toggled, onToggle }) {
  return (
    <Wrapper toggled={toggled} title={file}>
      <Toggle toggled={toggled} onClick={onToggle}>
        <i className="fa fa-trash"></i>
      </Toggle>
      <FileIconWrapper>
        <Icon icon={getFileIcon(file)} />
      </FileIconWrapper>
      <Name>{file}</Name>
    </Wrapper>
  )
}
