import { useQuery, useMutation } from '@apollo/client'
import { Icon, useToast } from '@ur/react-components'
import { QuoTable } from 'components/QuoTable'
import { Column } from 'components/QuoTable/types'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import {
  AllArticlesShallowQueryParameters,
  AllArticlesShallowQueryReturn,
  ArticleShallowNode,
} from 'types/graphql/articles'
import { ArticleDropdown } from '.'
import { DELETE_ARTICLE_MUTATION } from './mutations'
import { ALL_ARTICLES_SHALLOW_QUERY } from './queries'

const StyledQuotable = styled(QuoTable)`
  box-shadow: ${props => props.theme.shadow.input};
  tr {
    font-size: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }
`
interface IdCellProps {
  miniTable?: boolean
}

const IdCell = styled.h1<IdCellProps>`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 34ch;
`
const NameCell = styled.h1`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.darkBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 34ch;
`
interface ArticleTableProps {
  id: string | null
  showData?: boolean
  miniTable?: boolean

  onEditArticle?: (article: ArticleShallowNode) => void
}

export const ArticleTable: React.UFC<ArticleTableProps> = ({
  id,
  showData,
  miniTable,
  onEditArticle,
}) => {
  const toasts = useToast()
  const [openMenu, setOpenMenu] = useState('')
  const [sort, setSort] = useState('name')

  const { data, error } = useQuery<
    AllArticlesShallowQueryReturn,
    AllArticlesShallowQueryParameters
  >(ALL_ARTICLES_SHALLOW_QUERY, {
    variables: {
      pricelistId: id,
      orderBy: sort,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const [deleteArticle] = useMutation(DELETE_ARTICLE_MUTATION, {
    refetchQueries: ['Pricelist'],
    onCompleted: () => {
      toasts('success', 'Varen er slettet')
    },
  })

  function onDeleteArticle(id: string) {
    return () =>
      deleteArticle({
        variables: {
          id,
        },
      })
  }

  if (error) {
    return <span>Kan ikke hente varer</span>
  }

  const columns: Column[] = [
    {
      id: 'identifier',
      label: 'id',
      cellStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '34ch',
      },
      sortable: true,
      sortKey: 'identifier',
    },
    {
      id: 'name',
      label: 'navn',
      hide: isMobile,
      sortable: true,
      sortKey: 'name',
    },
    {
      id: 'cost',
      label: 'kostpris',
      hide: isMobile || miniTable,
      headerProps: {
        style: { whiteSpace: 'nowrap' },
      },
      sortable: true,
      sortKey: 'cost',
    },
    {
      id: 'price',
      label: 'salgspris',
      hide: isMobile,
      headerProps: {
        style: { whiteSpace: 'nowrap' },
      },
      sortable: true,
      sortKey: 'price',
    },
    {
      id: 'rowMenu',
      label: '',
      isMenu: true,
    },
  ]
  const articles = data?.allArticles.edges.map(edge => edge.node) ?? []

  const articlesData = articles.map(article => {
    return {
      id: article.id,
      data: {
        identifier: (
          <IdCell title={article.identifier}>{article.identifier}</IdCell>
        ),
        name: <NameCell title={article.name}> {article.name}</NameCell>,
        cost: article.cost,
        price: article.price,
        rowMenu: miniTable ? (
          <ArticleDropdown
            open={openMenu === article.id}
            onBarsClick={() => setOpenMenu(article.id)}
            article={article}
            onEdit={() => onEditArticle?.(article)}
            onClose={() => setOpenMenu('')}
          />
        ) : (
          <Icon
            icon="trash-alt"
            type="solid"
            onClick={onDeleteArticle(article.id)}
          />
        ),
      },
    }
  })

  return (
    <StyledQuotable
      columns={columns}
      tableProps={{ fullWidth: true, tableLayout: 'fixed' }}
      data={showData ? articlesData : []}
      sort={sort}
      loadingProps={{
        rows: 3,
        expectedWidths: [400, 400, 10],
      }}
      headerProps={{}}
      onSortChanged={setSort}
    />
  )
}
