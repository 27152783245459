import React from 'react'
import styled from 'styled-components'

import { ChangePassword } from './ChangePassword'
import { ChangeProfilePicture } from './ChangeProfilePicture'
import { ChangeBasicInfo } from './ChangeBasicInfo'
import { useUser } from 'util/hooks'

const Wrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}
  display: grid;
  grid-template-areas:
    'title    title title'
    'picture  info  password';
  grid-column-gap: 48px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr 3fr 2fr;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`
const Title = styled.span`
  grid-area: title;
  font-size: 32px;
  font-weight: 600;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

export const EditUser: React.FC = () => {
  const user = useUser()
  return (
    <Wrapper>
      <Title>Endre bruker</Title>

      <ChangeProfilePicture user={user} area="picture" />
      <ChangeBasicInfo user={user} area="info" />
      <ChangePassword user={user} area="password" />
    </Wrapper>
  )
}
