import { useQuery } from '@apollo/client'
import { Modal } from '@ur/react-components'
import { Button } from 'components/Button'
import Thumbnail from 'components/Thumbnail'
import { useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { BreadcrumbsGraph } from 'store/breadcrumbs'
import styled from 'styled-components'
import {
  ButtonWrapper,
  ViewWrapper,
  HeaderWrapper,
  OptionCard,
  Title,
  ServiceFormModal,
  OptionFormModal,
} from '../components'
import { ALL_TAGS_QUERY, GET_SERVICE_QUERY } from '../queries'
import {
  AllTagsQuery,
  GetServiceQuery,
  GetServiceQueryVariables,
} from '../types.graphql'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 1rem;
  }
`

export const Service: React.UFC = () => {
  const match = useRouteMatch<{ id: string }>()

  const serviceId = match.params.id

  const [openModal, setOpenModal] = useState(false)
  const [openOptionModal, setOpenOptionModal] = useState(false)
  const [{ setOverride }] = BreadcrumbsGraph.useGraph()

  const { data } = useQuery<GetServiceQuery, GetServiceQueryVariables>(
    GET_SERVICE_QUERY,
    {
      variables: {
        id: serviceId,
      },
      onCompleted({ service }) {
        if (!service) return
        setOverride(service.id, service.name)
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  const { data: tagsData } = useQuery<AllTagsQuery>(ALL_TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const service = useMemo(() => {
    return data?.service ?? null
  }, [data])

  const tags = useMemo(() => {
    return tagsData?.allTags.edges?.map(edge => edge.node) ?? []
  }, [tagsData])

  const serviceOptions = useMemo(() => {
    return service?.serviceOptions.edges.map(edge => edge.node) ?? []
  }, [service])

  return (
    <ViewWrapper>
      <HeaderWrapper>
        <TitleWrapper>
          {service?.image && <Thumbnail src={service.image} size={50} />}
          <Title>{service?.name ?? 'Fant ikke tjeneste'}</Title>
        </TitleWrapper>
        <Button
          type="button"
          icon={{ size: '20px', icon: 'edit' }}
          onClick={() => setOpenModal(true)}
        >
          Endre tjeneste
        </Button>
      </HeaderWrapper>
      {serviceOptions.map(option => (
        <OptionCard option={option} key={option.id} />
      ))}
      <ButtonWrapper>
        <Button
          icon={{ icon: 'plus', size: '20px' }}
          onClick={() => setOpenOptionModal(true)}
        >
          Ny valgkategori
        </Button>
      </ButtonWrapper>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ServiceFormModal
          service={service}
          tags={tags}
          closeModalCallback={() => setOpenModal(false)}
        />
      </Modal>
      <Modal open={openOptionModal} onClose={() => setOpenOptionModal(false)}>
        <OptionFormModal
          serviceId={service?.id}
          closeModalCallback={() => setOpenOptionModal(false)}
        />
      </Modal>
    </ViewWrapper>
  )
}
