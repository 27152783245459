import styled from 'styled-components'
import { OfferWizardArticle } from 'modules/offers/OfferWizard/types'
import { numberWithSpaces } from 'util/parsing'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'articlename articlecount articleprice articletotal';
  margin-bottom: 20px;
  grid-template-columns: 200px 130px 130px 130px;
  grid-column-gap: 10px 0 0;

  ${props => props.theme.media.mobile} {
    grid-template-areas:
      'articlename'
      'articlecount'
      'articleprice'
      'articletotal';
    grid-row-gap: 10px;
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
`

const ArticleName = styled.div`
  grid-area: articlename;
  font-size: 14px;
  font-weight: 600;
  width: 200px;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`
const ArticleCount = styled.div`
  grid-area: articlecount;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${props => props.theme.colors.darkBlue};
`

const MobileOnlyLabel = styled.div`
  display: none;
  margin-right: 3px;
  ${props => props.theme.media.mobile} {
    display: flex;
  }
`

const ArticlePrice = styled.div`
  grid-area: articleprice;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${props => props.theme.colors.darkBlue};
`

const ArticleTotal = styled.div`
  grid-area: articletotal;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: ${props => props.theme.colors.darkBlue};
`

interface AddRoomTemplateActionResultsRowProps {
  article: OfferWizardArticle
}

export const AddRoomTemplateActionResultsRow: React.FC<
  AddRoomTemplateActionResultsRowProps
> = ({ article }) => {
  return (
    <Wrapper>
      <ArticleName>{article.name}</ArticleName>
      <ArticleCount>
        <MobileOnlyLabel>Antall: </MobileOnlyLabel>
        {article.count}
      </ArticleCount>
      <ArticlePrice>
        <MobileOnlyLabel>Pris: </MobileOnlyLabel>
        {numberWithSpaces(article.price)},- NOK
      </ArticlePrice>
      <ArticleTotal>
        <MobileOnlyLabel>Totalt: </MobileOnlyLabel>
        {numberWithSpaces(article.price * article.count)},- NOK
      </ArticleTotal>
    </Wrapper>
  )
}
