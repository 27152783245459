import { CrumbInsert } from '@ur/react-components'
import traph from 'react-traph'

interface Overrides {
  [key: string]: string | null
}
interface BreadcrumbsGraphType {
  overrides: Overrides
  inserts: CrumbInsert[]

  setOverride: (key: string, value: string) => void
  setOverrides: (overrides: Overrides) => void
  deleteOverride: (key: string) => void
  deleteOverrides: (...keys: string[]) => void

  setInserts: (inserts: CrumbInsert[]) => void
  clearInserts: () => void

  setOverridesAndInserts: (overrides: Overrides, inserts: CrumbInsert[]) => void
}

export const BreadcrumbsGraph = traph<BreadcrumbsGraphType>({
  overrides: {},
  inserts: [],
  setOverride(key, value) {
    if (this.overrides[key] === value) return

    this.updateGraph({
      overrides: {
        [key]: value,
      },
    })
  },
  setOverrides(overrides) {
    if (
      Object.entries(overrides).every(
        ([key, value]) =>
          this.overrides.hasOwnProperty(key) && this.overrides[key] === value
      )
    )
      return

    this.updateGraph({
      overrides,
    })
  },
  deleteOverride(key) {
    this.deleteOverrides(key)
  },
  deleteOverrides(...keys) {
    const overrides = { ...this.overrides }
    for (const key of keys) delete overrides[key]

    this.updateGraph({
      overrides,
    })
  },

  setInserts(inserts) {
    this.updateGraph({
      inserts,
    })
  },
  clearInserts() {
    this.updateGraph({ inserts: [] })
  },

  setOverridesAndInserts(overrides, inserts) {
    if (
      Object.entries(overrides).every(
        ([key, value]) =>
          this.overrides.hasOwnProperty(key) && this.overrides[key] === value
      )
    )
      overrides = {}

    this.updateGraph({
      overrides,
      inserts,
    })
  },
})
