import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import PermissionsRequired from 'containers/PermissionsRequired/PermissionsRequired'
import { BarChart } from 'modules/statistics/BarChart'
import { DoughnutChart } from 'modules/statistics/DoughnutChart'
import getYear from 'date-fns/getYear'
import { isMobile } from 'react-device-detect'

const DashboardWrapper = styled.div`
  ${props => props.theme.defaultContentWrapper}

  display: flex;
  flex-direction: column;
  gap: 56px;
  padding-bottom: 40px;
`

const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkBlue};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`
const SectionTitle = styled.h1`
  color: ${props => props.theme.colors.darkBlue};
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
`
const ShortcutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const DashboardLink = styled(Link)`
  background-color: white;

  width: 100%;
  min-width: 130px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  align-items: center;

  i {
    font-size: 27px;
  }
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 56px;
  text-align: center;
  padding: 1.438rem 1rem 0.688rem 1rem;
  box-sizing: border-box;

  cursor: pointer;

  color: ${props => props.theme.colors.darkBlue};

  &:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
`

const GraphCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  ${props => props.theme.media.mobile} {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
`

export const Dashboard: React.FC = () => {
  const currentYear = String(getYear(new Date()))

  return (
    <DashboardWrapper>
      <Title>Kontrollpanel</Title>

      <Section>
        <SectionTitle>Snarveier</SectionTitle>
        <ShortcutWrapper>
          <PermissionsRequired permissions="users.view_user">
            <DashboardLink to="/users">
              <i className="fa fa-users"></i>
              <span>Ansatte</span>
            </DashboardLink>
          </PermissionsRequired>
          <PermissionsRequired permissions="calculator.view_offer">
            <DashboardLink to="/offers">
              <i className="fa fa-cart-plus"></i>
              <span>Tilbud</span>
            </DashboardLink>
          </PermissionsRequired>
          <PermissionsRequired permissions="articles.view_pricelist">
            <DashboardLink to="/pricelists">
              <i className="fa fa-clipboard-list"></i>
              <span>Prislister</span>
            </DashboardLink>
          </PermissionsRequired>
          <PermissionsRequired permissions="handbook.view_handbookfolder">
            <DashboardLink to="/handbook">
              <i className="fa fa-book"></i>
              <span>Håndbok</span>
            </DashboardLink>
          </PermissionsRequired>
        </ShortcutWrapper>
      </Section>
      <Section>
        <SectionTitle>
          {isMobile ? 'Salgsoversikt ' + currentYear : 'Salgsoversikt'}
        </SectionTitle>
        <GraphCardWrapper>
          <DoughnutChart inputYear={2021} enableFilter={false} />
          <BarChart inputYear={2021} />
        </GraphCardWrapper>
      </Section>
    </DashboardWrapper>
  )
}
