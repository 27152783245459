import React, { useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { FormattedMessage } from 'react-intl'
import { validateField } from 'util/forms'

import Margin from 'components/Margin'
import Padding from 'components/Padding'
import Color from 'components/Color'
import FlexCenter from 'components/FlexCenter'
import { Button } from 'components/Button'
import Form from 'components/Form'
import { REGISTER_USER_MUTATION } from './mutations'
import RegisterUserField from './RegisterUserField'
import Grid from 'components/Grid'

export default function RegisterUser({ user }) {
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    // internalNumber: user.internalNumber,
    address: user.address,
    postalCode: user.postalCode,
    city: user.city,
    // contactPersonName: user.contactPersonName,
    // contactPersonPhoneNumber: user.contactPersonPhoneNumber,
  })

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    // internalNumber: null,
    address: null,
    postalCode: null,
    city: null,
    // contactPersonName: null,
    // contactPersonPhoneNumber: null,
  })

  function submitForm(registerUserMutation) {
    const standardValuation = x => !!x
    const newErrors = {}
    // Validate
    newErrors['firstName'] = validateField(
      formData,
      'firstName',
      standardValuation
    )
    newErrors['lastName'] = validateField(
      formData,
      'lastName',
      standardValuation
    )
    newErrors['phoneNumber'] = validateField(
      formData,
      'phoneNumber',
      standardValuation
    )
    // newErrors['internalNumber'] = validateField(formData, 'internalNumber', standardValuation)
    newErrors['address'] = validateField(formData, 'address', standardValuation)
    newErrors['postalCode'] = validateField(
      formData,
      'postalCode',
      standardValuation
    )
    newErrors['city'] = validateField(formData, 'city', standardValuation)
    // newErrors['contactPersonName'] = validateField(formData, 'contactPersonName', standardValuation)
    // newErrors['contactPersonPhoneNumber'] = validateField(formData, 'contactPersonPhoneNumber', standardValuation)

    const canSubmit = !Object.values(newErrors).some(x => x !== null)

    if (!canSubmit) {
      setErrors(newErrors)
      return
    }

    registerUserMutation({
      variables: {
        ...formData,
      },
    })
  }

  return (
    <Mutation mutation={REGISTER_USER_MUTATION}>
      {registerUser => (
        <Padding top="6rem">
          <FlexCenter horizontal>
            <Form preventDefault onSubmit={() => submitForm(registerUser)}>
              <Margin bottom="3em">
                <h1>
                  <Color primary>
                    <FormattedMessage
                      id="registerUser.title"
                      defaultMessage="Fyll inn din personlige info."
                    />
                  </Color>
                </h1>
                <p>
                  <Color color="darkGray">
                    <FormattedMessage
                      id="registerUser.description"
                      defaultMessage="Siden dette er første gang du logger inn, må du registrere noe standardinfo."
                    />
                  </Color>
                </p>
              </Margin>
              <Grid columns="1fr 1fr">
                <RegisterUserField
                  name="firstName"
                  labelId="common.firstName"
                  labelDefaultMessage="Fornavn"
                  errors={errors.firstName}
                  value={formData.firstName}
                  onChange={e =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
                <RegisterUserField
                  name="lastName"
                  labelId="common.lastName"
                  labelDefaultMessage="Etternavn"
                  errors={errors.lastName}
                  value={formData.lastName}
                  onChange={e =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
                <RegisterUserField
                  name="phoneNumber"
                  labelId="common.phoneNumber"
                  labelDefaultMessage="Telefonnummer"
                  errors={errors.phoneNumber}
                  value={formData.phoneNumber}
                  onChange={e =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                />
                {/* <RegisterUserField
                  name="internalNumber"
                  labelId="common.internalNumber"
                  labelDefaultMessage="Internal number"
                  errors={errors.internalNumber}
                  value={formData.internalNumber}
                  onChange={e => setFormData({ ...formData, internalNumber: e.target.value })}
                /> */}
                <RegisterUserField
                  name="address"
                  labelId="common.address"
                  labelDefaultMessage="Adresse"
                  errors={errors.address}
                  value={formData.address}
                  onChange={e =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                />
                <RegisterUserField
                  name="postalCode"
                  labelId="common.postalCode"
                  labelDefaultMessage="Postnummer"
                  errors={errors.postalCode}
                  value={formData.postalCode}
                  onChange={e =>
                    setFormData({ ...formData, postalCode: e.target.value })
                  }
                />
                <RegisterUserField
                  name="city"
                  labelId="common.city"
                  labelDefaultMessage="By"
                  errors={errors.city}
                  value={formData.city}
                  onChange={e =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
                {/* <RegisterUserField
                  name="contactPersonName"
                  labelId="common.contactPersonName"
                  labelDefaultMessage="Contact person name"
                  errors={errors.contactPersonName}
                  value={formData.contactPersonName}
                  onChange={e => setFormData({ ...formData, contactPersonName: e.target.value })}
                />
                <RegisterUserField
                  name="contactPersonPhoneNumber"
                  labelId="common.contactPersonPhoneNumber"
                  labelDefaultMessage="Contact person phone"
                  errors={errors.contactPersonPhoneNumber}
                  value={formData.contactPersonPhoneNumber}
                  onChange={e => setFormData({ ...formData, contactPersonPhoneNumber: e.target.value })}
                /> */}
              </Grid>
              <Margin left="1em" right="1em" top="1em">
                <Button primary width="100%">
                  <FormattedMessage
                    id="common.submit"
                    defaultMessage="Submit"
                  />
                </Button>
              </Margin>
            </Form>
          </FlexCenter>
        </Padding>
      )}
    </Mutation>
  )
}
