import {
  FilterCheckmarksWrapper,
  FilterChildren,
  FilterPopup,
  StyledCheckbox,
  StyledScrollBar,
} from 'components/FilterPopup'
import xor from 'lodash/xor'
import React from 'react'
import { UserNode } from 'types/graphql/users'

export interface UsersFilter {
  excludeUsers: string[]
}

type UserPopupFilterProps = FilterChildren<UsersFilter> & {
  users: Pick<UserNode, 'id' | 'fullName' | 'totalSales'>[]
}

export const UserPopupFilter: React.UFC<UserPopupFilterProps> = ({
  users,
  filter,
  onUpdate,
}) => {
  function toggleUser(id: string) {
    return () =>
      onUpdate({
        excludeUsers: xor(filter.excludeUsers, [id]),
      })
  }

  return (
    <FilterPopup>
      <FilterCheckmarksWrapper>
        <StyledScrollBar>
          {users.map(user => {
            const checked = !filter.excludeUsers.includes(user.id)
            return (
              <StyledCheckbox
                key={user.id}
                checked={checked}
                label={user.fullName}
                boxSize="16px"
                onChange={toggleUser(user.id)}
              />
            )
          })}
        </StyledScrollBar>
      </FilterCheckmarksWrapper>
    </FilterPopup>
  )
}
