import styled from 'styled-components'

export interface FlexCenterProps {
  horizontal?: boolean
  vertical?: boolean
  fillParent?: boolean
}

export default styled.div<FlexCenterProps>`
  display: flex;
  justify-content: ${props => (props.horizontal ? 'center' : 'auto')};
  align-items: ${props => (props.vertical ? 'center' : 'auto')};
  width: ${props => (props.fillParent ? '100%' : 'auto')};
  height: ${props => (props.fillParent ? '100%' : 'auto')};
`
