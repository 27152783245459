import React from 'react'
import Card from 'components/Card/index'

import Table from 'components/Table'

export default function AddRoomTemplateActionResults({
  articles
}) {
  return (
    <Card>
      <Table fullWidth>
        <Table.Head>
          <Table.Row>
            <Table.HeaderCell>Navn</Table.HeaderCell>
            <Table.HeaderCell>Antall</Table.HeaderCell>
            <Table.HeaderCell>Pris</Table.HeaderCell>
            <Table.HeaderCell>Sum</Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {articles && articles.length > 0 ? (
            articles.map(article => (
              <Table.Row key={article.id}>
                <Table.Cell>{article.name} (A)</Table.Cell>
                <Table.Cell>{article.count}</Table.Cell>
                <Table.Cell>{article.price} kr</Table.Cell>
                <Table.Cell>{article.price * article.count} kr</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="4">Ingen varer lagt til enda</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Card>
  )
}
