import Table from './Table'
import TableHead from './TableHead'
import TableHeaderCell from './TableHeaderCell'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TableCell from './TableCell'

type TableWithParts = {
  Head: typeof TableHead
  HeaderCell: typeof TableHeaderCell
  Body: typeof TableBody
  Row: typeof TableRow
  Cell: typeof TableCell
} & typeof Table
;(Table as TableWithParts).Head = TableHead
;(Table as TableWithParts).HeaderCell = TableHeaderCell
;(Table as TableWithParts).Body = TableBody
;(Table as TableWithParts).Row = TableRow
;(Table as TableWithParts).Cell = TableCell

export { Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell }

export default Table as TableWithParts
