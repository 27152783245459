import { gql } from '@apollo/client'

/* Service mutations */
export const CREATE_SERVICE_MUTATION = gql`
  mutation CreateServiceMutation($input: CreateServiceInput!) {
    createService(input: $input) {
      service {
        id
      }
    }
  }
`

export const PATCH_SERVICE_MUTATION = gql`
  mutation PatchServiceMutation($id: ID!, $input: PatchServiceInput!) {
    patchService(id: $id, input: $input) {
      service {
        id
      }
    }
  }
`

export const DELETE_SERVICE_MUTATION = gql`
  mutation DeleteServiceMutation($id: ID!) {
    deleteService(id: $id) {
      deletedId
    }
  }
`

/* Service Option Mutations */
export const CREATE_SERVICE_OPTION_MUTATION = gql`
  mutation CreateServiceOptionMutation($input: CreateServiceOptionInput!) {
    createServiceOption(input: $input) {
      serviceOption {
        id
      }
    }
  }
`
export const PATCH_SERVICE_OPTION_MUTATION = gql`
  mutation PatchServiceOptionMutation(
    $id: ID!
    $input: PatchServiceOptionInput!
  ) {
    patchServiceOption(id: $id, input: $input) {
      serviceOption {
        id
      }
    }
  }
`

export const DELETE_SERVICE_OPTION_MUTATION = gql`
  mutation DeleteServiceOptionMutation($id: ID!) {
    deleteServiceOption(id: $id) {
      deletedId
    }
  }
`

/* Service Option Item mutations */
export const CREATE_SERVICE_OPTION_ITEM_MUTATION = gql`
  mutation CreateServiceOptioItemnMutation(
    $input: CreateServiceOptionItemInput!
  ) {
    createServiceOptionItem(input: $input) {
      serviceOptionItem {
        id
      }
    }
  }
`
export const PATCH_SERVICE_OPTION_ITEM_MUTATION = gql`
  mutation PatchServiceOptionItemMutation(
    $id: ID!
    $input: PatchServiceOptionItemInput!
  ) {
    patchServiceOptionItem(id: $id, input: $input) {
      serviceOptionItem {
        id
      }
    }
  }
`

export const DELETE_SERVICE_OPTION_ITEM_MUTATION = gql`
  mutation DeleteServiceOptionItemMutation($id: ID!) {
    deleteServiceOptionItem(id: $id) {
      deletedId
    }
  }
`
