import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { SidebarNav } from './SidebarNav'
import { SidebarUserInfo } from './SidebarUserInfo'
import CompanyContext from 'context/CompanyContext'
import Image from 'components/Image'
import { SidebarGraph } from 'store/sidebar'
import { Icon } from '@ur/react-components'
import { ZIndexRange } from 'types/enums'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  display: grid;
  position: relative;
  z-index: ${ZIndexRange.Sidebar};
  background-color: ${props => props.theme.colors.darkBlue};
  width: 100%;
  min-height: 100vh;
  padding: 22px 14px 0 14px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 72px 60px auto;
  grid-template-areas:
    'header header'
    'userInfo userInfo'
    'navitems navitems';
  color: white;

  ${props => props.theme.media.mobile} {
    position: fixed;
    ${ZIndexRange.Sidebar};
    grid-template-rows: 60px 0px auto;
    left: 0;
    width: 100%;
    overflow: auto;
  }
`

const SidebarHeader = styled.div`
  grid-area: header;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;

  ${props => props.theme.media.mobile} {
    padding-left: 0px;
  }
`

const SiderbarMinimize = styled.div`
  grid-area: minimize;
  display: none;
  align-items: center;
  justify-content: center;

  ${props => props.theme.media.mobile} {
    display: flex;
  }
`

const SidebarNavWrapper = styled.div`
  grid-area: navitems;
`

const SidebarUserInfoWrapper = styled.div`
  grid-area: userInfo;
  display: flex;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`
const Sidebar: React.UFC = () => {
  const [{ toggle, open }] = SidebarGraph.useGraph()
  const history = useHistory()

  if (!open && isMobile) return null

  function handleLogoClick() {
    history.push(`/dashboard`)
    isMobile && toggle()
  }

  return (
    <Wrapper>
      <CompanyContext.Consumer>
        {company => (
          <React.Fragment>
            {company.companyLogo && open && (
              <SidebarHeader>
                <Image
                  maxWidth="160px"
                  maxHeight="40px"
                  filter="brightness(0) invert(1)"
                  src={company.companyLogo}
                  style={{ cursor: 'pointer' }}
                  onClick={handleLogoClick}
                />

                <SiderbarMinimize onClick={() => toggle()}>
                  <Icon icon="long-arrow-left" size="40px" type="light" />
                </SiderbarMinimize>
              </SidebarHeader>
            )}

            <SidebarUserInfoWrapper>
              <SidebarUserInfo />
            </SidebarUserInfoWrapper>

            <SidebarNavWrapper>
              <SidebarNav onClick={isMobile ? () => toggle() : undefined} />
            </SidebarNavWrapper>
          </React.Fragment>
        )}
      </CompanyContext.Consumer>
    </Wrapper>
  )
}

export const StyledSidebar = styled(Sidebar)`
  background-color: ${props => props.theme.colors.darkBlue};
  color: white;
  height: 100%;
`
