export function validateField(
  formData,
  key,
  validationFunc,
  errorMessage = 'Invalid value'
) {
  const dataPoint = formData[key]

  if (!validationFunc(dataPoint, formData)) {
    return errorMessage
  }

  return null
}

export function notEmptyValidation(x) {
  return !!x
}

export function passwordValidation(x: string) {
  return x.length > 6 && !!x.match(/[0-9]/)
}
