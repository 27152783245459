import React from 'react'
import styled from 'styled-components'
import { AddRoomTemplateActionResultsRow } from './AddRoomTemplateActionResultsRow'

const Wrapper = styled.div`
  width: 100%;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-areas: 'name count price total';
  grid-template-columns: 200px 130px 130px 130px;
  margin-bottom: 15px;
  padding-bottom: 30px;
  width: 640px;
  border-bottom: 3px solid ${props => props.theme.colors.background};

  ${props => props.theme.media.mobile} {
    display: none;
  }
`

interface HeaderCellProps {
  gridArea: string
}

const HeaderCell = styled.div<HeaderCellProps>`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.gray3};

  grid-area: ${props => props.gridArea};
`

export default function AddRoomTemplateActionResults({ articles }) {
  return (
    <Wrapper>
      <TableHeader>
        <HeaderCell gridArea="name">Navn</HeaderCell>
        <HeaderCell gridArea="count">Antall</HeaderCell>
        <HeaderCell gridArea="price">Pris</HeaderCell>
        <HeaderCell gridArea="total">Sum</HeaderCell>
      </TableHeader>
      <React.Fragment>
        {articles.length !== 0 ? (
          articles.map(article => {
            return <AddRoomTemplateActionResultsRow article={article} />
          })
        ) : (
          <span>Ingen varer blir lagt til</span>
        )}
      </React.Fragment>
    </Wrapper>
  )
}
