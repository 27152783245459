import Checkbox from 'components/Checkbox'
import xor from 'lodash/xor'
import React from 'react'
import styled from 'styled-components'
import { UserNode } from 'types/graphql/users'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  align-items: flex-start;
  overflow-y: scroll;
  max-height: 400px;
  background-color: ${props => props.theme.colors.white};
`

interface UsersFilterListProps {
  users: Pick<UserNode, 'id' | 'fullName'>[]
  createdByIds: string[]
  setCreatedByIdsCallback: React.Dispatch<React.SetStateAction<string[]>>
}

export const UsersFilterList: React.FC<UsersFilterListProps> = ({
  users,
  createdByIds,
  setCreatedByIdsCallback,
}) => {
  const handleToggle = (id: string) => {
    setCreatedByIdsCallback(v => xor(v, [id]))
  }

  return (
    <Wrapper>
      {users.map(user => (
        <Checkbox
          key={user.id}
          value={createdByIds.includes(user.id)}
          label={user.fullName}
          onChange={() => handleToggle(user.id)}
          filterLabel={true}
        />
      ))}
    </Wrapper>
  )
}
