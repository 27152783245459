import Center from 'components/Center'
import Margin from 'components/Margin'
import UserContext from 'context/UserContext'
import PropTypes from 'prop-types'
import React from 'react'
import { match, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  font-size: 24px;
  color: ${props => props.theme.colors.red};
`

interface SuperuserPermissionsRequiredRouteProps extends RouteProps {
  computedMatch: match<{ [param: string]: string }>
  alsoMe?: string
  component: React.FC<any>
  render?: (props: RouteComponentProps<any, any, any>) => any
}
const SuperuserPermissionsRequiredRoute: React.FC<SuperuserPermissionsRequiredRouteProps> = ({
  component: Component,
  render,
  computedMatch,
  ...rest
}) => {
  return (
    <UserContext.Consumer>
      {user => {
        if (!user.isSuperuser) {
          return (
            <Margin top="20em">
              <Center>
                <ErrorMessage>
                  Du har dessverre ikke tillatelse til å se dette.
                </ErrorMessage>
              </Center>
            </Margin>
          )
        }

        return (
          <Route
            {...rest}
            render={props =>
              render ? render(props) : <Component {...props} />
            }
          />
        )
      }}
    </UserContext.Consumer>
  )
}

SuperuserPermissionsRequiredRoute.propTypes = {
  component: PropTypes.any,
}

SuperuserPermissionsRequiredRoute.defaultProps = {}

export default SuperuserPermissionsRequiredRoute
