import styled from 'styled-components'
import { DiffStates } from './types'

interface ArticleNameCellProps {
  deleted: boolean
}

const ArticleNameCell = styled.div<ArticleNameCellProps>`
  width: 300px;
  font-size: 16px;

  ${props => props.theme.media.mobile} {
    width: 200px;
  }

  color: ${props =>
    props.deleted ? props.theme.colors.danger : props.theme.colors.darkBlue};
  text-decoration: ${props => (props.deleted ? 'line-through' : 'none')};
`

interface ArticlePriceState {
  state: Omit<DiffStates, 'deleted'>
}

interface ArticleCountState {
  state: DiffStates
}

const ArticlePriceCell = styled.div<ArticlePriceState>`
  width: 300px;
  font-size: 16px;

  ${props => props.theme.media.mobile} {
    width: 200px;
  }
  color: ${props =>
    props.state === 'increased'
      ? props.theme.colors.success
      : props.state === 'decreased'
      ? props.theme.colors.danger
      : props.theme.colors.darkBlue};
`

const ArticleCountCell = styled.div<ArticleCountState>`
  width: 300px;
  font-size: 16px;

  ${props => props.theme.media.mobile} {
    width: 200px;
  }

  color: ${props =>
    props.state === 'increased'
      ? props.theme.colors.success
      : props.state === 'decreased' || props.state === 'deleted'
      ? props.theme.colors.danger
      : props.theme.colors.darkBlue};

  text-decoration: ${props =>
    props.state === 'deleted' ? 'line-through' : 'none'};
`

const DetailsContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.background};

  > :last-child {
    width: 80px;
    text-align: left;
  }
`

interface ArticleDiff {
  name: string
  count: number
  countDiff: number
  countState: DiffStates
  priceState: Omit<DiffStates, 'deleted'>
  price: number
  priceDiff: number
}

interface RoomArticleRowDiffProps {
  article: ArticleDiff
  showArticlePrices: boolean
}
export const RoomArticleRowDiff: React.FC<RoomArticleRowDiffProps> = ({
  article,
  showArticlePrices,
}) => {
  const countDiffSign = article.countDiff <= 0 ? '' : '+'
  const priceDiffSign = article.priceDiff <= 0 ? '' : '+'
  return (
    <DetailsContentRow>
      <ArticleNameCell deleted={article.count === 0}>
        {article.name}
      </ArticleNameCell>
      {showArticlePrices && (
        <ArticlePriceCell state={article.priceState}>
          {article.price} ({priceDiffSign}
          {article.priceDiff}),- NOK
        </ArticlePriceCell>
      )}
      <ArticleCountCell state={article.countState}>
        {article.countState === 'deleted'
          ? -article.countDiff
          : article.countState === 'unaltered'
          ? article.count
          : `${article.count} (${countDiffSign}${article.countDiff})`}
      </ArticleCountCell>
    </DetailsContentRow>
  )
}
